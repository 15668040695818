import styled from "styled-components";
import { Button, Control, Input } from "ratel-ui";

export const Container = styled.div`
  width: 408px;
  padding: 56px 24px;
  text-align: center;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0px 2px 3px rgba(0,0,0,0.3);

  @media (max-width: 767px) {
    width: 298px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 8px;
  color: #FD7138;
  font-size: 1.5rem;
  font-weight: 700;
`;

export const Subtitle = styled.span`
  display: block;
  margin-bottom: 25px;
  color: #BBBBBB;
`;

export const RequiredTag = styled.div`
  float: right;
  display: block;
  width: fit-content;
  margin-bottom: 16px;
  padding: 2px 12px;
  font-size: 0.625rem;
  color: #999999;
  border-radius: 16px;
  background-color: #DDDDDD;

  > span {
    color: #DB001B;
  }
`;

export const Form = styled.form`
  padding-top: 16px;
`;

export const InputBox = styled(Control)`
  margin-bottom: 16px;
`;

export const InputLabel = styled.span`
  display: inline-block;
  margin-bottom: 8px;
  margin-left: 16px;
  color: #999999;
  font-size: 0.875rem;

  > strong {
    color: #DB001B;
  }
`;

export const CustomInput = styled(Input)`
  border-radius: 16px;
  border: 1px solid #e3e3e3;
  box-shadow: none;

  &:hover {
    border: 1px solid #e3e3e3;
    box-shadow: none;
  }

  &:focus {
    border: 1px solid #003558;
    box-shadow: none;
  }

  &.is-danger {
    border: 1px solid #db001b;
  }
`;

export const Select = styled.select`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 2.25rem;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
  line-height: 1.5;
  font-size: 1rem;
  color: #999999;
  border-radius: 16px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
`;

export const FormButton = styled(Button)`
  margin-top: 40px;

  @media (max-width: 767px) {
    padding: 0 28px;
  }
`;
