import React, { useEffect } from 'react';
import { Toast } from 'ratel-ui';
import { Router, Route, Redirect } from 'react-router';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import lightTheme from './themes/light';
import Landing from './landing/landing';
import 'ratel-ui/index.css';
import Contexts from './core/context';
import Redirector from './enemSimulation/containers/list';
import Zoing from './enemSimulation/containers/details';
import Lives from './landing';
import SubscribeConfirmation from './landing/subscribeConfirmation';
import InitApm from './core/apm';

const history = createBrowserHistory();

export default function Root() {

  useEffect(() => {
    InitApm();
  }, []);

  return (
    <Contexts>
      <ThemeProvider theme={lightTheme}>
        <Router history={history}>
          {/* <Route exact path="/lives" component={Lives} /> */}
          <Route exact path="/simulado" component={Redirector}/>
          <Route exact path="/simulado/:slug" component={Zoing}/>
          <Route exact path="/" component={Lives} />
          {/*<Route exact path="/inscricao-confirmada" component={SubscribeConfirmation} />*/}
        </Router>
        <Toast.box/>
      </ThemeProvider>
    </Contexts>
  );
}
