// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class CircleWrong extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Erro</title>
        <desc>Representação de um x no meio de um círculo</desc>
        <g id="icon-wrong">
          <path d="M16,8A8,8,0,1,1,8,0,8,8,0,0,1,16,8ZM9.41,8l1.8-1.79A1,1,0,1,0,9.79,4.79L8,6.59,6.21,4.79A1,1,0,0,0,4.79,6.21L6.59,8,4.79,9.79a1,1,0,0,0,1.42,1.42L8,9.41l1.79,1.8a1,1,0,0,0,1.42-1.42Z" />
        </g>
      </svg>
    );
  }
}

export default CircleWrong;
