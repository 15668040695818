import React, { PureComponent } from "react";
import classNames from "classnames";

class NewTab extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Abrir em nova aba</title>
        <desc>Ícone de uma seta saindo de um quadrado</desc>
        <g id="icon-tab">
          <path d="M8.29,7.71a1,1,0,0,0,1.42,0L14,3.43,14,5a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V1a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1h0a1,1,0,0,0,1,1l1.57,0L8.29,6.29A1,1,0,0,0,8.29,7.71Z" />
          <path d="M15,8a1,1,0,0,0-1,1v5H2V2H7A1,1,0,0,0,7,0H1A1,1,0,0,0,0,1V15a1,1,0,0,0,1,1H15a1,1,0,0,0,1-1V9A1,1,0,0,0,15,8Z" />
        </g>
      </svg>
    );
  }
}

export default NewTab;
