// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Pending extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title> Pendente</title>
        <desc>Círculo incompleto indicando pendência</desc>
        <g id="icon-pending">
          <g style={{ opacity: 0.2 }}>
            <path d="M63.91,34.29A32,32,0,0,1,0,33a1,1,0,0,1,1-1H1a1,1,0,0,1,1,1A30,30,0,1,0,32,2,29.67,29.67,0,0,0,15,7.3a1,1,0,0,1-1.38-.22h0a1,1,0,0,1,.22-1.42A32,32,0,0,1,63.91,34.29Z" />
          </g>
          <path d="M29.71,63.92A32,32,0,0,1,31,0a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1A30,30,0,1,0,56.71,15a1,1,0,0,1,.22-1.37h0a1,1,0,0,1,1.42.22A32,32,0,0,1,29.71,63.92Z" />
        </g>
      </svg>
    );
  }
}

export default Pending;
