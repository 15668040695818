// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Search extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone lupa</title>
        <desc>Ícone de uma lupa para buscar conteúdo</desc>
        <path d="M15.71,15.71h0a1,1,0,0,1-1.42,0l-4-4a6.51,6.51,0,1,1,1.42-1.42l4,4A1,1,0,0,1,15.71,15.71ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.49,4.49,0,0,0,6.5,2Z" />
      </svg>
    );
  }
}

export default Search;
