import styled from "styled-components";
import { MediaQueries } from "ratel-ui";

export const Container = styled.div`
  .video-player-simulation-resolution-embed {
    height: 357px;

    ${MediaQueries.mobile`
      height: 144px;
    `}
  }
`;

export const ContainerYoutube = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;

  :after{
    padding-top: 56.25%;
    display:block;
    content: "";
  }

   iframe{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;