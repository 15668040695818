// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class RoundCheckXL extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>Concluído</title>
        <desc>Símbolo de check</desc>
        <g id="icon-check-xl">
          <path d="M32,16A16,16,0,1,1,16,0,16,16,0,0,1,32,16ZM7.29,16l5.83,5.71a1,1,0,0,0,1.42,0l10.17-10a1,1,0,0,0,0-1.42h0a1,1,0,0,0-1.42,0l-9.46,9.3-5.12-5a1,1,0,0,0-1.42,0h0A1,1,0,0,0,7.29,16Z" />
        </g>
      </svg>
    );
  }
}

export default RoundCheckXL;
