// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Notification extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone notificações</title>
        <desc>Ícone de um sino</desc>
        <g id="icon-bell">
          <path d="M10,12a2,2,0,0,1-2,2,2,2,0,0,1-2-2ZM8,0C2,0,2,6,2,6V9a1,1,0,0,1-1,1,1,1,0,0,0,0,2H4c0,4,4,4,4,4s4,0,4-4h3a1,1,0,0,0,0-2,1,1,0,0,1-1-1V6S14,0,8,0Zm4,6V9a1,1,0,0,0,1,1H3A1,1,0,0,0,4,9V6S4,2,8,2,12,6,12,6Z" />
        </g>
      </svg>
    );
  }
}

export default Notification;
