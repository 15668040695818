// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Block extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Bloqueio</title>
        <desc>Círculo com um traço diagonal indicando bloqueio</desc>
        <g id="icon-block">
          <path d="M32,0A32,32,0,1,0,64,32,32,32,0,0,0,32,0Zm0,2a29.91,29.91,0,0,1,20.73,8.34L9.87,52.23A30,30,0,0,1,32,2Zm0,60a29.91,29.91,0,0,1-20.73-8.34L54.13,11.77A30,30,0,0,1,32,62Z" />
        </g>
      </svg>
    );
  }
}

export default Block;
