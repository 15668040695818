import { Component } from "react";
import ReactDOM from "react-dom";

class renderModal extends Component {
  el
  modalRoot

  constructor(props) {
    super(props);

    this.el = document.createElement("div");
    this.modalRoot = document.getElementById("modal-root");
  }

  componentDidMount() {
    this.modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);
  }

  render() {
    const modal = this.props.children;
    return ReactDOM.createPortal(modal, this.el);
  }
}

export default renderModal;
