import styled from "styled-components";
import { MediaQueries, Button } from "ratel-ui";
import Icon from "../../../components/icon";

export const Logo = styled.img`
  position: absolute;
  top: 15px;
  right: 35px;
  width: 40px;

  @media (max-width: 767px) {
    top: 16px;
    right: 16px;
    width: 28px;
  }
`;
export const Container = styled.div`
  padding: 48px 80px 0 96px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
  min-height: 100vh;
  text-align: left;
  animation: fadein 0.2s;
  overflow: auto;
  background: #1B3FB5;
  font-family: Roboto;

  .simulate-strong {
    font-weight: 700;
  }

  &.no-border {
    border: 0;
  }

  ${MediaQueries.mobileTablet`
    height: calc(100vh - 112px);

  `}

  @media (min-width: 1920px) {
    padding: 104px;
  }

  @media (max-width: 767px) {
    padding: 16px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 46px;
  font-weight: 700;
  font-size: 2.0625rem;
  color: #ffffff;
  /* color: ${(props) => props.theme.colors.font4}; */
  font-weight: 900;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0;
`;

export const List = styled.div`
  margin-bottom: 32px;
  overflow: auto;

  ${MediaQueries.mobileTablet`
    margin-bottom: 76px;
  `}
  @media (max-width: 414px) {
    margin-bottom: 0;
  }
`;

export const Item = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

export const ItemIcon = styled(Icon)`
  margin-right: 16px;
  fill: ${(props) => props.theme.colors.font6};
`;

export const ItemTitle = styled.span`
  /* margin-bottom: 81px; */
  font-weight: 700;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.titleRules};
`;

export const Text = styled.p`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.font1};
  font-size: 0.875rem;

  strong {
    color: ${(props) => props.theme.colors.font1};
    font-weight: 900;
  }

  ${(props) =>
    props.isRules &&
    `
    display: flex;
    align-items: center;
    margin: 16px 0;
  `};
`;

export const Counter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.colors.transparent};
  text-align: center;

  ${MediaQueries.mobileTablet`
    height: 50px;
    bottom: 50px;
  `}
`;

export const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh - 69px);
  padding: 96px;
  /* border: 1px solid ${(props) => props.theme.colors.font7}; */
  /* border-radius: 16px; */
  background-size: cover;
  background-image: linear-gradient(#ff6a40, #ffb908);
  overflow: hidden;

  @media (max-width: 414px) {
    padding: 64px 32px;
  }
`;

export const BoxTitle = styled.div`
  font-size: 2.0625rem;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  margin-bottom: 36px;
  color: ${(props) => props.theme.colors.clear};

  ${MediaQueries.mobile`
    font-size: 24px;
  `}

  strong {
    color: ${(props) => props.theme.colors.clear};
  }
`;

export const BoxText = styled.div`
  font-size: 1.125rem;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  color: ${(props) => props.theme.colors.clear};

  ${MediaQueries.mobile`
    font-size: 24px;
  `}

  strong {
    color: ${(props) => props.theme.colors.clear};
  }
`;

export const Gif = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
`;

export const Image = styled.img``;

export const Scrollable = styled.div`
  margin-bottom: 100px;
  overflow: auto;

  ${(props) =>
    props.noCounter &&
    `
    margin-bottom: 0;
  `};
`;

export const Footer = styled.div`
  height: 64px;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.transparent};
  width: 100%;
  bottom: 0px;
  position: absolute;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  border-top: 1px solid ${(props) => props.theme.colors.font7};

  ${MediaQueries.mobileTablet`
    height: 50px;
    bottom: 0px;
  `};
`;

export const Wrapper = styled.div``;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-right: 8px;
  min-width: 76px;
  font-size: 1.125rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.bg2};

  > svg {
    fill: ${(props) => props.theme.colors.bg2};
  }
`;

export const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #bbbbbb;

  > svg {
    fill: ${(props) => props.theme.colors.bg2};
  }
`;

export const QuestionBall = styled.div`
  width: 8px;
  height: 8px;
  border: 1px solid ${(props) => props.theme.colors.bg2};
  background-color: transparent;
  border-radius: 50%;

  ${(props) =>
    props.isFilled &&
    `
    margin-right: 4px;
    background-color: ${props.theme.colors.bg2};
  `};
`;

export const BtnStart = styled(Button)`
  width: 216px;
  position: absolute;
  bottom: 16px;
`;
export const ButtonPosition = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
