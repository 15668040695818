import React, { useEffect, useState } from "react";
import moment from "moment";
import { Toast } from "ratel-ui";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getServerTime, useOpenSimuladao } from "../utils";

import {
  doFeedbackRequest,
  doFinishSimulatedRequest,
  doInsertAnswerRequest,
  doOpenSimulatedRequest,
  doRankingRequest,
  doSimulationAverageRequest,
  doSimulationRequest,
  doStartSimulatedRequest,
  doReportRequest,
} from "../index";

import DoSimulated from "./doSimulated";
import LoadingSimulated from "./Loading";
import Result from "../components/Result";
import SimulateStart from "../components/simulateStart";
import SimulatedContainer from "../components/container";
import AnswersRegistered from "../components/answersRegistered";
import { dateFormat, convertToDate } from "../../utils/dateAux";
import * as S from "./styles";

const STEPS = {
  LOADING: -1,
  INFO: 0,
  RUNNING: 1,
  WAITING_FEEDBACK: 2,
  FEEDBACK: 3,
};

const Zoing = (props) => {
  const {
    match,
    history,
    doFeedbackRequest,
    doRankingRequest,
    isFetchingSimulationSetAnswer,
    isFetchingSimulationStart,
    doFinishSimulatedRequest,
    doStartSimulatedRequest,
    doInsertAnswerRequest,
    doSimulationAverageRequest,
    doReportRequest,
    ranking,
    average,
    feedback,
    isFetchingSimulationRanking
  } = props;
  const simulatedSlug = match.params.slug;
  const [user, setUser] = useState(null);
  const [step, setStep] = useState(-1);
  const [isLoadingSomething, setLoadingSomething] = useState(false);
  const [
    simulated,
    setSimulated,
    simulatedHistory,
    setSimulatedHistory,
    isLoading,
  ] = useOpenSimuladao(simulatedSlug, history);

  //Cria um contador com data final no dia seguinte, pra termos a data do servidor atualizada
  const [serverTime, setServerTime] = useState(null);
  const [beforeTimeSimulation, setBeforeTimeSimulation] = useState(false);

  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem("inscrito-simulado-super-med-i-2024"));

    if(!_user) {
      const urlAtual = window.location.href;
      const parametros = new URL(urlAtual).search

      history.push(`/?${parametros}`)
    }

    setUser(_user);
  }, []);

  useEffect(() => {
    const today = dateFormat(new Date());
    const convertedToday = convertToDate(today).valueOf();
    const changeDate = dateFormat(new Date("12/11/2022"));
    const convertedChangeDate = convertToDate(changeDate).valueOf();
    const isClosed = convertedToday < convertedChangeDate;

    if (isClosed) {

    }
  },[]);

  useEffect(() => {
    if (!serverTime) return;

    setBeforeTimeSimulation(
      moment(simulated.endDate).isAfter(new Date(serverTime))
    );
  }, [serverTime]);

  useEffect(() => {
    async function fetchServerTime() {
      if (!simulated) {
        history.push("/simulado");
        return;
      }

      const fServerTime = await getServerTime();
      setServerTime(fServerTime);

      if (!simulated.isStarted) {
        setStep(STEPS.INFO);
        return;
      }

      const isGabaritoReleased = moment(simulated.rankingStartDate).isBefore(
        new Date(fServerTime)
      );
      if (simulatedHistory.endDate && !isGabaritoReleased) {
        setStep(STEPS.WAITING_FEEDBACK);
        return;
      }
      if (simulated.isStarted && simulatedHistory.endDate) {
        const body = {
          simulated: simulated.id,
          user
        };
        Promise.all([
          doFeedbackRequest(body),
        ]).then(() => {
          setStep(STEPS.FEEDBACK);
        });

        return;
      }
      setStep(STEPS.RUNNING);
    }

    if (simulated) {
      fetchServerTime();
    }
  }, [simulated, simulatedHistory]);

  if (step === STEPS.LOADING) {
    return (
      <S.Container>
        <LoadingSimulated />
      </S.Container>
    );
  }

  const doStartSimulation = () => {
    setLoadingSomething(true);
    const today = dateFormat(new Date());
    // YURI
    // AQUI CONFIGURA ATE QUE DATA PODE SER FEITO O SIMULADO
    const convertedToday = convertToDate(today).valueOf();
    const changeDate = dateFormat(new Date(2023, 11, 12, 23, 59, 59));
    const convertedChangeDate = convertToDate(changeDate).valueOf();
    const isOver = convertedToday > convertedChangeDate;

    if (isOver) {
      return Toast.error(
        <div>
          <b>O Simulado Super Med já acabou!</b>
          <br />
          Fique ligado nos próximos eventos do Aprova Total
        </div>
      );
    } else {
      doStartSimulatedRequest(match.params.slug, user).then((response) => {
        const emptyHistory = Object.keys(response.history).length === 0;

        if (emptyHistory) {
          return Toast.error(
            <div>
              <b>Erro ao iniciar simulado!</b>
              <br />
              Por favor, atualize a página.
            </div>
          )
        }

        setSimulated(response.simulated);
        setSimulatedHistory(response.history);
        setLoadingSomething(false);
      }).catch(e => console.log(e));
    }
  };

  const onFeedbackReleased = () => {
    setStep(STEPS.FEEDBACK);
  };

  const getFeedback = () => {
    const { doFeedbackRequest, simulated } = props;
    const body = {
      simulated: simulated.id,
      user
    };
    Promise.all([
      doFeedbackRequest(body),
    ]).then(() => {
      setStep(STEPS.FEEDBACK);
    });
  };

  const doInsertAnswer = (body) => {
    return doInsertAnswerRequest(body).then((response) => {
      if (response.simulated) {
        setSimulatedHistory(response);
      }
      return response;
    });
  };

  const doFinish = (body) => {
    return doFinishSimulatedRequest(body).then((response) => {
      if (response.simulated) {
        setSimulatedHistory(response);
      }
      window.location.reload();
      return response;
    });
  };

  const getRanking = (forced) => {
    const { doRankingRequest, simulated } = props;
    doRankingRequest(simulated.slug).then(() => getFeedback());
    doSimulationAverageRequest(simulated.slug);
  };

  if (step === STEPS.FEEDBACK) {
    return (
      <SimulatedContainer
        user={user}
        name={simulated.name}
      >
        <Result
          user={user}
          history={history}
          ranking={ranking}
          average={average}
          feedback={feedback}
          simulated={simulated}
          slug={simulated.slug}
          completeFeedback={feedback}
          beforeTimeSimulation={beforeTimeSimulation}
        />
      </SimulatedContainer>
    );
  }

  if (step === STEPS.WAITING_FEEDBACK) {
    return (
      <SimulatedContainer user={user} name={simulated.name}>
        <AnswersRegistered
          history={history}
          slug={simulatedSlug}
          simulated={simulated}
          simulatedHistory={simulatedHistory}
          getRanking={getRanking}
          onFeedbackRelease={onFeedbackReleased}
        />
      </SimulatedContainer>
    );
  }

  if (step === STEPS.INFO) {
    return (
      <SimulatedContainer user={user} name={simulated.name}>
        <SimulateStart
          beforeTimeSimulation={beforeTimeSimulation}
          doStartSimulation={doStartSimulation}
          isFetchingSimulationStart={isLoadingSomething}
          loading={isFetchingSimulationStart}
          endDate={simulated.endDate}
          startDate={simulated.startDate}
          currentDate={serverTime}
          rankingStartDate={simulated.rankingStartDate}
          history={history}
        />
      </SimulatedContainer>
    );
  }

  if (step === STEPS.RUNNING) {
    return (
      <SimulatedContainer user={user} name={simulated.name}>
        <DoSimulated
          beforeTimeSimulation={beforeTimeSimulation}
          simulated={simulated}
          time={serverTime}
          doStartSimulatedRequest={doStartSimulatedRequest}
          match={match}
          doFinishSimulatedRequest={doFinish}
          simulatedHistory={simulatedHistory}
          doInsertAnswerRequest={doInsertAnswer}
          isFetchingSimulationSetAnswer={isFetchingSimulationSetAnswer}
          getRanking={getRanking}
          history={history}
          doReportRequest={doReportRequest}
        />
      </SimulatedContainer>
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  return {
    user: state.app.data.user,
    simulated: state.simulation.data.simulation,
    ranking: state.simulation.data.ranking,
    average: state.simulation.data.simulation.average,
    feedback: state.simulation.data.feedback,
    simulatedHistory: state.simulation.data.history,
    isFetchingSimulation: state.simulation.ui.isFetchingSimulation,
    isFetchingSimulationOpen: state.simulation.ui.isFetchingSimulationOpen,
    isFetchingSimulationStart: state.simulation.ui.isFetchingSimulationStart,
    isFetchingSimulationSetAnswer:
      state.simulation.ui.isFetchingSimulationSetAnswer,
    isFetchingSimulationRanking:
      state.simulation.ui.isFetchingSimulationRanking,
    isFetchingSimulationFeedback:
      state.simulation.ui.isFetchingSimulationFeedback,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doStartSimulatedRequest,
      doInsertAnswerRequest,
      doOpenSimulatedRequest,
      doFinishSimulatedRequest,
      doRankingRequest,
      doFeedbackRequest,
      doSimulationRequest,
      doSimulationAverageRequest,
      doReportRequest,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Zoing);
