// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Lock extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 136">
        <title>ico-lock</title>
        <g id="ico-lock">
          <circle style={{ fill: "#eee" }} cx="74" cy="65" r="48" />
          <path d="M109.53,21.79a1.48,1.48,0,0,1-1.48-1.49c0-6-1.93-7.91-7.92-7.91a1.49,1.49,0,1,1,0-3c6,0,7.92-1.93,7.92-7.92a1.49,1.49,0,1,1,3,0c0,6,1.93,7.92,7.92,7.92a1.49,1.49,0,1,1,0,3c-6,0-7.92,1.92-7.92,7.91A1.48,1.48,0,0,1,109.53,21.79ZM107,10.9a6.94,6.94,0,0,1,2.51,2.51A7.1,7.1,0,0,1,112,10.9a7.18,7.18,0,0,1-2.51-2.51A7,7,0,0,1,107,10.9Z" />
          <path d="M28.77,50.23a1.49,1.49,0,0,1-1.48-1.49c0-2-.37-2.32-2.32-2.32a1.49,1.49,0,1,1,0-3c2,0,2.32-.36,2.32-2.31a1.49,1.49,0,1,1,3,0c0,1.95.37,2.31,2.32,2.31a1.49,1.49,0,1,1,0,3c-1.95,0-2.32.37-2.32,2.32A1.49,1.49,0,0,1,28.77,50.23Z" />
          <path
            style={{ fill: "#3171f1" }}
            d="M139.71,79.94A4.29,4.29,0,1,1,144,75.65,4.3,4.3,0,0,1,139.71,79.94Zm0-5.6A1.31,1.31,0,1,0,141,75.65,1.31,1.31,0,0,0,139.71,74.34Z"
          />
          <path
            style={{ fill: "#3171f1" }}
            d="M8,72a1.49,1.49,0,0,1-1.06-.44L.44,65.09A1.49,1.49,0,1,1,2.54,63L9,69.46A1.49,1.49,0,0,1,8,72Z"
          />
          <path
            style={{ fill: "#3171f1" }}
            d="M1.49,72a1.49,1.49,0,0,1-1-.44,1.47,1.47,0,0,1,0-2.1L6.91,63A1.49,1.49,0,1,1,9,65.09L2.54,71.56A1.49,1.49,0,0,1,1.49,72Z"
          />
          <path d="M29.21,135.56a1.48,1.48,0,0,1-.44-1.05v-9.16a1.49,1.49,0,1,1,3,0v9.16a1.48,1.48,0,0,1-2.54,1.05Z" />
          <path d="M24.63,131a1.46,1.46,0,0,1-.44-1,1.49,1.49,0,0,1,1.49-1.49h9.16a1.49,1.49,0,0,1,0,3H25.68A1.48,1.48,0,0,1,24.63,131Z" />
          <path
            style={{ fill: "#000200" }}
            d="M74.19,115.5A50.53,50.53,0,0,1,25.12,53a1.5,1.5,0,1,1,2.91.71,47.51,47.51,0,1,0,68.65-30.6A1.5,1.5,0,0,1,98.1,20.5a50.51,50.51,0,0,1-23.91,95Z"
          />
          <path
            style={{ fill: "#000200" }}
            d="M33.55,39.12a1.54,1.54,0,0,1-.84-.26,1.49,1.49,0,0,1-.4-2.08A50.84,50.84,0,0,1,50.57,20.35,1.5,1.5,0,1,1,52,23,47.87,47.87,0,0,0,34.79,38.46,1.49,1.49,0,0,1,33.55,39.12Z"
          />
          <path
            style={{ fill: "#000200" }}
            d="M92.46,51.58a5,5,0,0,1,5,5V83.63a5,5,0,0,1-5,5H55.06a5,5,0,0,1-5-5v-27a5,5,0,0,1,5-5h37.4m0-3H55.06a8,8,0,0,0-8,8V83.63a8,8,0,0,0,8,8h37.4a8,8,0,0,0,8-8v-27a8,8,0,0,0-8-8Z"
          />
          <path
            style={{ fill: "#000200" }}
            d="M92.25,50.23h-3V31.5c-.22-1.3-2.6-13-15.49-13S58.25,30.5,58,31.88V50.23H55l0-18.72c0-.16,2.78-16,18.74-16S92.21,31,92.23,31.16l0,.22Z"
          />
          <path
            style={{ fill: "#000200" }}
            d="M73.63,77.33a1.5,1.5,0,0,1-1.5-1.5V66.89a1.5,1.5,0,1,1,3,0v8.94A1.5,1.5,0,0,1,73.63,77.33Z"
          />
          <circle style={{ fill: "#000200" }} cx="73.91" cy="65.18" r="4.33" />
        </g>
      </svg>
    );
  }
}

export default Lock;
