import React from 'react'
import ReactDOM from 'react-dom'
import Root from './App'
import { simulationReducer } from './enemSimulation/state/reducer'
import { Provider } from 'react-redux'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from "react-router-redux";
import thunkMiddleware from "redux-thunk";
import { createBrowserHistory } from "history";
import './index.css'
import { appReducer } from './state/reducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory();

const middleware = [
  routerMiddleware(history),
  thunkMiddleware
];


const store = createStore(
  combineReducers({
    simulation: simulationReducer,
    app: appReducer
  }),
  composeEnhancers(
    applyMiddleware(...middleware)
  )
)


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Root/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
