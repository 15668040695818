// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Answers extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Respostas</title>
        <desc>Caixas checadas representando respostas</desc>
        <g id="icon-answers">
          <path d="M12,24.08H4a4,4,0,0,0-4,4V36a4,4,0,0,0,4,4h8a4,4,0,0,0,4-4V28.08A4,4,0,0,0,12,24.08ZM14,36a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V28.08a2,2,0,0,1,2-2h8a2,2,0,0,1,2,2Z" />
          <path d="M10.29,29.29,7,32.59l-1.29-1.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,1.42,0l4-4a1,1,0,0,0-1.42-1.42Z" />
          <path d="M63,34H21a1,1,0,0,0,0,2H63a1,1,0,0,0,0-2Z" />
          <path d="M21,30H47a1,1,0,0,0,0-2H21a1,1,0,0,0,0,2Z" />
          <path d="M12,48.08H4a4,4,0,0,0-4,4V60a4,4,0,0,0,4,4h8a4,4,0,0,0,4-4V52.08A4,4,0,0,0,12,48.08ZM14,60a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V52.08a2,2,0,0,1,2-2h8a2,2,0,0,1,2,2Z" />
          <path d="M10.29,53.29,7,56.59l-1.29-1.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,1.42,0l4-4a1,1,0,0,0-1.42-1.42Z" />
          <path d="M63,58H21a1,1,0,0,0,0,2H63a1,1,0,0,0,0-2Z" />
          <path d="M21,54H47a1,1,0,0,0,0-2H21a1,1,0,0,0,0,2Z" />
          <path d="M10.29,5.21,7,8.5,5.71,7.21a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l2,2a1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,0-1.41A1,1,0,0,0,10.29,5.21Z" />
          <path d="M12,0H4A4,4,0,0,0,0,4v7.92a4,4,0,0,0,4,4h8a4,4,0,0,0,4-4V4A4,4,0,0,0,12,0Zm2,11.92a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V4A2,2,0,0,1,4,2h8a2,2,0,0,1,2,2Z" />
          <path d="M21,11.92H63a1,1,0,0,0,0-2H21a1,1,0,0,0,0,2Z" />
          <path d="M21,5.92H47a1,1,0,0,0,0-2H21a1,1,0,0,0,0,2Z" />
        </g>
      </svg>
    );
  }
}

export default Answers;
