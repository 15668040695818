import moment from "moment";

export const SimulatedGuide = startDate => ({
  dataDoLembreteInicial: new Date(
    moment(startDate)
      .subtract("15", "days")
      .startOf("day")
  ),
  dataDoLembreteDaSemana: new Date(
    moment(startDate)
      .subtract("5", "days")
      .startOf("day")
  ),
  dataQueComecaSimulado: new Date(moment(startDate).startOf("day")),
  dataFinalDoRanking: new Date(
    moment(startDate)
      .add("7", "days")
      .add("10", "hours")
  ),
  simulatedStart: new Date(moment(startDate).subtract("1", "seconds")),
  beforeClosedGateStart: new Date(moment(startDate)),
  beforeClosedGateEnd: new Date(
    moment(startDate)
      .add("30", "minutes")
      .add("59", "seconds")
  ),
  closedGateStart: new Date(moment(startDate).add("31", "minutes")),
  rankingStart: new Date(moment(startDate).add("4", "hours"))
});
