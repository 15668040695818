import React from "react";
import MediaQuery from "react-responsive";

import * as Dimmer from "../../components/dimmer";
import * as S from "./styles";

export default function LoadingSimulated() {
  return (
    <>
      <MediaQuery query="(min-width: 768px)">
        <S.Loading>
          <Dimmer.Text width="86%" />
          <Dimmer.Text width="100%" />
          <Dimmer.Text width="40%" />
          <Dimmer.Text width="40%" />
          <Dimmer.Text width="30%" />
          <Dimmer.Text width="30%" />
          <Dimmer.Text width="100%" />
          <Dimmer.Text width="100%" />
          <Dimmer.Text width="100%" />
          <Dimmer.Text width="100%" />
          <Dimmer.Text width="86%" />
          <Dimmer.Text width="40%" />
          <Dimmer.Text width="30%" />
          <Dimmer.Text width="30%" />
          <Dimmer.Text width="30%" />
        </S.Loading>
      </MediaQuery>
      <MediaQuery query="(max-width: 767px)">
        <S.Loading>
          <Dimmer.Text width="86%" />
          <Dimmer.Text width="100%" />
          <Dimmer.Text width="40%" />
          <Dimmer.Text width="40%" />
          <Dimmer.Text width="100%" />
          <Dimmer.Text width="100%" />
          <Dimmer.Text width="40%" />
          <Dimmer.Text width="40%" />
          <Dimmer.Text width="100%" />
          <Dimmer.Text width="100%" />
          <Dimmer.Text width="86%" />
          <Dimmer.Text width="40%" />
        </S.Loading>
      </MediaQuery>
    </>
  );
}
