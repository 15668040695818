import React, { Component } from "react";
import * as S from "./styles";

class PrintFeedback extends Component {
  render() {
    const { feedback = [] } = this.props;
    const isDarkMode = localStorage.getItem("isDarkMode") === "true";

    const mathFeedback = feedback
      .filter((it) => it.area === "5b2186ec9c5aa43084ff7d1c")
      .sort((a, b) => a.order - b.order);
    const restFeedback = feedback
      .filter((it) => it.area !== "5b2186ec9c5aa43084ff7d1c")
      .sort((a, b) => a.order - b.order);
    const completeFeedback = [...restFeedback, ...mathFeedback];

    return (
      <S.Container>
        <div className="print-list-simulated">
          <div className="print-list-simulated-feedback">
            <div className="print-list-simulated-feedback-title">GABARITO</div>

            <div className="print-list-simulated-feedback-container">
              <div className="print-list-simulated-feedback-container-left">
                <table className="print-list-simulated-feedback-table">
                  <thead>
                    <tr className="print-list-simulated-feedback-table-header">
                      <th className="print-list-simulated-feedback-table-th">
                        Questão
                      </th>
                      <th className="print-list-simulated-feedback-table-th">
                        Sua resposta
                      </th>
                      <th className="print-list-simulated-feedback-table-th">
                        Gabarito
                      </th>
                    </tr>
                  </thead>
                  <tbody className="print-list-simulated-feedback-table-body">
                    {completeFeedback
                      .slice(0, 23)
                      .map(({ correctLetter, myAnswer }, index) => (
                        <S.TableRow
                          isDarkMode={isDarkMode}
                          className="print-list-simulated-feedback-table-item"
                        >
                          <td className="print-list-simulated-feedback-table-td number">
                            {index + 1}
                          </td>
                          <td className="print-list-simulated-feedback-table-td">
                            {myAnswer}
                          </td>
                          <td className="print-list-simulated-feedback-table-td">
                            {correctLetter}
                          </td>
                        </S.TableRow>
                      ))}
                  </tbody>
                </table>
              </div>
              {/* <div className="print-list-simulated-feedback-container-right">
                <table className="print-list-simulated-feedback-table">
                  <thead>
                    <tr className="print-list-simulated-feedback-table-header">
                      <th className="print-list-simulated-feedback-table-th modal-view">Questão</th>
                      <th className="print-list-simulated-feedback-table-th modal-view">Sua resposta</th>
                      <th className="print-list-simulated-feedback-table-th modal-view">Gabarito</th>
                    </tr>
                  </thead>
                  <tbody className="print-list-simulated-feedback-table-body">
                    {completeFeedback.slice(23, 45).map(({ correctLetter, myAnswer }, index) => (
                      <S.TableRow isDarkMode={isDarkMode} className="print-list-simulated-feedback-table-item">
                        <td className="print-list-simulated-feedback-table-td number">{index + 24}</td>
                        <td className="print-list-simulated-feedback-table-td">{myAnswer}</td>
                        <td className="print-list-simulated-feedback-table-td">{correctLetter}</td>
                      </S.TableRow>
                    ))}
                  </tbody>
                </table>
              </div> */}
            </div>
            {/* <div className="print-list-simulated-feedback-container">
              <div className="print-list-simulated-feedback-container-left">
                <table className="print-list-simulated-feedback-table">
                  <thead>
                    <tr className="print-list-simulated-feedback-table-header">
                      <th className="print-list-simulated-feedback-table-th">
                        Questão
                      </th>
                      <th className="print-list-simulated-feedback-table-th">
                        Sua resposta
                      </th>
                      <th className="print-list-simulated-feedback-table-th">
                        Gabarito
                      </th>
                    </tr>
                  </thead>
                  <tbody className="print-list-simulated-feedback-table-body">
                    {completeFeedback
                      .slice(45, 68)
                      .map(({ correctLetter, myAnswer }, index) => (
                        <S.TableRow
                          isDarkMode={isDarkMode}
                          className="print-list-simulated-feedback-table-item"
                        >
                          <td className="print-list-simulated-feedback-table-td number">
                            {index + 46}
                          </td>
                          <td className="print-list-simulated-feedback-table-td">
                            {myAnswer}
                          </td>
                          <td className="print-list-simulated-feedback-table-td">
                            {correctLetter}
                          </td>
                        </S.TableRow>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="print-list-simulated-feedback-container-right">
                <table className="print-list-simulated-feedback-table">
                  <thead>
                    <tr className="print-list-simulated-feedback-table-header">
                      <th className="print-list-simulated-feedback-table-th modal-view">
                        Questão
                      </th>
                      <th className="print-list-simulated-feedback-table-th modal-view">
                        Sua resposta
                      </th>
                      <th className="print-list-simulated-feedback-table-th modal-view">
                        Gabarito
                      </th>
                    </tr>
                  </thead>
                  <tbody className="print-list-simulated-feedback-table-body">
                    {completeFeedback
                      .slice(68, 90)
                      .map(({ correctLetter, myAnswer }, index) => (
                        <S.TableRow
                          isDarkMode={isDarkMode}
                          className="print-list-simulated-feedback-table-item"
                        >
                          <td className="print-list-simulated-feedback-table-td number">
                            {index + 69}
                          </td>
                          <td className="print-list-simulated-feedback-table-td">
                            {myAnswer}
                          </td>
                          <td className="print-list-simulated-feedback-table-td">
                            {correctLetter}
                          </td>
                        </S.TableRow>
                      ))}
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </S.Container>
    );
  }
}

export default PrintFeedback;
