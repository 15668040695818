// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class CircleCheckTrace extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>Informações</title>
        <desc>Símbolo de check com um círculo em volta</desc>
        <g id="icon-check-stroke">
          <path d="M24,10a1,1,0,0,0-.71.29l-9.46,9.3-5.12-5A1,1,0,0,0,7.31,16l5.83,5.71a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l10.17-10a1,1,0,0,0,0-1.42A1,1,0,0,0,24,10Z" />
          <path d="M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm0,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30Z" />
        </g>
      </svg>
    );
  }
}

export default CircleCheckTrace;
