// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class CircleWrongTraceXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Erro</title>
        <desc>Representação de um x no meio de um círculo</desc>
        <g id="icon-wrong-xl">
          <path d="M32,0A32,32,0,1,0,64,32,32,32,0,0,0,32,0Zm0,62A30,30,0,1,1,62,32,30,30,0,0,1,32,62Z" />
          <path d="M43.71,20.29a1,1,0,0,0-1.42,0L32,30.59,21.71,20.29a1,1,0,0,0-1.42,1.42L30.59,32,20.29,42.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L32,33.41l10.29,10.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L33.41,32l10.3-10.29A1,1,0,0,0,43.71,20.29Z" />
        </g>
      </svg>
    );
  }
}

export default CircleWrongTraceXl;
