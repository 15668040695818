// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Medal extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Jubicalouros</title>
        <desc>Representação de uma medalha</desc>
        <g id="icon-medal">
          <path d="M14.13,9.81l0-1.88L15,6.5,14.08,5l0-1.7-1.55-.84L11.5.84h-2L7.93,0,6.34.84H4.5L3.61,2.27l-1.74.92,0,1.88L1,6.5,1.92,8l0,1.7,1.55.84.78,1.26L3,14l2.15.28L6.46,16,8,13.34,9.54,16l1.31-1.72L13,14l-1.27-2.21.66-1.07ZM11.46,9,11,9.21l-.28.46-.31.49H9.17l-.44.23-.65.34-.62-.33L7,10.16H5.61l-.42-.68L4.91,9l-.47-.26-.51-.28V7.41l-.29-.47L3.35,6.5l.24-.38.3-.5V4.39L4.54,4,5,3.79l.28-.46.31-.49H6.83l.44-.23.65-.34.62.33L9,2.84h1.41l.42.68.28.45.47.26.51.28V5.59l.29.47.27.44-.24.38-.3.5V8.61Z" />
        </g>
      </svg>
    );
  }
}

export default Medal;
