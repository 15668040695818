import React, { PureComponent } from 'react'

class Wpp extends PureComponent {
  render() {
    const {height, width} = this.props

    return (
      <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.966 28.109">
        <title>WhatsApp</title>
        <path d="M.057,28.109l2.116-7.218A13.2,13.2,0,0,1,.182,13.927C.186,6.248,6.879,0,15.1,0A15.375,15.375,0,0,1,25.658,4.085a13.4,13.4,0,0,1,4.366,9.855c0,7.68-6.7,13.928-14.92,13.928a15.776,15.776,0,0,1-7.136-1.7L.057,28.109Zm8.276-4.459A13.164,13.164,0,0,0,15.1,25.515c6.835,0,12.4-5.193,12.406-11.578S21.965,2.354,15.108,2.352C8.268,2.352,2.7,7.545,2.7,13.928a11.144,11.144,0,0,0,2.19,6.6L3.639,24.8l4.695-1.149Zm14.286-6.4c-.093-.145-.341-.232-.715-.406s-2.206-1.017-2.548-1.133-.59-.175-.839.175-.964,1.133-1.181,1.364-.435.261-.808.087a10.352,10.352,0,0,1-3-1.728A10.617,10.617,0,0,1,11.456,13.2c-.217-.348-.023-.536.163-.71.168-.156.373-.406.56-.61a2.26,2.26,0,0,0,.376-.58.6.6,0,0,0-.031-.61c-.094-.173-.839-1.887-1.149-2.584-.3-.678-.611-.587-.839-.6L9.82,7.5a1.411,1.411,0,0,0-.994.436,3.8,3.8,0,0,0-1.3,2.9,6.549,6.549,0,0,0,1.522,3.6,16.228,16.228,0,0,0,6.368,5.255c.889.358,1.585.573,2.125.733a5.476,5.476,0,0,0,2.349.138A3.847,3.847,0,0,0,22.4,18.907a2.721,2.721,0,0,0,.217-1.656Z" transform="translate(-0.057)"/>
      </svg>
    )
  }
}

export default Wpp
