// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class downloadXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Download</title>
        <desc>Ícone de uma seta para baixo indicando download</desc>
        <g id="icon-download-xl">
          <path d="M63,62H1a1,1,0,0,0,0,2H63a1,1,0,0,0,0-2Z" />
          <path d="M31.26,56.71a1,1,0,0,0,1.42,0l20-20a1,1,0,0,0-1.41-1.41L33,53.56V1a1,1,0,0,0-2,0V53.62L12.69,35.31a1,1,0,0,0-1.41,1.41Z" />
        </g>
      </svg>
    );
  }
}

export default downloadXl;
