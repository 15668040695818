// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class FlowerThree extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 80">
        <title>animacao</title>
        <g id="flowerThree">
          <path d="M28.78,22.35a8.44,8.44,0,0,0-1.54.67.93.93,0,0,0-.28-.7c1-1.68,2.71-5,2.24-8.14-.64-4.32-5.33-7.84-5.52-8l-.81-.6-.59.8c-.15.2-3.61,4.93-3,9.25.61,4.08,4.83,7.45,5.45,7.92q-3.12,5.3-5.53,10a11.23,11.23,0,0,0-1.14-6.08c-2.16-3.79-7.81-5.37-8-5.43l-1-.26-.26,1c-.07.24-1.58,5.9.59,9.69,1.91,3.35,6.54,5,7.75,5.34a114,114,0,0,0-5.52,13.89c.23-2,.24-4.94-1-7.19-2.17-3.79-7.81-5.37-8-5.43l-1-.26-.26,1c-.06.24-1.57,5.9.59,9.69S9.72,54.84,10,54.91l.68.18C6.12,71.69,9.88,79,10.13,79.48A1,1,0,0,0,11,80a1.07,1.07,0,0,0,.48-.12,1,1,0,0,0,.4-1.36c-.06-.12-4.85-9.72,3.76-32a16.88,16.88,0,0,0,6.62,1.63,8.57,8.57,0,0,0,2.47-.34c4.17-1.29,6.94-6.46,7.05-6.68l.47-.88-.89-.47c-.21-.12-5.41-2.82-9.58-1.53a9.68,9.68,0,0,0-3.61,2.22c1.36-3,2.95-6.25,4.81-9.67a16.57,16.57,0,0,0,6.28,1.48,8.29,8.29,0,0,0,2.47-.35c4.17-1.29,6.94-6.45,7.05-6.67l.47-.89-.89-.46C38.15,23.76,33,21.06,28.78,22.35ZM11.15,31.49c-1.27-2.22-.92-5.49-.62-7.14,1.57.58,4.55,1.93,5.83,4.16s.91,5.48.62,7.14C15.41,35.06,12.42,33.72,11.15,31.49ZM9.48,52.65c-1.57-.59-4.56-1.93-5.83-4.16S2.73,43,3,41.35c1.57.58,4.56,1.93,5.83,4.16S9.78,51,9.48,52.65ZM22.37,40.13c2.44-.75,5.55.3,7.1.95-.91,1.41-2.88,4-5.33,4.79s-5.55-.3-7.1-.95C18,43.51,19.92,40.89,22.37,40.13ZM21.29,15.35c-.38-2.53,1.14-5.44,2-6.87,1.25,1.11,3.55,3.45,3.92,6s-1.13,5.44-2,6.87C24,20.23,21.67,17.89,21.29,15.35ZM31.14,30c-2.44.76-5.55-.3-7.1-.94.91-1.41,2.88-4,5.33-4.79s5.55.3,7.1.94C35.56,26.61,33.58,29.24,31.14,30Z" />
        </g>
      </svg>
    );
  }
}

export default FlowerThree;
