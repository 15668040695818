import * as React from "react";
import classNames from "classnames";
import * as S from "./styles";

/*isSmall = width 100px
isMedium = width 200px
isLarge = width 300px
size=*/
const Text = props => (
  <S.DimmerBlock
    className={classNames(props.className)}
    style={{ margin: props.margin, width: props.width, height: props.height }}
    data-width={props.width}>
    {" "}
  </S.DimmerBlock>
);

export default Text;
