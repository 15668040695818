import styled from "styled-components";
import Tooltip from "../../../components/tooltip";

export const Container = styled.div`
  .modal-resolution-simulation {
    &-card {
      position: relative;
      width: 670px;
      border-radius: 8px;
      overflow: hidden;
    }

    &-header {
      padding: 16px;
      align-items: flex-start;
      justify-content: space-between;
      text-align: left;
      background-color: ${props => props.theme.colors.bg1};
    }

    &-title {
      display: block;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: left;
      line-height: 24px;
      color: ${props => props.theme.colors.clear};
    }

    &-subtitle {
      display: inline-block;
      width: 100%;
      font-size: 1rem;
      text-align: left;
      color: ${props => props.theme.colors.clear};
    }

    &-close {
      display: flex;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.15);
    }

    &-icon {
      margin: auto;
      fill: ${props => props.theme.colors.clear};
    }

    &-body {
      width: 100%;
      padding: 16px;
      background-color: ${props => props.theme.colors.transparent};
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-video {
      width: 100%;
      border-radius: 8px;
      background-color: ${props => props.theme.colors.transparent};
      overflow: hidden;

      &-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 16px 16px;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 64px;
      border-top: 1px solid ${props => props.theme.colors.font7};
      background: ${props => props.theme.colors.transparent};

      &-button {
        display: flex;
        flex: 0 0 32px;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin-right: 16px;
        margin-left: 16px;
        border-radius: 50%;
        background: ${props => props.theme.colors.bg1};
        transition: 0.15s all ease;
        cursor: pointer;

        svg {
          fill: ${props => props.theme.colors.clear};
        }

        &:hover {
          opacity: 0.8;
        }

        &.disabled {
          cursor: default;
          background: ${props => props.theme.colors.font7};
        }
      }
    }
  }
`;

export const ReportIcon = styled.div`
  color: ${props => props.theme.colors.font6};
  position: relative;
  font-size: 14px;

  svg {
    fill: ${props => props.theme.colors.font6};
  }
`;

export const Report = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;

  textarea {
    margin-top: 8px;
    height: 56px;
    width: 100%;
    border-radius: 8px;
    padding: 8px;
    outline: none;
  }

  svg {
    margin: 12px 8px 0 0;
    fill: ${props => props.theme.colors.font6};
    cursor: pointer;
  }

  &.active {
    svg {
      fill: ${props => props.theme.colors.live};
    }
  }
`;

export const ReportTooltip = styled(Tooltip)`
  position: absolute;
  top: -150px;
  padding: 16px;
  text-align: right;
  z-index: 15;

  span {
    display: flex;
    width: 100%;
    padding-left: 16px;
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
  }

  button {
    right: 16px;
  }

  &:after {
    content: " ";
    position: absolute;
    top: 145px;
    left: 50px;
    width: 0;
    height: 0;
    margin-left: -35px;
    transform: rotate(-180deg);
  }
`;
