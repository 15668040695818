// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Live extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Lives</title>
        <desc>Ícone de um sinal de play</desc>
        <g id="icon-lives">
          <path d="M14,14H2a2,2,0,0,1-2-2V4A2,2,0,0,1,2,2H14a2,2,0,0,1,2,2v8A2,2,0,0,1,14,14Zm0-3V5a1,1,0,0,0-1-1H3A1,1,0,0,0,2,5v6a1,1,0,0,0,1,1H13A1,1,0,0,0,14,11ZM10,8,7,6v4Z" />
        </g>
      </svg>
    );
  }
}

export default Live;
