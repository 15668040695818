import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: left;
`;

export const Title = styled.h2`
  margin: 0 0 16px;
  color: #1B3FB5;
  font-family: "Roboto", sans-serif;
  font-size: 1.75rem;
  text-align: left;

  > strong {
    color: #1B3FB5;
    font-weight: 900;
  }

  @media (max-width: 767px) {
    font-size: 1.5rem;
    text-align: center;

    > strong {
      display: block;
    }
  }
`;

export const Text = styled.p`
  max-width: 520px;
  margin: 0 0 30px;
  color: #001036;
  font-weight: 400;
  line-height: 27px;
  font-size: 1.125rem;

  a {
    color: #1B3FB5;
    text-decoration: underline;
  }

  @media (max-width: 767px) {
    font-size: 0.875rem;
    max-width: 262px;
    line-height: 19px;
    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    font-size: 1rem;
    max-width: 639px;
    line-height: 22px;
  }
`;
