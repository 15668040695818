import React, { useState } from "react";
import MediaQuery from "react-responsive";

import ModalAnswer from "../ModalAnswer";
import ModalPrintFeedback from "../ModalPrintFeedback";

import Icon from "../../../components/icon";
import RenderModal from "../../../components/renderModal";
import { stripTags } from "../../../components/utils/strings";
import * as S from "./styles";
import { Footer } from "../../../components/footer";

export const Feedback = ({ feedback = [], pdfUrl, isRanking }) => {
  const [modalResolution, setModalResolution] = useState(false);
  const [index, setIndex] = useState(false);
  const [modalAnswers, setModalAnswers] = useState(false);

  const handleModal = (index) => {
    setModalResolution(!modalResolution);
    setIndex(index);
  };

  const handleModalPrint = () => {
    setModalAnswers(!modalAnswers);
  };

  const canceleds = feedback.filter((it) => it.isCanceled).length;
  let corrects = feedback
    .filter((it) => !it.isCanceled)
    .filter((it) => it.hit).length;
  corrects = isRanking ? corrects + canceleds : corrects;
  const incorrects = feedback
    .filter((it) => !it.isCanceled)
    .filter((it) => !it.hit).length;
  const colSpanMobile = window.matchMedia("(max-width: 1023px)").matches
    ? "1"
    : "4";
  const isDarkMode = localStorage.getItem("isDarkMode") === "true";

  const mathFeedback = feedback
    .filter((it) => it.area === "5b2186ec9c5aa43084ff7d1c")
    .sort((a, b) => a.order - b.order);
  const restFeedback = feedback
    .filter((it) => it.area !== "5b2186ec9c5aa43084ff7d1c")
    .sort((a, b) => a.order - b.order);
  const orderedFeedback = [...restFeedback, ...mathFeedback].sort(
    (a, b) => a.order - b.order
  );

  return (
    <>
      <S.Content>
        <S.Header>
          <S.Left>Gabarito</S.Left>
        </S.Header>
        <S.Grid>
          <S.Table cellPadding="16">
            <S.Thead>
              <S.Tr isDarkMode={isDarkMode}>
                <S.Th question scope="col" colSpan={colSpanMobile}>
                  Questão
                </S.Th>
                <S.Th small scope="col">
                  {" "}
                  Sua resposta{" "}
                </S.Th>
                <S.Th small scope="col">
                  {" "}
                  Gabarito{" "}
                </S.Th>
              </S.Tr>
            </S.Thead>

            <S.Tbody>
              {orderedFeedback.map((it, index) => {
                const labelStatement = stripTags(it.text);
                const questionCanceled = isRanking ? it.isCanceled : false;

                return (
                  <S.Tr
                    isLast={index + 1 === feedback.length}
                    isDarkMode={isDarkMode}
                    key={`feedback-${index}`}
                  >
                    <S.Td question colSpan={colSpanMobile}>
                      <S.NumberQuestion questionCanceled={questionCanceled}>
                        {" "}
                        {index + 1}{" "}
                      </S.NumberQuestion>
                      {questionCanceled && (
                        <S.BadgeCanceled>Anulada</S.BadgeCanceled>
                      )}
                      <S.QuestionText questionCanceled={questionCanceled}>
                        {labelStatement.replace(/\\.*\\\)?/, "")}
                      </S.QuestionText>
                    </S.Td>
                    <S.Td questionCanceled={questionCanceled} bold>
                      {it.myAnswer ? it.myAnswer : "-"}
                    </S.Td>
                    <S.Td questionCanceled={questionCanceled} bold>
                      {it.correctLetter}
                    </S.Td>
                    <MediaQuery query="(min-width: 1024px)">
                      <S.Td correct={it.hit}>
                        {!questionCanceled && (
                          <Icon
                            icon={it.hit ? "circleCheck" : "circleWrong"}
                            height={16}
                            width={16}
                          />
                        )}
                      </S.Td>
                    </MediaQuery>
                  </S.Tr>
                );
              })}
            </S.Tbody>
          </S.Table>
        </S.Grid>

        <S.Footer>
          {/* <S.FooterLeft>
            <S.MyAnswerDownload onClick={handleModalPrint}>
              <Icon icon="download" height={16} width={16} />
              Salvar minhas respostas
            </S.MyAnswerDownload>
            {pdfUrl && (
              <a
                title="Simulado ciencias da natureza"
                href={pdfUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <S.Right>
                  <Icon icon="download" height={16} width={16} />
                  Baixar simulado em PDF
                </S.Right>
              </a>
            )}
          </S.FooterLeft> */}
          <S.FooterRight>
            <S.Corrects>
              <Icon icon={"circleCheck"} height={16} width={16} />
              {corrects} ACERTOS
            </S.Corrects>
            <S.Incorrects>
              <Icon icon={"circleWrong"} height={16} width={16} />
              {incorrects} ERROS
            </S.Incorrects>
          </S.FooterRight>
        </S.Footer>

        {/* <S.FooterButtons>
          <S.ButtonLink href="/">ESCOLHER UM NOVO SIMULADO</S.ButtonLink>
          <S.Span>
            Não deixe de acompanhar os próximos{" "}
            <strong>aulões ao vivo no Youtube</strong>
          </S.Span>
          <S.ButtonLink
            href="https://www.youtube.com/c/AprovaTotal"
            target="_blank"
          >
            ACOMPANHAR NO YOUTUBE
            <Icon icon={"youtube"} height={17} width={23} />
          </S.ButtonLink>
        </S.FooterButtons> */}

        {modalResolution && (
          <RenderModal>
            <ModalAnswer
              resolutionIndex={index}
              feedback={orderedFeedback}
              toggleModalAnswer={handleModal}
              feedbackQuestion={orderedFeedback[index]}
            />
          </RenderModal>
        )}

        {modalAnswers && (
          <RenderModal>
            <ModalPrintFeedback
              toggleModalAnswer={handleModalPrint}
              feedback={orderedFeedback}
            />
          </RenderModal>
        )}
      </S.Content>
      {/* <Footer /> */}
    </>
  );
};
