// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Instagram extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>Instagram</title>
        <path d="M12,0C8.74,0,8.33,0,7.05.07A8.76,8.76,0,0,0,4.14.63,6.07,6.07,0,0,0,.63,4.14,8.76,8.76,0,0,0,.07,7.05C0,8.33,0,8.74,0,12S0,15.67.07,17a8.76,8.76,0,0,0,.56,2.91,6.07,6.07,0,0,0,3.51,3.51,8.76,8.76,0,0,0,2.91.56C8.33,24,8.74,24,12,24s3.67,0,4.95-.07a8.76,8.76,0,0,0,2.91-.56,6.07,6.07,0,0,0,3.51-3.51A8.76,8.76,0,0,0,23.93,17C24,15.67,24,15.26,24,12s0-3.67-.07-4.95a8.76,8.76,0,0,0-.56-2.91A6.07,6.07,0,0,0,19.86.63,8.76,8.76,0,0,0,17,.07C15.67,0,15.26,0,12,0Zm0,2.16c3.2,0,3.58,0,4.85.07a6.67,6.67,0,0,1,2.23.42,3.61,3.61,0,0,1,1.38.89,3.61,3.61,0,0,1,.89,1.38,6.67,6.67,0,0,1,.42,2.23c.06,1.27.07,1.65.07,4.85s0,3.58-.07,4.85a6.67,6.67,0,0,1-.42,2.23,3.89,3.89,0,0,1-2.27,2.27,6.67,6.67,0,0,1-2.23.42c-1.27.06-1.65.07-4.85.07s-3.58,0-4.85-.07a6.67,6.67,0,0,1-2.23-.42,3.61,3.61,0,0,1-1.38-.89,3.61,3.61,0,0,1-.89-1.38,6.67,6.67,0,0,1-.42-2.23c-.06-1.27-.07-1.65-.07-4.85s0-3.58.07-4.85a6.67,6.67,0,0,1,.42-2.23,3.61,3.61,0,0,1,.89-1.38,3.61,3.61,0,0,1,1.38-.89,6.67,6.67,0,0,1,2.23-.42C8.42,2.17,8.8,2.16,12,2.16" />
        <path d="M12,16a4,4,0,1,1,4-4,4,4,0,0,1-4,4ZM12,5.84A6.16,6.16,0,1,0,18.16,12,6.16,6.16,0,0,0,12,5.84Z" />
        <path d="M19.85,5.59a1.44,1.44,0,1,1-1.44-1.44,1.44,1.44,0,0,1,1.44,1.44Z" />
      </svg>
    );
  }
}

export default Instagram;
