import React, { PureComponent } from "react";
import classNames from "classnames";

class MedalJubicalouro extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg
        className={classes}
        height={height}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48.11 72.24">
        <title>jubicalouro-medalha</title>
        <g id="icon-pencil">
          <g id="Grupo_600" data-name="Grupo 600">
            <path
              id="Caminho_696"
              data-name="Caminho 696"
              className="cls-1"
              d="M6.28,62.84l6-1.33a1.5,1.5,0,0,1,1.52.54l3.8,4.83a1.52,1.52,0,0,0,2.13.26,1.49,1.49,0,0,0,.49-.68l9.15-25.64a1.52,1.52,0,0,0-.92-1.94h0L15.61,34.29a1.52,1.52,0,0,0-1.94.92L4.53,60.85a1.52,1.52,0,0,0,.92,1.94A1.56,1.56,0,0,0,6.28,62.84Z"
            />
            <path
              id="Caminho_697-2"
              data-name="Caminho 697-2"
              className="cls-1"
              d="M45.37,29l2.43-4.24a1.51,1.51,0,0,0,0-1.51l-2.55-4.45a1.47,1.47,0,0,1-.2-.74l0-5.09a1.52,1.52,0,0,0-.76-1.3L40,9.15a1.55,1.55,0,0,1-.55-.56l-2.86-5a1.54,1.54,0,0,0-1.32-.76H29.55a1.54,1.54,0,0,1-.76-.2L24.5.17A1.51,1.51,0,0,0,23,.17l-4.4,2.49a1.48,1.48,0,0,1-.75.2H12.77a1.54,1.54,0,0,0-1.32.76L9,7.86a1.53,1.53,0,0,1-.57.56L3.55,11.2a1.52,1.52,0,0,0-.77,1.34l.06,5.67a1.61,1.61,0,0,1-.2.77L.2,23.21a1.51,1.51,0,0,0,0,1.51l2.55,4.45a1.49,1.49,0,0,1,.2.74L3,35a1.54,1.54,0,0,0,.76,1.3l4.29,2.49a1.55,1.55,0,0,1,.55.56l2.85,5a1.53,1.53,0,0,0,1.32.76h5.69a1.54,1.54,0,0,1,.76.2l4.29,2.49a1.51,1.51,0,0,0,1.51,0l4.4-2.49a1.47,1.47,0,0,1,.74-.2h5.08a1.51,1.51,0,0,0,1.31-.76L39,40.08a1.64,1.64,0,0,1,.57-.57l4.91-2.77a1.55,1.55,0,0,0,.77-1.34l-.06-5.67A1.48,1.48,0,0,1,45.37,29Z"
            />
            <path
              id="Caminho_696-2"
              data-name="Caminho 696-2"
              className="cls-1"
              d="M43.67,60.85,34.53,35.21a1.53,1.53,0,0,0-1.94-.92L19.73,38.88a1.52,1.52,0,0,0-.92,1.94h0L28,66.46a1.52,1.52,0,0,0,1.94.92,1.59,1.59,0,0,0,.68-.49l3.81-4.84a1.5,1.5,0,0,1,1.52-.54l6,1.33a1.52,1.52,0,0,0,1.82-1.13A1.48,1.48,0,0,0,43.67,60.85Z"
            />
            <path
              id="Caminho_697-2-2"
              data-name="Caminho 697-2-2"
              className="cls-2"
              d="M43.91,28.59l2.23-3.87a1.56,1.56,0,0,0,0-1.51l-2.33-4.07a1.49,1.49,0,0,1-.2-.74l0-4.66a1.52,1.52,0,0,0-.75-1.3L38.9,10.17a1.64,1.64,0,0,1-.55-.56L35.72,5.07a1.51,1.51,0,0,0-1.31-.76H29.2a1.54,1.54,0,0,1-.76-.2L24.52,1.83a1.53,1.53,0,0,0-1.51,0L19,4.11a1.53,1.53,0,0,1-.75.2H13.6a1.51,1.51,0,0,0-1.31.76L10.06,8.94a1.5,1.5,0,0,1-.56.56L5,12.05a1.5,1.5,0,0,0-.77,1.33l0,5.19a1.58,1.58,0,0,1-.2.77L1.87,23.21a1.51,1.51,0,0,0,0,1.51L4.2,28.79a1.47,1.47,0,0,1,.2.74l0,4.66a1.53,1.53,0,0,0,.76,1.3l3.92,2.27a1.55,1.55,0,0,1,.56.55l2.6,4.55a1.55,1.55,0,0,0,1.32.77h5.21a1.36,1.36,0,0,1,.76.2l3.92,2.26a1.48,1.48,0,0,0,1.51,0l4-2.28a1.5,1.5,0,0,1,.75-.2h4.64a1.51,1.51,0,0,0,1.31-.76L37.94,39a1.53,1.53,0,0,1,.57-.56L43,35.89a1.52,1.52,0,0,0,.77-1.33l-.06-5.2A1.58,1.58,0,0,1,43.91,28.59Z"
            />
            <path
              id="Caminho_1057"
              data-name="Caminho 1057"
              className="cls-1"
              d="M25.71,10.72l3.14,6.37a1.91,1.91,0,0,0,1.43,1l7,1a1.91,1.91,0,0,1,1.6,2.16,1.93,1.93,0,0,1-.55,1.09l-5.09,5A1.9,1.9,0,0,0,32.73,29l1.2,7a1.9,1.9,0,0,1-1.55,2.19,2,2,0,0,1-1.21-.19l-6.29-3.31a1.91,1.91,0,0,0-1.77,0l-6.29,3.31a1.89,1.89,0,0,1-2.56-.8,1.86,1.86,0,0,1-.2-1.2l1.21-7a1.9,1.9,0,0,0-.55-1.68l-5.09-5a1.91,1.91,0,0,1,1.06-3.25l7-1a1.91,1.91,0,0,0,1.43-1l3.15-6.37a1.9,1.9,0,0,1,2.54-.87A1.93,1.93,0,0,1,25.71,10.72Z"
            />
          </g>
          <rect className="cls-3" width="48.11" height="72.24" />
        </g>
      </svg>
    );
  }
}

export default MedalJubicalouro;
