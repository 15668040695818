// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class LampXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Lâmpada</title>
        <desc>Representação de uma lâmpada</desc>
        <g id="icon-lamp-xl">
          <path d="M55,23A23,23,0,1,0,24.71,44.8V47H23.49a3.49,3.49,0,0,0-1,6.82A3.43,3.43,0,0,0,22,55.5a3.5,3.5,0,0,0,3.5,3.5h.59A6,6,0,0,0,37.9,59h.59A3.5,3.5,0,0,0,42,55.5a3.43,3.43,0,0,0-.45-1.68,3.49,3.49,0,0,0-1-6.82H39.27V44.8A23.15,23.15,0,0,0,55,23ZM32,62a4,4,0,0,1-3.86-3h7.72A4,4,0,0,1,32,62Zm6.5-5h-13a1.5,1.5,0,1,1,0-3h13a1.5,1.5,0,1,1,0,3ZM42,50.5a1.5,1.5,0,0,1-1.5,1.5h-17a1.5,1.5,0,1,1,0-3h17A1.5,1.5,0,0,1,42,50.5ZM26.71,47V44.07a1,1,0,0,0-.72-1,21,21,0,1,1,12,0,1,1,0,0,0-.72,1V47Z" />
          <path d="M43.25,13.5a1,1,0,0,0,.87.5A1,1,0,0,0,45,12.5,15,15,0,0,0,32,5a1,1,0,1,0,0,2A13.06,13.06,0,0,1,43.25,13.5Z" />
        </g>
      </svg>
    );
  }
}

export default LampXl;
