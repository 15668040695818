import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.h2`
  margin: 0 0 16px;
  color: #1B3FB5;
  font-family: "Roboto", sans-serif;
  font-size: 1.75rem;
  text-align: left;

  > strong {
    color: #1B3FB5;
    font-weight: 900;
  }

  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
`;

export const Text = styled.p`
  color: #001036;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 25px;
  margin: 0 0 30px;
  max-width: 759px;
  
  > strong {
    color: #001036;
    font-weight: 700;
  }

  @media (max-width: 767px) {
    font-size: 0.875rem;
    max-width: 262px;
    line-height: 19px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    font-size: 1rem;
    max-width: 639px;
    line-height: 22px;
  }
`;

export const List = styled.ul`
  max-width: 500px;
  padding-left: 16px;
  text-align: left;
  list-style: disc;
`;

export const Item = styled.li`
  font-family: Nunito;

  > strong {
    color: #001036;
    font-weight: 700;
  }

  @media (max-width: 767px) {
    line-height: 22px;
  }
`;
