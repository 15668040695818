import React from "react";
import { SectionMainB } from "../components/sectionMainB";
import styled from "styled-components";
import { SectionMainD } from '../components/sectionMainD';

export default function Lives({ history }) {

  return (
    <Container>
      <SectionMainD history={history} />
    </Container>
  );

}

const Container = styled.div`
  height: 100%;
  background: #ffffff;
  overflow-x: hidden;
`;
