// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Dislike extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>Não curtir</title>
        <desc>Ícone de uma mão com polegar para baixo</desc>
        <g id="icon-dislike">
          <path d="M0,14a2,2,0,0,0,2,2h8v3c0,3,1,5,3,5a1,1,0,0,0,1-1V19c0-4,4-4,4-4a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2H20a2,2,0,0,0-2,2v.46A9.24,9.24,0,0,0,12,0H5A2,2,0,0,0,5,4H4A2,2,0,0,0,4,8H3a2,2,0,0,0,0,4H2A2,2,0,0,0,0,14Z" />
        </g>
      </svg>
    );
  }
}

export default Dislike;
