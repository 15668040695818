// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class DislikeTrace extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>Ícone não curtir</title>
        <desc>Ícone de mão com polegar para baixo</desc>
        <g id="icon-dislike-trace">
          <path d="M20,2h2a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H20a2,2,0,0,1-2-2h0s-6,4.56-6,9a1,1,0,0,1-1,1c-.75,0-2-2-1-6a2,2,0,0,0-2-2H2a2,2,0,0,1-2-2C0,12.84,3.26,4.86,4.61,1.29A2,2,0,0,1,7,.07L18,4h0A2,2,0,0,1,20,2ZM6,2.59,2,13a1,1,0,0,0,1,1H8c4,0,4,4,4,4,3-6,8-7,8-7v2a1,1,0,0,0,2,0V5a1,1,0,0,0-2,0V7L7.27,2.07A1,1,0,0,0,6,2.59Z" />
        </g>
      </svg>
    );
  }
}

export default DislikeTrace;
