import styled from "styled-components";

export const Container = styled.div`
  .no-user-placeholder-title {
    color: ${props => props.theme.colors.font2};
  }

  .no-user-placeholder-description {
    color: ${props => props.theme.colors.font1};
  }

  .no-user-placeholder-free-text {
    color: ${props => props.theme.colors.font1};
  }
`;
