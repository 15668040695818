import React, { PureComponent } from "react";
import { Button, Toast } from "ratel-ui";

import Icon from "../../../components/icon";
import * as S from "./styles.js";
import { Alternative } from "../alternatives";

export default class simulateQuestion extends PureComponent {
  state = {
    selectedAnswer: "",
    reportToast: false,
    messageReport: "",
    loading: false,
  };

  componentDidMount() {
    const { simulatedHistory, question } = this.props;
    const questionAnswer = simulatedHistory.filter(
      (it) => it.question === question.questionId
    );
    const answer = questionAnswer[0] ? questionAnswer[0].answer : "";
    this.setState({ userAllAnswers: simulatedHistory, selectedAnswer: answer });
  }

  componentDidUpdate(prevProps, prevState) {
    const { simulatedHistory, question, answerError } = this.props;
    if (answerError) {
      const questionAnswer = simulatedHistory.filter(
        (it) => it.question === question.questionId
      );
      const answer = questionAnswer[0] ? questionAnswer[0].answer : "";
      this.setState({
        userAllAnswers: simulatedHistory,
        selectedAnswer: answer,
      });
    }
  }

  changeSelectedAnswer = (alternativeId) => {
    const { question, onChangeAnswer } = this.props;
    this.setState({ selectedAnswer: alternativeId });
    onChangeAnswer(question.questionId, alternativeId);
  };

  sendReport = () => {
    const { messageReport } = this.state;
    this.setState({ loading: true });
    const { question, simulateId, doReportRequest } = this.props;
    const body = {
      question: question,
      message: messageReport,
      simulated: simulateId,
    };
    doReportRequest(body).then((response) => {
      if (response.error) {
        Toast.error("Não foi possível enviar a mensagem, tente novamente!");
      } else {
        Toast.success(
          "Mensagem enviada! Nossa equipe já recebeu e vai avaliar o problema!"
        );
      }
      this.setState({ messageReport: "", reportToast: false, loading: false });
    });
  };
  render() {
    const { selectedAnswer, reportToast, loading } = this.state;
    const {
      question,
      number = 0,
      name,
      endDate,
      beforeTimeSimulation,
      idScroll,
    } = this.props;
    const questionName = name;

    const now = new Date();
    const newEndDate = new Date(endDate);
    const showIconReport = now < newEndDate;
    const isOdd = number % 2 === 0;

    const isCanceled = beforeTimeSimulation ? question.isCanceled : false;
    return (
      <S.Container
        key={questionName}
        id={questionName}
        isOdd={isOdd}
        data-id-selector={idScroll}
      >
        {isCanceled && (
          <S.CanceledQuestion>
            <S.CanceledBorder>
              <span>
                Questão
                <br />
                anulada
              </span>
            </S.CanceledBorder>
          </S.CanceledQuestion>
        )}
        <S.Header>
          <S.Report
            className={`simulate-question-report ${
              reportToast ? "active" : ""
            }`}
          >
            <S.Number className="simulate-question-number">{number}</S.Number>
            {showIconReport && (
              <S.ReportIcon
                id={`report-question-simulation-${number}`}
                onClick={() => {
                  this.setState({ reportToast: !reportToast });
                }}
              >
                <Icon icon={"reportFill"} height={16} width={16} />
                <S.ReportTooltip
                  className={`simulate-question-report-tooltip ${
                    reportToast ? "active" : ""
                  }`}
                  open={reportToast}
                  toggleTooltip={() => {
                    this.setState({ reportToast: !reportToast });
                  }}
                >
                  <span>Qual o problema encontrado?</span>
                  <textarea
                    name="text"
                    id="text"
                    placeholder="Descreva o problema (opcional)"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      this.setState({ messageReport: e.target.value });
                    }}
                    value={this.state.messageReport}
                  />
                  <Button
                    outline
                    transparent
                    loading={loading}
                    onClick={() => this.sendReport()}
                  >
                    ENVIAR
                  </Button>
                </S.ReportTooltip>
              </S.ReportIcon>
            )}
          </S.Report>
          <S.Statement
            className="simulate-question-statement"
            dangerouslySetInnerHTML={{ __html: question.text.replaceAll("{STORAGE_BASE_URL}", "https://storage.aprovatotal.com.br") }}
          />
        </S.Header>
        <S.Wrapper>
          {question.alternatives &&
            question.alternatives
              .filter((a) => a && a.text)
              .map((alternative) => {
                const isSelected = selectedAnswer === alternative.alternativeId;
                return (
                  <Alternative
                    isSelected={isSelected}
                    alternative={alternative}
                    changeSelectedAnswer={this.changeSelectedAnswer}
                  />
                );
              })}
        </S.Wrapper>
      </S.Container>
    );
  }
}
