// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class PhysicsXL extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Física</title>
        <desc>Ícone de moléculas</desc>
        <g id="icon-phy-xl">
          <path d="M54,40a9.9,9.9,0,0,0-6,2l-3.16-3.16a14,14,0,0,0,.68-16.78L46.84,21a10,10,0,1,0-1.24-1.56l-1.32,1.11a14,14,0,0,0-18-2.23L25.2,16.93a9.92,9.92,0,1,0-1.52,1.3l1.07,1.28a14,14,0,0,0-1.52,19.43l-3.71,3.71A12.22,12.22,0,1,0,21,44l3.66-3.66a14,14,0,0,0,18.85-.09l3.06,3.07A10,10,0,1,0,54,40ZM54,6a8,8,0,1,1-8,8A8,8,0,0,1,54,6ZM10,10a8,8,0,1,1,8,8A8,8,0,0,1,10,10Zm2,52A10,10,0,1,1,22,52,10,10,0,0,1,12,62ZM34,42A12,12,0,1,1,46,30,12,12,0,0,1,34,42ZM54,58a8,8,0,1,1,8-8A8,8,0,0,1,54,58Z" />
        </g>
      </svg>
    );
  }
}

export default PhysicsXL;
