// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class MicroHome extends PureComponent<Props> {
  render() {
    const { className, height, width } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 136">
        <title>Ciências da Natureza</title>
        <desc>Imagem de um microscópio simbolizando as ciências da natureza</desc>
        <circle className="cls-1" cx="73" cy="67" r="48" />
        <path d="M32.36,49.9a1.44,1.44,0,0,1-1.45-1.44c0-5.82-1.87-7.69-7.69-7.69a1.45,1.45,0,0,1,0-2.89c5.82,0,7.69-1.87,7.69-7.69a1.45,1.45,0,0,1,2.89,0c0,5.82,1.87,7.69,7.69,7.69a1.45,1.45,0,0,1,0,2.89c-5.82,0-7.69,1.87-7.69,7.69A1.44,1.44,0,0,1,32.36,49.9ZM29.92,39.33a6.85,6.85,0,0,1,2.44,2.43,6.74,6.74,0,0,1,2.43-2.43,6.85,6.85,0,0,1-2.43-2.44A7,7,0,0,1,29.92,39.33Z" />
        <path d="M119.21,10.28a1.45,1.45,0,0,1-1.45-1.45c0-1.89-.36-2.25-2.25-2.25a1.45,1.45,0,0,1,0-2.89c1.89,0,2.25-.35,2.25-2.24a1.45,1.45,0,1,1,2.89,0c0,1.89.36,2.24,2.25,2.24a1.45,1.45,0,1,1,0,2.89c-1.89,0-2.25.36-2.25,2.25A1.45,1.45,0,0,1,119.21,10.28Z" />
        <path d="M26.28,116.59a5.29,5.29,0,1,1,5.29-5.29A5.3,5.3,0,0,1,26.28,116.59Zm0-7.69a2.4,2.4,0,1,0,2.4,2.4A2.4,2.4,0,0,0,26.28,108.9Z" />
        <path
          className="cls-2"
          d="M139.83,76A4.17,4.17,0,1,1,144,71.83,4.17,4.17,0,0,1,139.83,76Zm0-5.45a1.28,1.28,0,1,0,1.28,1.28A1.28,1.28,0,0,0,139.83,70.55Z"
        />
        <path
          className="cls-2"
          d="M7.73,47.19a1.44,1.44,0,0,1-1-.42L.42,40.48a1.45,1.45,0,1,1,2.05-2l6.28,6.28a1.45,1.45,0,0,1-1,2.47Z"
        />
        <path
          className="cls-2"
          d="M1.45,47.19a1.48,1.48,0,0,1-1-.42,1.46,1.46,0,0,1,0-2.05l6.29-6.28a1.44,1.44,0,1,1,2,2L2.47,46.77A1.47,1.47,0,0,1,1.45,47.19Z"
        />
        <path
          className="cls-2"
          d="M82.73,136a1.44,1.44,0,0,1-1.44-1.45v-8a1.45,1.45,0,0,1,2.89,0v8A1.45,1.45,0,0,1,82.73,136Z"
        />
        <path className="cls-2" d="M86.71,132h-8a1.45,1.45,0,0,1,0-2.89h8a1.45,1.45,0,1,1,0,2.89Z" />
        <path d="M77.74,96.72a1.45,1.45,0,0,1-.54-2.79c10.41-4.17,16.25-10.32,17.36-18.28,1.38-9.94-5.06-19.4-6.29-20.4a1.45,1.45,0,0,1,1.4-2.53c1.81.93,8.44,10.79,8,21.24-.33,6.94-4,16.49-19.34,22.66A1.47,1.47,0,0,1,77.74,96.72ZM88.3,55.27l0,0Z" />
        <path d="M92,85.89H52A1.45,1.45,0,0,1,52,83H92a1.45,1.45,0,0,1,0,2.9Z" />
        <path d="M101.49,96.81H46.21a1.45,1.45,0,1,1,0-2.89h55.28a1.45,1.45,0,0,1,0,2.89Z" />
        <path d="M95.45,28.63a1.45,1.45,0,0,1-.69-.18L80.82,20.87a1.45,1.45,0,0,1,1.38-2.54l13.94,7.58a1.46,1.46,0,0,1,.58,2A1.44,1.44,0,0,1,95.45,28.63Z" />
        <path d="M75.05,65.09a1.45,1.45,0,0,1-.69-.18l-14-7.64a1.45,1.45,0,0,1-.69-.86,1.41,1.41,0,0,1,.12-1.1L76.16,25.15a1.43,1.43,0,0,1,2-.58l14,7.63a1.51,1.51,0,0,1,.69.86,1.45,1.45,0,0,1-.11,1.1l-5,9.15a1.45,1.45,0,0,1-2.54-1.38l4.29-7.88L78,27.8,63,55.43l11.49,6.25,3.42-6.27a1.45,1.45,0,0,1,2.54,1.38l-4.11,7.54A1.45,1.45,0,0,1,75.05,65.09Z" />
        <path d="M69.37,70.28a1.38,1.38,0,0,1-.68-.18l-9.64-5.24a1.43,1.43,0,0,1-.7-.86,1.45,1.45,0,0,1,.12-1.1L62,56.51a1.45,1.45,0,0,1,2-.58l9.63,5.24a1.45,1.45,0,0,1,.58,2l-3.47,6.39A1.45,1.45,0,0,1,69.37,70.28ZM61.7,63l7.1,3.86L70.89,63l-7.1-3.86Z" />
        <path d="M89.26,33.72a1.56,1.56,0,0,1-.69-.17L78.94,28.3a1.45,1.45,0,0,1-.58-2L81.84,20a1.46,1.46,0,0,1,2-.58l9.63,5.25a1.43,1.43,0,0,1,.7.86,1.41,1.41,0,0,1-.12,1.1L90.53,33A1.43,1.43,0,0,1,89.26,33.72Zm-7.67-7.27,7.1,3.87,2.09-3.86-7.1-3.86Z" />
        <path d="M41.49,30.54A1.44,1.44,0,0,1,40.57,28,48.73,48.73,0,0,1,68,16.88a1.44,1.44,0,0,1,.22,2.88A45.88,45.88,0,0,0,42.41,30.21,1.46,1.46,0,0,1,41.49,30.54Z" />
        <path d="M73.86,118A49.19,49.19,0,0,1,24.73,68.86a49.93,49.93,0,0,1,1-9.85,1.45,1.45,0,0,1,2.84.58,46.25,46.25,0,1,0,76.57-24.81,1.45,1.45,0,0,1,2-2.13A49.14,49.14,0,0,1,73.86,118Z" />
      </svg>
    );
  }
}

export default MicroHome;
