// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Facebook extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>Facebook</title>
        <path
          className="cls-1"
          d="M22.67,0H1.33A1.32,1.32,0,0,0,0,1.33V22.67A1.32,1.32,0,0,0,1.33,24H12.82V14.74H9.68V11.09H12.8V8.42c0-3.1,1.89-4.79,4.66-4.79a26.32,26.32,0,0,1,2.81.16V7H18.36c-1.5,0-1.79.71-1.79,1.76V11.1h3.6l-.47,3.64H16.57V24h6.1A1.32,1.32,0,0,0,24,22.67h0V1.33A1.32,1.32,0,0,0,22.67,0Z"
        />
      </svg>
    );
  }
}

export default Facebook;
