import React, { useState, useEffect } from "react";

export const PlansContext = React.createContext({
  plansFromJSON: null,
  setPlansFromJSON: () => {}
});

const PlansProvider = ({ children }) => {
  useEffect(() => {
    fetchPlans();
  }, []);

  async function fetchPlans() {
    let response = await fetch("https://platform-storage-prod.s3.amazonaws.com/planos.json");
    response = await response.json();
    setPlansFromJSON(response);
  }

  const [plansFromJSON, setPlansFromJSON] = useState(null);
  return <PlansContext.Provider value={{ plansFromJSON }}>{children}</PlansContext.Provider>;
};

export const SimuladaoContext = React.createContext({
  simuladaoFromJSON: null,
  setSimuladaoFromJSON: () => {}
});

const SimuladaoProvider = ({ children }) => {
  useEffect(() => {
    fetchSimuladao();
  }, []);

  async function fetchSimuladao() {
    let response = await fetch("https://admin-storage-alpha.s3.amazonaws.com/providers/simuladao.json");
    response = await response.json();
    setSimuladaoFromJSON(response);
  }

  const [simuladaoFromJSON, setSimuladaoFromJSON] = useState(null);
  return <SimuladaoContext.Provider value={{ simuladaoFromJSON }}>{children}</SimuladaoContext.Provider>;
};

export default function Contexts({ children }) {
  return (
    <PlansProvider>
      <SimuladaoProvider>{children}</SimuladaoProvider>
    </PlansProvider>
  );
}
