import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router";

import List from "./containers/list";
import QuestionDetails from "./containers/details";
import CompleteRanking from "./containers/ranking";
import styled from "styled-components";

import { PATH, DETAILS_PATH, RANKING_PATH } from "./paths";

class Router extends Component {
  render() {
    return (
      <Container>
        <Switch>
          {/*<Route path={RANKING_PATH} component={CompleteRanking} />*/}
          {/*<Route path={DETAILS_PATH} component={QuestionDetails} />*/}
          {/*<Route path={PATH} component={List} />*/}
        </Switch>
      </Container>
    );
  }
}

export default Router;

export const Container = styled.div`
  padding: 0 16px;

  @media (max-width: 767px) {
    padding: 0 8px;
  }
`;
