// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class LiveMd extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>Lives</title>
        <desc>Representação de uma tela com um símbolo de play</desc>
        <g id="icon-live-md">
          <path d="M28,28H4a4,4,0,0,1-4-4V8A4,4,0,0,1,4,4H28a4,4,0,0,1,4,4V24A4,4,0,0,1,28,28Zm2-4V8a2,2,0,0,0-2-2H4A2,2,0,0,0,2,8V24a2,2,0,0,0,2,2H28A2,2,0,0,0,30,24ZM19,16l-6-4v8Z" />
        </g>
      </svg>
    );
  }
}

export default LiveMd;
