import styled from "styled-components";
import { MediaQueries, Button } from "ratel-ui";

export const Box = styled.div`
  position: relative;
  display: flex;
  /* justify-content: center; */
  margin-top: 24px;
  padding-bottom: 16px;
  margin-bottom: 49px;

  ${props =>
    props.hasStarted &&
    `
    display: block;
    color: ${props.theme.colors.clear};
  `};

   @media (max-width: 767px) {
    margin-bottom: 19px;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    margin-bottom: 19px;
  }
`;

export const Item = styled.div`
  position: relative;
  padding: 8px;
  width: 156px;

 
  @media (max-width: 767px) {
    padding: 6px;  
    width: 84px;
  }
  @media (max-width: 1199px) {
    width: 120px;
  }

  &:not(:last-child):after {
    content: "";
    position: absolute;
    top: 25px;
    right: 0;
    width: 1px;
    height: 80px;
    background: ${props => props.theme.colors.clear};

    @media (max-width: 767px) {
      top: 0;
      width: 2px;
      height: 65px;
    }
    @media (min-width: 768px) and (max-width: 1199px) {
      top: 15px;
      width: 1px;
      height: 75px;
    }

  }
`;

export const Text = styled.div` 
  color: ${props => props.theme.colors.clear};
  margin-top: -64px;
  z-index: 8;
  margin-left: 142px;
  ${props =>
    props.hasStarted &&
    `
    display: block;
    padding: 16px;  

    strong {
      font-size: 1rem;
      color: ${props.theme.colors.clear};
    }
  `};

  @media (max-width: 413px) {
    margin-top: -94px;  
    margin-left: 10px;
  }

  @media (min-width: 413px) and (max-width: 767px) {
    margin-top: -74px;  
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    margin-top: -94px;  
    margin-left: 52px;
  }
  @media (min-width: 1680px) and (max-width: 1919px) {
    margin-top: -190px;  
  }
  @media (min-width: 1920px) and (max-width: 2559px) {
    margin-top: -250px;  
  }
  @media (min-width: 2560px)  {
    margin-top: -350px;  
  }
`;

export const Number = styled.span`
  display: block;
  font-size: 5.5rem;
  text-align: center;
  font-weight: 600;
  color: ${props => props.theme.colors.clear};

  ${MediaQueries.mobile`
    font-size: 2.5rem;
  `}

  @media (max-width: 374px) {
    font-size: 2.5rem;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    font-size: 4rem;
  }
`;

export const Label = styled.span`
  display: block;
  font-size: 1rem;
  text-align: center;
  color: ${props => props.theme.colors.clear};
`;

export const TextHeader = styled.div`
  > h1{
    font-size: 2.0625rem;
    font-weight: 900;
    margin-bottom: 8px;

    @media (max-width: 767px) {
       font-size: 1.5rem;
      margin-bottom: 24px;
    }
 
    @media (min-width: 2560px)  {
      font-size: 3.2625rem;
    } 
  }
  
  > h2{
    font-size: 2rem;
    margin-bottom: 16px;
    font-weight: 600;

  @media (max-width: 767px) {
    font-size: 1.225rem;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2560px)  {
      font-size: 3rem;
    } 
  }
`;

export const TextFooter = styled.span`
  font-size: 2rem;
  font-weight: 600;

  @media (max-width: 767px) {
   font-size: 1.225rem;
  }

  @media (min-width: 2560px)  {
      font-size: 3rem;
    } 

`;
export const ButtonPosition = styled.div`
  width:100%;
 
  margin-top: 16px;

  @media (max-width: 767px) {
    margin-top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    margin-top: 22px;
  }
`;

export const Btn = styled(Button)`
  width: 216px;
  
`;

export const Logo = styled.img`

  position: absolute;
  top: 34px;
  right: 47px;
  width: 40px;

  @media (max-width: 767px) {
    top: 16px;
    right: 16px;
    width: 32px;
  }
  
  
`;