import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Icon from "../icon";
import "./breadcrumbs.scss";
import * as Dimmer from "../dimmer";
import * as S from "./styles";

class Breadcrumbs extends Component {
  render() {
    const { className, id, linkColor = "#4a4a4a", loading, items, title } = this.props;

    let linkStyle = { color: linkColor };
    let arrowStyle = { fill: linkColor };

    return (
      <S.Container id={id} className={classNames("breadcrumbs", className)}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <h1 className="breadcrumbs-title">{title}</h1>

            <nav className="breadcrumb breadcrumbs-nav is-hidden-touch" aria-label="breadcrumbs">
              {items.map((it, index) => {
                const isLast = items.length === index + 1;

                return (
                  <div key={index} className="breadcrumbs-item">
                    <li className={isLast ? "is-active" : ""} key={`li-${index}`}>
                      <Link style={isLast ? {} : linkStyle} to={it.url || "#"}>
                        {it.label}
                      </Link>
                    </li>
                    {!isLast && <Icon style={arrowStyle} icon="arrowRight" width={8} height={8} />}
                  </div>
                );
              })}
            </nav>
          </>
        )}
      </S.Container>
    );
  }
}

const Loading = () => (
  <>
    <LoadingTitle />
    <LoadingBread />
  </>
);

const LoadingTitle = () => <Dimmer.Text margin="0" width={"200px"} height={24} />;

const LoadingBread = () => <Dimmer.Text margin="12px 0px 0px 0px" width={"200px"} height={10} />;

export default Breadcrumbs;
