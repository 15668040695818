// @flow
import React, { Component } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Calendar extends Component<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
        <title>Data</title>
        <desc>Representação de um calendário</desc>
        <g id="icon-calendar">
          <path d="M7,1A1,1,0,0,0,5,1H3A1,1,0,0,0,1,1,1,1,0,0,0,0,2V7A1,1,0,0,0,1,8H5L8,5V2A1,1,0,0,0,7,1ZM1,7V2H7V4H4.5a.5.5,0,0,0-.5.5V7ZM6.59,5,5,6.59V5Z" />
        </g>
      </svg>
    );
  }
}

export default Calendar;
