// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Menu extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone menu</title>
        <desc>Ícone com três traços para abrir o menu</desc>
        <g id="icon-menu">
          <path d="M15,9H1A1,1,0,0,1,0,8H0A1,1,0,0,1,1,7H15a1,1,0,0,1,1,1h0A1,1,0,0,1,15,9Zm1,6h0a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H15A1,1,0,0,0,16,15ZM16,1h0a1,1,0,0,0-1-1H1A1,1,0,0,0,0,1H0A1,1,0,0,0,1,2H15A1,1,0,0,0,16,1Z" />
        </g>
      </svg>
    );
  }
}

export default Menu;
