// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Lamp extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Lâmpada</title>
        <desc>Imagem de uma lâmpada acesa</desc>
        <g id="icon-lamp">
          <path d="M38.68,47.77a20,20,0,0,0-5.6-38.83c-.36,0-.72,0-1.08,0a20,20,0,0,0-6.68,38.85,1,1,0,0,1,.68.94V50H25a3,3,0,0,0-.78,5.88,3,3,0,0,0,2.1,4,6,6,0,0,0,11.36,0,3,3,0,0,0,2.1-4A3,3,0,0,0,39,50H38V48.71A1,1,0,0,1,38.68,47.77ZM32,62a4,4,0,0,1-3.46-2h6.92A4,4,0,0,1,32,62Zm5-4H27a1,1,0,0,1,0-2H37a1,1,0,0,1,0,2Zm2-6a1,1,0,0,1,0,2H25a1,1,0,0,1,0-2H39ZM28,50V48.71a3,3,0,0,0-2-2.82,18,18,0,0,1,6-35c.32,0,.65,0,1,0a18,18,0,0,1,5,35,3,3,0,0,0-2,2.82V50Z" />
          <path d="M32,7a1,1,0,0,0,1-1V1a1,1,0,0,0-2,0V6A1,1,0,0,0,32,7Z" />
          <path d="M60,26H55a1,1,0,0,0,0,2h5a1,1,0,0,0,0-2Z" />
          <path d="M9,26H4a1,1,0,0,0,0,2H9a1,1,0,0,0,0-2Z" />
          <path d="M49,42.56A1,1,0,0,0,47.56,44l3.53,3.54a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42Z" />
          <path d="M15,11.44a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41L12.91,6.49a1,1,0,1,0-1.42,1.42Z" />
          <path d="M51.09,6.49,47.56,10a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l3.54-3.53a1,1,0,0,0-1.42-1.42Z" />
          <path d="M15,42.56l-3.54,3.53a1,1,0,0,0,0,1.42,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29L16.44,44A1,1,0,0,0,15,42.56Z" />
          <path d="M43.26,22.5a1,1,0,1,0,1.73-1A15,15,0,0,0,32,14a1,1,0,0,0,0,2A13,13,0,0,1,43.26,22.5Z" />
        </g>
      </svg>
    );
  }
}

export default Lamp;
