// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Average extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Média</title>
        <desc>Representação de um relógio pontilhado</desc>
        <g id="icon-average">
          <path d="M32,.13a32,32,0,0,0,0,64c.69,0,1.38,0,2.06-.07A1,1,0,0,0,35,63a1,1,0,0,0-1.06-.94c-.64,0-1.29.07-1.94.07a30,30,0,1,1,30-30c0,.65,0,1.29-.06,1.93a1,1,0,0,0,.93,1.06h.07a1,1,0,0,0,1-.94c0-.68.07-1.37.07-2.06A32,32,0,0,0,32,.13Z" />
          <path d="M48.67,57.07a30.33,30.33,0,0,1-3.4,2,1,1,0,1,0,.88,1.79,31.89,31.89,0,0,0,3.63-2.09,1,1,0,0,0-1.11-1.67Z" />
          <path d="M62.61,37.17a1,1,0,0,0-1.18.79,29.55,29.55,0,0,1-1,3.8A1,1,0,0,0,61.05,43a.87.87,0,0,0,.32.06,1,1,0,0,0,.94-.68,31.42,31.42,0,0,0,1.09-4A1,1,0,0,0,62.61,37.17Z" />
          <path d="M54.56,51.9a29.18,29.18,0,0,1-2.78,2.78,1,1,0,0,0-.09,1.41,1,1,0,0,0,.75.34,1,1,0,0,0,.66-.25,30.63,30.63,0,0,0,3-3A1,1,0,0,0,56,51.81,1,1,0,0,0,54.56,51.9Z" />
          <path d="M41.64,60.54a30.69,30.69,0,0,1-3.79,1,1,1,0,0,0-.79,1.17,1,1,0,0,0,1,.81l.2,0a31.82,31.82,0,0,0,4-1.08,1,1,0,0,0-.65-1.9Z" />
          <path d="M60.26,44.93a1,1,0,0,0-1.34.45,30.46,30.46,0,0,1-2,3.41,1,1,0,0,0,.28,1.38.94.94,0,0,0,.55.17,1,1,0,0,0,.84-.44,31.89,31.89,0,0,0,2.09-3.63A1,1,0,0,0,60.26,44.93Z" />
          <path d="M32,13a1,1,0,0,0-1,1V32a1,1,0,0,0,.47.85l8,5a1,1,0,0,0,1.38-.32,1,1,0,0,0-.32-1.38L33,31.45V14A1,1,0,0,0,32,13Z" />
        </g>
      </svg>
    );
  }
}

export default Average;
