import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { MediaQueries } from "ratel-ui";

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: block;
  background-color: #fff;
`;

export const SimulatedHeader = styled.div`
  width: 100%;
  /* height: 468px; */
  padding: 40px 16px 60px;
  background-color: #1B3FB5;

  @media (max-width: 767px) {
    height: auto;
  }
`;

export const SimulatedHeaderContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
`;

export const Logo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 140px;
  height: 32px;
  z-index: 5;

  @media (max-width: 767px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const HeaderTitle = styled.h2`
  position: absolute;
  top: 40px;
  left: 0;
  color: #fff;
  font-size: 1.75rem;
  font-family: "Roboto", sans-serif;
  z-index: 5;

  strong {
    color: #fff;
    font-weight: 900;
  }

  @media (max-width: 767px) {
    position: relative;
    top: 52px;
    text-align: center;
  }
`;