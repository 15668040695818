// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class ArrowDown extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
        <title>Ícone seta para baixo</title>
        <desc>Ícone de uma seta apontando para baixo</desc>
        <g id="icon-down">
          <path d="M7.71,3.71l-3,3a1,1,0,0,1-1.42,0l-3-3a1,1,0,0,1,0-1.42h0a1,1,0,0,1,1.42,0L4,4.59l2.29-2.3a1,1,0,0,1,1.42,0h0A1,1,0,0,1,7.71,3.71Z" />
        </g>
      </svg>
    );
  }
}

export default ArrowDown;
