import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1215px) {
    flex-direction: column;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 69px;
  font-family: 'Rubik', sans-serif;

  @media (max-width: 767px) {
    align-items: center;
    margin-right: 0;
  }
    @media (min-width: 768px) and (max-width: 1215px) {
    align-items: center;
    margin: 0 auto;
  }
`;

export const Title = styled.h1`
  font-size: 3.75rem;
  font-weight: 900;
  line-height: 100px;
  color: #FD7138;

  @media (max-width: 767px) {
    font-size: 2.475rem;
    line-height: 53px;
    margin-top: 24px;
  }

  @media (min-width: 768px) and (max-width: 1215px) {
    margin-top: 24px;

  }
`;

export const Subtitle = styled.h2`
  font-size: 1.875rem;
  font-weight: 700;
  color: #00C5E6;
  height: 42px;
  width: 158px;
  background-color: #003558;
  color: #ffffff;
  border-radius: 16px;
  text-align: center;
  padding: 6px 16px;
  margin-bottom: 12px;
 

  @media (max-width: 767px) {
    font-size: 1.275rem;
    line-height: 19px;
    margin-bottom: 8px;
    text-align: center;
    padding: 12px 16px;
    width: 128px;
  }
`;

export const Dates = styled.h3`
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: #003558;

  @media (max-width: 767px) {
    font-size: 0.75rem;
  }
`;

export const Content = styled.div`
  display: flex;
`;

export const Image = styled.img`
  margin-right: -68px;

  @media (max-width: 1215px) {
    display: none;
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 1215px) {
    align-items: center;
  }
`;

export const Text = styled.p`
  width: 364px;
  margin-bottom: 32px;
  font-size: 1.125rem;
  line-height: 24px;
  color: #707070;
  text-align: right;

  > strong {
    font-weight: 500;
    font-style: italic;
    color: #707070;
  }

  @media (max-width: 767px) {
    width: unset;
    font-size: 0.75rem;
    line-height: 14px;
    text-align: center;
  }

  @media (max-width: 1215px) {
    margin-bottom: 24px;
    text-align: center;

  }
`;

export const YoutubeBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 216px;

  @media (max-width: 1215px) {
    margin-top: 24px;
  }
`;

export const YoutubeText = styled.span`
  display: inline-block;
  margin-bottom: 8px;
  font-size: 0.75rem;
  color:#707070;
  text-align: center;

  > strong {
    font-weight: 700;
    color:#707070;
  }
`;

export const YoutubeButton = styled.a`
  display: flex;
  align-items: center;
  padding: 2px 10px;
  background-color: #003558;
  border: 1px solid #003558;
  border-radius: 16px;
  font-size: 0.625rem;
  font-weight: 300;
  color: #ffffff;
  cursor: pointer;

  > svg {
    margin-left: 8px;
    fill: #ffffff;
  }

  &:visited,
  &:hover {
    color: #ffffff;
  }
`;

export const Placeholder = styled.div`
  width: 407px;
  height: 530px;
  background-color: #ffffff;
`;
