// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class ArrowLeftHome extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 16">
        <title>Esquerda</title>
        <desc>Seta apontando para o lado esquerdo</desc>
        <path d="M7.29,15.71l-7-7a1,1,0,0,1,0-1.42l7-7a1,1,0,0,1,1.42,0h0a1,1,0,0,1,0,1.42L2.41,8l6.3,6.29a1,1,0,0,1,0,1.42h0A1,1,0,0,1,7.29,15.71Z" />
      </svg>
    );
  }
}

export default ArrowLeftHome;
