import React from "react";

import { Feedback } from "../Feedback";
import FeedbackHeader from "../feedbackHeader";
import Logo from "../../../images/logo-text.svg";
import * as S from "./styles";

const Result = ({
  feedback,
  completeFeedback,
  beforeTimeSimulation,
}) => {
  return (
    <S.Container>
      <S.SimulatedHeader>
        <S.SimulatedHeaderContainer>
          <S.Logo src={Logo} alt="Logotipo do Aprova Total" />
          <S.HeaderTitle>
            <strong>SIMULADO</strong> SUPER MED
          </S.HeaderTitle>
          <FeedbackHeader isWinner={completeFeedback.awarded} />
        </S.SimulatedHeaderContainer>
      </S.SimulatedHeader>

      <Feedback
        pdfUrl={feedback.pdfUrl}
        feedback={feedback.questions}
        isRanking={feedback.isRanking}
        completeFeedback={completeFeedback}
        beforeTimeSimulation={beforeTimeSimulation}
      />
    </S.Container>
  );
};

export default Result;
