// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Jubiluzinho extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>Jubiluzinho</title>
        <desc>Imagem do Jubiluzinho</desc>
        <defs>
          <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#156ca1" />
            <stop offset="1" stopColor="#2289bf" />
          </linearGradient>
          <clipPath id="clip-path">
            <circle
              id="Elipse_222"
              data-name="Elipse 222"
              cx="16"
              cy="16"
              r="16"
              transform="translate(600 2592)"
              fill="url(#linear-gradient)"
            />
          </clipPath>
          <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 64 64">
            <image
              width="64"
              height="64"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAABACAYAAABlR0UdAAAcRUlEQVRogZV6CZhc1XXm//ZX+977pq3V2ncBEqswIDAEg8HG4BgzjhlvTHDMkLFDJo5xviSTMcE4cezx
                    DHiwPZkYL2PHISAZbAaEAAkJtIDU3VJv6q7u6qquvd7+3nznvqpWC0zGrqbU1UXVe+eee85//vOfy3meh6UPz9bh6mUIgRiOv/ATdK0YQr2Yh2vUAV5CrbwA13URisYRTGTgaDXOtvTdMxNnrm/Uqt
                    vmpid6HduWAsFQOd3RPRlNZI47tnl4+Zotr5YLc/MczwMch/a+lcjPToLjJaiyjJmzpxBv70bX6s3Qi3kUZicRbetHsnslmI3vsFPE7/DwXBeCKCEUCAKu0zszfOze4ZNH7szP55bnZrLQDQOiEgAv
                    SHAsHZ5zcLssS7cm0xmcPPpaPZnKvJnOZPb1Dm36oRxJvC2XCjB17Xcx4Xcz3PNccKKESCwNe2Z87ciJww+NDb9128y5Scm0AQgSIKjgVAWW4wCuDY6TwUsKLABz+TJmc/mQgFO7ZJHf1XXs9S+vWr
                    tp/4p12/46ku59Do4OD95vYclvbbgLDzzUcAKcXkydOnrga0cP/vruXC4HByI4MQxwDkzThGVrcBwX7tJt5TgIHAee5yGKAjxRhAseo2cmcGZk5JqOV168Zv22i1/efPGV94fD0UM8L8Bzf7sFvLfh
                    ngeOExCNxZCbGPngM//rW/9j8lw25nASeDEEyzBgag1Ytg3bcWDbzqLh9MM1L8PTj8CBYwvgIAg8REGAqoYxk69g5p9/uuv46y+/tmHz9u9tvvSG+2Q1UjbKebwz9975eHdyWhocowIxlMC54y9i/O
                    QrTxx84bmPL5QbEOQANDLYMGHaDizLZgaT4WS047rMY4tXbL3g2H/gm97nBR6SKECWBAQCKjjXgcw7WLN2bfG2e//jnbFUxzNnTx5GrK0Pye5VvzE532W4a9bBSxI818n8/O+/uO/A889u5gIJOB6P
                    RkODYVkwTRsWM5gM9+C4DlzXY0+6Hu2267lAc9s9jjzve52j18zzAmRmvAhFlRBQFNh6FZ3pCO761AMPdQ0M/oXlSe+JKu8ynB6OtrD6B3/12V8feu1whxRrh6EbaDR06GR008uO48FxfG8zo8lg14
                    Vl++EiNz1KXnZcj32ektz1NwAihYwoQpEESJIARRYRDAQZHEdkG3d98r6v7bj2zgcgBZtx//+DQ6cx8ORffuaXBw4c7FDjXaiWazAdG5phwTJteHRrz4NhWjBtm12UDKUFkUfbExG0JaLgeKBU01Ao
                    11GsNlDTDBZezACvaQbHsZBJRILse7QrwWAAdcfCD/7b178QTnbV1l5++5c5Rwdc97097mkL+Ke/e+i1/U//Yocc64Sh62iQt3ULQUVkHqzWNGQLNQgCBx7eooe7UlH0d6VYDM/MlzA6PY+Fcp15Nh
                    MNoysRRWciimQ4AEWRWNo2DBO5YgWnpnM4k1tAOKhg44putKfjsA0NnckgPvmlR67rWr1ln16rvLfh+5/4s2//8HuP3ytGu6DrGkpVDfMLVZSrDWwd6sVCpY4TZ3NQVQmpsAzbdiHLIjLxCJKRAOZL
                    NZyemoVpOtjQ24HdqweweWUPlnem0ZmOIxgLA2S0JDJvs7jVLJQKJRw4cQb/c98reO74WWwe7MSWoWXQawvYtHHdyN1//MhO2/FKtmUsMdwxWSmfHT1626NfvOepiimyxJvNlzE2s4BypY5l3WkEgz
                    KyuSoCqoCAzLNdoGiNhhSIPIfxbAG0BztWD+BDuzbi2m3rIHWmAImnkus/KVZbBns4n3CSBKgKUG3g8R88g/u/8y/YMJjBFdvWwKrN45bf/8x/2Xb1bX9cymdZOPqGWzVAFKUn/vzeE68efGVQDicx
                    MZ3DsdEZ1OoG7rhiK3o7kjj41hiqWgMLlSps10VAFsFzAnSD4t/Gio423HzJOty0fQid/R1AexpQFKBRB0zzwhilBbDFNI2nvx0PkGUgE8e+p57H+//zE7hmRz+2rGhHb39v8aN/+BdrXPBzlqmzS/
                    CNShFvvfT0PW8de30wmmpHYaGME2eyKNcMfP6WK/HAB69mr18fmcDI9BxkUUQ6EoEkqNBNHtFgFLdesgUP3n4lbr1kHToTVE15cgYQUAFFJgBvJlHTSGZx673W3nOApgNTOVz7offhkU/diP2HJlAx
                    gUJuNjHyxoGPmFoN9XKBPUVTq+LYwec+5rgCHNPGyFQO2YKGBz94Of7ddbtQrDfAwcOHLt+KnlQc86UGpvJl5uW2WAC7hvqwaXkni/EwbTd5TeB8D7stA7nz3l6EFO+80a2/BUI1B5jO474/+AD++c
                    AxPHvwJD58+SBy2amrt1z1gUfL+Tm2SFGrlddlZ6YuEpUgpmYKODFWwN6tK3DPdRehbloMs++5ZicahoXRmTwsGwiqMtpjAazoSKEzGUVHIoJwQGa8BCJPgAFYFmAYgNNcQKuItMKjZWzryd5rllhd
                    B+IR/MndN+L2h76JiVwZa+amN06PHo/VKqUyw/FqMbezMDstWo6E0xNziCg8Pn7txVBVFbWGDkngUa5r0HSTOXJ1TxrxoMJgLRKQEQ8HkM2VEY8G0NHb5nuabLFMwBB8Dy7G89LY8C4MlSUbwkKrUM
                    IVF6/H9qHleGM0h42rz/ap4cj67qGtB/RaGbxWry4nFCGoG8+WcckagrBeVnC45lWpCqqyhO5UDKu701jWkUAmHkIkqCIaUJiXHPpHIG/zvgFUbMjjtEXukpJtu1Q2AVnyd4OMdJcuovmavhcIYO/2
                    1cgW68jO5pAdO33Rwsw45iZHIZbyc22W6zG8Ni1g19plCCgyqg2dcQRFFNi1yfiQIkMWeXiOg4gqs0Li2C6GNq4A+7Jm0Ad9I8nTTehir2lR8QhLVrfWYOYJbUnAMIGG4X+mpgGG6yc37ZJpYcvQAK
                    KKhLFzWUydPbV7/e7rHwknMhD1Ri2m6yYrHvEwj5VdbTBZNXQhMe95UDiBcQpVFBBLx8GFg/AaGiOvYkiFW6uDJ+hrYTWDN4I/p5mbHHs+9cTPEUxG8NLrwyhpBrYODWBiMov7P34jkooMLhoBJ5gM
                    z9l3GjpWdaTQ25ZAtlBGbnZ2DWSZk2XFE+u1arShWajUNbRHQ0hGQzCpReN4cJxPjiiW0+1JQJVx4NWT0B0brw1P4fW3xnHZhgHsO3waf3Trlbh690Y/RNgCmjHb9PQ3vvEUvvLkMwipHEp1DzUbcJ
                    4+yj7y1AtvgBdc/Pubr8T9n7nDz5Oqxq4VD4WQiYcxPFVGdvpcz5u//Em/ZVnjYq1aiRBnIC6dTsZY48q2muLWcRGPBBENKth34A2cyRXw6M9exGi2joAAaC7w09dGEA0Ax/7m+9jX+XmsobApV5eg
                    iMfg8fXxOeQNQJU8rOtPoqe7HZGggnJVw5lzsyzHHvrOPpwYm8F//0+f8GuA47AQDcgSXHCYzWYjHMf39gwMjou1Wi1oWA7rShLRAItrdj/XgyqJSKRi+Luf7Md9336WeSceANb0hJGKRhizq2o6hs
                    fzqBguBEk4b6zbTDTXAeo6vvDhPQjxHvZctA7X79qAYFfaT2TNgDNfRi63gNOzC5gtVtAoVRhLJHgkVYCjH15AsZBHJNU+0LFm94uiphsq9ZUCz8H2PMZTWgWjO53Ao089iwe/ux9XrOnAjsF+XLxm
                    ALuH+pHpSEIQRTQqdfzfN0eQbktikEp9vgQIAsBTuDRxuVDGhi2r8Pd7tvqoUqoCMwUfOVivLaGzrwOdK3sATmBQ6NR1CKrKjCYfUD9KX52bPNPT178MomPbAtfkxbRdmmkyR1CSz5erGOxsx08fvB
                    MXr+5DKpMAFJF50K0bgGAxz+y98TLWWCNXanq6hQytiigAxRq87ALKhTJM04JCfaciQpQkcMTlNb9Dot2yHRc2RavtgBc51qBQr0qXK+bn0vnsOV70OysOiiSiWGug0tAhgAdBZLnewKVDy6DIAmq6
                    gXMzObiEFgS9jgvLclhzQPAYDMhoT8UQCgWaqAI/FMgY3cL0dA7n5gpQRBE9mThilOzJiE9z6YLkfWKcFLamDVs3WN5xrKuyWatHOVepVJKVciksSpJsup4NRZJQrNRRqNSYlwgOOQ/IV+uQJZ4lSV
                    iRobJ2jIfHcawzqlTqmMkVMXJuDs7wFFb1tmNNXzvkcMAvLoaFt4YnMTaTw0A6jrWbVoFb2cveB91LszA3OQGzUUNXTw+EWAJ2vsJCg3aAWsOabvqIyvOoVkrxQm46JMqKUqdVqYrEOpn5co31hrRa
                    VnhkCUFZRDQcghhVSQYAAiFmlOI6iEgSugGsG8vihUOncOjkWRiGhZ2ELiEV8+fmMZEtYKAjhXUXr2Uh+N1/+Dl+9uKbyBXKyGbncedH78SVV12Fx370HPjsCL50xx5EEnHYlgs5EUE0FMXZ+TL4TA
                    havRYqL+RVUQ2EiuRhVZFZbI1l8yymmJgjCNBMB7GgispCHscOTeH4bAWnZ0soVRosVjszCezZshI3XrUFV29fgX994U2cODuDFQOdSKWjyJeqCKkS4pyJv/nmj/Ffnz3B2rWlj77dN2DjVe/D6dPD
                    qNh5BOMhXwmg+qfK+Ng1O/Hio6dR00LU2yqaYUpiOBpbcAkvVY5l9NnZBTiO7Wcx4bjC40vf+gkO5DnsufnDuOFzH8F3PnkXjh8+sXjrx37xCvDw9/HIJ27CdWsHMHnoBPKlGlKixCpuYXYGwW2fxK
                    ujR5Ervrz4vVQogFuu3oFt5puwn34Jn7m8C2L7R4DZEuM5ArV4CxXcsGcnbj50EvtfPYQrmmqCmEi15Sk0CHoN20E0FEBIVRiNNR0XsqTgonXLsdwLYmM/h9WNt/GB7Wtw/PArF7I6QUHixs+hNPcK
                    uiOnWLhQ2Y8GZSxbPgDe0/H4f7gVvaVj8Iwa9mxfg8t3DiHemwFqVcDm/Gc27ycrGU2yhuVAAIePXb0LP9r/GjTT1kPBoCWmegbOkZJEqCFwHq7ZsprhKm/ZiAQU6LaD26+/BCRfutUijFO/wJ9d14
                    tPX/PnOH1uDvliHbn5AjatW4sd6imUhSJSa1dDsxx2Y0lRsH7tCnDWMNxjp/C3X73H70Mbmk+qZheaihHvFy7i88557RGkJzZM9HVkEJWBhoNiMtNeFa3Kwhhp3aPjeazr7cBlm1ajpplMZuBIKuM5
                    1Et1OCKPoBJFIJZiENYZUNA50ObfkBjhfAGYnkAmE0fDXIBlNQBdg0zIUKtD7WyDEAwCCzW/B7Ut/7ui1OxD3Sb1bT5dv/pS0fFshxU7uk0oEp/u7ulbEEOR2OloImVPvTwuXrd5PeKRCHRTZ7CHpm
                    QmiRyLVdCToIa8adbO94+tZiCcgK2byBcrSMeCQDgAOZNAZXwGUqkKgcKHvk8FiTi50yRjXLOBdi9kl7bpQnfo/jJr0A0dGFq/daJr07Uuv+Gq29+WQ9HJugck4xF4PElmhJ++Fsh5HKMDHL+kuW21
                    Xq1W0ms2DqaFbKHEILR/3QpADkDo7UCgIwWtWG1qb6L/mz5v237hIS5v2Rc8XctGw7JZ3tE6qLFRYhHYHjdSz5+FmBt+xe1q7z0CYHmDMpnnWTdDMerYNjhZYHIzcRiG64LA2jlebHp/iSJLO0LELJ
                    svYe5XRzDYmUKh1mByx2WbBhEOh3wjl/agzOt+mHi222yeyNMeDJeH1XRStVJFpn/lfEPXjz73L/8HYtvgJlyx97pf4rEnb5uYLTALCL9ty4HGyrmNUsVm2p9LkwZ4yMSiCAVkJlRSJyQSAlC8CmDv
                    vT05h6pmwrMsVlmjQRXJaNBvoLlmPDOPOww1NNNHMMN2WfFTZRGCIDG+QuuiHa+ZNvSFbGZPX+r2/p0Xf0t0TAvrN2/f3xmP1k6OTIQNy2b6NW8DNsmQnMv4Wa5YRrlShaaZyNc0DA504arNKyFSaa
                    f+UTdZg6zIEm6+YjMkXkAkGoZAfFrkwBEnIe/RZ2m3dBNmw8B0voJcsYqGrrOpRSYRYwWPKIVj+xtKqu5sVcNgJohE+exjjRNCr2hoNcQ6B8/+3odv++kT337894u0JakEqobu5w3PoTcTZWoskSrD
                    5RADh5NjWZyezmNFRxJdyShTW9OxEEJBFQN9nc3QkdhOGLUGjLoBt6bBdTxG4HjbQbXWwPGxadimjUQkhEwsjK5kmO1mVXf87o/zQ0XXDawfWo1i3YI+eqpHVAQV0OaxMSVQt4fJ+RIGujtQqVQZJG
                    qWBVEQMdTTxsKi3NCRikXgeB5Oncth9Fwew+fmGTuMBVRkogGkIv4CCJlEjmMkjeN4JnFQrlCzQrtKu7N2oMsPpUgQktBUCgRipzabP1HVr2s64vEYRLOOcmEembXbR0XP41F8+42PXhoz7+xKBPDS
                    sVFcu3M9Y2Yk8Og2UNUttCUj6BeTjMbWdIpJB5uXd2HLyh7G4Io13c98UWBPSfTHJZIostfkODWgQuR5JuSzWRAtShbPFx/4CU5MzHb9BpvClHJgVX8vpubHoIbaZ2JD2w6KVqOMaqXoRINBvP+STT
                    g2PsNmO3Rh4r88L6KsmWjzXIiyz52p4VUIVy2HqbY0IgwoIusNiTvrlo2q5nuWRQzFuSKxHpMqKdFiUhCoXTxPGfhFjHcNmxUe6kU42h1JhqO5WJiewLab7ngyuGzDv4qN3DlEOnqfH37Nnd+UkjPH
                    zuo4O1tEKkTaSoMlRtW0UGsYCEeCbIImeTy7KN/snIgCU4Eg3Ldtgb1niDa7KSEUNSlBmvPIElRJ8ndA4BdlCyaRcdyimOTQfIlUhuaaLJJYqgtYueOy16NDFz9MFEGUgiEEYsm5YGf/j6WDL37qc7
                    fshRyJwTSqLCl4gbiPgGJVQzhMU2MREhzwVKToXkxeExiXJynDlV0EHJHtFvmboIymEmxIJQgQyWhCFaGpeDG3tngJxyDSsD02DxWaBcIktEp2vpBembzJtYyGrdVJ4pZhESTecNefypnerDk3ia6O
                    dljNfpFzPTYGLxs2TN3wL877Yz9Z4Fg8U3dElCCgCAjJElNtiWXGggqT6MIBhb0vSSI4imGh6V2WiJwvkjbXQYZbjrtIN4hiux6Hnkv2fja6/rKqJwfh2NaFo5Ty2IktJ370zadj7T0dAlyYtRKTBc
                    jtlq6hNyKhLR07X/WWVHzmNmr3WL97XlbmWp5s/eabai7HnR+AtqQ6Gjc2dMxXbWi8DB4u9GoJct+GFzt333y5Y2pwTH+QxWPJI9befbR7w/ZeLpp5nBcEBmFkIMUyJ4goNkxYVGiWGkOx2OQyHCUb
                    fU9sPlvEjKhqKzwY5+GbIdI03sMisWINOFuNPzOlJFXb+p4hB1IVpwMOdEbgAsONWpnmQXbP9qv/SAwnZzzbZ3NUhqkCao6HsmbAc5ZIbFzznws8y/vGttRbQViy0KaXl8rNLdJm2zCJI3m+Q2jsLk
                    TTeqhn5Q/I24uLpQn1BU1MMI5o/zpKqHKod/DrxOS4JnOlVs7lBJTqPl577pIZDpYsopVsSxdzPm7Ov255uUW4HAee5TAa6zW/qzeqCPWv+8dwe9+EEk1CTbQtPkVtYW7JtTyoqXZmVHzlpkeqU8N3
                    Vs8e3yRHEkzk4UURdVNDVTMYQ5TQTKxWrHrnS/Tiey3+e4Go37ph82/Xl6KpuJk08KVdNk14UgChnlVPWloddqN6wcJFgaSGJYnEkpH+h6zanbt/7/P17Pjzrq1DkFQGhRZ4VHWDTd2gABJJZq2OhV
                    uyECyR4BanDxwufHjnB1q2g4ZpMy5EO62V5xAZ2vXDQKrr10Ypj3c+REkNvevN1iOc7vxVZNWWrxaO/PKhUIowXGDQWDd0VJlIw8OT/Lk8zy3xeisAlzqYW7obSzzeTEoahjUsBxanwNPrsHnZTm+6
                    7GElEoMSCMJ9h22iqdV/o9HkfbpH+7Y9f2rVSlfWRo9eGki2s/JrWQbKDWOxZBPhIg4iNLGX81rF5YIrNhd2fmzCTlowHm4ztapBwoDEoVEpI71971eC4dgJWBU0DKonF6YIVysW3tPj9FDjSZTz81
                    25/d99DtXcUAMKBNuAozfYfJ+4MxF/MpxEHB+q+UWnn78Z17TXDw9yNNFb4jaabqFi2DCIqepVZFZtPNh1w727qEL+arQIBBPY3h1g322Ni0QpGn9Po7lmTh0peDMToR2f3lh57ldtQR7zVR6ipLCQ
                    IZ4SUiSmPVLIsP60lS+LVnuLkeE1D6XZrn/iguKa2kQdIhK8gUC6PY9tH/jQK+N5HBmZxlmvA9v6RCgyD9M5v2Hi4pTsNzyawgFeOHz8C1999FsPLHNm8fR9e9DXlsDYzDwkUWJnWIglyoLJSj91MX
                    SyQmiRpCURws60NGVk3bSgWw5cXmCjyY0JhS3io8/Oisbwsx/Zsbz9u53J+Hx/RkCkOaBbVC/osua/cfaJDP/aM2/+9QMPP/JgqD6F+thZrEzI+P6Dt2JtZxRT+QrrTDzHgmv7bJDChXH5xdDhFnOW
                    nW1paud0pCmiKhA5wDR0HJnT8VdHbbz06jGE2uIYuOyWyvItl+aVSPrgH2wMf/G6FcGpkrkkGv6tQ1tPHpq54+6Hv/OP7XYWqqux0zuTR44iAg1fvOMy7F3fhUQo4COK67AQ8FybcRa/uPp8nBbBhH
                    mOY9S30jAwNlfE23NVHJ/XcTjPYaQRh2DXENQmgWAcvBRAWe2GuvezuOv9W07/5WpsVWU0NLtp+ELr1ZJHQBJQ0t3kjY/ue3N0dLSnV6qzSdz0ycMIjb2IWr6IBp9Ed18GQ2kV6XgAmYjKEpXiPUxN
                    gywgLPslWjdtVKoacsUSk5yHp+cxu1CGCQGpVArLOzMQugfx7OkczIkTuK5PxeXLEuiNS3h8hMPzN3wDX7l752M3BfGHC1Yzxr935N2okgpJeObUwoPDVbVn7eq1MDkeU68+i1Xjz2Dvmm4U7BSmKw
                    1Mz8/ireN1VEyTzUbZ0BX+YTFmOA1ziWJbLmsOJMFj/WUsFMC2gXakIypUHkiFLbRJE4jGSdzvxg2bV2KorwuJTStx/chhDH39T3Dwiv1bbtwMWHrT8NPz7z4iGqmYyNWsnnQiBjERQq7SQO/4fnz5
                    hg3Qo32YLyxgRb2OUod/1qqhmzCa6EC/2WlP3p/ZEMoEJJFNM6iZ4JtcnkJntlzHyGyRYXlXRMWtO1di9+YhdGVSSKQiQK6IyJYd+MR2HS+88Wql99KLFvsP8QtXdL/L8I6oiH86Mv/S/T9+666Ozi
                    4Ujh3F9SkDW7ddhOFcDRE1A8NKsDNVdDiBunA6u0Xki/4m5ZcYpSJKzfjmWLFiChidIxJ51s5tXdXLvB9QRYQDKmvrMqkYknTmhaQNImzFOnat68IvcrPHPv+zcXh6zTc8JL0bDkkle99g8n/v7I0+
                    NFnVuz2tglhQZhO2eNiBqUjN84Uea9HoNBzxaNt1WFhouoGFSg3VuuZPzIg0Nc8dsgT2XARlCYWajnP5CjYs70RnJoC2ZJzxesJ4qaVHVjV2Eqmvv/+4w6vgRL/4iy9PNt5lOFWnjqhcumhF289nz5
                    ifltJ9WMgpCHI2a56ZAc3p89ITnNRyUSUkXG9vS8K2bBRLZbYL7OM0NxJFNlMKqTITNCfmS5gvVqHKNOw12ayIMJ4Gv7IkI1wv4MBkuL7n45sPbGj3ZXUA+H9uGzyVAqY6dgAAAABJRU5ErkJggg=="
            />
          </pattern>
        </defs>
        <g id="img-jubiluzinho" transform="translate(-600 -2592)">
          <circle
            id="Elipse_221"
            data-name="Elipse 221"
            cx="16"
            cy="16"
            r="16"
            transform="translate(600 2592)"
            fill="url(#linear-gradient)"
          />
          <g id="Grupo_de_máscara_5" data-name="Grupo de máscara 5" clipPath="url(#clip-path)">
            <rect id="_05" data-name="05" width="32" height="32" transform="translate(600 2592)" fill="url(#pattern)" />
          </g>
        </g>
      </svg>
    );
  }
}

export default Jubiluzinho;
