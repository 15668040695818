// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Chat extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>Chat</title>
        <desc>Representação de dois balões de fala</desc>
        <g id="icon-chat">
          <path d="M27,11H23V5a5,5,0,0,0-5-5H5A5,5,0,0,0,0,5v6a5,5,0,0,0,5,5v4a1,1,0,0,0,.62.92A.84.84,0,0,0,6,21a1,1,0,0,0,.71-.29L10,17.41V22a5,5,0,0,0,5,5h5.59l4.7,4.71A1,1,0,0,0,26,32a.84.84,0,0,0,.38-.08A1,1,0,0,0,27,31V27a5,5,0,0,0,5-5V16A5,5,0,0,0,27,11ZM7,15a1,1,0,0,0-1-1H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H18a3,3,0,0,1,3,3v6H15a5,5,0,0,0-4.67,3.27l0,0L7,17.59Zm23,7a3,3,0,0,1-3,3H26a1,1,0,0,0-1,1v2.59l-3.29-3.3a1,1,0,0,0-.33-.21A1,1,0,0,0,21,25H15a3,3,0,0,1-3-3V16a3,3,0,0,1,3-3H27a3,3,0,0,1,3,3Z" />
        </g>
      </svg>
    );
  }
}

export default Chat;
