import styled from "styled-components";
import { Button, MediaQueries } from "ratel-ui";

export const Content = styled.div`
  padding: 32px 96px 16px;

  @media (max-width: 767px) {
    padding: 24px 16px 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.125rem;
  font-weight: bold;

  ${MediaQueries.mobile`
    justify-content: center;
    font-size: 0.875rem;
  `}
`;

export const Left = styled.span`
  margin: 0 0 8px 16px;
  color: ${(props) => props.theme.colors.bg2};

  ${MediaQueries.mobile`
    text-align: center;
    color: #555;
  `}
`;

export const Right = styled.span`
  padding: 0px 16px 8px 0;
  color: ${(props) => props.theme.colors.font2};
  fill: ${(props) => props.theme.colors.font2};
  font-size: 1rem;
  border-radius: 24px;
  cursor: pointer;

  > svg {
    margin-right: 8px;
  }
`;

export const FooterLeft = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;

  ${MediaQueries.mobile`
    display: flex;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  `}
`;

export const FooterRight = styled.div`
  display: flex;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 32px 0 0;
  font-size: 10px;

  ${MediaQueries.mobile`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column-reverse;
    padding: 16px 0 0 8px;
  `}
`;

export const Corrects = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.font4};

  svg {
    margin-right: 8px;
    fill: ${(props) => props.theme.colors.success};
  }
`;

export const Incorrects = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-size: 10px;
  color: ${(props) => props.theme.colors.font4};

  svg {
    margin-right: 8px;
    fill: ${(props) => props.theme.colors.error};
  }
`;

export const Grid = styled.div`
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid #265bf1;
`;

export const Table = styled.table`
  width: 100%;
  margin: 0;
  padding: 0;
  table-layout: fixed;
`;

export const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.transparent};
`;

export const Tr = styled.tr`
  margin: 0;
  text-align: left;
  background-color: ${(props) => props.theme.colors.transparent};
  border-bottom: 1px solid
    ${(props) => (props.isDarkMode ? "#265BF1" : "#265BF1")};

  ${({ isLast }) =>
    isLast &&
    `
    border-bottom: none;
  `};

  ${MediaQueries.mobileTablet`
    text-align: center;
  `}
`;

export const Th = styled.th`
  padding: 8px;
  text-align: center;
  font-size: 0.875rem;
  letter-spacing: 0.1em;
  vertical-align: middle;
  color: #265bf1;

  ${MediaQueries.mobile`
    font-size: 0.75rem;
  `}

  ${({ resolution }) =>
    resolution &&
    `
      color: #265bf1;
      padding: 27px;

      ${MediaQueries.mobile`
        padding: 8px;
      `}

      ${MediaQueries.desktop`
        padding: 16px;
      `}
  `};

  ${({ question }) =>
    question &&
    `
      padding-left: 8px;

      ${MediaQueries.mobile`
        padding-left: 16px;
      `}

      ${MediaQueries.desktop`
        text-align: left;
      `}
  `};

  ${({ small }) =>
    small &&
    `
      font-weight: 600;
  `};
`;

export const Td = styled.td`
  padding: 16px;
  text-align: ${(props) => (props.question ? "left" : "center")};
  font-size: 0.875rem;
  font-weight: ${(props) => (props.bold ? 700 : 400)};
  color: ${(props) =>
    props.questionCanceled ? props.theme.colors.font5 : "#0B1943  "};
  ${(props) =>
    props.questionCanceled &&
    `
    text-decoration: line-through;
  `};
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  svg {
    fill: ${(props) =>
      props.correct ? props.theme.colors.success : props.theme.colors.error};
  }

  &:last-child {
    padding: 8px;
  }

  ${MediaQueries.mobileTablet`
    padding: 8px;
    justify-content: center;
  `}
`;

export const IconVideo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: auto;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.bg1};
  border: 1px solid ${(props) => props.theme.colors.clear};

  ${MediaQueries.mobile`
    min-height: 24px;
    min-width: 24px;
    max-height: 24px;
    max-width: 24px;
  `}

  svg {
    fill: ${(props) => props.theme.colors.clear};

    ${MediaQueries.mobile`
      width: 12px;
      height: 12px;
    `}
  }
`;

export const DownloadPDF = styled.a`
  margin-right: 16px;
  fill: ${(props) => props.theme.colors.bg1};
  color: ${(props) => props.theme.colors.bg1};
  cursor: pointer;

  svg {
    margin-right: 8px;
  }
`;

export const QuestionText = styled.span`
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  ${(props) =>
    props.questionCanceled &&
    `
    text-decoration: line-through;
  `};
  color: ${(props) =>
    props.questionCanceled ? props.theme.colors.font5 : "#0B1943"};
`;

export const NumberQuestion = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  padding-top: 3px;
  border-radius: 50%;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  color: ${(props) =>
    props.questionCanceled ? props.theme.colors.font5 : "#265BF1"};
  border: 2px solid
    ${(props) =>
      props.questionCanceled ? props.theme.colors.font5 : "#265BF1"};
`;

export const BadgeCanceled = styled.span`
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  font-size: 10px;
  padding: 1px 8px;
  margin-right: 8px;
  color: ${(props) => props.theme.colors.font1};
  background: #ffb908;
`;

export const Tbody = styled.tbody``;

export const MyAnswerDownload = styled.div`
  padding: 8px 0;
  color: ${(props) => props.theme.colors.font2};
  fill: ${(props) => props.theme.colors.font2};
  text-align: right;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  margin-left: 16px;
  margin-right: 24px;

  svg {
    margin-right: 8px;
    fill: ${(props) => props.theme.colors.font2};
  }

  ${MediaQueries.mobile`
    margin-left: 0;
  `}

  ${MediaQueries.mobileSmall`
    margin-top: 8px;
  `}
`;

export const Buttons = styled(Button)`
  color: #265bf1;
  border: 1px solid #265bf1;
  background: transparent;
`;

export const FooterButtons = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Span = styled.span`
  margin: 78px 0 8px;
  color: #0b1943;
  font-family: "Rubik", sans-serif;
  font-size: 0.75rem;
  text-align: center;
  strong {
    font-weight: 700;
    color: #0b1943;
  }
`;

export const ButtonLink = styled.a`
  align-items: center;
  border-radius: 32px;
  color: #ed2929;
  border: 2px solid #ed2929;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  height: 32px;
  justify-content: center;
  padding: 12px 20px;
  transition: all 0.6s ease;
  text-align: center;
  width: 310px;
  font-size: 0.9375rem;
  font-weight: 600;
  :first-child {
    background: #265bf1;
    width: 325px;
    height: 46px;
    border: 1px solid #265bf1;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;

    :hover {
      color: #265bf1;
      background: transparent;
    }
  }

  svg {
    margin-left: 18px;
    fill: #ed2929;
  }

  :hover {
    color: #fff;
    background: #ed2929;
    svg {
      transition: all 1s ease;
      fill: #fff;
    }
  }
`;
