// @flow
import React, { Component } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class BugXl extends Component<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 136">
        <title>Reportar problemas</title>
        <desc>Ícone de um inseto</desc>
        <circle className="cls-1" cx="76.03" cy="60.51" r="48" />
        <path d="M11.24,118.4a1.53,1.53,0,0,1-1.53-1.53c0-6.16-2-8.13-8.17-8.11A1.52,1.52,0,0,1,0,107.24a1.54,1.54,0,0,1,1.52-1.54c6.16,0,8.13-2,8.11-8.16a1.53,1.53,0,0,1,3.06,0c0,6.16,2,8.13,8.17,8.11a1.53,1.53,0,1,1,0,3.06c-6.16,0-8.13,2-8.11,8.17A1.53,1.53,0,0,1,11.24,118.4ZM8.62,107.21a7.33,7.33,0,0,1,2.59,2.57,7.25,7.25,0,0,1,2.57-2.59,7.25,7.25,0,0,1-2.59-2.57A7.33,7.33,0,0,1,8.62,107.21Z" />
        <path d="M139.59,64.82A4.41,4.41,0,1,1,144,60.41,4.42,4.42,0,0,1,139.59,64.82Zm0-5.76a1.35,1.35,0,1,0,1.35,1.35A1.36,1.36,0,0,0,139.59,59.06Z" />
        <path
          className="cls-2"
          d="M46.47,9.72a1.52,1.52,0,0,1-1.08-.45L38.73,2.61A1.53,1.53,0,0,1,40.9.45L47.55,7.1a1.54,1.54,0,0,1-1.08,2.62Z"
        />
        <path
          className="cls-2"
          d="M39.81,9.72A1.54,1.54,0,0,1,38.73,7.1L45.39.45a1.53,1.53,0,1,1,2.16,2.16L40.9,9.27A1.53,1.53,0,0,1,39.81,9.72Z"
        />
        <path
          className="cls-2"
          d="M122.25,136a1.52,1.52,0,0,1-1.52-1.53V126a1.53,1.53,0,1,1,3,0v8.43A1.52,1.52,0,0,1,122.25,136Z"
        />
        <path className="cls-2" d="M126.47,131.79H118a1.53,1.53,0,1,1,0-3.06h8.43a1.53,1.53,0,1,1,0,3.06Z" />
        <path
          className="cls-3"
          d="M30.86,35.65a50.56,50.56,0,0,1,78.5-12.26,1.5,1.5,0,1,1-2,2.2,47.51,47.51,0,1,0-6.89,74.84A1.5,1.5,0,1,1,102,103,50.51,50.51,0,0,1,30.86,35.65Z"
        />
        <path
          className="cls-3"
          d="M107.5,69a1.5,1.5,0,0,1-.25-3,1,1,0,0,0-.25,0,1.5,1.5,0,0,0-1.5,1.5c0,12.68-7.4,23-16.5,23s-16.5-10.32-16.5-23c0-11.24,6-21,14-22.73a1.5,1.5,0,1,0-.64-2.93c-5.67,1.22-10.4,5.6-13.29,11.66h-7.3L59.7,46.1a1.5,1.5,0,0,0-2.4,1.8l6,8a1.49,1.49,0,0,0,1.2.6h6.81A33.35,33.35,0,0,0,69.55,66h1a1.5,1.5,0,0,1,0,3h-1a32.89,32.89,0,0,0,1.81,9.5H64.5a1.49,1.49,0,0,0-1.2.6l-6,8a1.5,1.5,0,0,0,.3,2.1,1.52,1.52,0,0,0,.9.3,1.49,1.49,0,0,0,1.2-.6l5.55-7.4h7.33c3.47,7.21,9.53,12,16.42,12s13-4.79,16.42-12h7.33l5.55,7.4a1.49,1.49,0,0,0,1.2.6,1.52,1.52,0,0,0,.9-.3,1.5,1.5,0,0,0,.3-2.1l-6-8a1.49,1.49,0,0,0-1.2-.6h-6.84a32.89,32.89,0,0,0,1.81-9.5Z"
        />
        <path
          className="cls-3"
          d="M106,67.5a1.5,1.5,0,0,0,1.5,1.5h12a1.5,1.5,0,0,0,0-3h-12a1,1,0,0,0-.25,0A1.49,1.49,0,0,0,106,67.5Z"
        />
        <path className="cls-3" d="M72,67.5A1.5,1.5,0,0,0,70.5,66h-12a1.5,1.5,0,0,0,0,3h12A1.5,1.5,0,0,0,72,67.5Z" />
        <path
          className="cls-3"
          d="M105,64A16,16,0,1,0,89,48,16,16,0,0,0,105,64Zm0-29A13,13,0,1,1,92,48,13,13,0,0,1,105,35Z"
        />
        <path className="cls-3" d="M105,52a1.5,1.5,0,0,0,1.5-1.5v-10a1.5,1.5,0,0,0-3,0v10A1.5,1.5,0,0,0,105,52Z" />
        <circle className="cls-3" cx="105" cy="55.5" r="1.5" />
      </svg>
    );
  }
}

export default BugXl;
