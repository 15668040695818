// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Tutorial extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 136">
        <title>Tutorial</title>
        <desc>Um check dentro de um círculo</desc>
        <circle className="cls-1" cx="74.19" cy="65" r="48" />
        <path d="M109.53,21.79a1.48,1.48,0,0,1-1.48-1.49c0-6-1.93-7.91-7.92-7.91a1.49,1.49,0,1,1,0-3c6,0,7.92-1.93,7.92-7.92a1.49,1.49,0,1,1,3,0c0,6,1.93,7.92,7.92,7.92a1.49,1.49,0,1,1,0,3c-6,0-7.92,1.92-7.92,7.91A1.48,1.48,0,0,1,109.53,21.79ZM107,10.9a6.94,6.94,0,0,1,2.51,2.51A7.1,7.1,0,0,1,112,10.9a7.18,7.18,0,0,1-2.51-2.51A7,7,0,0,1,107,10.9Z" />
        <path d="M65,18.41a1.49,1.49,0,0,1,.57-2c1.69-1,1.83-1.46.87-3.16a1.49,1.49,0,1,1,2.6-1.46c1,1.69,1.46,1.83,3.15.88a1.49,1.49,0,1,1,1.47,2.59c-1.7,1-1.84,1.46-.88,3.16a1.49,1.49,0,0,1-2.59,1.46c-1-1.7-1.46-1.84-3.16-.88A1.5,1.5,0,0,1,65,18.41Z" />
        <path
          className="cls-2"
          d="M139.71,79.94A4.29,4.29,0,1,1,144,75.65,4.3,4.3,0,0,1,139.71,79.94Zm0-5.6A1.31,1.31,0,1,0,141,75.65,1.31,1.31,0,0,0,139.71,74.34Z"
        />
        <path
          className="cls-2"
          d="M8,72a1.49,1.49,0,0,1-1.06-.44L.44,65.09A1.49,1.49,0,1,1,2.54,63L9,69.46A1.49,1.49,0,0,1,8,72Z"
        />
        <path
          className="cls-2"
          d="M1.49,72a1.49,1.49,0,0,1-1-.44,1.47,1.47,0,0,1,0-2.1L6.91,63A1.49,1.49,0,1,1,9,65.09L2.54,71.56A1.49,1.49,0,0,1,1.49,72Z"
        />
        <path d="M29.21,135.56a1.48,1.48,0,0,1-.44-1.05v-9.16a1.49,1.49,0,1,1,3,0v9.16a1.48,1.48,0,0,1-2.54,1.05Z" />
        <path d="M24.63,131a1.46,1.46,0,0,1-.44-1,1.49,1.49,0,0,1,1.49-1.49h9.16a1.49,1.49,0,0,1,0,3H25.68A1.48,1.48,0,0,1,24.63,131Z" />
        <path
          className="cls-3"
          d="M30.52,90.05A50.54,50.54,0,0,1,60.8,16.6a1.5,1.5,0,1,1,.81,2.89A47.51,47.51,0,1,0,122,64.23a1.5,1.5,0,0,1,3-.06A50.51,50.51,0,0,1,30.52,90.05Z"
        />
        <path
          className="cls-3"
          d="M77.05,17.1a1.54,1.54,0,0,1-.19-.85,1.5,1.5,0,0,1,1.62-1.38,50.86,50.86,0,0,1,23.28,7.83,1.5,1.5,0,1,1-1.62,2.52,47.87,47.87,0,0,0-21.9-7.36A1.5,1.5,0,0,1,77.05,17.1Z"
        />
        <polyline className="cls-4" points="44 72 68 96 120 44" />
      </svg>
    );
  }
}

export default Tutorial;
