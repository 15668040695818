import React, { Fragment, PureComponent } from "react";
import { Box } from "ratel-ui";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LoadingSimulated from "./Loading";
import { doCompleteRankingRequest, doSimulationRequest } from "../index";

import Breadcrumbs from "../../components/breadcrumbs";
import { Ranking } from "../components/RankingComplete";
import NoUserPlaceholder from "../../components/noUserPlaceholder";
import * as S from "./styles";

class CompleteRanking extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { doSimulationRequest, simulated } = this.props;
    if (!simulated.id) doSimulationRequest();
    else this.doRankingRequest();

    window.scroll({ top: 0 });

  }

  componentDidUpdate(prevProps) {
    const { simulated } = this.props;
    if (prevProps.simulated !== this.props.simulated && simulated.slug) {
      this.doRankingRequest();
    }
  }

  doRankingRequest = () => {
    const { doCompleteRankingRequest, simulated } = this.props;
    doCompleteRankingRequest(simulated.slug);
  };

  render() {
    const { user, simulated, isFetchingSimulation, completeRanking, isFetchingSimulationCompleteRanking } = this.props;
    const breadcrumb = [
      { label: "Minha Área", url: "/medio" },
      { label: simulated.name, url: "/medio/enem-simulado" },
      { label: "Top 100" }
    ];

    return (
      <S.RankingContainer>
        <Fragment>
          <Breadcrumbs items={breadcrumb} title={simulated.name} className="questions-breadcrumbs" />
          {user.situation === "guest" ? (
            <NoUserPlaceholder />
          ) : isFetchingSimulation || isFetchingSimulationCompleteRanking ? (
            <LoadingSimulated />
          ) : (
            <Box style={{ padding: 0 }}>
              <Ranking userLevel={user.role} ranking={completeRanking} />
            </Box>
          )}
        </Fragment>
      </S.RankingContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.app.data.user,
    simulated: state.simulation.data.simulation,
    completeRanking: state.simulation.data.completeRanking,
    isFetchingSimulationCompleteRanking: state.simulation.ui.isFetchingSimulationCompleteRanking
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      doSimulationRequest,
      doCompleteRankingRequest
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteRanking);
