// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Remove extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
        <title>Ícone fechar</title>
        <desc>Ícone de um x</desc>
        <g id="icon-x-small">
          <path d="M.29.29h0a1,1,0,0,0,0,1.42L2.59,4,.29,6.29a1,1,0,0,0,0,1.42h0a1,1,0,0,0,1.42,0L4,5.41l2.29,2.3a1,1,0,0,0,1.42,0h0a1,1,0,0,0,0-1.42L5.41,4l2.3-2.29a1,1,0,0,0,0-1.42h0a1,1,0,0,0-1.42,0L4,2.59,1.71.29A1,1,0,0,0,.29.29Z" />
        </g>
      </svg>
    );
  }
}

export default Remove;
