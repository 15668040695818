// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Health extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Saúde</title>
        <desc>Estetoscópio médico</desc>
        <g id="icon-health">
          <path d="M14.89,12.22A2.83,2.83,0,0,0,12,10a2.91,2.91,0,0,0-3,3,.91.91,0,0,1-1,1,.91.91,0,0,1-1-1V10.89c3.95-.84,4.94-6.47,5-6.73A.86.86,0,0,0,12,4a3.17,3.17,0,0,0-1-2.32A2,2,0,1,0,9,4a1.94,1.94,0,0,0,1-.26c0,.06,0,.12,0,.18C9.86,4.61,8.84,9,6,9,2.05,9,2,4.2,2,4a2.13,2.13,0,0,1,0-.27A1.92,1.92,0,0,0,3,4,2,2,0,1,0,1,1.69,2.83,2.83,0,0,0,0,4c0,2.24,1.08,6.32,5,6.92V13a2.91,2.91,0,0,0,3,3,2.91,2.91,0,0,0,3-3,.91.91,0,0,1,1-1,.88.88,0,0,1,.83.39,2,2,0,1,0,2.06-.17Z" />
        </g>
      </svg>
    );
  }
}

export default Health;
