import React, { PureComponent } from "react";
import { SimulatedGuide } from "../../../core/simulationConfig";

import Timer from "../timer";
import bg1 from "../../../images/cerebro-alongando.gif";
import bg2 from "../../../images/simulation-timer-gif-02.gif";
import * as S from "./styles";
import { Footer } from "../../../components/footer";

class Clock extends PureComponent {
  render() {
    const { time, verifyStartedSimulation, currentDate, slug, name, history } = this.props;

    const { dataDoLembreteInicial, dataQueComecaSimulado, simulatedStart } = SimulatedGuide(time);

    let image = bg1;

    const now = new Date(currentDate);

    if (now > dataDoLembreteInicial && now < dataQueComecaSimulado) {
      image = bg1;
    }

    if (now >= dataQueComecaSimulado && now <= simulatedStart) {
      image = bg2;
    }

    return (
      <>
        <S.Container>
          <Timer
            slug={slug}
            history={history}
            name={name}
            date={time}
            previousDate={new Date()}
            startDate={now}
            verifyStartedSimulation={verifyStartedSimulation}
          />

          {/* 
        <S.Brain>
          <img src={image} alt="Imagem simulado" />
        </S.Brain> */}

        </S.Container>
        {/* <Footer /> */}
      </>
    );
  }
}

export default Clock;
