import initApm from "@elastic/apm-rum";

export default function InitApm() {
  try {
    initApm({
      serviceName: process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME,
      serverUrl: process.env.REACT_APP_ELASTIC_APM_SERVER_URL,
      environment: process.env.REACT_APP_ELASTIC_APM_ENVIRONMENT
    });
  } catch (e) {
    console.log("APM ERROR", e.message);
  }
}
