// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class SearchXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Lupa</title>
        <desc>Representação de uma lupa</desc>
        <g id="icon-search-xl">
          <path d="M63.71,62.29l-22-22-.07,0a24,24,0,1,0-1.39,1.39s0,0,0,.07l22,22a1,1,0,0,0,1.42,0A1,1,0,0,0,63.71,62.29ZM24,46A22,22,0,1,1,46,24,22,22,0,0,1,24,46Z" />
        </g>
      </svg>
    );
  }
}

export default SearchXl;
