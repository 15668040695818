// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Trophy extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Conquistas</title>
        <desc>Imagem de um troféu</desc>
        <g id="icon-trophy">
          <path d="M52,28s12,0,12-12c0,0,0-4-4-4H52V4s0-4-4-4H16s-4,0-4,4v8H4s-4,0-4,4S4,28,12,28c0,0,0,20,16,20V58H12a3,3,0,0,0,0,6H52a3,3,0,0,0,0-6H36V48S52,48,52,28ZM36,60H52a.88.88,0,0,1,1,1c0,.34,0,1-1,1H12a.88.88,0,0,1-1-1c0-.34,0-1,1-1H30V46H28C14.21,46,14,28.73,14,28V26H12C5.07,26,2,18.75,2,16a1.83,1.83,0,0,1,2-2H14V4a1.83,1.83,0,0,1,2-2H48a1.83,1.83,0,0,1,2,2V14H60a1.83,1.83,0,0,1,2,2c0,9.65-9,10-10,10H50v2c0,17.68-13.44,18-14,18H34V60Z" />
          <path d="M12,16H7a2,2,0,0,0-1.81,2.85C5.44,19.38,7.73,24,12,24a2,2,0,0,0,2-2V18A2,2,0,0,0,12,16Zm0,6c-3.12,0-5-4-5-4h5Z" />
          <path d="M52,24c4.27,0,6.56-4.62,6.81-5.15A2,2,0,0,0,57,16H52a2,2,0,0,0-2,2v4A2,2,0,0,0,52,24Zm0-6h5s-1.87,4-5,4Z" />
        </g>
      </svg>
    );
  }
}

export default Trophy;
