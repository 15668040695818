// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class FlowerFive extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 80">
        <title>animacao</title>
        <g id="flowerFive">
          <path d="M33.88,26.37a4.06,4.06,0,1,0-2.56-1c-.6,1-1.17,2-1.72,3a4,4,0,1,0-3.74,5.37,4.36,4.36,0,0,0,1-.13c-.6,1.18-1.17,2.33-1.71,3.46A4,4,0,1,0,21.59,43a4.26,4.26,0,0,0,.95-.12q-1.12,2.67-2,5.13a4,4,0,0,0-7,.15c-.63-1.51-1.34-3.11-2.14-4.83a4.07,4.07,0,0,0,.71.07A4,4,0,1,0,10,36a4,4,0,1,0-3.84,3A3.74,3.74,0,0,0,7,38.89q1.44,2.8,2.59,5.28a4,4,0,0,0-2.45,7.6A4.15,4.15,0,0,0,8.49,52a4,4,0,0,0,1.72-.39,3.91,3.91,0,0,0,1.84-1.84c.82,2,1.49,3.82,2,5.42A4,4,0,1,0,12.91,63a4,4,0,0,0,2.91-1.27c.65,3.49.32,4.91.3,5a.94.94,0,0,0,0,.55c0,.23,0,.44,0,.66a1.49,1.49,0,0,0,0,.21s0,.05,0,.08c-.3,7.45,1.5,11,1.66,11.27a1,1,0,0,0,.88.52,1.07,1.07,0,0,0,.48-.12,1,1,0,0,0,.4-1.36s-1.12-2.25-1.35-6.9a4,4,0,1,0,.32-7.16c.13-1.07.31-2.22.53-3.43a4,4,0,1,0,2.58-7,3.91,3.91,0,0,0-.91.12c.47-1.65,1-3.38,1.69-5.21a4,4,0,1,0,3.11-6.48,3.68,3.68,0,0,0-.58.06c.57-1.33,1.19-2.71,1.86-4.12a4,4,0,1,0,3.45-6,3.87,3.87,0,0,0-.49.05q1.56-3,3.4-6.16A3.84,3.84,0,0,0,33.88,26.37Zm-10,3.44a2,2,0,1,1,2,2A2,2,0,0,1,23.86,29.81Zm8.35,6.59a2,2,0,1,1-2-2A2,2,0,0,1,32.21,36.4ZM19.59,39a2,2,0,1,1,2,2A2,2,0,0,1,19.59,39Zm-2.51,9a2,2,0,1,1-2,2A2,2,0,0,1,17.08,48ZM9.35,49.81a2,2,0,0,1-2.67-1h0a2,2,0,0,1-.08-1.53,2,2,0,0,1,1-1.14,2,2,0,1,1,1.72,3.62ZM4.12,35a2,2,0,1,1,2,2A2,2,0,0,1,4.12,35Zm8,2.4a2,2,0,1,1-2,2A2,2,0,0,1,12.12,37.4ZM12.91,61a2,2,0,1,1,2-2A2,2,0,0,1,12.91,61Zm2.78-7.26a4,4,0,0,0,1.39.26,4,4,0,0,0,1.59-.33c-.28.95-.53,1.86-.75,2.76a3.84,3.84,0,0,0-.28,1.12c-.13.52-.24,1-.35,1.54C16.92,57.56,16.4,55.78,15.69,53.74ZM21.59,56a2,2,0,1,1-2,2A2,2,0,0,1,21.59,56ZM20.08,66.13a2,2,0,1,1-2,2A2,2,0,0,1,20.08,66.13Zm7.4-19.7a2,2,0,1,1-2-2A2,2,0,0,1,27.48,46.43Zm4.4-24.06a2,2,0,1,1,2,2A2,2,0,0,1,31.88,22.37Z" />
        </g>
      </svg>
    );
  }
}

export default FlowerFive;
