// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class LiveXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Lives</title>
        <desc>Ícone de um sinal de play</desc>
        <g id="icon-lives-xl">
          <path d="M56,56H8a8,8,0,0,1-8-8V16A8,8,0,0,1,8,8H56a8,8,0,0,1,8,8V48A8,8,0,0,1,56,56Zm6-8V16a6,6,0,0,0-6-6H8a6,6,0,0,0-6,6V48a6,6,0,0,0,6,6H56A6,6,0,0,0,62,48ZM40,32,28,24V40Z" />
        </g>
      </svg>
    );
  }
}

export default LiveXl;
