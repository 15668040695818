import { http } from "../../core/Api.js";

export function simulationRequest() {
  return {
    type: "SIMULATION/SIMULATION_REQUEST",
  };
}

export function simulationRequestSuccess(data) {
  return {
    type: "SIMULATION/SIMULATION_SUCCESS",
    data,
  };
}

export function simulationRequestFailure(data) {
  return {
    type: "SIMULATION/SIMULATION_FAILURE",
    data,
  };
}

export function simulationStartRequest() {
  return {
    type: "SIMULATION/SIMULATION_START_REQUEST",
  };
}

export function simulationStartRequestSuccess(data) {
  return {
    type: "SIMULATION/SIMULATION_START_SUCCESS",
    data,
  };
}

export function simulationStartRequestFailure(data) {
  return {
    type: "SIMULATION/SIMULATION_START_FAILURE",
    data,
  };
}

export function simulationOpenRequest() {
  return {
    type: "SIMULATION/SIMULATION_OPEN_REQUEST",
  };
}

export function simulationOpenRequestSuccess(data) {
  return {
    type: "SIMULATION/SIMULATION_OPEN_SUCCESS",
    data,
  };
}

export function simulationOpenRequestFailure(data) {
  return {
    type: "SIMULATION/SIMULATION_OPEN_FAILURE",
    data,
  };
}

export function simulationSetAnswerRequest() {
  return {
    type: "SIMULATION/SIMULATION_SET_ANSWER_REQUEST",
  };
}

export function simulationSetAnswerRequestSuccess(data) {
  return {
    type: "SIMULATION/SIMULATION_SET_ANSWER_SUCCESS",
    data,
  };
}

export function simulationSetAnswerRequestFailure(data) {
  return {
    type: "SIMULATION/SIMULATION_SET_ANSWER_FAILURE",
    data,
  };
}

export function simulationFinishRequest() {
  return {
    type: "SIMULATION/SIMULATION_FINISH_REQUEST",
  };
}

export function simulationFinishRequestSuccess(data) {
  return {
    type: "SIMULATION/SIMULATION_FINISH_SUCCESS",
    data,
  };
}

export function simulationFinishRequestFailure(data) {
  return {
    type: "SIMULATION/SIMULATION_FINISH_FAILURE",
    data,
  };
}

export function simulationRankingRequest() {
  return {
    type: "SIMULATION/SIMULATION_RANKING_REQUEST",
  };
}

export function simulationRankingRequestSuccess(data) {
  return {
    type: "SIMULATION/SIMULATION_RANKING_SUCCESS",
    data,
  };
}

export function simulationRankingRequestFailure(data) {
  return {
    type: "SIMULATION/SIMULATION_RANKING_FAILURE",
    data,
  };
}

export function simulationCompleteRankingRequest() {
  return {
    type: "SIMULATION/SIMULATION_COMPLETE_RANKING_REQUEST",
  };
}

export function simulationCompleteRankingRequestSuccess(data) {
  return {
    type: "SIMULATION/SIMULATION_COMPLETE_RANKING_SUCCESS",
    data,
  };
}

export function simulationCompleteRankingRequestFailure(data) {
  return {
    type: "SIMULATION/SIMULATION_COMPLETE_RANKING_FAILURE",
    data,
  };
}

export function simulationFeedbackRequest() {
  return {
    type: "SIMULATION/SIMULATION_FEEDBACK_REQUEST",
  };
}

export function simulationFeedbackRequestSuccess(data) {
  return {
    type: "SIMULATION/SIMULATION_FEEDBACK_SUCCESS",
    data,
  };
}

export function simulationFeedbackRequestFailure(data) {
  return {
    type: "SIMULATION/SIMULATION_FEEDBACK_FAILURE",
    data,
  };
}

export function simulationLastFeedbackRequest() {
  return {
    type: "SIMULATION/SIMULATION_LAST_FEEDBACK_REQUEST",
  };
}

export function simulationLastFeedbackRequestSuccess(data) {
  return {
    type: "SIMULATION/SIMULATION_LAST_FEEDBACK_SUCCESS",
    data,
  };
}

export function simulationLastFeedbackRequestFailure(data) {
  return {
    type: "SIMULATION/SIMULATION_LAST_FEEDBACK_FAILURE",
    data,
  };
}

export function simulationAverageRequest() {
  return {
    type: "SIMULATION/SIMULATION_AVERAGE_REQUEST",
  };
}

export function simulationAverageRequestSuccess(data) {
  return {
    type: "SIMULATION/SIMULATION_AVERAGE_SUCCESS",
    data,
  };
}

export function simulationAverageRequestFailure(data) {
  return {
    type: "SIMULATION/SIMULATION_AVERAGE_FAILURE",
    data,
  };
}

export function simulationReportRequest() {
  return {
    type: "SIMULATION/SIMULATION_REPORT_REQUEST",
  };
}

export function simulationReportRequestSuccess(data) {
  return {
    type: "SIMULATION/SIMULATION_REPORT_SUCCESS",
    data,
  };
}

export function simulationReportRequestFailure(data) {
  return {
    type: "SIMULATION/SIMULATION_REPORT_FAILURE",
    data,
  };
}

export function simulationLastRequest() {
  return {
    type: "SIMULATION/SIMULATION_LAST_REQUEST",
  };
}

export function simulationLastRequestSuccess(data) {
  return {
    type: "SIMULATION/SIMULATION_LAST_SUCCESS",
    data,
  };
}

export function simulationLastRequestFailure(data) {
  return {
    type: "SIMULATION/SIMULATION_LAST_FAILURE",
    data,
  };
}

export function simulationLastAllRequest() {
  return {
    type: "SIMULATION/SIMULATION_LAST_ALL_REQUEST",
  };
}

export function simulationLastAllRequestSuccess(data) {
  return {
    type: "SIMULATION/SIMULATION_LAST_ALL_SUCCESS",
    data,
  };
}

export function simulationLastAllRequestFailure(data) {
  return {
    type: "SIMULATION/SIMULATION_LAST_ALL_FAILURE",
    data,
  };
}

export function OneSimulationLastRequest() {
  return {
    type: "SIMULATION/ONE_SIMULATION_LAST_REQUEST",
  };
}

export function OneSimulationLastSuccess(data) {
  return {
    type: "SIMULATION/ONE_SIMULATION_LAST_SUCCESS",
    data,
  };
}

export function OneSimulationLastFailure(data) {
  return {
    type: "SIMULATION/ONE_SIMULATION_LAST_FAILURE",
    data,
  };
}

export function doSimulationAverageRequest(slug) {
  return (dispatch) => {
    dispatch(simulationAverageRequest());
    return http(`api/backend/v2/scholarshipcontest/${slug}/average`, "GET")
      .then((response) => {
        if (!response || response.error) {
          return dispatch(simulationAverageRequestFailure(response.error));
        } else {
          dispatch(simulationAverageRequestSuccess(response));
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function doSimulationRequest() {
  return (dispatch) => {
    dispatch(simulationRequest());
    return http(`api/backend/v2/simulated`, "GET")
      .then((response) => {
        if (!response || response.error) {
          return dispatch(simulationRequestFailure(response.error));
        } else {
          dispatch(simulationRequestSuccess(response));
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function doStartSimulatedRequest(simulatedID, ranking, isLast = false) {
  return (dispatch) => {
    dispatch(simulationStartRequest());
    let rout = isLast
      ? `api/backend/v2/simulatedfree/last/start`
      : `api/backend/v2/scholarshipcontest/start`;
    return http(rout, "POST", {
      simulated: simulatedID,
      ranking: ranking,
      isLast: isLast,
    })
      .then((response) => {
        if (response.error || response.status === 500) {
          return dispatch(simulationStartRequestFailure(response.error));
        } else {
          dispatch(simulationStartRequestSuccess(response));
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function doOpenSimulatedRequest(simulated) {
  return (dispatch) => {
    dispatch(simulationOpenRequest());
    return http(`api/backend/v2/scholarshipcontest/verify/started`, "POST", {
      simulated: simulated,
    })
      .then((response) => {
        if (response.error) {
          return dispatch(simulationOpenRequestFailure(response.error));
        } else {
          dispatch(simulationOpenRequestSuccess(response));
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function doInsertAnswerRequest(body) {
  return (dispatch) => {
    dispatch(simulationSetAnswerRequest());
    return http(`api/backend/v2/scholarshipcontest/answer`, "POST", body)
      .then((response) => {
        if (response.error) {
          return dispatch(simulationSetAnswerRequestFailure(response.error));
        } else {
          dispatch(simulationSetAnswerRequestSuccess(response));
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function doFinishSimulatedRequest(body, isLast) {
  return (dispatch) => {
    dispatch(simulationFinishRequest());
    const rout = isLast
      ? `api/backend/v2/simulatedfree/last/finish`
      : `api/backend/v2/scholarshipcontest/finish`;
    return http(rout, "POST", body)
      .then((response) => {
        if (response.error) {
          return dispatch(simulationFinishRequestFailure(response.error));
        } else {
          dispatch(simulationFinishRequestSuccess(response));
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function doRankingRequest(slug) {
  return (dispatch) => {
    dispatch(simulationRankingRequest());
    return http(`api/backend/v2/scholarshipcontest/${slug}/ranking`, "GET")
      .then((response) => {
        if (response.error) {
          return dispatch(simulationRankingRequestFailure(response.error));
        } else {
          dispatch(simulationRankingRequestSuccess(response));
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function doCompleteRankingRequest(slug) {
  return (dispatch) => {
    dispatch(simulationCompleteRankingRequest());
    return http(
      `api/backend/v2/scholarshipcontest/${slug}/ranking/complete`,
      "GET"
    )
      .then((response) => {
        if (response.error) {
          return dispatch(
            simulationCompleteRankingRequestFailure(response.error)
          );
        } else {
          dispatch(simulationCompleteRankingRequestSuccess(response));
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function doFeedbackRequest(body) {
  return (dispatch) => {
    dispatch(simulationFeedbackRequest());
    return http(`api/backend/v2/scholarshipcontest/feedback`, "POST", body)
      .then((response) => {
        if (response.error) {
          return dispatch(simulationFeedbackRequestFailure(response.error));
        } else {
          dispatch(simulationFeedbackRequestSuccess(response));
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function doLastFeedbackSimulationRequest(slug, tryNumber) {
  return (dispatch) => {
    dispatch(simulationLastFeedbackRequest());
    return http(
      `api/backend/v2/scholarshipcontest/last/${slug}/feedback`,
      "POST",
      {
        tryNumber: tryNumber,
      }
    )
      .then((response) => {
        if (response.error) {
          return dispatch(simulationLastFeedbackRequestFailure(response.error));
        } else {
          dispatch(simulationLastFeedbackRequestSuccess(response));
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function doReportRequest(body) {
  return (dispatch) => {
    dispatch(simulationReportRequest());
    return http(`api/backend/v2/scholarshipcontest/report`, "POST", body)
      .then((response) => {
        if (response.error) {
          return dispatch(simulationReportRequestFailure(response.error));
        } else {
          dispatch(simulationReportRequestSuccess(response));
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function doLastSimulationsRequest() {
  return (dispatch, getState) => {
    dispatch(simulationLastRequest());

    const { simulation } = getState();

    if (simulation.data.cachedLastSimulations) {
      return dispatch(
        simulationLastRequestSuccess(simulation.data.cachedLastSimulations)
      );
    }

    return http(`api/backend/v2/scholarshipcontest/last`, "GET")
      .then((response) => {
        if (response.error) {
          return dispatch(simulationLastRequestFailure(response.error));
        } else {
          return dispatch(simulationLastRequestSuccess(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function doLastAllSimulationsRequest() {
  return (dispatch, getState) => {
    dispatch(simulationLastAllRequest());

    const { simulation } = getState();

    if (simulation.data.cachedLastAllSimulations) {
      return dispatch(
        simulationLastAllRequestSuccess(
          simulation.data.cachedLastAllSimulations
        )
      );
    }

    return http(`api/backend/v2/scholarshipcontest/last/all`, "GET")
      .then((response) => {
        if (response.error) {
          return dispatch(simulationLastAllRequestFailure(response.error));
        } else {
          return dispatch(simulationLastAllRequestSuccess(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function doLastSimulationRequest(slug) {
  return (dispatch) => {
    dispatch(OneSimulationLastRequest());
    return http(`api/backend/v2/scholarshipcontest/last/${slug}`, "GET")
      .then((response) => {
        if (response.error) {
          return dispatch(OneSimulationLastFailure(response.error));
        } else {
          dispatch(OneSimulationLastSuccess(response));
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
