import React, { Component } from "react";
import classNames from "classnames";
import { Box, Button } from "ratel-ui";
import "./noUserPlaceholder.scss";
import Icon from "../icon";
import * as S from "./styles";

class NoUserPlaceholder extends Component {
  render() {
    const { className } = this.props;
    const classes = classNames("no-user-placeholder", className);
    return (
      <S.Container className={`columns is-centered ${classes}`}>
        <div className="column no-user-placeholder-centered is-10-mobile is-6-tablet is-6-desktop is-4-widescreen">
          <Box className="no-user-placeholder-box">
            <div className="no-user-placeholder-icon">
              <Icon icon={"lock"} height={136} width={144} />
            </div>
            <div className="no-user-placeholder-title">VOCÊ AINDA NÃO É UM JUBIALUNO?</div>
            <div className="no-user-placeholder-description">
              Assine agora mesmo e tenha acesso a todo o conteúdo de Biologia, Física, Química e Matemática
            </div>
            <Button onClick={() => (window.location = "/#section-plans")} link>
              VEJA COMO ASSINAR
            </Button>
          </Box>
        </div>
      </S.Container>
    );
  }
}

export default NoUserPlaceholder;
