// @flow
import React, { Component } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class CalendarXl extends Component<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Calendário</title>
        <desc>Representação de um calendário</desc>
        <g id="icon-calendar-2" data-name="icon-calendar">
          <path d="M59,10H49V8a1,1,0,0,0-2,0v2H33V8a1,1,0,0,0-2,0v2H17V8a1,1,0,0,0-2,0v2H5a5,5,0,0,0-5,5V52a5,5,0,0,0,5,5H59a5,5,0,0,0,5-5V15A5,5,0,0,0,59,10ZM5,12H15v2a1,1,0,0,0,2,0V12H31v2a1,1,0,0,0,2,0V12H47v2a1,1,0,0,0,2,0V12H59a3,3,0,0,1,3,3v2H2V15A3,3,0,0,1,5,12ZM59,55H5a3,3,0,0,1-3-3V19H62V52A3,3,0,0,1,59,55Z" />
          <rect x="54" y="47" width="4" height="4" rx="1" />
          <rect x="46" y="47" width="4" height="4" rx="1" />
          <rect x="38" y="47" width="4" height="4" rx="1" />
          <rect x="30" y="47" width="4" height="4" rx="1" />
          <rect x="22" y="47" width="4" height="4" rx="1" />
          <rect x="14" y="47" width="4" height="4" rx="1" />
          <rect x="6" y="47" width="4" height="4" rx="1" />
          <rect x="54" y="39" width="4" height="4" rx="1" />
          <rect x="46" y="39" width="4" height="4" rx="1" />
          <rect x="38" y="39" width="4" height="4" rx="1" />
          <rect x="30" y="39" width="4" height="4" rx="1" />
          <rect x="22" y="39" width="4" height="4" rx="1" />
          <rect x="14" y="39" width="4" height="4" rx="1" />
          <rect x="6" y="39" width="4" height="4" rx="1" />
          <rect x="54" y="31" width="4" height="4" rx="1" />
          <rect x="46" y="31" width="4" height="4" rx="1" />
          <rect x="38" y="31" width="4" height="4" rx="1" />
          <rect x="30" y="31" width="4" height="4" rx="1" />
          <rect x="22" y="31" width="4" height="4" rx="1" />
          <rect x="14" y="31" width="4" height="4" rx="1" />
          <rect x="6" y="31" width="4" height="4" rx="1" />
          <rect x="54" y="23" width="4" height="4" rx="1" />
          <rect x="46" y="23" width="4" height="4" rx="1" />
          <rect x="38" y="23" width="4" height="4" rx="1" />
          <rect x="30" y="23" width="4" height="4" rx="1" />
        </g>
      </svg>
    );
  }
}

export default CalendarXl;
