// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Check extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Celular</title>
        <desc>Representação de um aparelho celular</desc>
        <g id="icon-cell">
          <path d="M44,0H20a8,8,0,0,0-8,8V56a8,8,0,0,0,8,8H44a8,8,0,0,0,8-8V8A8,8,0,0,0,44,0Zm6,56a6,6,0,0,1-6,6H20a6,6,0,0,1-6-6V8a6,6,0,0,1,6-6H44a6,6,0,0,1,6,6Z" />
          <circle cx="32" cy="57" r="3" />
          <path d="M44,4H20a4,4,0,0,0-4,4V48a4,4,0,0,0,4,4H44a4,4,0,0,0,4-4V8A4,4,0,0,0,44,4Zm2,44a2,2,0,0,1-2,2H20a2,2,0,0,1-2-2V8a2,2,0,0,1,2-2H44a2,2,0,0,1,2,2Z" />
        </g>
      </svg>
    );
  }
}

export default Check;
