import styled from "styled-components";

export const Container = styled.div`
  .modal-print-feedback {
    &-card {
      position: relative;
      width: auto;
      border-radius: 8px;
      overflow: hidden;
    }

    &-header {
      justify-content: space-between;
      align-items: flex-start;
      padding: 16px;
      text-align: left;
      background-color: ${props => props.theme.colors.bg1};
    }

    &-title {
      display: block;
      font-weight: 700;
      text-align: left;
      line-height: 24px;
      font-size: 1.5rem;
      color: ${props => props.theme.colors.clear};
    }

    &-subtitle {
      display: inline-block;
      width: 100%;
      font-size: 1rem;
      text-align: left;
      color: ${props => props.theme.colors.clear};
    }

    &-close {
      display: flex;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.15);
    }

    &-icon {
      margin: auto;
      fill: ${props => props.theme.colors.clear};
    }

    &-body {
      width: 100%;
      padding: 16px;
      background-color: ${props => props.theme.colors.transparent};
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-video {
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      background-color: ${props => props.theme.colors.transparent};

      &-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px 16px;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 64px;
      padding: 16px;
      border-top: 1px solid ${props => props.theme.colors.font7};
      background: ${props => props.theme.colors.transparent};
    }
  }
`;
