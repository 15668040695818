// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class User extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone usuário</title>
        <desc>Ícone representando um usuário</desc>
        <g id="icon-user">
          <path d="M15,13.07C15,17,1,17,1,13.07a7.08,7.08,0,0,1,4-6.4,4,4,0,1,1,5.92,0A7.08,7.08,0,0,1,15,13.07ZM8,8C5.24,8,3,9.9,3,12.24s10,2.35,10,0S10.76,8,8,8ZM8,2a2,2,0,1,0,2,2A2,2,0,0,0,8,2Z" />
        </g>
      </svg>
    );
  }
}

export default User;
