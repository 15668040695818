// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Send extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} viewBox="0 0 16 16">
        <title>Enviar mensagem</title>
        <desc>Enviar mensagem</desc>
        <g id="icon-send">
          <path d="M15.45,7.11l-14-7A1,1,0,0,0,0,1V6.07A1,1,0,0,0,.63,7L10,8,.63,9A1,1,0,0,0,0,9.93V15a1,1,0,0,0,1.45.89l14-7A1,1,0,0,0,15.45,7.11Z" />
        </g>
      </svg>
    );
  }
}

export default Send;
