// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class PencilXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Exercícios</title>
        <desc>Representação de um lápis</desc>
        <g id="icon-pencil-xl">
          <path d="M62.54,16.64,47.36,1.46a5,5,0,0,0-7.07,0L.29,41.29A1,1,0,0,0,0,42V63a1,1,0,0,0,1,1H22a1,1,0,0,0,.71-.29l39.83-40A5,5,0,0,0,62.54,16.64ZM21.58,62H11L2,53V42.42L34.57,10,54,29.43ZM61.12,22.29,55.43,28,36,8.57l5.72-5.69a3.08,3.08,0,0,1,4.24,0L61.12,18.05a3,3,0,0,1,0,4.24Z" />
        </g>
      </svg>
    );
  }
}

export default PencilXl;
