import React, { PureComponent } from "react";
import classNames from "classnames";

class Quote extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>Aspas</title>
        <desc>Ícone de aspas</desc>
        <g id="icon-quote">
          <path
            className="cls-1"
            d="M17.72254,5.99661H15.44508a2.28814,2.28814,0,0,0-2.246,2.17847c-.00445.026-.0203.0461-.02336.0728-.0745.65093-.41479,3.98322.11788,5.60026A4.43962,4.43962,0,0,0,16.01469,16.76a4.345,4.345,0,0,0,2.11909.21066,1.16083,1.16083,0,0,0,.85576-1.28624,1.19185,1.19185,0,0,0-.50857-.83c-.358-.24017-.7134-.13825-1.1139-.17466A2.211,2.211,0,0,1,15.45092,13.099a.16875.16875,0,0,1-.00584-.08607h2.27746A2.30885,2.30885,0,0,0,20,10.67415V8.33538A2.30875,2.30875,0,0,0,17.72254,5.99661Z"
          />
          <path
            className="cls-1"
            d="M8.72254,5.97292H6.44508A2.28814,2.28814,0,0,0,4.199,8.15138c-.00445.026-.0203.04611-.02336.07281-.0745.65092-.41479,3.98322.11788,5.60026a4.43965,4.43965,0,0,0,2.72113,2.91188,4.345,4.345,0,0,0,2.11909.21066,1.16083,1.16083,0,0,0,.85576-1.28624,1.19185,1.19185,0,0,0-.50857-.83c-.358-.24018-.7134-.13825-1.1139-.17466a2.211,2.211,0,0,1-1.91615-1.58078.16879.16879,0,0,1-.00584-.08608H8.72254A2.30884,2.30884,0,0,0,11,10.65046V8.31169A2.30875,2.30875,0,0,0,8.72254,5.97292Z"
          />
        </g>
      </svg>
    );
  }
}

export default Quote;
