import { useRef, useEffect, useState } from "react";
import VanillaTilt from "vanilla-tilt";
import MediaQuery from "react-responsive";

import useForm from "../../utils/useForm";
import AssetIcon from "../../images/icone-simulado.png";
import AssetLogoDesktop from "../../images/logo-text.svg";
import AssetLogoMobile from "../../images/logoAprovaBranco.svg";

import * as S from "./styles";

export function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

const options = {
  scale: 1,
  speed: 500,
  max: 2,
  glare: true,
  "max-glare": 0.2,
};

export const SectionMainD = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [allUrlParams, setAllUrlParams] = useState("");
  const [isSecondPhase, setIsSecondPhase] = useState(true);
  const [{ values }, handleChange, handleSubmit] = useForm();

  useEffect(() => {
    setIsMounted(true);
    setAllUrlParams(window.location.search);
  }, []);

  const enviarContato = () => {
    localStorage.setItem("inscrito-simulado-super-med-i-2024", JSON.stringify(values));
    setLoading(true);
    return history.push("/simulado");
  };

  const handleCpfField = (e) => {
    e.target.value = cpfMask(e.target.value);
    handleChange(e);
  };

  const cpfMask = (cpf) => {
    return cpf
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  };

  return (
    <S.Section>
      <S.Container>
        <MediaQuery query="(max-width: 1023px)">
          <S.Logo src={AssetLogoMobile} alt="Logotipo do Aprova Total" />
        </MediaQuery>
        <MediaQuery query="(min-width: 1024px)">
          <S.Logo src={AssetLogoDesktop} alt="Logotipo do Aprova Total" />
        </MediaQuery>

        <S.Column maxWidth="543px">
          <S.AssetIcon src={AssetIcon} alt="" />
          <S.Title>
            <strong>SIMULADO</strong> SUPER MED
          </S.Title>

          <S.WhiteText>
            <strong>RESULTADO DISPONÍVEL! <br/> Acesse agora o resultado e gabarito do simulado Super MED.</strong>
            <span>
              Confira todas as informações no{" "}
              <a
                href="https://content.app-us1.com/ldV4B/2023/12/04/77daa1ee-7196-4135-b965-a1a119fe0829.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                regulamento
              </a>
              .
            </span>
          </S.WhiteText>
        </S.Column>

        <S.Column maxWidth="400px" marginRight alignCenter>
          {isMounted ? (
            <S.CardTilt options={options}>

              <S.FormTitle>
                PREENCHA SEUS DADOS PARA
                <br /> FAZER O LOGIN
              </S.FormTitle>

              <S.Form
                onSubmit={handleSubmit(enviarContato)}
                isSecondPhase={isSecondPhase}
              >
                <S.FormInputWrapper>
                  <S.Input
                    required
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    onChange={handleChange}
                  />
                  <S.Input
                    required
                    name="cpf"
                    type="text"
                    maxLength={14}
                    placeholder="CPF"
                    mask="999.999.999-99"
                    onChange={e => handleCpfField(e)}
                  />

                  <S.Button type="submit" disabled={loading} loading={loading}>
                    <span>FAZER LOGIN</span>
                  </S.Button>
                </S.FormInputWrapper>
              </S.Form>
            </S.CardTilt>
          ) : null}
        </S.Column>
      </S.Container>
    </S.Section>
  );
};
