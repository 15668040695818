// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Pin extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Pergunta fixada</title>
        <desc>Representação de um alfinete</desc>
        <path
          d="M-1.207-21.207l-2.586-2.586a.707.707,0,0,0-1,0h0a.707.707,0,0,0,0,1l.248.248L-10-18l-.793-.793a.707.707,0,0,0-1,0h0a.707.707,0,0,0,0,1l1.852,1.852L-17-8l7.941-7.059,1.852,1.852a.707.707,0,0,0,1,0h0a.707.707,0,0,0,0-1L-7-15l4.545-5.455.248.248a.707.707,0,0,0,1,0h0A.707.707,0,0,0-1.207-21.207Z"
          transform="translate(17 24)"
        />
      </svg>
    );
  }
}

export default Pin;
