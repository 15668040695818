// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Smile extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} viewBox="0 0 64 64">
        <title>Bem-vindo!</title>
        <desc>Ícone de um rosto feliz</desc>
        <g id="icon-smile">
          <path d="M32,0A32,32,0,1,0,64,32,32,32,0,0,0,32,0Zm0,62A30,30,0,1,1,62,32,30,30,0,0,1,32,62Z" />
          <path d="M48.57,39.1a1,1,0,0,0-1.34.45,17,17,0,0,1-30.46,0,1,1,0,1,0-1.79.9,19,19,0,0,0,34,0A1,1,0,0,0,48.57,39.1Z" />
          <ellipse cx="24" cy="24.5" rx="2" ry="4.5" />
          <ellipse cx="40" cy="24.5" rx="2" ry="4.5" />
        </g>
      </svg>
    );
  }
}

export default Smile;
