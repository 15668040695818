import React from "react";
import * as S from "./styles";

const Timeline = ({ currentStep, totalSteps }) => {
  return (
    <S.Timeline>
      {[1, 2, 3].map((index) => {
        const firstChild = index === 0 ? "firstChild" : "";
        const lastChild =
          index === 7 && currentStep === 7 ? "lastChild" : "";
        const active = currentStep >= index ? "active" : "";

        return (
          <S.Item
            key={index}
            className={`${active} ${firstChild} ${lastChild}`}
          />
        );
      })}
    </S.Timeline>
  );
};

export default Timeline;
