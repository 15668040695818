// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class CrownXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Coroa</title>
        <desc>Representação de uma coroa</desc>
        <g id="icon-crown-xl">
          <path d="M59.5,16a4.48,4.48,0,0,0-2.84,8L43.41,38.1,33.32,8.78a4.5,4.5,0,1,0-2.64,0L20.59,38.1,7.34,24A4.47,4.47,0,1,0,4.5,25a3.68,3.68,0,0,0,.58-.06L8,57.05a3.49,3.49,0,0,0,.5,7h47a3.49,3.49,0,0,0,.59-6.94l2.83-32.12a3.68,3.68,0,0,0,.58.06,4.5,4.5,0,0,0,0-9ZM2,20.5A2.5,2.5,0,1,1,4.5,23,2.5,2.5,0,0,1,2,20.5Zm27.5-16A2.5,2.5,0,1,1,32,7,2.5,2.5,0,0,1,29.5,4.5ZM57,60.5A1.5,1.5,0,0,1,55.5,62H8.5a1.5,1.5,0,0,1,0-3h47A1.5,1.5,0,0,1,57,60.5ZM54.08,57H10s0-.06,0-.09L7.26,26.81l13,13.87a1,1,0,0,0,.94.3,1,1,0,0,0,.74-.65L32,11.08l10,29.25a1,1,0,0,0,.74.65,1,1,0,0,0,.94-.3l13-13.88ZM59.5,23A2.5,2.5,0,1,1,62,20.5,2.5,2.5,0,0,1,59.5,23Z" />
        </g>
      </svg>
    );
  }
}

export default CrownXl;
