// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Star extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Estrelas</title>
        <desc>Imagem de uma estrela</desc>
        <g id="icon-star">
          <path d="M8,3.53,9,6,9.4,7.11l1.18.14,2.15.27L11.32,8.65l-1,.81.31,1.24L11.2,13,9.08,11.58,8,10.89l-1.08.69L4.8,13l.57-2.25.31-1.24-1-.81L3.27,7.52l2.15-.27L6.6,7.11,7,6,8,3.53M8,0A1.14,1.14,0,0,0,7,.6L5.17,5.27,1,5.78a1.15,1.15,0,0,0-.61,2l3.05,2.44L2.31,14.65A1.14,1.14,0,0,0,3.43,16a1.18,1.18,0,0,0,.5-.12L8,13.27l4.07,2.61a1.18,1.18,0,0,0,.5.12,1.14,1.14,0,0,0,1.12-1.35l-1.12-4.44,3-2.44a1.15,1.15,0,0,0-.61-2l-4.18-.51L9,.6A1.14,1.14,0,0,0,8,0Z" />
        </g>
      </svg>
    );
  }
}

export default Star;
