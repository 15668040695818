import React from "react";
import { Box } from "ratel-ui";

import Logo from "../../../images/logo-text.svg";

import * as S from "./style";

const AnswersRegistered = (props) => {
  return (
    <S.Container>
      <S.SimulatedHeader>
        <S.Logo src={Logo} alt="Logotipo do Aprova Total" />
        <S.HeaderTitle><strong>SIMULADO</strong> SUPER MED</S.HeaderTitle>
      </S.SimulatedHeader>
      <Box className="box">
        <S.Title>
          Simulado finalizado
        </S.Title>
        <S.Text>
          <span><strong>Suas respostas foram registradas!</strong></span>
          Divulgaremos o <strong>gabarito</strong> e o <strong>resultado</strong> do simulado Super Med <strong>por e-mail</strong> no dia <strong>14 de dezembro</strong>, a partir das <strong>19h</strong>.
        </S.Text>

      </Box>
    </S.Container>
  );
};

export default AnswersRegistered;
