import styled from "styled-components";
import { MediaQueries } from "ratel-ui";

import Icon from "../../../components/icon";
import Tabbable from "../../../components/tabbable";

export const Container = styled.div``;

export const Card = styled.div`
  position: relative;
  width: 480px;
  border-radius: 16px;
  overflow: hidden;
`;

export const Background = styled.div``;

export const Header = styled.header`
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  text-align: left;
  background-color: ${props => props.theme.colors.bg1};
`;

export const Title = styled.span`
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: left;
  line-height: 24px;
  color: ${props => props.theme.colors.clear};
`;

export const Close = styled(Tabbable)`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const CloseIcon = styled(Icon)`
  margin: auto;
  fill: ${props => props.theme.colors.clear};
`;

export const Body = styled.section`
  width: 100%;
  overflow: auto;
  padding: 16px;
  background-color: ${props => props.theme.colors.transparent};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Text = styled.div`
  font-size: 0.875rem;
  text-align: left;
  color: ${props => props.theme.colors.font1};

  strong {
    font-weight: 600;
    color: ${props => props.theme.colors.font1};
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 16px;
  border-radius: 0 0 16px 16px;
  background-color: ${props => props.theme.colors.transparent};

  .button {
    margin-left: 16px;
  }

  ${MediaQueries.mobile`
    display: block;
    width: 100%;
    padding: 8px 16px 16px 16px;

    .button {
      margin-left: 0;
      margin-top: 8px;
      width: 100%;
    }
  `}
`;
