import React, { useState } from "react";
import useCountdown from "../HookCounter";
import * as S from "./styles";

function SimulateRunningTimer({ warning, textHide, date, forceFinish, AlertUser }) {
  const countdown = useCountdown(date);
  const [hasForcedFinish, setForcedFinish] = useState(false);
  const { loading, alert15min, alert1min, alert30min, hours, minutes, seconds, isFinished } = countdown;
  const [event, setEvent] = useState(true);

  if (isFinished && !hasForcedFinish) {
    setForcedFinish(true);
    forceFinish();
  }

  if (alert30min && event) {
    setEvent(false);
    fSetEvent();
    AlertUser("alert30min");
  }

  if (alert15min && event) {
    setEvent(false);
    fSetEvent();
    AlertUser("alert15min");
  }

  if (alert1min && event) {
    setEvent(false);
    fSetEvent();
    AlertUser("alert1min");
  }

  function fSetEvent() {
    setTimeout(() => {
      setEvent(true);
    }, 66000);
  }
  const textHours = loading ? "--" : String(hours).padStart(2, "0");
  const textMinutes = loading ? "--" : String(minutes).padStart(2, "0");
  const textSeconds = loading ? "--" : String(seconds).padStart(2, "0");

  return (
    <S.Text warning={warning}>
      <S.Box>
        <S.Item>
          <S.Number warning={warning}>{textHours}:</S.Number>
        </S.Item>
        <S.Item>
          <S.Number warning={warning}>{textMinutes}:</S.Number>
        </S.Item>
        <S.Item>
          <S.Number warning={warning}>{textSeconds}</S.Number>
        </S.Item>
      </S.Box>
      {!textHide && "tempo restante"}
    </S.Text>
  );
}

export default SimulateRunningTimer;
