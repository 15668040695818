import React, { useState, useEffect } from "react";
import MediaQuery from "react-responsive";
import { Button } from "ratel-ui";
import Logo from '../../../images/logoAprovaBranco.svg'

import ModalRules from "../ModalRules";
import useCountdown from "../HookCounter";
import RenderModal from "../../../components/renderModal";
import * as S from "./styles";


export default function Timer({ date, previousDate, name, startDate, history, slug }) {
  const [isToggleModal, setIsToggleModal] = useState(false);

  useEffect(() => {
    document.addEventListener("keyup", () => setIsToggleModal(false));
  });

  const countdown = useCountdown(date);
  const { loading, days, hours, minutes, seconds, isFinished } = countdown;

  if (isFinished) {
    history.push(`/simulado/${slug}`);

    return (
      <S.Box hasStarted={isFinished}>
        <S.Text hasStarted={isFinished}>
          <strong>O simuladão já começou!</strong> <br />
          Clique no botão abaixo para recarregar a página e iniciar o simulado.
        </S.Text>
        <Button
          outline
          link
          onClick={() => {
            window.location.reload();
          }}>
          ATUALIZAR
        </Button>
      </S.Box>
    );
  }

  const daysText = loading ? "--" : days;
  const hoursText = loading ? "--" : hours;
  const minutesText = loading ? "--" : minutes < 0 ? "00" : minutes;
  const secondsText = loading ? "--" : seconds < 0 ? "00" : seconds;

  return (
    <>
      <S.Logo src={Logo} alt="Logotipo do Aprova Total" />

      <S.Text>
        {/* <S.TextHeader>
          <h1>INSCRIÇÃO CONFIRMADA!</h1>
          <h2>O {name} começa em</h2>
        </S.TextHeader>

        <S.Box>
          {days > 0 && (
            <S.Item>
              <S.Number>{daysText}</S.Number>
              <S.Label>{days <= 1 ? "DIA" : "DIAS"}</S.Label>
            </S.Item>
          )}
          {hours > 0 && (
            <S.Item>
              <S.Number>{hoursText}</S.Number>
              <S.Label>{hours <= 1 ? "HORA" : "HORAS"}</S.Label>
            </S.Item>
          )}
          <S.Item>
            <S.Number>{minutesText}</S.Number>
            <MediaQuery query="(min-width: 375px)">
              <S.Label>{minutes <= 1 ? "MINUTO" : "MINUTOS"}</S.Label>
            </MediaQuery>
            <MediaQuery query="(max-width: 374px)">
              <S.Label>MIN</S.Label>
            </MediaQuery>
          </S.Item>
          <S.Item>
            <S.Number>{secondsText}</S.Number>
            <MediaQuery query="(min-width: 375px)">
              <S.Label>{seconds <= 1 ? "SEGUNDO" : "SEGUNDOS"}</S.Label>
            </MediaQuery>
            <MediaQuery query="(max-width: 374px)">
              <S.Label>SEG</S.Label>
            </MediaQuery>
          </S.Item>
        </S.Box>
        <S.TextFooter>
          Não deixe de conferir as regras e horários
        </S.TextFooter>
        <br />
        <S.ButtonPosition>
          <S.Btn
            outline
            transparent
            onClick={() => setIsToggleModal(!isToggleModal)}>
            VER REGRAS
          </S.Btn>

        </S.ButtonPosition>

        <RenderModal>{isToggleModal && <ModalRules toggleModal={() => setIsToggleModal(!isToggleModal)} />}</RenderModal> */}
      </S.Text>
    </>

  );
}
