// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Diversas extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <g id="icon-misc">
          <path d="M15,7H10.41l3.25-3.24a1,1,0,0,0-1.42-1.42L9,5.59V1A1,1,0,0,0,7,1V5.59L3.76,2.34A1,1,0,0,0,2.34,3.76L5.59,7H1A1,1,0,0,0,1,9H5.59L2.34,12.24a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L7,10.41V15a1,1,0,0,0,2,0V10.41l3.24,3.25a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L10.41,9H15a1,1,0,0,0,0-2Z" />
        </g>
      </svg>
    );
  }
}

export default Diversas;
