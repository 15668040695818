// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

class Ribbon extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <title>Fita</title>
        <desc>Imagem de uma fita/medalha com o rosto de um aluno no centro</desc>
        <g id="icon-ribbon">
          <path id="Caminho_696" data-name="Caminho 696" className="cls-1" d="M48,128l16-8,16,8V80H48Z" />
          <path
            id="Caminho_697-2"
            data-name="Caminho 697-2"
            className="cls-2"
            d="M112.9,68.36,120,56l-7.36-12.82-.14-14.7-12.44-7.22L92,7.22H75.88L63.42,0,50.7,7.22H36L28.9,19.6,14.94,27.52l.16,16.12L8,56l7.36,12.82.14,14.7,12.44,7.22L36,104.78H52.12L64.58,112l12.72-7.22H92L99.1,92.4l13.96-7.92Z"
          />
          <circle id="Elipse_192" data-name="Elipse 192" className="cls-1" cx="64" cy="56" r="37" />
        </g>
      </svg>
    );
  }
}

export default Ribbon;
