// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class arrowUp extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
        <title>Ícone seta para cima</title>
        <desc>Ícone de uma seta apontando para cima</desc>
        <g id="icon-up">
          <path d="M.29,4.29l3-3a1,1,0,0,1,1.42,0l3,3a1,1,0,0,1,0,1.42h0a1,1,0,0,1-1.42,0L4,3.41,1.71,5.71a1,1,0,0,1-1.42,0h0A1,1,0,0,1,.29,4.29Z" />
        </g>
      </svg>
    );
  }
}

export default arrowUp;
