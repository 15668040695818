import React, { PureComponent } from "react";
import classNames from "classnames";

class LinkShare extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>link</title>
        <desc>ícone de link</desc>
        <g id="icon-link">
          <path
            class="cls-1"
            d="M24.23507,7.76493a4.28028,4.28028,0,0,0-6.04161-.1347l-3.86314,3.86315a5.51019,5.51019,0,0,1,2.83089.46313l2.67927-2.67926a2.0401,2.0401,0,0,1,2.88227,2.88227L18.281,16.60125a1.99754,1.99754,0,0,1-2.81942-.06286,1.11493,1.11493,0,1,0-1.57518,1.57518,4.28028,4.28028,0,0,0,6.04161.13469l4.44173-4.44172A4.28028,4.28028,0,0,0,24.23507,7.76493Z"
          />
          <path
            class="cls-1"
            d="M14.81067,20.07161l-2.65115,2.65114a2.0401,2.0401,0,0,1-2.88227-2.88227L13.719,15.39875a1.99754,1.99754,0,0,1,2.81942.06286,1.11493,1.11493,0,1,0,1.57518-1.57518,4.28028,4.28028,0,0,0-6.04161-.13469L7.63023,18.19346a4.28028,4.28028,0,0,0,.1347,6.04161,4.28028,4.28028,0,0,0,6.04161.1347l3.85223-3.85224A5.51251,5.51251,0,0,1,14.81067,20.07161Z"
          />
        </g>
      </svg>
    );
  }
}

export default LinkShare;
