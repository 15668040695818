// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class ArrowRight extends PureComponent<Props> {
  render() {
    const { height, width, className, style } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg
        className={classes}
        style={style}
        height={height}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 8 8">
        <title>Ícone seta direita</title>
        <desc>Ícone de uma seta apontando para a direita</desc>
        <g id="icon-right">
          <path d="M3.71.29l3,3a1,1,0,0,1,0,1.42l-3,3a1,1,0,0,1-1.42,0h0a1,1,0,0,1,0-1.42L4.59,4,2.29,1.71a1,1,0,0,1,0-1.42h0A1,1,0,0,1,3.71.29Z" />
        </g>
      </svg>
    );
  }
}

export default ArrowRight;
