import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  display: block;
  width: 248px;
  padding: 8px 8px 16px 8px;
  text-align: left;
  color: ${props => props.theme.colors.clear};
  background-color: ${props => props.theme.colors.bg2};
  outline: none;
  pointer-events: none;
  opacity: 0;
  border-radius: 8px;
  transform: translateY(10px);
  transition: all 0.25s ease-out;

  &.active {
    display: block;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0px);
  }

  &.info {
    top: 50px;
    right: 0;
  }

  &.subarea {
    top: 150px;
    right: 0;
  }

  &.list {
    top: 60px;
    left: 0%;
    width: 80%;
    font-size: 0.625rem;
    z-index: 99;
  }

  &.account {
    top: 55px;
    right: 53px;
    width: auto;
    min-width: 175px;
    padding: 16px 8px;
  }

  &:after {
    content: " ";
    position: absolute;
    top: -10px;
    left: 88%;
    width: 0;
    height: 0;
    margin-left: -13px;
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-bottom: solid ${props => props.theme.colors.bg2} 10px;
  }

  .tooltip-text {
    display: inline-block;
    font-size: 0.75rem;
    line-height: 14px;
  }

  .tooltip-item {
    display: flex;
    padding: 8px 16px;
    position: relative;

    &.tabblabe {
      width: 100%;
      text-align: left;
    }

    &:hover,
    &:focus {
      border-radius: 24px;
      background-color: rgba(0, 0, 0, 0.15);
    }

    &:not(:last-child) {
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 0;
        width: 32px;
        height: 1px;
        background-color: ${props => props.theme.colors.clear};
        opacity: 0.3;
      }
    }
  }

  .tooltip-icon {
    background-color: ${props => props.theme.colors.clear};
    width: 32px;
    height: 32px;
  }

  .tooltip-label {
    color: ${props => props.theme.colors.clear};
    flex: 1 auto;
    margin-top: 6px;

    &:hover,
    &:focus {
      color: ${props => props.theme.colors.clear};
    }
  }
`;
