// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Info extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Mais informações</title>
        <desc>Símbolo de interrogação</desc>
        <g id="icon-info">
          <path d="M16,8A8,8,0,1,1,8,0,8,8,0,0,1,16,8ZM8,10V9a2.94,2.94,0,0,0,3-3A2.94,2.94,0,0,0,8,3,2.94,2.94,0,0,0,5,6H7A1,1,0,1,1,8,7C7,7,6,7,6,9v1ZM7,11a1,1,0,1,0,1,1A1,1,0,0,0,7,11Z" />
        </g>
      </svg>
    );
  }
}

export default Info;
