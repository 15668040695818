// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Login extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 136">
        <title>Login</title>
        <desc>Ícone representando um usuário</desc>
        <circle className="cls-1" cx="76.03" cy="60.51" r="48" />
        <path d="M11.24,118.4a1.53,1.53,0,0,1-1.53-1.53c0-6.16-2-8.13-8.17-8.11A1.52,1.52,0,0,1,0,107.24a1.54,1.54,0,0,1,1.52-1.54c6.16,0,8.13-2,8.11-8.16a1.53,1.53,0,0,1,3.06,0c0,6.16,2,8.13,8.17,8.11a1.53,1.53,0,1,1,0,3.06c-6.16,0-8.13,2-8.11,8.17A1.53,1.53,0,0,1,11.24,118.4ZM8.62,107.21a7.33,7.33,0,0,1,2.59,2.57,7.25,7.25,0,0,1,2.57-2.59,7.25,7.25,0,0,1-2.59-2.57A7.33,7.33,0,0,1,8.62,107.21Z" />
        <path d="M139.59,64.82A4.41,4.41,0,1,1,144,60.41,4.42,4.42,0,0,1,139.59,64.82Zm0-5.76a1.35,1.35,0,1,0,1.35,1.35A1.36,1.36,0,0,0,139.59,59.06Z" />
        <path
          className="cls-2"
          d="M46.47,9.72a1.52,1.52,0,0,1-1.08-.45L38.73,2.61A1.53,1.53,0,0,1,40.9.45L47.55,7.1a1.54,1.54,0,0,1-1.08,2.62Z"
        />
        <path
          className="cls-2"
          d="M39.81,9.72A1.54,1.54,0,0,1,38.73,7.1L45.39.45a1.53,1.53,0,1,1,2.16,2.16L40.9,9.27A1.53,1.53,0,0,1,39.81,9.72Z"
        />
        <path
          className="cls-2"
          d="M122.25,136a1.52,1.52,0,0,1-1.52-1.53V126a1.53,1.53,0,1,1,3,0v8.43A1.52,1.52,0,0,1,122.25,136Z"
        />
        <path className="cls-2" d="M126.47,131.79H118a1.53,1.53,0,1,1,0-3.06h8.43a1.53,1.53,0,1,1,0,3.06Z" />
        <path d="M118.57,87.44a1.5,1.5,0,0,1-1.37,0,1.53,1.53,0,0,1-.66-2.07,49.16,49.16,0,0,0,.54-44.06A1.53,1.53,0,0,1,119.83,40a52.26,52.26,0,0,1-.57,46.81A1.49,1.49,0,0,1,118.57,87.44Z" />
        <path d="M80.05,35A14.78,14.78,0,1,1,65.28,49.78,14.8,14.8,0,0,1,80.05,35m0-3A17.78,17.78,0,1,0,97.83,49.78,17.78,17.78,0,0,0,80.05,32Z" />
        <path d="M112,97.5a1.51,1.51,0,0,1-1.5-1.42A30.55,30.55,0,0,0,90.22,69a1.5,1.5,0,0,1,1-2.83A33.58,33.58,0,0,1,113.5,95.92a1.51,1.51,0,0,1-1.42,1.58Z" />
        <path d="M48.11,97.5H48a1.51,1.51,0,0,1-1.42-1.58A33.58,33.58,0,0,1,68.89,66.18a1.5,1.5,0,0,1,1,2.83A30.51,30.51,0,0,0,49.61,96.08,1.51,1.51,0,0,1,48.11,97.5Z" />
        <path
          className="cls-3"
          d="M30.86,35.65a50.56,50.56,0,0,1,78.5-12.26,1.5,1.5,0,1,1-2,2.2,47.51,47.51,0,1,0-6.89,74.84A1.5,1.5,0,1,1,102,103,50.51,50.51,0,0,1,30.86,35.65Z"
        />
        <path d="M114.56,37.44A1.52,1.52,0,0,1,113,35.91c0-2-.38-2.38-2.38-2.38a1.53,1.53,0,0,1,0-3.06c2,0,2.38-.38,2.38-2.38a1.53,1.53,0,0,1,3.06,0c0,2,.38,2.38,2.38,2.38a1.53,1.53,0,0,1,0,3.06c-2,0-2.38.38-2.38,2.38A1.53,1.53,0,0,1,114.56,37.44Z" />
      </svg>
    );
  }
}

export default Login;
