import styled, { keyframes } from "styled-components";
import { Button } from "ratel-ui";
const FadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  color: ${(props) => props.theme.colors.clear};
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 8;
  text-align: center;
  ${(props) =>
    props.hasStarted &&
    `
    strong {
      display: block;
      margin-bottom: 16px;
      font-size: 2.5rem;
      color: ${props.theme.colors.clear};
    }
  `};
`;

export const Scrollable = styled.div`
  overflow: auto;

  ${(props) =>
    props.noCounter &&
    `
    margin-bottom: 0;
  `};
`;

export const LogoText = styled.img`
  width: 140px;
  margin-bottom: 16px;
`;

export const Title = styled.div`
  margin-bottom: 48px;
  color: #fff;
  font-size: 1.75rem;
  text-align: center;

  > strong {
    color: #fff;
    font-weight: 900;
  }

  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
`;

export const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 792px;
  padding: 32px;
  color: #061946;
  background: #ffffff;
  border-radius: 8px;

  @media (max-width: 767px) {
    width: 342px;
    max-width: 342px;
    padding: 32px 16px;
  }
`;

export const IconCircle = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #555555;
  cursor: pointer;
  z-index: 99;

  > svg {
    fill: #555555;
  }

  @media (max-width: 767px) {
    top: 8px;
    left: 8px;
    width: 16px;
    height: 16px;
    border: none;
    position: unset;
    margin-bottom: 16px;
  }
`;
export const BackButton = styled.div`
  position: absolute;
  left: 24px;
  top: 24px;
`;

export const Instruction = styled.div`
  display: none;
  flex-direction: column;
  position: relative;

  &.visible {
    animation: ${FadeIn} 0.8s;
    display: flex;
  }
`;

export const NextButton = styled(Button)`
  width: fit-content;
  height: 46px;
  margin: 0 auto;
  padding: 12px 50px;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 24px;
  background: #FD742F;
  border: 1px solid #FD742F;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  :hover {
    background: #e3682a;
    border: 1px solid #e3682a;
  }

  ${props => props.transparentButton && `
    color: #FD742F;
    background: transparent;

    :hover {
      color: #fff;
      background: #e3682a;
      border: 1px solid #e3682a;
    }
  `};

  @media (max-width: 767px) {
    width: 100%;
    padding: 12px 32px;
  }
`;

export const StartSimulated = styled.a`
  margin-top: 16px;
  color: #005afa;
  line-height: 19px;
  font-size: 0.875rem;
  text-decoration-line: underline;
`;
