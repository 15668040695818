// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class LikeTrace extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>Ícone curtir</title>
        <desc>Ícone de mão com polegar para cima</desc>
        <g id="icon-like-trace">
          <path d="M4,22H2a2,2,0,0,1-2-2V10A2,2,0,0,1,2,8H4a2,2,0,0,1,2,2H6s6-4.56,6-9a1,1,0,0,1,1-1c.75,0,2,2,1,6a2,2,0,0,0,2,2h6a2,2,0,0,1,2,2c0,1.16-3.26,9.14-4.61,12.71A2,2,0,0,1,17,23.93L6,20H6A2,2,0,0,1,4,22Zm14-.59L22,11a1,1,0,0,0-1-1H16c-4,0-4-4-4-4-3,6-8,7-8,7V11a1,1,0,0,0-1-1,1,1,0,0,0-1,1v8a1,1,0,0,0,1,1,1,1,0,0,0,1-1V17l12.73,4.93A1,1,0,0,0,18,21.41Z" />
        </g>
      </svg>
    );
  }
}

export default LikeTrace;
