// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Crown extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Coroa</title>
        <desc>Imagem de uma coroa com três pontas</desc>
        <g id="icon-crown">
          <path d="M58,20a6,6,0,0,0-5.18,9,3.91,3.91,0,0,0-1.36.29l-6.6,2.61a.84.84,0,0,1-.38.08,1,1,0,0,1-.9-.55l-8-19.24a4,4,0,0,0-.67-1,6,6,0,1,0-5.82,0,4,4,0,0,0-.67,1l-8,19.24a1,1,0,0,1-.9.55.84.84,0,0,1-.38-.08l-6.6-2.61A3.91,3.91,0,0,0,11.18,29,6,6,0,1,0,6,32a6.34,6.34,0,0,0,1.16-.11,3.88,3.88,0,0,0-.1,1.77L12,54v4a6,6,0,0,0,6,6H46a6,6,0,0,0,6-6V54l4.94-20.34a3.88,3.88,0,0,0-.1-1.77A6.34,6.34,0,0,0,58,32a6,6,0,0,0,0-12ZM32,2a4,4,0,1,1-4,4A4,4,0,0,1,32,2ZM2,26a4,4,0,1,1,4,4A4,4,0,0,1,2,26ZM46,62H18a4,4,0,0,1-4-4V55H50v3A4,4,0,0,1,46,62Zm9-28.73L50.18,53H13.82L9,33.27a1.93,1.93,0,0,1,.45-1.55,2,2,0,0,1,2.07-.64l6.34,2.4.22.18.27.11a3.06,3.06,0,0,0,1.15.23,3,3,0,0,0,2.69-1.66l.05-.1,0-.11,8-19.11a2,2,0,0,1,3.48,0l8,19.11,0,.11,0,.1a3,3,0,0,0,3.84,1.43l.27-.11.22-.18,6.34-2.4a2,2,0,0,1,2.07.64A1.93,1.93,0,0,1,55,33.27ZM58,30a4,4,0,1,1,4-4A4,4,0,0,1,58,30Z" />
        </g>
      </svg>
    );
  }
}

export default Crown;
