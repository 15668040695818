// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Subscriber extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 136">
        <title>Jubialuno</title>
        <desc>Ícone de um estudante assinante da plataforma</desc>
        <circle className="cls-1" cx="73.03" cy="69.51" r="48" />
        <path d="M118.91,136.19a4.41,4.41,0,1,1,4.41-4.4A4.41,4.41,0,0,1,118.91,136.19Zm0-5.75a1.35,1.35,0,1,0,1.35,1.35A1.35,1.35,0,0,0,118.91,130.44Z" />
        <path
          className="cls-2"
          d="M8.19,96a1.53,1.53,0,0,1-1.09-.45L.45,88.9a1.53,1.53,0,1,1,2.16-2.17l6.66,6.66A1.53,1.53,0,0,1,8.19,96Z"
        />
        <path
          className="cls-2"
          d="M1.53,96A1.53,1.53,0,0,1,.45,93.39L7.1,86.73A1.53,1.53,0,1,1,9.27,88.9L2.61,95.55A1.52,1.52,0,0,1,1.53,96Z"
        />
        <path d="M115.57,93.44a1.5,1.5,0,0,1-1.37,0,1.53,1.53,0,0,1-.66-2.07,49.16,49.16,0,0,0,.54-44.06A1.53,1.53,0,0,1,116.83,46a52.26,52.26,0,0,1-.57,46.81A1.49,1.49,0,0,1,115.57,93.44Z" />
        <path d="M77.05,44A14.78,14.78,0,1,1,62.28,58.78,14.8,14.8,0,0,1,77.05,44m0-3A17.78,17.78,0,1,0,94.83,58.78,17.78,17.78,0,0,0,77.05,41Z" />
        <path d="M109,106.5a1.51,1.51,0,0,1-1.5-1.42A30.55,30.55,0,0,0,87.22,78a1.5,1.5,0,0,1,1-2.83,33.58,33.58,0,0,1,22.28,29.74,1.51,1.51,0,0,1-1.42,1.58Z" />
        <path d="M45.11,106.5H45a1.51,1.51,0,0,1-1.42-1.58A33.58,33.58,0,0,1,65.89,75.18a1.5,1.5,0,0,1,1,2.83,30.51,30.51,0,0,0-20.28,27.07A1.51,1.51,0,0,1,45.11,106.5Z" />
        <path
          className="cls-2"
          d="M99.5,109.89a1.5,1.5,0,0,0-2.07-.46A47.5,47.5,0,1,1,63.14,22.69a1.47,1.47,0,0,0,1.2-1.39v0a1.45,1.45,0,0,0-1.71-1.48A50.47,50.47,0,1,0,99,112,1.49,1.49,0,0,0,99.5,109.89Z"
        />
        <path d="M138.56,10.88A1.52,1.52,0,0,1,137,9.35c0-2-.38-2.38-2.38-2.38a1.53,1.53,0,0,1,0-3.06c2,0,2.38-.38,2.38-2.38a1.53,1.53,0,1,1,3.06,0c0,2,.38,2.38,2.38,2.38a1.53,1.53,0,1,1,0,3.06c-2,0-2.38.38-2.38,2.38A1.53,1.53,0,0,1,138.56,10.88Z" />
        <path
          className="cls-3"
          d="M78.87,10.84h0M77.05,14.7l1,2.11a3,3,0,0,0,2.26,1.69l2.52.39-1.92,2a3,3,0,0,0-.82,2.58L80.52,26l-2-1.11a3,3,0,0,0-1.46-.37,3,3,0,0,0-1.45.37l-2,1.11L74,23.44a3,3,0,0,0-.82-2.58l-1.92-2,2.52-.39a3,3,0,0,0,2.26-1.69l1-2.11m0-5.71a1,1,0,0,0-.9.57l-2.8,6-6.37,1a1,1,0,0,0-.57,1.69L71.05,23,70,29.6a1,1,0,0,0,1,1.17,1,1,0,0,0,.48-.13l5.61-3.1,5.62,3.1a1,1,0,0,0,.48.13,1,1,0,0,0,1-1.17L83.05,23,87.7,18.2a1,1,0,0,0-.57-1.69l-6.37-1-2.8-6A1,1,0,0,0,77.05,9Z"
        />
      </svg>
    );
  }
}

export default Subscriber;
