// @flow

export function getMateria(text: string) {
  switch (text) {
    case "biologia":
      return "Biologia";
    case "fisica":
      return "Física";
    case "quimica":
      return "Química";
    case "matematica":
      return "Matemática";
    case "saude":
      return "Saúde";
    case "interdisciplinar":
      return "Interdisciplinar";
    default:
      break;
  }
}

export const resolveStyles = {
  class: "Aulão",
  monitory: "Monitoria",
  review: "Revisão"
};

export const getSlugSubject = {
  Biologia: "biology",
  Física: "physical",
  Química: "chemistry",
  Interdisciplinar: "interdisciplinary",
  Matemática: "mathematics"
};

export function capsFirst(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function stripTags(html: any): any {
  var doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}
