import React, { PureComponent } from "react";
import classNames from "classnames";

class LogoAprova extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg
        width={width}
        height={height}
        className={classes}
        viewBox="0 0 373.163 86.797"
      >
        <title>Logo</title>
        <desc>Logo Aprova Total com escrito</desc>

        <g id="Grupo_8" data-name="Grupo 8" transform="translate(-413.287 -412.835)">
          <g id="Grupo_8-2" data-name="Grupo 8" transform="translate(496.433 436.016)">
            <path id="Caminho_23" data-name="Caminho 23" d="M666.171,514.577q-.349,1.1-1.247,1.1H658.19a1.19,1.19,0,0,1-1.1-.573,1.619,1.619,0,0,1,0-1.472L667.817,482a1.547,1.547,0,0,1,1.546-1.247h10.126A1.548,1.548,0,0,1,681.035,482l10.724,31.624a1.668,1.668,0,0,1,0,1.5,1.256,1.256,0,0,1-1.147.548h-7.133a1.254,1.254,0,0,1-1.247-1.1l-1.9-6.235H668.066Zm4.24-13.717h7.582L674.2,488.489Z" transform="translate(-656.918 -480.758)" />
            <path id="Caminho_24" data-name="Caminho 24" d="M776.16,511.121a8.643,8.643,0,0,1,6.684-2.594,11.532,11.532,0,0,1,8.48,3.566,12.83,12.83,0,0,1,3.541,9.453,13.035,13.035,0,0,1-3.566,9.528,11.608,11.608,0,0,1-8.6,3.641q-4.69,0-6.534-2.294v11.472a1.109,1.109,0,0,1-1.147,1.148h-6.235a1.109,1.109,0,0,1-1.147-1.148v-33.47a1.109,1.109,0,0,1,1.147-1.147h6.235a1.141,1.141,0,0,1,.8.324,1.078,1.078,0,0,1,.349.823Zm1.5,6.933a5.181,5.181,0,0,0,0,7.183,4.939,4.939,0,0,0,7.083,0,5.181,5.181,0,0,0,0-7.183,4.939,4.939,0,0,0-7.083,0Z" transform="translate(-729.846 -499.05)" />
            <path id="Caminho_25" data-name="Caminho 25" d="M871.524,509.4h.649q1.346,0,1.347,1.5v6.135a1.367,1.367,0,0,1-1.547,1.546h-1.446a4.141,4.141,0,0,0-3.118,1.023,4.46,4.46,0,0,0-.972,3.168V533.5a.921.921,0,0,1-.374.748,1.287,1.287,0,0,1-.823.3h-6.285a1.109,1.109,0,0,1-1.147-1.148V511a1.109,1.109,0,0,1,1.147-1.147h5.936a1.291,1.291,0,0,1,.823.3.922.922,0,0,1,.374.749v1.4A6.35,6.35,0,0,1,871.524,509.4Z" transform="translate(-789.248 -499.628)" />
            <path id="Caminho_26" data-name="Caminho 26" d="M907.5,521.7a12.463,12.463,0,0,1,3.991-9.328,14.11,14.11,0,0,1,19.354,0,12.465,12.465,0,0,1,3.991,9.328,12.25,12.25,0,0,1-3.991,9.228,14.247,14.247,0,0,1-19.354,0A12.248,12.248,0,0,1,907.5,521.7Zm10.126-3.642a4.982,4.982,0,0,0-1.446,3.592,4.9,4.9,0,0,0,1.446,3.566,5,5,0,0,0,7.083,0,4.9,4.9,0,0,0,1.446-3.566,4.986,4.986,0,0,0-1.446-3.592,4.94,4.94,0,0,0-7.083,0Z" transform="translate(-821.981 -499.05)" />
            <path id="Caminho_27" data-name="Caminho 27" d="M1008.318,535.41H999.04a1.459,1.459,0,0,1-1.5-1.2l-7.083-21.549a1.61,1.61,0,0,1,.1-1.372,1.192,1.192,0,0,1,1.1-.574h6.634a1.508,1.508,0,0,1,1.5,1.2l4.24,14.714,3.941-14.714a1.386,1.386,0,0,1,1.446-1.2h6.285a1.193,1.193,0,0,1,1.1.574,1.614,1.614,0,0,1,.1,1.372l-7.083,21.549A1.46,1.46,0,0,1,1008.318,535.41Z" transform="translate(-876.561 -500.494)" />
            <path id="Caminho_28" data-name="Caminho 28" d="M1075.031,531.048a12.808,12.808,0,0,1-3.691-9.5,12.56,12.56,0,0,1,3.691-9.428,12.042,12.042,0,0,1,8.679-3.591q4.538,0,6.335,2.644v-.748a1.077,1.077,0,0,1,.349-.823,1.141,1.141,0,0,1,.8-.324h6.235a1.109,1.109,0,0,1,1.147,1.147v22.4a1.109,1.109,0,0,1-1.147,1.148h-6.235a1.109,1.109,0,0,1-1.147-1.148v-.748a5.446,5.446,0,0,1-2.519,1.921,9.9,9.9,0,0,1-3.866.723A11.862,11.862,0,0,1,1075.031,531.048Zm6.435-12.994a5.181,5.181,0,0,0,0,7.183,4.939,4.939,0,0,0,7.083,0,5.182,5.182,0,0,0,0-7.183,4.939,4.939,0,0,0-7.083,0Z" transform="translate(-929.906 -499.05)" />
          </g>
          <g id="Grupo_13" data-name="Grupo 13" transform="translate(676.523 437.441)">
            <g id="Grupo_9" data-name="Grupo 9" transform="translate(0 0)">
              <path id="Caminho_29" data-name="Caminho 29" d="M1194.985,516.15V490.313h-9.526a.849.849,0,0,1-.849-.849v-3.682a.849.849,0,0,1,.849-.849h25.48a.849.849,0,0,1,.849.849v3.682a.849.849,0,0,1-.849.849h-9.526V516.15a.849.849,0,0,1-.849.849h-4.729A.849.849,0,0,1,1194.985,516.15Z" transform="translate(-1184.609 -484.933)" />
            </g>
            <g id="Grupo_10" data-name="Grupo 10" transform="translate(24.199 9.477)">
              <path id="Caminho_30" data-name="Caminho 30" d="M1267.757,536.04a12.054,12.054,0,0,1-8.7-3.4,10.786,10.786,0,0,1-3.547-8.194,11,11,0,0,1,3.553-8.3,12.685,12.685,0,0,1,17.378,0,11,11,0,0,1,3.553,8.3,10.789,10.789,0,0,1-3.547,8.194A12.058,12.058,0,0,1,1267.757,536.04Zm0-18.156a6.131,6.131,0,0,0-4.567,1.931,6.385,6.385,0,0,0-1.847,4.582,6.3,6.3,0,0,0,1.856,4.566,6.422,6.422,0,0,0,9.115,0,6.3,6.3,0,0,0,1.855-4.566,6.387,6.387,0,0,0-1.846-4.582A6.135,6.135,0,0,0,1267.757,517.884Z" transform="translate(-1255.515 -512.703)" />
            </g>
            <g id="Grupo_11" data-name="Grupo 11" transform="translate(50.241 2.993)">
              <path id="Caminho_31" data-name="Caminho 31" d="M1343.517,523.425a8.453,8.453,0,0,1-5.452-1.739,5.945,5.945,0,0,1-2.051-5.017V505.218h-3.8a.391.391,0,0,1-.391-.391v-3.5a.391.391,0,0,1,.391-.391h3.8v-6.841a.391.391,0,0,1,.391-.391h5a.391.391,0,0,1,.391.391v5.371a1.47,1.47,0,0,0,1.47,1.471h6.02a.391.391,0,0,1,.391.391v3.5a.391.391,0,0,1-.391.391h-6.842a.648.648,0,0,0-.648.648v8.658c0,2.329,1.26,3.72,3.37,3.72a5.188,5.188,0,0,0,2.761-.922,1.924,1.924,0,0,1,.295-.157l2.119,3.4a1.1,1.1,0,0,1,.116.218c-.011.009-.113.117-.136.143a6.549,6.549,0,0,1-2.776,1.812A11.728,11.728,0,0,1,1343.517,523.425Z" transform="translate(-1331.823 -493.703)" />
            </g>
            <g id="Grupo_12" data-name="Grupo 12" transform="translate(100.409 0)">
              <path id="Caminho_32" data-name="Caminho 32" d="M1484.081,517c-1.929,0-3.293-.429-4.054-1.274a5.113,5.113,0,0,1-1.205-3.586V484.994a.06.06,0,0,1,.06-.061h5.658a.061.061,0,0,1,.061.061V510.2a2.572,2.572,0,0,0,.249,1.176,2.365,2.365,0,0,0,2.124,1.34h1.3a.057.057,0,0,1,.06.056c0,.5,0,4.2,0,4.217a1.416,1.416,0,0,1-.17.009Z" transform="translate(-1478.822 -484.933)" />
            </g>
            <path id="Caminho_33" data-name="Caminho 33" d="M1417.628,513.451h-4.017a1.31,1.31,0,0,0-1.238.915,1.5,1.5,0,0,1-.43.749c-.246.16-3.141-2.413-7.109-2.413a10.575,10.575,0,0,0-7.686,3.188,11.1,11.1,0,0,0-3.259,8.406,11.344,11.344,0,0,0,3.27,8.491,10.4,10.4,0,0,0,7.626,3.252,9.243,9.243,0,0,0,2.255-.272l0,.011s.1-.024.254-.07l.119-.036a8.484,8.484,0,0,0,3.1-1.627c1-.943,1.635-.154,1.635-.154h0a2.962,2.962,0,0,1,.224.517,1.3,1.3,0,0,0,1.227.884h4.034a.645.645,0,0,0,.645-.645V514.1A.645.645,0,0,0,1417.628,513.451Zm-16.064,15.528a6.607,6.607,0,0,1,0-9.164,6.365,6.365,0,0,1,9.133,0,6.607,6.607,0,0,1,0,9.164,6.366,6.366,0,0,1-9.132,0Z" transform="translate(-1322.466 -503.226)" />
          </g>
          <path id="Caminho_34" data-name="Caminho 34" d="M421.133,435.294h0a.615.615,0,0,1-.01-1.131l35.937-15.7a1.111,1.111,0,0,0,.666-1.018v-3.5a1.111,1.111,0,0,0-1.554-1.019l-39.664,17.253a5.358,5.358,0,0,0-3.221,4.913v56.147a6.484,6.484,0,0,0,3.834,5.918h0a28.32,28.32,0,0,0,22.888.114L471.36,483.61a3.427,3.427,0,0,0,2.058-3.141V420.736a1.111,1.111,0,0,0-1.555-1.019l-35.486,15.45A19.466,19.466,0,0,1,421.133,435.294Zm22.247,39v-24a.8.8,0,0,1,1.04-.766l16.654,5.163a.8.8,0,0,1,.363,1.3l-16.654,18.834A.8.8,0,0,1,443.381,474.3Z" />
        </g>
      </svg>
    );
  }
}

export default LogoAprova;
