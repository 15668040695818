export const colorsConfig = type => {
  switch (type) {
    case "SIMULATED":
      return {
        boxShadow: "0px 0px 0px 2px rgba(253, 215, 3, 0.3)",
        border: "2px solid #FDD703"
      };
    case "JUBICALOURO":
      return {
        border: "2px solid #00c5e5"
      };
    default: {
    }
  }
};
