// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Report extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Reportar problemas</title>
        <desc>Representação de um balão de fala com um ponto de exclamação</desc>
        <g id="icon-report">
          <path d="M8,3A1,1,0,0,0,7,4V6A1,1,0,0,0,9,6V4A1,1,0,0,0,8,3Z" />
          <circle cx="8" cy="9" r="1" />
          <path d="M8,0C3.59,0,0,2.92,0,6.5S3.59,13,8,13l.57,0,2.72,2.73A1,1,0,0,0,12,16a.84.84,0,0,0,.38-.08A1,1,0,0,0,13,15V11.58A6.13,6.13,0,0,0,16,6.5C16,2.92,12.41,0,8,0Zm3.52,10.13A1,1,0,0,0,11,11v1.58L9.65,11.24a1,1,0,0,0-.71-.3h-.1A7.73,7.73,0,0,1,8,11C4.69,11,2,9,2,6.5S4.69,2,8,2s6,2,6,4.5A4.31,4.31,0,0,1,11.52,10.13Z" />
        </g>
      </svg>
    );
  }
}

export default Report;
