import styled, { keyframes } from "styled-components";

const dimmerGray = keyframes`
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.3;
  }
`;

const dimmerGrayCircle = keyframes`
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.8;
  }
`;

export const DimmerBlock = styled.div`
  height: 8px;
  background-color: ${props => props.theme.colors.dimmer};
  border-radius: 24px;
  animation: ${dimmerGray} 1.5s;
  animation-iteration-count: infinite;

  &:not(:last-child) {
    margin-bottom: 18px;
  }
`;

export const DimmerCircle = styled.div`
  margin: 0px auto 10px auto;
  background-color: ${props => props.theme.colors.dimmer};
  border-radius: 50%;
  animation: ${dimmerGrayCircle} 1.5s;
  animation-iteration-count: infinite;
`;
