// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class More extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone mais opções</title>
        <desc>Ícone de três pontos para ver mais opções</desc>
        <g id="icon-more">
          <path d="M8,9H8A1,1,0,0,1,7,8H7A1,1,0,0,1,8,7H8A1,1,0,0,1,9,8H9A1,1,0,0,1,8,9Zm1,6H9a1,1,0,0,0-1-1H8a1,1,0,0,0-1,1H7a1,1,0,0,0,1,1H8A1,1,0,0,0,9,15ZM9,1H9A1,1,0,0,0,8,0H8A1,1,0,0,0,7,1H7A1,1,0,0,0,8,2H8A1,1,0,0,0,9,1Z" />
        </g>
      </svg>
    );
  }
}

export default More;
