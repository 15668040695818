export const datePadTwoDigits = num => {
  return num.toString().padStart(2, "0");
};

export const dateFormat = date => {
  return [datePadTwoDigits(date.getDate()), datePadTwoDigits(date.getMonth() + 1), date.getFullYear()].join("/");
};

export const convertToDate = date => {
  const [day, month, year] = date.split("/");
  return new Date(year, month - 1, day);
};
