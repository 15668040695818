import React from "react";

import * as S from "./styles";

export const StepOne = () => {
  return (
    <S.Container>
      <S.Title>
        Bem-vindo(a) ao Simulado Super Med
      </S.Title>
      <S.Text>
        Você poderá realizar o simulado <strong>entre às 09h de 10/12/2023 </strong><br/>
        <strong>até às 23h59 de 12/12/2023</strong> (horários de Brasília).
        <span>Clique no botão abaixo para iniciar agora:</span>
      </S.Text>
    </S.Container>
  );
};
