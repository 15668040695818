import ReactGA from "react-ga";
import { http } from "./Api";
import config from "./config";

export const sendEventGA = function(category, action, label) {
  try {
    if (config.env === "production") {
      ReactGA.event({
        category,
        action,
        label
      });
    }
  } catch (e) {
    http("api/backend/v2/logger/", "POST", {
      buildNumber: config.buildNumber,
      message: "SendGA error",
      category,
      action,
      label
    }).catch(e => console.log(e));
  }
};
