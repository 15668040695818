// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class StarXL extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Estrela</title>
        <desc>Imagem de uma estrela de cinco pontas</desc>
        <g id="icon-star-xl">
          <path d="M32,2a2.52,2.52,0,0,1,2.21,1.26l7,18a3,3,0,0,0,2.43,1.89l16.11,2a2.58,2.58,0,0,1,1.42,4.44L49.54,38.88a3,3,0,0,0-1,3.08l4.32,17a2.56,2.56,0,0,1-.54,2,2.65,2.65,0,0,1-2,1,2.48,2.48,0,0,1-1-.22L33.62,51.73a3,3,0,0,0-3.24,0L14.74,61.78a2.48,2.48,0,0,1-1,.22,2.65,2.65,0,0,1-2-1,2.56,2.56,0,0,1-.54-2L15.5,42a3,3,0,0,0-1-3.08L2.82,29.55a2.51,2.51,0,0,1-.7-2.64,2.49,2.49,0,0,1,2.1-1.79l16.13-2a3,3,0,0,0,2.43-1.89l7-18A2.52,2.52,0,0,1,32,2m0-2a4.52,4.52,0,0,0-4,2.41l-7,18.1a1,1,0,0,1-.81.63L4,23.13a4.58,4.58,0,0,0-2.45,7.94l11.69,9.37a1,1,0,0,1,.35,1L9.22,58.58a4.56,4.56,0,0,0,6.49,5L31.46,53.41a1,1,0,0,1,1.08,0L48.29,63.53a4.56,4.56,0,0,0,6.49-5L50.44,41.47a1,1,0,0,1,.35-1l11.69-9.37A4.58,4.58,0,0,0,60,23.13l-16.14-2a1,1,0,0,1-.81-.63L36,2.41A4.52,4.52,0,0,0,32,0Z" />
        </g>
      </svg>
    );
  }
}

export default StarXL;
