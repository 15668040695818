// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class ArrowRightHome extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 16">
        <title>Direita</title>
        <desc>Seta apontando para o lado direito</desc>
        <path d="M1.71.29l7,7a1,1,0,0,1,0,1.42l-7,7a1,1,0,0,1-1.42,0h0a1,1,0,0,1,0-1.42L6.59,8,.29,1.71A1,1,0,0,1,.29.29h0A1,1,0,0,1,1.71.29Z" />
      </svg>
    );
  }
}

export default ArrowRightHome;
