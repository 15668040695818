import React, { PureComponent } from "react";
import "./icon.scss";

import FlowerOne from "./components/flowerOne";
import FlowerTwo from "./components/flowerTwo";
import FlowerThree from "./components/flowerThree";
import FlowerFour from "./components/flowerFour";
import FlowerFive from "./components/flowerFive";
import Text from "./components/text";
import TextMd from "./components/textMd";
import TextXl from "./components/textXl";
import Pending from "./components/pending";
import CheckTrace from "./components/checktrace";
import MathXL from "./components/mathXl";
import PhysicsXL from "./components/physicsXl";
import Trophy from "./components/trophy";
import Forward from "./components/forward";
import Contract from "./components/contract";
import Biology from "./components/biology";
import Chemestry from "./components/chemestry";
import RoundCheckXL from "./components/roundCheckxl";
import Physical from "./components/physical";
import Mathematics from "./components/mathematics";
import Live from "./components/live";
import LiveMd from "./components/liveMd";
import LiveXl from "./components/liveXl";
import Chat from "./components/chat";
import ChatXl from "./components/chatXl";
import Community from "./components/community";
import Cell from "./components/cell";
import Search from "./components/search";
import SearchXl from "./components/searchXl";
import ArrowDown from "./components/arrowDown";
import Tag from "./components/tag";
import ArrowUp from "./components/arrowUp";
import ArrowRight from "./components/arrowRight";
import ArrowLeft from "./components/arrowLeft";
import Notification from "./components/notification";
import Menu from "./components/menu";
import Info from "./components/info";
import InfoStroke from "./components/infoStroke";
import Download from "./components/download";
import DownloadXl from "./components/downloadXl";
import Back from "./components/back";
import Trash from "./components/trash";
import User from "./components/user";
import Show from "./components/show";
import Play from "./components/play";
import More from "./components/more";
import Chart from "./components/chart";
import Clock from "./components/clock";
import ClockMD from "./components/clockmd";
import ClockXL from "./components/clockxl";
import Answers from "./components/answers";
import Average from "./components/average";
import Min from "./components/min";
import Max from "./components/max";
import Hide from "./components/hide";
import Close from "./components/close";
import Expand from "./components/expand";
import Locker from "./components/locker";
import LockerTrace from "./components/lockerTrace";
import Like from "./components/like";
import LikeTrace from "./components/likeTrace";
import Dislike from "./components/dislike";
import DislikeTrace from "./components/dislikeTrace";
import CircleCheck from "./components/circleCheck";
import CircleCheckTrace from "./components/circleCheckTrace";
import CircleCheckTraceXl from "./components/circleCheckTraceXl";
import CircleWrongTraceXl from "./components/circleWrongTraceXl";
import Check from "./components/check";
import Star from "./components/star";
import StarXL from "./components/starxl";
import Crown from "./components/crown";
import CrownXl from "./components/crownXl";
import CrownSmall from "./components/crownSmall";
import Lamp from "./components/lamp";
import LampXl from "./components/lampXl";
import LampSmall from "./components/lampSmall";
import Paper from "./components/paper";
import Pencil from "./components/pencil";
import PencilXl from "./components/pencilXl";
import Mic from "./components/mic";
import CommunityXl from "./components/communityXl";
import BiologyXl from "./components/biologyXl";
import ChemXl from "./components/chemXl";
import Send from "./components/send";
import Test from "./components/test";
import Video from "./components/video";
import Wave from "./components/wave";
import Wrong from "./components/wrong";
import PlayHome from "./components/playHome";
import ContentHome from "./components/contentHome";
import MicroHome from "./components/microHome";
import TeachersHome from "./components/teachersHome";
import Facebook from "./components/facebook";
import Instagram from "./components/instagram";
import Twitter from "./components/twitter";
import Youtube from "./components/youtube";
import ArrowLeftHome from "./components/arrowLeftHome";
import ArrowRightHome from "./components/arrowRightHome";
import Lock from "./components/lock";
import Board from "./components/board";
import Login from "./components/login";
import Block from "./components/block";
import Health from "./components/health";
import Card from "./components/card";
import Cart from "./components/cart";
import Calendar from "./components/calendar";
import CalendarXl from "./components/calendarXl";
import Bill from "./components/bill";
import Subscriber from "./components/subscriber";
import Unsubscriber from "./components/unsubscriber";
import Bug from "./components/bug";
import BugXl from "./components/bugXl";
import Smile from "./components/smile";
import Flower from "./components/flower";
import Flag from "./components/flag";
import Remove from "./components/remove";
import Some from "./components/some";
import UserXl from "./components/userXl";
import LockerLarge from "./components/lockerLarge";
import Buy from "./components/buy";
import Attach from "./components/attach";
// import Seal from "./components/seal";
import Jubiluzinho from "./components/jubiluzinho";
import Inter from "./components/inter";
import SealXl from "./components/sealXl";
import Tutorial from "./components/tutorial";
import CloseSmall from "./components/closeSmall";
import Interdisciplinary from "./components/interdisciplinary";
import Rotate from "./components/rotate";
import Speaker from "./components/speaker";
import Pin from "./components/pin";
import Go from "./components/go";
import CircleWrong from "./components/circleWrong";
import YellowSeptember from "./components/yellowSeptember";
import ClapGray from "./components/clapGray";
import ClapBlue from "./components/clapBlue";
import Ribbon from "./components/ribbon";
import NewTab from "./components/newTab";
import Quote from "./components/quote";
import MedalJubicalouro from "./components/medalJubicalouro";
import FacebookBackground from "./components/facebookBackground";
import TwitterBackground from "./components/twitterBackground";
import JubicalouroStar from "./components/jubicalouroStar";
import InstagramBackground from "./components/instagramBackground";
import LinkShare from "./components/linkShare";
import Medal from "./components/medal";
import Report from "./components/report";
import ReportFill from "./components/reportFill";
import StarFill from "./components/starFill";
import Diversas from "./components/diversas";
import Wpp from "./components/wpp";
import ToggleArea from "./components/toggleArea";
import Whatsapp from "./components/whatsapp";
import Linkedin from './components/linkedin'
import TikTok from './components/tiktok'
import LogoAprova from "./components/logoAprova";

class Icon extends PureComponent {
  render() {
    const { icon, height, width, className, style } = this.props;

    switch (icon) {
      case "flowerOne":
        return (
          <FlowerOne height={height} width={width} className={className} />
        );
      case "flowerTwo":
        return (
          <FlowerTwo height={height} width={width} className={className} />
        );
      case "flowerThree":
        return (
          <FlowerThree height={height} width={width} className={className} />
        );
      case "flowerFour":
        return (
          <FlowerFour height={height} width={width} className={className} />
        );
      case "flowerFive":
        return (
          <FlowerFive height={height} width={width} className={className} />
        );
      case "text":
        return <Text height={height} width={width} className={className} />;
      case "pin":
        return <Pin height={height} width={width} className={className} />;
      case "textMd":
        return <TextMd height={height} width={width} className={className} />;
      case "textXl":
        return <TextXl height={height} width={width} className={className} />;
      case "bug":
        return <Bug height={height} width={width} className={className} />;
      case "bugXl":
        return <BugXl height={height} width={width} className={className} />;
      case "smile":
        return <Smile height={height} width={width} className={className} />;
      case "trophy":
        return <Trophy height={height} width={width} className={className} />;
      case "board":
        return <Board height={height} width={width} className={className} />;
      case "contract":
        return <Contract height={height} width={width} className={className} />;
      case "biology":
        return <Biology height={height} width={width} className={className} />;
      case "biologyXl":
        return (
          <BiologyXl height={height} width={width} className={className} />
        );
      case "chemestry":
        return (
          <Chemestry height={height} width={width} className={className} />
        );
      case "chemXl":
        return <ChemXl height={height} width={width} className={className} />;
      case "roundcheckxl":
        return (
          <RoundCheckXL height={height} width={width} className={className} />
        );
      case "physical":
        return <Physical height={height} width={width} className={className} />;
      case "mathematics":
        return (
          <Mathematics height={height} width={width} className={className} />
        );
      case "mathematicsXL":
        return <MathXL height={height} width={width} className={className} />;
      case "pending":
        return <Pending height={height} width={width} className={className} />;
      case "checktrace":
        return (
          <CheckTrace height={height} width={width} className={className} />
        );
      case "physicsXL":
        return (
          <PhysicsXL height={height} width={width} className={className} />
        );
      case "paper":
        return <Paper height={height} width={width} className={className} />;
      case "pencil":
        return <Pencil height={height} width={width} className={className} />;
      case "pencilXl":
        return <PencilXl height={height} width={width} className={className} />;
      case "mic":
        return <Mic height={height} width={width} className={className} />;
      case "live":
        return <Live height={height} width={width} className={className} />;
      case "liveMd":
        return <LiveMd height={height} width={width} className={className} />;
      case "liveXl":
        return <LiveXl height={height} width={width} className={className} />;
      case "chat":
        return <Chat height={height} width={width} className={className} />;
      case "chatXl":
        return <ChatXl height={height} width={width} className={className} />;
      case "community":
        return (
          <Community height={height} width={width} className={className} />
        );
      case "communityXl":
        return (
          <CommunityXl height={height} width={width} className={className} />
        );
      case "searchXl":
        return <SearchXl height={height} width={width} className={className} />;
      case "search":
        return <Search height={height} width={width} className={className} />;
      case "send":
        return <Send height={height} width={width} className={className} />;
      case "arrowDown":
        return (
          <ArrowDown height={height} width={width} className={className} />
        );
      case "arrowUp":
        return <ArrowUp height={height} width={width} className={className} />;
      case "cell":
        return <Cell height={height} width={width} className={className} />;
      case "arrowRight":
        return (
          <ArrowRight
            height={height}
            width={width}
            className={className}
            style={style}
          />
        );
      case "arrowLeft":
        return (
          <ArrowLeft height={height} width={width} className={className} />
        );
      case "notification":
        return (
          <Notification height={height} width={width} className={className} />
        );
      case "menu":
        return <Menu height={height} width={width} className={className} />;
      case "info":
        return <Info height={height} width={width} className={className} />;
      case "infoStroke":
        return (
          <InfoStroke height={height} width={width} className={className} />
        );
      case "download":
        return <Download height={height} width={width} className={className} />;
      case "downloadXl":
        return (
          <DownloadXl height={height} width={width} className={className} />
        );
      case "back":
        return <Back height={height} width={width} className={className} />;
      case "go":
        return <Go height={height} width={width} className={className} />;
      case "forward":
        return <Forward height={height} width={width} className={className} />;
      case "lock":
        return <Lock height={height} width={width} className={className} />;
      case "trash":
        return <Trash height={height} width={width} className={className} />;
      case "user":
        return <User height={height} width={width} className={className} />;
      case "show":
        return <Show height={height} width={width} className={className} />;
      case "play":
        return <Play height={height} width={width} className={className} />;
      case "more":
        return <More height={height} width={width} className={className} />;
      case "chart":
        return <Chart height={height} width={width} className={className} />;
      case "clock":
        return <Clock height={height} width={width} className={className} />;
      case "clockxl":
        return <ClockXL height={height} width={width} className={className} />;
      case "clockmd":
        return <ClockMD height={height} width={width} className={className} />;
      case "min":
        return <Min height={height} width={width} className={className} />;
      case "max":
        return <Max height={height} width={width} className={className} />;
      case "answers":
        return <Answers height={height} width={width} className={className} />;
      case "average":
        return <Average height={height} width={width} className={className} />;
      case "hide":
        return <Hide height={height} width={width} className={className} />;
      case "close":
        return <Close height={height} width={width} className={className} />;
      case "tag":
        return <Tag height={height} width={width} className={className} />;
      case "expand":
        return <Expand height={height} width={width} className={className} />;
      case "locker":
        return <Locker height={height} width={width} className={className} />;
      case "lockerTrace":
        return (
          <LockerTrace height={height} width={width} className={className} />
        );
      case "like":
        return <Like height={height} width={width} className={className} />;
      case "likeTrace":
        return (
          <LikeTrace height={height} width={width} className={className} />
        );
      case "dislike":
        return <Dislike height={height} width={width} className={className} />;
      case "dislikeTrace":
        return (
          <DislikeTrace height={height} width={width} className={className} />
        );
      case "circleCheck":
        return (
          <CircleCheck height={height} width={width} className={className} />
        );
      case "circleCheckTrace":
        return (
          <CircleCheckTrace
            height={height}
            width={width}
            className={className}
          />
        );
      case "circleCheckTraceXl":
        return (
          <CircleCheckTraceXl
            height={height}
            width={width}
            className={className}
          />
        );
      case "circleWrongTraceXl":
        return (
          <CircleWrongTraceXl
            height={height}
            width={width}
            className={className}
          />
        );
      case "check":
        return <Check height={height} width={width} className={className} />;
      case "star":
        return <Star height={height} width={width} className={className} />;
      case "starxl":
        return <StarXL height={height} width={width} className={className} />;
      case "lamp":
        return <Lamp height={height} width={width} className={className} />;
      case "lampXl":
        return <LampXl height={height} width={width} className={className} />;
      case "lampSmall":
        return (
          <LampSmall height={height} width={width} className={className} />
        );
      case "crown":
        return <Crown height={height} width={width} className={className} />;
      case "crownXl":
        return <CrownXl height={height} width={width} className={className} />;
      case "crownSmall":
        return (
          <CrownSmall height={height} width={width} className={className} />
        );
      case "test":
        return <Test height={height} width={width} className={className} />;
      case "video":
        return <Video height={height} width={width} className={className} />;
      case "wave":
        return <Wave className={className} />;
      case "wrong":
        return <Wrong height={height} width={width} className={className} />;
      case "playHome":
        return <PlayHome height={height} width={width} className={className} />;
      case "contentHome":
        return (
          <ContentHome height={height} width={width} className={className} />
        );
      case "microHome":
        return (
          <MicroHome height={height} width={width} className={className} />
        );
      case "teachersHome":
        return (
          <TeachersHome height={height} width={width} className={className} />
        );
      case "facebook":
        return <Facebook height={height} width={width} className={className} />;
      case "instagram":
        return (
          <Instagram height={height} width={width} className={className} />
        );
      case "twitter":
        return <Twitter height={height} width={width} className={className} />;
      case "youtube":
        return <Youtube height={height} width={width} className={className} />;
      case "arrowLeftHome":
        return (
          <ArrowLeftHome height={height} width={width} className={className} />
        );
      case "arrowRightHome":
        return (
          <ArrowRightHome height={height} width={width} className={className} />
        );
      case "login":
        return <Login height={height} width={width} className={className} />;
      case "block":
        return <Block height={height} width={width} className={className} />;
      case "health":
        return <Health height={height} width={width} className={className} />;
      case "card":
        return <Card height={height} width={width} className={className} />;
      case "cart":
        return <Cart height={height} width={width} className={className} />;
      case "calendar":
        return <Calendar height={height} width={width} className={className} />;
      case "calendarXl":
        return (
          <CalendarXl height={height} width={width} className={className} />
        );
      case "bill":
        return <Bill height={height} width={width} className={className} />;
      case "subscriber":
        return (
          <Subscriber height={height} width={width} className={className} />
        );
      case "unsubscriber":
        return (
          <Unsubscriber height={height} width={width} className={className} />
        );
      case "flower":
        return <Flower height={height} width={width} className={className} />;
      case "flag":
        return <Flag height={height} width={width} className={className} />;
      case "remove":
        return <Remove height={height} width={width} className={className} />;
      case "some":
        return <Some height={height} width={width} className={className} />;
      case "userXl":
        return <UserXl height={height} width={width} className={className} />;
      case "lockerLarge":
        return (
          <LockerLarge height={height} width={width} className={className} />
        );
      case "checkTrace":
        return (
          <CheckTrace height={height} width={width} className={className} />
        );
      case "buy":
        return <Buy height={height} width={width} className={className} />;
      case "attach":
        return <Attach height={height} width={width} className={className} />;
      // case "seal":
      //   return <Seal height={height} width={width} className={className} />;
      case "SealXl":
        return <SealXl height={height} width={width} className={className} />;
      case "jubiluzinho":
        return (
          <Jubiluzinho height={height} width={width} className={className} />
        );
      case "inter":
        return <Inter height={height} width={width} className={className} />;
      case "tutorial":
        return <Tutorial height={height} width={width} className={className} />;
      case "closeSmall":
        return (
          <CloseSmall height={height} width={width} className={className} />
        );
      case "interdisciplinary":
        return (
          <Interdisciplinary
            height={height}
            width={width}
            className={className}
          />
        );
      case "rotate":
        return <Rotate height={height} width={width} className={className} />;
      case "speaker":
        return <Speaker height={height} width={width} className={className} />;
      case "circleWrong":
        return (
          <CircleWrong height={height} width={width} className={className} />
        );
      case "yellowSeptember":
        return (
          <YellowSeptember
            height={height}
            width={width}
            className={className}
          />
        );
      case "clapGray":
        return <ClapGray height={height} width={width} className={className} />;
      case "clapBlue":
        return <ClapBlue height={height} width={width} className={className} />;
      case "ribbon":
        return <Ribbon height={height} width={width} className={className} />;
      case "newTab":
        return <NewTab height={height} width={width} className={className} />;
      case "quote":
        return <Quote height={height} width={width} className={className} />;
      case "medalJubicalouro":
        return (
          <MedalJubicalouro
            height={height}
            width={width}
            className={className}
          />
        );
      case "facebookBackground":
        return (
          <FacebookBackground
            height={height}
            width={width}
            className={className}
          />
        );
      case "twitterBackground":
        return (
          <TwitterBackground
            height={height}
            width={width}
            className={className}
          />
        );
      case "instagramBackground":
        return (
          <InstagramBackground
            height={height}
            width={width}
            className={className}
          />
        );
      case "jubicalouroStar":
        return (
          <JubicalouroStar
            height={height}
            width={width}
            className={className}
          />
        );
      case "linkShare":
        return (
          <LinkShare height={height} width={width} className={className} />
        );
      case "medal":
        return <Medal height={height} width={width} className={className} />;
      case "report":
        return <Report height={height} width={width} className={className} />;
      case "reportFill":
        return (
          <ReportFill height={height} width={width} className={className} />
        );
      case "starFill":
        return <StarFill height={height} width={width} className={className} />;
      case "diversas":
        return <Diversas height={height} width={width} className={className} />;
      case "wpp":
        return <Wpp height={height} width={width} className={className} />;
      case "toggleArea":
        return (
          <ToggleArea height={height} width={width} className={className} />
        );
      case "whatsapp":
        return <Whatsapp height={height} width={width} className={className} />;
      case "logoAprova":
        return <LogoAprova height={height} width={width} className={className} />;
      case "linkedin":
        return <Linkedin height={height} width={width} className={className} />;
      case "tiktok":
        return <TikTok height={height} width={width} className={className} />;

      default:
        return null;
    }
  }
}

export default Icon;
