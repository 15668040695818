// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class LockerTrace extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone cadeado</title>
        <desc>Ícone de um cadeado</desc>
        <g id="icon-locker-trace">
          <path d="M8,0c4,0,4,4,4,4a2.94,2.94,0,0,1,3,3v6a2.94,2.94,0,0,1-3,3H4a2.94,2.94,0,0,1-3-3V7A2.94,2.94,0,0,1,4,4S4,0,8,0Zm2,4A2,2,0,0,0,8,2,2,2,0,0,0,6,4ZM8,6H4A1,1,0,0,0,3,7v6a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V7a1,1,0,0,0-1-1ZM7,11V9A1,1,0,0,1,8,8H8A1,1,0,0,1,9,9v2a1,1,0,0,1-1,1H8A1,1,0,0,1,7,11Z" />
        </g>
      </svg>
    );
  }
}

export default LockerTrace;
