import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  padding: 32px;
  background-color: #1B3FB5;

  .box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 520px;
    height: 266px;
    margin: 0 auto;
    padding: 32px;
    border-radius: 16px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 767px) {
    padding: 40px 8px;

    .box {
      max-width: 346px;
      padding: 40px 16px;
    }
  }
`;

export const SimulatedHeader = styled.div`
`;

export const Logo = styled.img`
  display: block;
  max-width: 140px;
  margin: 0 auto;
`;

export const HeaderTitle = styled.h1`
  margin: 16px auto 32px;
  color: #ffffff;
  font-size: 1.75rem;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  
  > strong {
    color: #ffffff;
    font-weight: 900;
  }
`;

export const Title = styled.span`
  display: block;
  margin-bottom: 24px;
  padding-bottom: 16px;
  color: #FD742F;
  font-size: 1.75rem;
  font-weight: 700;
  font-family: Roboto;

  svg {
    fill: ${props => props.theme.colors.success};
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
`;

export const Text = styled.div`
  font-size: 1rem;
  text-align: center;
  line-height: 22px;
  font-family: Nunito;

  > span {
    display: block;
    margin-bottom: 24px;
    font-size: 1.25rem;
    white-space: nowrap;
  }

  strong {
    color: #001036;
    font-weight: 700;
  }
`;