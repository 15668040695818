import styled from "styled-components";
import { MediaQueries } from "ratel-ui";

export const Container = styled.div`
  @media screen {
    img {
      max-width: 100%;
      height: auto;
    }

    .print-list-simulated {
      display: flex;
      justify-content: center;
      width: 100%;

      &-feedback {
        &-title {
          display: none;
        }

        &-container {
          display: block;
        }

        &-table {
          width: 100%;

          &-th {
            padding: 8px 32px;
            color: ${props => props.theme.colors.font6};

            ${MediaQueries.mobile`
            padding: 8px 16px;
          `}
          }

          &-td {
            margin: 8px 32px;
            padding-top: 5px;
            text-align: center;
            color: ${props => props.theme.colors.font1};
            border-radius: 50%;

            &.number {
              margin-left: 50px;
              padding-top: 5px;
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              text-align: center;
              font-weight: 700;
              font-size: 0.75rem;
              color: ${props => props.theme.colors.font1};
              border-radius: 100%;
              border: 2px solid ${props => props.theme.colors.font6};
              background-color: ${props => props.theme.colors.transparent};
            }
          }
        }

        &-body {
          padding: 0 16px;
        }
      }
    }
  }

  @media print {
    @page {
      margin: 0.5cm 0 1cm 0;
    }

    body {
      width: 100%;
      margin: 0;
      padding: 0;
      color: ${props => props.theme.colors.font1};
      background: ${props => props.theme.colors.transparent};
    }

    span {
      break-after: avoid;
    }

    img {
      break-inside: avoid;
    }

    .print-list-simulated {
      &-feedback {
        margin: 16px;

        &-title {
          display: block;
          text-align: center;
          margin-bottom: 16px;
        }

        &-container {
          display: flex;
          justify-content: space-between;

          &-right {
            margin-left: 16px;
          }
        }

        &-table {
          &-th {
            padding: 8px 24px;
            color: ${props => props.theme.colors.font6};
          }

          &-td {
            margin: 8px 32px;
            padding-top: 5px;
            text-align: center;
            border-radius: 50%;
            color: ${props => props.theme.colors.font1};

            &.number {
              margin-left: 50px;
              padding-top: 5px;
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              text-align: center;
              font-size: 0.75rem;
              font-weight: 700;
              color: ${props => props.theme.colors.font1};
              border-radius: 100%;
              border: 2px solid ${props => props.theme.colors.font6};
            }
          }
        }
      }
    }
  }
`;

export const TableRow = styled.tr`
  @media screen {
    border-bottom: 1px solid ${props => (props.isDarkMode ? props.theme.colors.ebony : "rgba(0,0,0, 0.1)")};
  }
  @media print {
    border-bottom: 1px solid ${props => (props.isDarkMode ? props.theme.colors.ebony : "rgba(0,0,0, 0.1)")};
  }
`;
