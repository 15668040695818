// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class TeachersHome extends PureComponent<Props> {
  render() {
    const { className, height, width } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 136">
        <title>Professores</title>
        <desc>Imagem de um capelo simbolizando os professores da plataforma</desc>
        <circle className="cls-1" cx="73.5" cy="61.5" r="50.5" />
        <path d="M85.27,117.27a1.52,1.52,0,0,1-1.53-1.53c0-6.16-2-8.14-8.14-8.14a1.53,1.53,0,1,1,0-3.06c6.16,0,8.14-2,8.14-8.14a1.53,1.53,0,0,1,3.06,0c0,6.17,2,8.14,8.13,8.14a1.53,1.53,0,0,1,0,3.06c-6.16,0-8.13,2-8.13,8.14A1.53,1.53,0,0,1,85.27,117.27Zm-2.58-11.2a7.41,7.41,0,0,1,2.58,2.58,7.25,7.25,0,0,1,2.58-2.58,7.17,7.17,0,0,1-2.58-2.58A7.33,7.33,0,0,1,82.69,106.07Z" />
        <path d="M5.44,104.69a1.54,1.54,0,0,1-1.53-1.53c0-2-.38-2.38-2.38-2.38a1.53,1.53,0,0,1,0-3.06c2,0,2.38-.38,2.38-2.38a1.53,1.53,0,0,1,3.06,0c0,2,.38,2.38,2.38,2.38a1.53,1.53,0,0,1,0,3.06c-2,0-2.38.37-2.38,2.38A1.53,1.53,0,0,1,5.44,104.69Z" />
        <path d="M113.91,11.2a5.6,5.6,0,1,1,5.6-5.6A5.6,5.6,0,0,1,113.91,11.2Zm0-8.14a2.54,2.54,0,1,0,2.54,2.54A2.54,2.54,0,0,0,113.91,3.06Z" />
        <path
          className="cls-2"
          d="M139.59,115.93a4.41,4.41,0,1,1,4.41-4.4A4.41,4.41,0,0,1,139.59,115.93Zm0-5.75a1.35,1.35,0,1,0,1.35,1.35A1.35,1.35,0,0,0,139.59,110.18Z"
        />
        <path
          className="cls-2"
          d="M31,10.46A1.52,1.52,0,0,1,29.93,10L23.27,3.36a1.55,1.55,0,0,1,0-2.17,1.53,1.53,0,0,1,2.17,0l6.65,6.66A1.53,1.53,0,0,1,31,10.46Z"
        />
        <path
          className="cls-2"
          d="M24.35,10.46A1.52,1.52,0,0,1,23.27,10a1.54,1.54,0,0,1,0-2.16l6.66-6.66a1.53,1.53,0,0,1,2.16,2.17L25.44,10A1.53,1.53,0,0,1,24.35,10.46Z"
        />
        <path
          className="cls-2"
          d="M40.5,136A1.53,1.53,0,0,1,39,134.47V126A1.53,1.53,0,0,1,42,126v8.43A1.52,1.52,0,0,1,40.5,136Z"
        />
        <path className="cls-2" d="M44.72,131.79H36.29a1.53,1.53,0,1,1,0-3.06h8.43a1.53,1.53,0,1,1,0,3.06Z" />
        <path d="M51.26,82A4.7,4.7,0,1,1,56,77.3,4.71,4.71,0,0,1,51.26,82Zm0-6.34A1.64,1.64,0,1,0,52.9,77.3,1.65,1.65,0,0,0,51.26,75.66Z" />
        <path d="M51.19,93.22a34.68,34.68,0,0,1-5.4-.44,1.54,1.54,0,0,1-1.29-1.51V85.5a6.57,6.57,0,0,1,13.13,0v5.77a1.54,1.54,0,0,1-1.27,1.51A30.22,30.22,0,0,1,51.19,93.22Zm-3.63-3.28a28.85,28.85,0,0,0,7,0V85.5a3.51,3.51,0,0,0-7,0Z" />
        <path d="M85.27,66.37a1.66,1.66,0,0,1-.54-.1L35.52,47.67a1.53,1.53,0,0,1,0-2.87L84.74,26.48a1.49,1.49,0,0,1,1.06,0L135,44.8a1.53,1.53,0,0,1,0,2.87l-49.2,18.6A1.66,1.66,0,0,1,85.27,66.37ZM40.42,46.25l44.85,17,44.85-17L85.27,29.54Z" />
        <path d="M83.75,80.85c-12,0-22.43-3.83-25.94-9.52a7.94,7.94,0,0,1-1.28-4.28V56.14a1.53,1.53,0,0,1,3.06,0V67.05a4.9,4.9,0,0,0,.82,2.66c2.93,4.76,12.53,8.08,23.34,8.08s20.42-3.32,23.34-8.06a5,5,0,0,0,.83-2.68V57.14a1.53,1.53,0,0,1,3.06,0v9.91a8,8,0,0,1-1.29,4.29C106.19,77,95.76,80.85,83.75,80.85Z" />
        <path d="M51.48,75.66A1.53,1.53,0,0,1,50,74.13V53.38a1.52,1.52,0,0,1,1-1.44l32.27-11.7a1.53,1.53,0,1,1,1,2.88L53,54.45V74.13A1.53,1.53,0,0,1,51.48,75.66Z" />
        <path d="M67.56,112.63h-.19a52,52,0,1,1,45.25-86.57,1.53,1.53,0,0,1-2.27,2.06,49,49,0,1,0-42.59,81.47,1.53,1.53,0,0,1-.2,3Z" />
        <path d="M104.17,103.15a1.51,1.51,0,0,1-1.23-.62,1.53,1.53,0,0,1,.32-2.14A49.2,49.2,0,0,0,123.09,61a1.53,1.53,0,1,1,3.06,0,52.28,52.28,0,0,1-21.07,41.81A1.53,1.53,0,0,1,104.17,103.15Z" />
      </svg>
    );
  }
}

export default TeachersHome;
