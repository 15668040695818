import { useState } from "react";

import Imagem from "../../../images/img-sim.png";
import JubiWinner from "../../../images/asset-jubi-bolsa.png";
import AssetConfetti from "../../../images/asset-confetti-bolsa.gif";
import * as S from "./styles";

const FeedbackHeader = ({ isWinner }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <S.Container>
      {isWinner ? (
        <S.Content isWinner={isWinner}>
          <S.Confetti src={AssetConfetti} alt=""  />
          <S.JubiGif src={JubiWinner} alt="Prof. Jubilut feliz" />
          <S.TextBox>
            <S.Title isWinner={isWinner}>PARABÉNS!</S.Title>

            <S.WinnerText>
              Você foi um dos ganhadores da
              <strong>BOLSA DE 100% DE DESCONTO</strong>
              para estudar no curso Super Med!
            </S.WinnerText>

            <S.Text isWinner={isWinner}>
              Entre em contato conosco através do e-mail
              <S.LinkClipboard data-clipboard-text="contato@aprovatotal.com.br" onClick={() => setShowTooltip(() => !showTooltip)}>
                contato@aprovatotal.com.br
                {showTooltip && <S.Tooltip showTooltip={showTooltip}>Copiado!</S.Tooltip>}
              </S.LinkClipboard>
              até o dia <strong>19/12/2023</strong> para resgatar o seu prêmio.
            </S.Text>
          </S.TextBox>
        </S.Content>
      ) : (
        <S.Content>
          <S.Rotator src={Imagem} alt="" />
          <S.Title isWinner={isWinner}>
            AGRADECEMOS A SUA PARTICIPAÇÃO!
          </S.Title>
          <S.Text>
            <strong>Você ganhou 25% de desconto</strong> para iniciar seus estudos no curso Super MED!<br/>
            Você tem até o dia <strong>19/12/2023</strong> para garantir seu benefício através deste {" "}
            <a href="https://checkout.aprovatotal.com.br/?seller=performance&source=simulado-supermed1&utm_source=simulado-supermed1&utm_medium=clique-texto&utm_campaign=simulado-supermed1&utm_content=banner-fixo-gabarito-simulado" target="_blank" rel="noreferrer">link</a>.
          </S.Text>

          <S.CouponClipboard data-clipboard-text="SUPERMED25" onClick={() => setShowTooltip(() => !showTooltip)}>
            <S.CouponText>CUPOM <strong>SUPERMED25</strong></S.CouponText>
            {showTooltip && <S.Tooltip showTooltip={showTooltip}>Copiado!</S.Tooltip>}
          </S.CouponClipboard>
        </S.Content>
      )}
    </S.Container>
  )
};

export default FeedbackHeader;
