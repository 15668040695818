// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Tag extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>Desconto</title>
        <desc>Representação de uma etiqueta de preço</desc>
        <g id="icon-tag"><path d="M31.71,5.29l-5-5A1,1,0,0,0,26,0H16a1,1,0,0,0-.71.29l-15,15a1,1,0,0,0,0,1.42l15,15a1,1,0,0,0,1.42,0l15-15A1,1,0,0,0,32,16V6A1,1,0,0,0,31.71,5.29ZM30,15.59l-14,14L2.41,16l14-14h9.18L30,6.41Z"/>
          <path d="M20,8.5A3.5,3.5,0,1,0,23.5,5,3.5,3.5,0,0,0,20,8.5Zm5,0A1.5,1.5,0,1,1,23.5,7,1.5,1.5,0,0,1,25,8.5Z"/>
        </g>
      </svg>
    );
  }
}

export default Tag;
