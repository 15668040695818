import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media (min-width: 1600px) {
    height: 100vh;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  padding: 76px 16px;

  @media (max-width: 1199px) {
    padding: 54px 12px 174px 12px;
  }
`;

export const Logo = styled.img`
  position: absolute;
  top: 34px;
  right: 36px;
  width: 40px;

  @media (max-width: 767px) {
    top: 16px;
    right: 16px;
    width: 32px;
  }
`;


// inscricao-confirmada
export const Section = styled.section`
  background: #1B3FB5;
  font-family: Roboto;
`;

export const LogoText = styled.img`
  width: 140px;
  margin-bottom: 16px;
`;

export const Title = styled.div`
  margin-bottom: 48px;
  color: #fff;
  font-size: 1.75rem;
  text-align: center;

  > strong {
    color: #fff;
    font-weight: 900;
  }

  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
`;

export const Card = styled.div`
  width: 100%;
  max-width: 928px;
  margin: 0 auto;
  padding: 64px;
  background: #fff;
  border-radius: 16px;

  @media (max-width: 767px) {
    max-width: 342px;
    padding: 32px 16px;
  }
`;

export const CardTitle = styled.div`
  margin-bottom: 40px;
  color: #FD742F;
  font-weight: 900;
  font-size: 2.375rem;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const Item = styled.div`
  width: 100%;

  & + & {
    margin-top: 32px;
  }
`;

export const ItemTitle = styled.div`
  margin-bottom: 8px;
  color: #1B3FB5;
  font-size: 1.75rem;
  white-space: nowrap;

  > strong {
    color: #1B3FB5;
    font-weight: 900;
  }

  > span {
    display: inline-block;
    margin-right: 24px;
    font-weight: 900;
  }

  @media (max-width: 767px) {
    font-size: 1.25rem;

    > span {
      margin-right: 16px;
    }
  }
`;

export const ItemText = styled.div`
  color: #001036;
  line-height: 27px;
  font-size: 1.25rem;
  font-family: Nunito;

  > strong {
    color: #001036;
    font-weight: 700;
  }

  > a {
    color: #1B3FB5;
    text-decoration: underline;

    &:visited,
    &:hover {
      color: #1B3FB5;
    }
  }

  @media (max-width: 767px) {
    font-size: 1rem;
  }
`;