import styled, { css } from "styled-components";
import { MediaQueries } from "ratel-ui";
import Tooltip from "../../../components/tooltip";
import { hexToRgb } from "../../../components/utils/hexToRgb";
import Icon from "../../../components/icon";
import Tabbable from "../../../components/tabbable";

export const Container = styled.div`
  padding: 16px;
  background-color: ${(props) => props.theme.colors.clear};
  position: relative;

  ${(props) =>
    props.isOdd &&
    `
    background-color: ${props.theme.colors.bg3};
  `}

  &:last-child {
    padding-bottom: 48px;

    ${MediaQueries.mobile`
      padding-bottom: 100px;
    `}
  }
`;

export const CanceledQuestion = styled.div`
  color: #fd7138;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  z-index: 999999;
  background: rgba(${(props) => hexToRgb(props.theme.colors.bg4)}, 0.8);

  ${(props) =>
    props.isOdd &&
    `
    background: rgba(${(props) => hexToRgb(props.theme.colors.bg3)}, 0.8);
  `}
`;

export const CanceledBorder = styled.div`
  color: #fd7138;
  margin: 8px 0;
  width: 100%;
  height: 100%;
  border: 5px dashed #fd7138;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    transform: matrix(0.97, -0.26, 0.26, 0.97, 0, 0);
    font-weight: 800;
    text-align: center;
    font-size: 56px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Report = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  textarea {
    margin-top: 8px;
    height: 56px;
    width: 100%;
    border-radius: 8px;
    padding: 8px;
    outline: none;
  }

  svg {
    margin: 12px 0 0 16px;
    fill: ${(props) => props.theme.colors.font6};
    cursor: pointer;
  }

  &.active {
    svg {
      fill: ${(props) => props.theme.colors.live};
    }
  }
`;

export const ReportIcon = styled.div``;

export const ReportTooltip = styled(Tooltip)`
  &&& {
    position: absolute;
    top: 70px;
    padding: 16px;
    text-align: right;
    z-index: 15;

    span {
      display: flex;
      width: 100%;
      padding-left: 16px;
      font-size: 0.875rem;
      color: rgba(255, 255, 255, 0.7);
      text-align: left;
    }

    button {
      right: 16px;
    }

    &:after {
      content: " ";
      position: absolute;
      top: -6%;
      left: 50px;
      width: 0;
      height: 0;
      margin-left: -35px;
      transform: rotate(360deg);
    }
  }
`;

export const Number = styled.div`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  margin-left: 16px;
  padding-top: 4px;
  color: #555555;
  font-weight: 700;
  text-align: center;
  font-size: 0.75rem;
  border-radius: 50%;
  font-family: Nunito;
  border: 1px solid #555555;

  ${MediaQueries.mobile`
    margin-left: 0;
  `}
`;

export const Statement = styled.p`
  flex: 1 1 auto;
  margin-right: 32px;
  margin-bottom: 16px;
  margin-left: 8px;
  font-size: 1rem;
  text-align: left;
  color: ${(props) => props.theme.colors.font1};
  word-break: break-word;

  strong {
    font-weight: 600;
    color: ${(props) => props.theme.colors.font1};
  }

  ${MediaQueries.mobileTablet`
    margin-right: 8px;
  `}

  ${MediaQueries.mobile`
    table {
      width: 100% !important;
    }
  `}
`;

export const Wrapper = styled.div``;

export const Alternative = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 16px;
  margin-bottom: 8px;
  padding: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: ${(props) => props.theme.colors.transparent};
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    `
    color: ${props.theme.colors.clear};
    background-color: #1B3FB5;

    &:after {
      background-color: #1B3FB5;
      border-color: #1B3FB5;
    }

    p,
    span {
      color: ${props.theme.colors.clear};
      background-color: #1B3FB5;
    }
  `};
`;

export const AlternativeInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 8px;
  text-align: left;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.font5};
  background: ${(props) => props.theme.colors.transparent};

  &::after {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.transparent};
    transition: 0.2s all ease-in-out;
  }

  ${(props) =>
    props.isSelected &&
    `
    &::after {
      background-color: #1B3FB5;
    }
  `}
`;

export const AlternativeText = styled.p`
  text-align: left;
  color: ${(props) => props.theme.colors.font1};
`;

export const Icons = styled(Icon)`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 16px;
  fill: ${(props) =>
    props.selected ? props.theme.colors.clear : props.theme.colors.font6};
  cursor: pointer;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const Tabbables = styled(Tabbable)`
  position: relative;

  ${(props) =>
    props.lineThrough &&
    css`
      span,
      p,
      label {
        color: ${(props) => props.theme.colors.font5} !important;
        text-decoration: line-through;
      }
    `}
`;
