// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Chemestry extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Química</title>
        <desc>Ícone de um tubo de ensaio</desc>
        <g id="icon-chem">
          <g id="_2" data-name=" 2">
            <path
              id="beaker_lab_research_chemistry"
              data-name="beaker lab research chemistry"
              d="M15.76,12.69,11,5V2h1a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H4A1,1,0,0,0,3,1H3A1,1,0,0,0,4,2H5V5L.24,12.69a2.28,2.28,0,0,0,2,3.31H13.72a2.29,2.29,0,0,0,2-3.31ZM7,5V2H9V5c0,.09,3,5,3,5H4ZM3,12H13l.28.55a1,1,0,0,1-.9,1.45H3.62a1,1,0,0,1-.9-1.45Z"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default Chemestry;
