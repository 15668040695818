import { useRef, useEffect, useState } from 'react';
import VanillaTilt from 'vanilla-tilt';
import MediaQuery from 'react-responsive';

import useForm from '../../utils/useForm';
import AssetIcon from '../../images/icone-simulado.png';
import AssetLogoDesktop from '../../images/logo-text.svg';
import AssetLogoMobile from '../../images/logoAprovaBranco.svg';
import { convertEvent, storeLead } from '../../utils/convertEvent';

import * as S from './styles';

export function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

const options = {
  scale: 1,
  speed: 500,
  max: 2,
  glare: true,
  'max-glare': 0.2,
};

export const SectionMainB = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [allUrlParams, setAllUrlParams] = useState('');
  const [isSecondPhase, setIsSecondPhase] = useState(true);
  const [{ values }, handleChange, handleSubmit] = useForm();

  useEffect(() => {
    setIsMounted(true);
    setAllUrlParams(window.location.search);
  }, []);


  const enviarContato = () => {
    localStorage.setItem('inscrito-simulado-super-med-i-2024', JSON.stringify(values));
    setLoading(true);
    convertEvent(values, 'inscrito-simulado-super-med-i-2024');
    // storeLead(values, "inscrito-simulado-super-med-i-2024", allUrlParams);

    return history.push('/simulado');
  };

  const handlePhoneField = (e) => {
    e.target.value = phoneMask(e.target.value);
    handleChange(e);
  };

  const phoneMask = (phone) => {
    return phone
      .replace(/\D/g, '')
      .replace(/^(\d)/, '($1')
      .replace(/^(\(\d{2})(\d)/, '$1) $2')
      .replace(/(\d{5})(\d{1,5})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  };

  const handleCpfField = (e) => {
    e.target.value = cpfMask(e.target.value);
    handleChange(e);
  };

  const cpfMask = (cpf) => {
    return cpf
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  };

  return (
    <S.Section>
      <S.Container>
        <MediaQuery query="(max-width: 1023px)">
          <S.Logo src={AssetLogoMobile} alt="Logotipo do Aprova Total"/>
        </MediaQuery>
        <MediaQuery query="(min-width: 1024px)">
          <S.Logo src={AssetLogoDesktop} alt="Logotipo do Aprova Total"/>
        </MediaQuery>

        <S.Column maxWidth="543px">
          <S.AssetIcon src={AssetIcon} alt=""/>
          <S.Title>
            <strong>SIMULADO</strong> SUPER MED
          </S.Title>

          <S.Subtitle>
            Inscreva-se e inicie o simulado agora mesmo!
          </S.Subtitle>

          <S.WhiteText>
            Todos os participantes ganham 25% de desconto.
            Mas os melhores colocados ganham BOLSA DE 100%* para estudar com o Aprova Total em 2024.

            <S.YellowText>
              O simulado ficará disponível entre os dias 10 e 12/12!
            </S.YellowText>

            <span>
              *consulte o {' '}
              <a
                href="https://content.app-us1.com/ldV4B/2023/12/04/77daa1ee-7196-4135-b965-a1a119fe0829.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                regulamento
              </a>
              .
            </span>
          </S.WhiteText>
        </S.Column>

        <S.Column maxWidth="400px" marginRight>
          {isMounted ? (
            <S.CardTilt options={options}>

              <S.FormTitle>
                CONFIRME SEUS DADOS!
              </S.FormTitle>
              <S.FormText>
                Válida somente <strong>uma participação por CPF!</strong>
              </S.FormText>

              <S.Form
                onSubmit={handleSubmit(enviarContato)}
                isSecondPhase={isSecondPhase}
              >
                <S.FormInputWrapper>
                  <S.Input
                    required
                    type="text"
                    name="name"
                    placeholder="Nome completo"
                    onChange={handleChange}
                  />
                  <S.Input
                    required
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    onChange={handleChange}
                  />
                  <S.Input
                    required
                    type="tel"
                    name="tel"
                    placeholder="WhatsApp"
                    onChange={(e) => handlePhoneField(e)}
                  />
                  <S.Input
                    required
                    name="cpf"
                    type="text"
                    maxLength={14}
                    placeholder="CPF"
                    mask="999.999.999-99"
                    onChange={e => handleCpfField(e)}
                  />

                  <S.Checkbox>
                    Li e concordo com os <a
                    href="https://www.aprovatotal.com.br/politica-de-privacidade?channel=organico&source=Paginas"
                    target="_blank" rel="noopener noreferrer">termos de Poítica e Privacidade</a>.
                    <S.CheckboxInput
                      required
                      name="privacyPolitics"
                      type="checkbox"
                      onChange={handleChange}
                    />
                    <S.FakeCheckbox/>
                    {/* {hasError && <S.CheckboxError>Campo obrigatório</S.CheckboxError>} */}
                  </S.Checkbox>

                  <S.Checkbox>
                    Li e concordo com o{' '}
                    <a
                      href="https://content.app-us1.com/ldV4B/2023/12/04/77daa1ee-7196-4135-b965-a1a119fe0829.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      regulamento
                    </a>
                    .
                    <S.CheckboxInput
                      required
                      type="checkbox"
                      name="rulesCheckbox"
                      onChange={handleChange}
                    />
                    <S.FakeCheckbox/>
                    {/* {hasError && <S.CheckboxError>Campo obrigatório</S.CheckboxError>} */}
                  </S.Checkbox>

                  <S.Button type="submit" disabled={loading} loading={loading}>
                    <span>INICIAR SIMULADO</span>
                  </S.Button>
                </S.FormInputWrapper>
              </S.Form>
            </S.CardTilt>
          ) : null}

          <S.InfoTooltip>
            Já se inscreveu?
            <S.Tooltip>
              Se você já se inscreveu e não foi redirecionado automaticamente para a tela de
              simualdo, por favor preencha seus dados novamente e clique no botão
              <strong>“INICIAR SIMULADO”</strong>.
              <br/>
              <br/>
              Desculpe o transtorno.
            </S.Tooltip>
          </S.InfoTooltip>
        </S.Column>
      </S.Container>
    </S.Section>
  );
};
