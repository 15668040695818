// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class MathXL extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Ícone Matemática</title>
        <desc>Ícone de uma calculadora</desc>
        <g id="icon-math-xl">
          <path d="M49.75,0h-32a8,8,0,0,0-8,8V56a8,8,0,0,0,8,8h32a8,8,0,0,0,8-8V8A8,8,0,0,0,49.75,0Zm6,56a6,6,0,0,1-6,6h-32a6,6,0,0,1-6-6V8a6,6,0,0,1,6-6h32a6,6,0,0,1,6,6Z" />
          <circle cx="28.75" cy="30" r="3" />
          <circle cx="38.75" cy="30" r="3" />
          <circle cx="48.75" cy="30" r="3" />
          <circle cx="18.75" cy="30" r="3" />
          <circle cx="28.75" cy="40" r="3" />
          <circle cx="38.75" cy="40" r="3" />
          <circle cx="48.75" cy="40" r="3" />
          <circle cx="18.75" cy="40" r="3" />
          <circle cx="28.75" cy="50" r="3" />
          <circle cx="38.75" cy="50" r="3" />
          <circle cx="48.75" cy="50" r="3" />
          <circle cx="18.75" cy="50" r="3" />
          <path d="M49.75,7h-32a4,4,0,0,0-4,4v8a4,4,0,0,0,4,4h32a4,4,0,0,0,4-4V11A4,4,0,0,0,49.75,7Zm2,12a2,2,0,0,1-2,2h-32a2,2,0,0,1-2-2V11a2,2,0,0,1,2-2h32a2,2,0,0,1,2,2Z" />
        </g>
      </svg>
    );
  }
}

export default MathXL;
