// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class BiologyXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Icone de Biologia</title>
        <desc>Representação de um DNA</desc>
        <g id="icon-bio-xl">
          <path d="M63.71,16.53a1,1,0,0,0-1.42,0c-.26.27-.52.51-.79.76,0,0,0,0,0,0L46.72,2.49c.24-.26.48-.52.75-.78A1,1,0,1,0,46.05.29c-7.21,7.22-6.65,13.37-5.16,17-3.72-1.43-10-1.81-17.72,5.85s-7.28,14-5.86,17.72c-3.65-1.49-9.8-2-17,5.16a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0c.26-.27.52-.51.79-.76,0,0,0,0,0,0l.74.74,8.85,8.86,5.18,5.18c-.24.26-.48.52-.75.78a1,1,0,0,0,0,1.42,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29c7.21-7.22,6.65-13.37,5.16-17,3.72,1.43,10,1.81,17.72-5.85s7.28-14,5.86-17.72c3.65,1.49,9.8,2.05,17-5.16A1,1,0,0,0,63.71,16.53ZM43.1,20.9a10,10,0,0,1,2,9.55L33.56,18.86A10.07,10.07,0,0,1,43.1,20.9ZM31.48,19.61,44.4,32.53a19.74,19.74,0,0,1-2.26,3.77L27.71,21.86A18.59,18.59,0,0,1,31.48,19.61Zm-12,24.9h0a10.29,10.29,0,0,1,2.19,9.69L9.8,42.34A10.24,10.24,0,0,1,19.47,44.51Zm-5.95,10.4L4.66,46.05,4,45.41a18.53,18.53,0,0,1,3.7-2.32L20.91,56.28A18.53,18.53,0,0,1,18.59,60ZM20.9,43.1a10,10,0,0,1-2-9.55L30.44,45.14A10.07,10.07,0,0,1,20.9,43.1Zm11.62,1.29L19.6,31.47a19.74,19.74,0,0,1,2.26-3.77L36.29,42.14A19.06,19.06,0,0,1,32.52,44.39Zm6.89-5c-.53.53-1,1-1.56,1.46L23.13,26.15c.45-.51.93-1,1.46-1.56s1.05-1,1.56-1.46l.71.72,6.65,6.64h0l7.36,7.35C40.42,38.36,39.94,38.88,39.41,39.41Zm5.12-19.92h0A10.29,10.29,0,0,1,42.33,9.8L54.2,21.67A10.26,10.26,0,0,1,44.53,19.49Zm11.75,1.42L43.09,7.72A18.53,18.53,0,0,1,45.41,4L60,18.59A18.53,18.53,0,0,1,56.28,20.91Z" />
        </g>
      </svg>
    );
  }
}

export default BiologyXl;
