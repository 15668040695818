import React, { PureComponent } from "react";
import * as S from "./styles";

const focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
  if (relatedTarget === null) return false;

  var node = relatedTarget.parentNode;

  while (node !== null) {
    if (node === currentTarget) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

class Tooltip extends PureComponent {
  constructor() {
    super();
    this.toolRef = React.createRef();

    this.onBlur = this.onBlur.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      setTimeout(() => {
        this.toolRef.current.focus();
      }, 50);
    }
  }

  onBlur = e => {
    if (this.props.open && !focusInCurrentTarget(e)) setTimeout(this.props.toggleTooltip(), 10);
  };

  render() {
    const { className, children } = this.props;
    return (
      <S.Container className={`tooltip ${className}`} tabIndex="0" ref={this.toolRef} onBlur={this.onBlur}>
        {children}
      </S.Container>
    );
  }
}

export default Tooltip;
