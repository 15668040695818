// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Physical extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Física</title>
        <desc>Ícone de moléculas</desc>
        <g id="icon-phy">
          <path d="M13.5,10a2.5,2.5,0,0,0-.91.18l-1-1A3.52,3.52,0,0,0,12,7.5a3.53,3.53,0,0,0-.2-1.13l.72-.57a2.45,2.45,0,0,0,1,.2A2.5,2.5,0,1,0,11,3.5a2.32,2.32,0,0,0,.16.84l-.52.41A3.48,3.48,0,0,0,8.5,4a3.62,3.62,0,0,0-.94.14l-.74-.73A2.53,2.53,0,1,0,5.41,4.82l.43.43A3.45,3.45,0,0,0,5,7.5a3.52,3.52,0,0,0,.43,1.65L4.29,10.3A3,3,0,1,0,6,13a3,3,0,0,0-.3-1.29l1.15-1.14a3.38,3.38,0,0,0,3.3,0l1,1A2.49,2.49,0,1,0,13.5,10Zm0-7a.5.5,0,1,1-.5.5A.5.5,0,0,1,13.5,3ZM4,2.5a.5.5,0,1,1,.5.5A.5.5,0,0,1,4,2.5ZM3,14a1,1,0,1,1,1-1A1,1,0,0,1,3,14ZM7,7.5A1.5,1.5,0,1,1,8.5,9,1.5,1.5,0,0,1,7,7.5ZM13.5,13a.5.5,0,1,1,.5-.5A.5.5,0,0,1,13.5,13Z" />
        </g>
      </svg>
    );
  }
}

export default Physical;
