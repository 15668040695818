// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Back extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone voltar</title>
        <desc>Ícone de uma seta apontando para a esquerda</desc>
        <g id="icon-back">
          <path d="M3.44,9l5.28,5.29a1,1,0,0,1,0,1.42h0a1,1,0,0,1-1.42,0l-7-7a1,1,0,0,1,0-1.42l7-7a1,1,0,0,1,1.41,0h0a1,1,0,0,1,0,1.42L3.44,7H15a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1Z" />
        </g>
      </svg>
    );
  }
}

export default Back;
