import React, { Component } from "react";
import classNames from "classnames";

class Cart extends Component {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>Venda</title>
        <desc>Representação de um carrinho de compras</desc>
        <g id="icon-cart">
          <path
            d="M26,21c0.4,0,0.8-0.2,0.9-0.6l5-12C32,8.1,32,7.7,31.8,7.4C31.6,7.2,31.3,7,31,7H6.4L5,2.7C4.9,2.3,4.4,2,4,2H1
		C0.4,2,0,2.4,0,3s0.4,1,1,1h2.3l6.5,19.5c-1.7,1-2.3,3.1-1.3,4.8c1,1.7,3.1,2.3,4.8,1.3c1.6-0.9,2.2-2.9,1.4-4.6h6.7
		c-0.2,0.5-0.3,1-0.3,1.5c0,1.9,1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S26.4,23,24.5,23c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0H11.7
		l-0.7-2H26z M8.4,13H13v2H9.1L8.4,13z M15,13h6v2h-6V13z M15,11V9h6v2H15z M27,15h-4v-2h4.8L27,15z M21,17v2h-6v-2H21z M25.3,19H23
		v-2h3.2L25.3,19z M28.7,11H23V9h6.5L28.7,11z M13,9v2H7.7L7.1,9H13z M13,26.5c0,0.8-0.7,1.5-1.5,1.5S10,27.3,10,26.5
		s0.7-1.5,1.5-1.5S13,25.7,13,26.5z M26,26.5c0,0.8-0.7,1.5-1.5,1.5S23,27.3,23,26.5s0.7-1.5,1.5-1.5S26,25.7,26,26.5z M9.7,17H13v2
		h-2.6L9.7,17z"
          />
        </g>
      </svg>
    );
  }
}

export default Cart;
