// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class YellowSeptember extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <g transform="translate(-2)">
          <rect style={{ fill: "none" }} width="32" height="32" transform="translate(2)" />
          <g transform="translate(-4)">
            <path
              style={{ fill: "none" }}
              d="M29,11.2a10.655,10.655,0,0,0-3,.414l3,4.866,3-4.866A10.655,10.655,0,0,0,29,11.2Z"
              transform="translate(-7 -5.6)"
            />
            <path
              style={{ fill: "#d8ae00" }}
              d="M26.327,5.6a10.655,10.655,0,0,1,3,.414L32,1.68C30.921.676,28.637,0,26.327,0s-4.594.676-5.673,1.68l2.673,4.334A10.655,10.655,0,0,1,26.327,5.6Z"
              transform="translate(-4.327)"
            />
            <path
              style={{ fill: "#f4c500" }}
              d="M32,12.56l3.265,5.294L40.719,9.01a1.109,1.109,0,0,0-.025-1.2L38.1,3.988l-.427-.628L35,7.694Z"
              transform="translate(-10 -1.68)"
            />
            <path
              style={{ fill: "#f4c500" }}
              d="M12.192,44.209l2.524,3.717a.571.571,0,0,0,.956-.02L22,37.642l-3.265-5.294L12.165,43A1.108,1.108,0,0,0,12.192,44.209Z"
              transform="translate(0 -16.174)"
            />
            <path
              style={{ fill: "#ffd600" }}
              d="M32.924,29.715a1.108,1.108,0,0,0,.026-1.206l-6.57-10.655L23.116,12.56l-3-4.866L17.443,3.36l-.427.628L14.423,7.806a1.109,1.109,0,0,0-.025,1.2l5.454,8.845,3.265,5.294,6.329,10.263a.571.571,0,0,0,.956.021Z"
              transform="translate(-1.116 -1.68)"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default YellowSeptember;
