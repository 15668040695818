import React, { Component } from "react";

import * as S from "./styles";

class VideoPlayerResolution extends Component {
  player: any;

  onEventListener = (event) => {};

  componentWillUnmount() {
    this.player = null;
  }

  componentDidMount() {
    const { resolution, defaultPlayer } = this.props;
    const heightPlayer = window.matchMedia("(max-width: 767px)").matches
      ? 145
      : 358;

    if (defaultPlayer === "samba") {
      setTimeout(() => {
        this.player = new window.SambaPlayer("player-simulation", {
          height: heightPlayer,
          width: "100%",
          ph: "ab581b507556a61face4d2fc75946a29", //"442189dbff37920ceae523517366b5fd",
          m: resolution,
          playerParams: {
            enableShare: false,
            startOutput: "360p",
          },
          events: {
            //Funcão que escuta os eventos do player
            //onLoad: this.onEventListener,
            onStart: this.onEventListener,
            onPause: this.onEventListener,
            //onResume: this.onEventListener,
            onFinish: this.onEventListener,
            //onSeek: this.onEventListener,
            // onError: this.onEventListener
          },
        });
      }, 10);
    }
  }

  render() {
    return (
      <S.ContainerYoutube>
        <iframe
          src={this.props.youtubeUrl}
          title="Rosulução questões simuladão - Aprova Total"
        />
      </S.ContainerYoutube>
    );
  }
}

export default VideoPlayerResolution;
