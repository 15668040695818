// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Flower extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Flor</title>
        <desc>Representação de uma flor murcha</desc>
        <g id="icon-flower">
          <path d="M57.11,21.64a13.3,13.3,0,0,0-8.06-3.57,1.84,1.84,0,0,0,.1-.52h0a3.49,3.49,0,0,0-2.07-3.37C46.73,11.13,44.72,0,34.15,0a10.43,10.43,0,0,0-8.71,4.25C18.7,13.05,21,34,23.71,48.38a47.56,47.56,0,0,0-4.1-4.74c.52-2,1.38-6.78-1.45-10.67-2.64-3.64-7.73-5.36-15.1-5.1a1,1,0,0,0-1,.89c-.05.42-1,10.38,4.47,15A10.08,10.08,0,0,0,13.26,46a19.08,19.08,0,0,0,5.09-.77,46.21,46.21,0,0,1,5.87,7.31,1,1,0,0,0,.38.35c1.21,5.8,2.29,9.84,2.44,10.37A1,1,0,0,0,28,64a.84.84,0,0,0,.27,0,1,1,0,0,0,.7-1.22C28.85,62.3,17.09,18.46,27,5.46A8.44,8.44,0,0,1,34.15,2c8.9,0,10.61,9.38,10.93,12.24a3.52,3.52,0,0,0-1.93,3.21,1.63,1.63,0,0,0,.21.85,12.42,12.42,0,0,0-6.07,3.19c-3.13,3-4.59,7.73-4.33,14a2.86,2.86,0,0,0,1.46,2.65,3.33,3.33,0,0,0,1.58.35,11.79,11.79,0,0,0,5-1.59C42.06,40.73,43.79,44,46.53,44c3.11,0,4.95-2.73,6-6.56a9.08,9.08,0,0,0,4.64,1.82A3.49,3.49,0,0,0,58.46,39c1.35-.54,2.13-1.91,2.33-4.08C61.32,29.07,60.09,24.59,57.11,21.64ZM7.86,42.22C4,39,3.93,32.25,4,29.85c6.18-.1,10.38,1.36,12.51,4.29,1.9,2.6,1.73,5.88,1.37,7.88a48.49,48.49,0,0,0-6.24-4.83,1,1,0,1,0-1.09,1.68,46.63,46.63,0,0,1,6.07,4.71C12.9,44.41,9.94,44,7.86,42.22ZM46.16,16h0c.5,0,.93.63,1,1.37a2.9,2.9,0,0,1-2,0C45.22,16.61,45.67,16,46.16,16ZM35.36,36.33c-.09,0-.37-.19-.4-1-.23-5.63,1-9.81,3.7-12.42a10,10,0,0,1,1.21-1,7.76,7.76,0,0,0-.65,3.1,63.68,63.68,0,0,0,1.26,9.8C38.79,35.81,36.29,36.82,35.36,36.33ZM46.53,42c-3.05,0-5-10-5.31-17a5.56,5.56,0,0,1,1.38-3.58A5.27,5.27,0,0,1,46.53,20a5.31,5.31,0,0,1,3.93,1.38A5.58,5.58,0,0,1,51.85,25v0A52.13,52.13,0,0,1,51,35.38a0,0,0,0,1,0,0C50.08,39.64,48.55,42,46.53,42ZM58.8,34.77c-.09.93-.34,2.1-1.09,2.4-1.17.48-3.41-.87-4.68-1.85A56.29,56.29,0,0,0,53.85,25a7.94,7.94,0,0,0-1.12-3.94,9.74,9.74,0,0,1,3,2C58.24,25.58,59.27,29.52,58.8,34.77Z" />
          <path d="M43.59,50.08c-1.27-.5-3,0-4.74,1.23a11.09,11.09,0,0,0-3.75,4.84,4.53,4.53,0,0,0,.89,5,6.27,6.27,0,0,0,2.11,1.45,6,6,0,0,0,2.12.43,4.42,4.42,0,0,0,3-1.14,5.72,5.72,0,0,0,1.47-2c0-.06,0-.12.05-.18C45.3,56.48,45.94,51,43.59,50.08Zm-.83,9.12a3.8,3.8,0,0,1-.88,1.18,2.78,2.78,0,0,1-3.05.34C38.52,60.6,36,59.49,37,56.87A9.23,9.23,0,0,1,40,52.92c1.28-.94,2.35-1.16,2.8-1C43.4,52.42,43.36,55.86,42.76,59.2Z" />
        </g>
      </svg>
    );
  }
}

export default Flower;
