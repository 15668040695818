import React, { PureComponent } from "react";
import classNames from "classnames";

class InstagramBackground extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>instagram</title>
        <desc>ícone da rede social instagram</desc>
        <g id="icon-insta">
          <circle className="cls-1" cx="16" cy="16" r="16" />
          <path
            className="cls-2"
            d="M22.51709,10.73555A1.21175,1.21175,0,0,1,21.228,11.96617,1.25184,1.25184,0,0,1,20.08972,10.698,1.229,1.229,0,0,1,21.36685,9.5287,1.19972,1.19972,0,0,1,22.51709,10.73555Z"
          />
          <path
            className="cls-2"
            d="M16.13671,10.844a5.158,5.158,0,1,0,5.0199,5.157A5.12792,5.12792,0,0,0,16.13671,10.844Zm3.178,5.17924a3.3115,3.3115,0,1,1-3.307-3.31951A3.27211,3.27211,0,0,1,19.31473,16.0232Z"
          />
          <path
            className="cls-2"
            d="M26,12.34921a.37724.37724,0,0,1-.01893-.21248,7.96172,7.96172,0,0,0-.16382-1.8697,5.22784,5.22784,0,0,0-2.231-3.29988,5.79747,5.79747,0,0,0-3.03048-.93255A9.67557,9.67557,0,0,1,19.53175,6h-7.0635a2.68764,2.68764,0,0,1-.54936.01939,8.89934,8.89934,0,0,0-1.41776.1187A5.38874,5.38874,0,0,0,7.61726,7.6172a5.60712,5.60712,0,0,0-1.58142,3.78786c-.01831.35451.01166.71014-.03584,1.06319v7.0635A3.38667,3.38667,0,0,1,6.02,20.121,10.114,10.114,0,0,0,6.103,21.32427,5.45621,5.45621,0,0,0,7.701,24.46767,5.60671,5.60671,0,0,0,11.40448,25.965a8.29453,8.29453,0,0,1,.94473.035h7.34127a8.9682,8.9682,0,0,1,.96321-.03465,5.65094,5.65094,0,0,0,2.46676-.655,5.335,5.335,0,0,0,2.84348-4.6765c.02042-.31468-.01438-.63059.03607-.94341Zm-1.83636,6.60088a9.52954,9.52954,0,0,1-.22488,2.42054,3.452,3.452,0,0,1-1.13056,1.85776,3.79154,3.79154,0,0,1-1.84609.7904,12.78312,12.78312,0,0,1-1.6573.12052c-2.10647.08034-4.21332.02358-6.3198.02525a10.87275,10.87275,0,0,1-1.50831-.07275,4.59282,4.59282,0,0,1-1.90827-.59123A3.25323,3.25323,0,0,1,8.22355,21.861a5.941,5.941,0,0,1-.3241-1.71824c-.13893-2.29451-.05854-4.59068-.06381-6.88588a14.51874,14.51874,0,0,1,.07155-1.70691A4.41658,4.41658,0,0,1,8.58138,9.4537a3.44858,3.44858,0,0,1,2.25724-1.43154,11.24584,11.24584,0,0,1,1.68647-.16666c2.0073-.05751,4.015-.02522,6.02233-.02594a18.69825,18.69825,0,0,1,1.90482.0831,4.34309,4.34309,0,0,1,2.08115.65978,3.35662,3.35662,0,0,1,1.40371,2.06512,9.15857,9.15857,0,0,1,.20159,1.8341C24.21654,14.631,24.173,16.79084,24.16364,18.95009Z"
          />
        </g>
      </svg>
    );
  }
}

export default InstagramBackground;
