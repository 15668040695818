import React, { Component } from "react";

function removerAcentos(newStringComAcento) {
  let string = newStringComAcento;
  let mapaAcentosHex = {
    a: /[\xE0-\xE6]/g,
    A: /[\xC0-\xC6]/g,
    e: /[\xE8-\xEB]/g,
    E: /[\xC8-\xCB]/g,
    i: /[\xEC-\xEF]/g,
    I: /[\xCC-\xCF]/g,
    o: /[\xF2-\xF6]/g,
    O: /[\xD2-\xD6]/g,
    u: /[\xF9-\xFC]/g,
    U: /[\xD9-\xDC]/g,
    c: /\xE7/g,
    C: /\xC7/g,
    n: /\xF1/g,
    N: /\xD1/g
  };

  for (let letra in mapaAcentosHex) {
    let expressaoRegular = mapaAcentosHex[letra];
    string = string.replace(expressaoRegular, letra);
  }
  return string;
}

function initials(user = "") {
  let nomecompleto = user.replace(/\s(de|da|dos|das)\s/g, " ");
  nomecompleto = removerAcentos(nomecompleto);
  let iniciais = nomecompleto.match(/\b(\w)/gi);
  if (iniciais) {
    return iniciais.length > 1 ? iniciais[0] + iniciais[iniciais.length - 1] : iniciais[0];
  } else return "";
}

class NewUserAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgLoad: false,
      modalUserInfo: false
    };
    this.handleOpenModalUserInfo = this.handleOpenModalUserInfo.bind(this);
  }

  handleOpenModalUserInfo() {
    this.setState({ modalUserInfo: !this.state.modalUserInfo });
  }
  render() {
    let {
      borderRadius = "100%",
      src,
      srcset,
      name = "",
      color = "#e3e3e3",
      textColor = "#555555",
      colorsConfig,
      fontSize = "0.875rem",
      size = 32,
      style,
      ranking,
      iconBottom,
      className,
      location
    } = this.props;

    const { imgLoad } = this.state;


    const abbr = initials(name.toUpperCase());
    size = `${size}px`;

    const imageStyle = {
      display: "block",
      minHeight: size,
      minWidth: size,
      ...colorsConfig,
      borderRadius
    };

    let innerStyle = {
      position: "relative",
      textAlign: "center",
      color: textColor,
      fontWeight: "bold",
      fontSize: fontSize,
      width: size,
      height: size,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      borderRadius
    };

    let pointStyle = {
      width: "20px",
      height: "20px",
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translate(-50%)",
      borderRadius: "50%",
      color: "#fff",
      backgroundColor: "#3171f1",
      textAlign: "center",
      fontSize: ".75rem",
      paddingTop: "1px",
      boxShadow: "0 2px 3px rgba(0,0,0,.1)"
    };

    let avatar,
      classes = [className, "UserAvatar"];

    let imgLoaded;

    if (src || srcset) {
      imgLoaded = (
        <img
          style={{ ...imageStyle, display: imgLoad ? "block" : "none" }}
          src={src}
          srcSet={srcset}
          alt={name}
          onLoad={() => {
            this.setState({ imgLoad: true });
          }}
        />
      );
    } else {
      innerStyle.backgroundColor = color;
      innerStyle = { ...innerStyle, ...colorsConfig };
      avatar = abbr;
    }

    if (src || srcset) {
      innerStyle = { ...innerStyle, ...colorsConfig, color: "transparent" };
    }

    if (innerStyle.backgroundColor) {
      classes.push(`UserAvatar`);
    }

    if (location && (location.pathname === "/medio" || location.pathname === "/superior")) {
      innerStyle = {
        ...innerStyle,
        border: "2px solid #20CDE8",
        boxShadow: "0px 0px 10px #20CDE8",
        overflow: "hidden"
      };
    }

    return (
      <>
        <div aria-label={name} className={classes.join(" ")} style={style}>
          <div className="UserAvatar--inner" style={innerStyle} onClick={this.handleOpenModalUserInfo}>
            {!imgLoad && avatar}
            {imgLoaded}
            {ranking && <span style={pointStyle}>{ranking}</span>}
            {iconBottom && <>{iconBottom}</>}
          </div>
        </div>
      </>
    );
  }
}

export default NewUserAvatar;
