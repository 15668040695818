import styled from "styled-components";
import { MediaQueries } from "ratel-ui";

export const Container = styled.div`
  .modal-rules {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      display: none;
    }

    &-card {
      position: relative;
      width: 670px;
      border-radius: 16px;
      overflow: auto;
    }

    &-header {
      justify-content: center;
      align-items: flex-start;
      padding: 16px;
      text-align: left;
      background-color: ${props => props.theme.colors.transparent};

      &-title {
        display: block;
        font-size: 1rem;
        font-weight: 700;
        text-align: left;
        line-height: 24px;
        color: ${props => props.theme.colors.font2};

        ${MediaQueries.mobile`
          font-size: 0.875rem;
        `}
      }

      &-close {
        display: flex;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        fill: ${props => props.theme.colors.clear};
        background-color: rgba(0, 0, 0, 0.15);
      }
    }

    &-body {
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 16px;
      color: ${props => props.theme.colors.font1};
      background: ${props => props.theme.colors.transparent};
    }

    &-footer {
      position: sticky;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      padding: 16px;
      border-top: 1px solid ${props => props.theme.colors.bg5};
      background-color: ${props => props.theme.colors.transparent};
    }

    &-item {
      display: flex;
      align-items: flex-start;
      padding-bottom: 24px;
      font-size: 0.875rem;

      &:last-child {
        padding-bottom: 0;
      }

      &-title {
        font-weight: bold;
      }

      &-text {
        line-height: 21px;

        strong {
          color: ${props => props.theme.colors.font1};
        }

        ${MediaQueries.mobile`
          font-size: 0.875rem;
        `}
      }

      &-list {
        list-style: disc;
        margin-left: 32px;
      }
    }

    &-icons {
      display: flex;
      align-items: center;
      margin-top: 8px;

      &-wrapper {
        display: flex;
        justify-content: space-around;
        margin-right: 8px;
        min-width: 76px;
        font-size: 1.125rem;
        font-weight: 700;

        > svg {
          fill: ${props => props.theme.colors.bg2};
        }
      }

      &-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #bbbbbb;

        > svg {
          fill: ${props => props.theme.colors.bg2};
        }
      }

      &-question {
        width: 8px;
        height: 8px;
        border: 1px solid ${props => props.theme.colors.bg2};
        background-color: transparent;
        border-radius: 50%;

        &.fill {
          background-color: ${props => props.theme.colors.bg2};
          margin-right: 4px;
        }
      }
    }
  }
`;
