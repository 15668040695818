import React from "react";

import * as S from "./styles";

export const StepFour = () => {
  return (
    <S.Container>
      <S.Title>Durante o <strong>simulado</strong></S.Title>

      <S.Text>
        <S.List>
          <S.Item>
            Você terá <strong>1h30min</strong> para responder o simulado;
          </S.Item>
          <S.Item>
            <strong>Não é possível parar o simulado.</strong> O tempo continuará correndo até que
            você encerre o simulado ou o tempo se esgote. Caso o tempo se encerre, questões em branco (não respondidas) serão consideradas erradas;
          </S.Item>
        </S.List>
      </S.Text>
    </S.Container>
  );
};
