// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Twitter extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 24">
        <title>Twitter</title>
        <path d="M10.75,24C21.9,24,28,14.77,28,6.76c0-.26,0-.53,0-.79a12.31,12.31,0,0,0,3-3.13,12.07,12.07,0,0,1-3.48,1A6.1,6.1,0,0,0,30.18.44a12.13,12.13,0,0,1-3.84,1.47A6.07,6.07,0,0,0,16,7.44,17.25,17.25,0,0,1,3.52,1.11,6.06,6.06,0,0,0,5.4,9.2a6.08,6.08,0,0,1-2.76-.76v.07a6.07,6.07,0,0,0,4.87,5.94,6,6,0,0,1-2.74.11,6.06,6.06,0,0,0,5.66,4.21,12.19,12.19,0,0,1-7.52,2.6,13.93,13.93,0,0,1-1.45-.09A17.19,17.19,0,0,0,10.75,24" />
      </svg>
    );
  }
}

export default Twitter;
