import { useState, useEffect } from "react";
import moment from "moment";
import { http } from "../core/Api";

export const useServerTime = () => {
  const [serverTime, setServerTime] = useState(null);

  useEffect(() => {
    try {
      const before = new Date();
      http(`api/backend/v2/time`, "GET").then((response) => {
        const after = new Date();

        const diff = after - before;
        setServerTime(moment(response).valueOf() + diff);
      });
    } catch (e) {}
  }, []);

  return [serverTime, setServerTime];
};

export const getServerTime = async () => {
  try {
    const before = new Date();
    const serverTime = await http(`api/backend/v2/time`, "GET");
    const after = new Date();
    const diff = after - before;
    return serverTime + diff;
  } catch (e) {}
};

export const useSimuladao = () => {
  const [simuladao, setSimuladao] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    try {
      http(`api/backend/v2/scholarshipcontest`, "GET").then((response) => {
        if (!response.error) {
          setSimuladao(response);
        }
        setLoading(false);
      });
    } catch (e) {
      setSimuladao(null);
      setLoading(false);
    }
  }, []);

  return [simuladao, setSimuladao, isLoading];
};

export const useOpenSimuladao = (simulated, redirect) => {
  const [simuladao, setSimuladao] = useState(null);
  const [history, setHistory] = useState({});
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    try {
      let rout = `api/backend/v2/scholarshipcontest/verify/started`;
      http(rout, "POST", { simulated: simulated }).then((response) => {
        if (!response.simulated) {
          redirect.push("/simulado");
        }

        if (!response.error) {
          setHistory(response.history);
          setSimuladao(response.simulated);
        }
        setLoading(false);
      });
    } catch (e) {
      setSimuladao(null);
      setLoading(false);
      redirect.push("/simulado");
    }
  }, []);

  return [simuladao, setSimuladao, history, setHistory, isLoading];
};

export const goToSimuladao = (user, history) => {
  history.push("/simulado");
};

export const verifyLinkSimulation = (rankingEndDate) =>
  new Date(rankingEndDate) <= new Date();
