// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Clock extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Relógio</title>
        <desc>Representação de um relógio</desc>
        <g id="icon-clock-2" data-name="icon-clock">
          <path d="M32,0A32,32,0,1,0,64,32,32,32,0,0,0,32,0Zm0,62A30,30,0,1,1,62,32,30,30,0,0,1,32,62Z" />
          <path d="M32,33H42a1,1,0,0,0,0-2H33V14a1,1,0,0,0-2,0V32A1,1,0,0,0,32,33Z" />
          <path d="M32,11a1,1,0,0,0,1-1V4a1,1,0,0,0-2,0v6A1,1,0,0,0,32,11Z" />
          <path d="M32,53a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V54A1,1,0,0,0,32,53Z" />
          <path d="M11,32a1,1,0,0,0-1-1H4a1,1,0,0,0,0,2h6A1,1,0,0,0,11,32Z" />
          <path d="M60,31H54a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" />
          <path d="M18.87,7.25a1,1,0,1,0-1.74,1l2,3.47a1,1,0,0,0,1.37.36,1,1,0,0,0,.37-1.36Z" />
          <path d="M44.87,52.28a1,1,0,0,0-1.74,1l2,3.47a1,1,0,1,0,1.74-1Z" />
          <path d="M11.72,19.13l-3.47-2a1,1,0,0,0-1,1.74l3.47,2a1,1,0,0,0,.49.13,1,1,0,0,0,.87-.5A1,1,0,0,0,11.72,19.13Z" />
          <path d="M56.75,45.13l-3.47-2a1,1,0,0,0-1,1.74l3.47,2a1,1,0,1,0,1-1.74Z" />
          <path d="M52.79,21a1,1,0,0,0,.49-.13l3.47-2a1,1,0,1,0-1-1.74l-3.47,2a1,1,0,0,0-.36,1.37A1,1,0,0,0,52.79,21Z" />
          <path d="M10.72,43.13l-3.47,2a1,1,0,0,0,1,1.74l3.47-2a1,1,0,0,0-1-1.74Z" />
          <path d="M46.5,6.88a1,1,0,0,0-1.37.37l-2,3.47a1,1,0,0,0,.37,1.36,1,1,0,0,0,1.37-.36l2-3.47A1,1,0,0,0,46.5,6.88Z" />
          <path d="M20.5,51.92a1,1,0,0,0-1.37.36l-2,3.47a1,1,0,0,0,1.74,1l2-3.47A1,1,0,0,0,20.5,51.92Z" />
        </g>
      </svg>
    );
  }
}

export default Clock;
