import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Toast } from "ratel-ui";
import { bindActionCreators } from "redux";

import Icon from "../../../components/icon";
import { doReportRequest } from "../../index";
import Tabbable from "../../../components/tabbable";
import VideoPlayerResolution from "../videoPlayerResolution";
import * as S from "./styles";

class ModalAnswer extends Component {
  state = {
    resolutionIndex: 0,
    loading: true,
    loadingTooltip: false,
    reportToast: false,
    messageReport: "",
  };

  reorderFeedback = (feedback) => {
    const mathFeedback = feedback
      .filter((it) => it.area === "5b2186ec9c5aa43084ff7d1c")
      .sort((a, b) => a.order - b.order);
    const restFeedback = feedback
      .filter((it) => it.area !== "5b2186ec9c5aa43084ff7d1c")
      .sort((a, b) => a.order - b.order);
    return [...restFeedback, ...mathFeedback];
  };

  componentDidMount() {
    const { resolutionIndex } = this.props;
    if (resolutionIndex || resolutionIndex === 0) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.setState({
            resolutionIndex: resolutionIndex,
            loading: false,
          });
        }
      );
    }
  }

  previusResolution = () => {
    const { resolutionIndex } = this.state;
    if (resolutionIndex > 0)
      this.setState(
        {
          loading: true,
        },
        () => {
          this.setState({
            resolutionIndex: resolutionIndex - 1,
            loading: false,
          });
        }
      );
  };

  nextResolution = () => {
    let { feedback } = this.props;
    feedback = this.reorderFeedback(feedback);
    const { resolutionIndex } = this.state;
    if (resolutionIndex < feedback.length - 1)
      this.setState(
        {
          loading: true,
        },
        () => {
          this.setState({
            resolutionIndex: resolutionIndex + 1,
            loading: false,
          });
        }
      );
  };

  sendReport = () => {
    const { messageReport, resolutionIndex } = this.state;
    let { simulated, doReportRequest, feedback } = this.props;

    if (messageReport.length === 0) {
      return Toast.error("Descreva o problema encontrado.");
    }

    this.setState({ loadingTooltip: true });
    feedback = this.reorderFeedback(feedback);
    const body = {
      question: {
        text: feedback[resolutionIndex].questionText,
        order: feedback[resolutionIndex].order,
        alternatives: [],
      },
      message: messageReport,
      simulated: simulated.id,
    };

    doReportRequest(body).then((response) => {
      if (response.error) {
        Toast.error("Não foi possível enviar a mensagem, tente novamente!");
      } else {
        Toast.success(
          "Mensagem enviada! Nossa equipe já recebeu e vai avaliar o problema!"
        );
      }
      this.setState({
        messageReport: "",
        reportToast: false,
        loadingTooltip: false,
      });
    });
  };

  render() {
    let { toggleModalAnswer, feedback = [] } = this.props;
    const { resolutionIndex, loading, reportToast, loadingTooltip } =
      this.state;
    feedback = this.reorderFeedback(feedback);

    return (
      <S.Container>
        <div className="modal modal-resolution-simulation is-active">
          <div
            className="modal-background"
            onClick={() => toggleModalAnswer(false)}
          />
          <div className="modal-card modal-resolution-simulation-card">
            <header className="modal-card-head modal-resolution-simulation-header">
              <div>
                <span className="modal-card-title modal-resolution-simulation-title">
                  Questão {resolutionIndex + 1}
                </span>
                <span className="modal-card-title modal-resolution-simulation-subtitle">
                  Resolução
                </span>
              </div>
              <Tabbable
                aria-label="close"
                className="modal-resolution-simulation-close"
                onClick={() => toggleModalAnswer(false)}
              >
                <Icon
                  icon="close"
                  width={16}
                  height={16}
                  className="modal-resolution-simulation-icon"
                />
              </Tabbable>
            </header>
            <section className="modal-resolution-simulation-body">
              <div className="modal-resolution-simulation-video">
                {!loading && (
                  <VideoPlayerResolution
                    youtubeUrl={
                      (feedback &&
                        feedback.length > 0 &&
                        feedback[resolutionIndex] &&
                        feedback[resolutionIndex].youtubeUrl) ||
                      ""
                    }
                  />
                )}
              </div>
              <S.Report>
                <S.ReportIcon
                  onClick={() => {
                    this.setState({ reportToast: !reportToast });
                  }}
                >
                  <Icon icon={"reportFill"} height={16} width={16} />
                  <span>Encontrou um problema?</span>
                  <S.ReportTooltip
                    className={`simulate-question-report-tooltip ${
                      reportToast ? "active" : ""
                    }`}
                    open={reportToast}
                    toggleTooltip={() => {
                      this.setState({ reportToast: !reportToast });
                    }}
                  >
                    <span>Qual o problema encontrado?</span>
                    <textarea
                      id="text"
                      name="text"
                      placeholder="Descreva o problema"
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        this.setState({ messageReport: e.target.value });
                      }}
                      value={this.state.messageReport}
                    />

                    <Button
                      outline
                      transparent
                      loading={loadingTooltip}
                      onClick={() => this.sendReport()}
                    >
                      ENVIAR
                    </Button>
                  </S.ReportTooltip>
                </S.ReportIcon>
              </S.Report>
            </section>

            <div className="modal-resolution-simulation-footer">
              <div
                className={`modal-resolution-simulation-footer-button ${
                  resolutionIndex === 0 ? "disabled" : ""
                }`}
                onClick={this.previusResolution}
              >
                <Icon icon="back" width={16} height={16} />
              </div>
              <div
                className={`modal-resolution-simulation-footer-button ${
                  resolutionIndex === feedback.length - 1 ? "disabled" : ""
                }`}
                onClick={this.nextResolution}
              >
                <Icon icon="forward" width={16} height={16} />
              </div>
            </div>
          </div>
        </div>
      </S.Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    simulated: state.simulation.data.simulation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doReportRequest,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAnswer);
