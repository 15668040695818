import React from 'react'
import * as S from './styles'

const Container = ({children, isLast}) => {

  return (
    <S.Wrapper isLast={isLast}>
      {children}
    </S.Wrapper>
  )
}

export default Container
