import React, { PureComponent } from "react";
import classNames from "classnames";

class JubicalouroStar extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg
        className={classes}
        height={height}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 39.96 39.96">
        <g>
          <title>Jubicalouro Star</title>
          <desc>Estrela amarela de 5 pontas</desc>
          <path
            id="Caminho_1204"
            data-name="Caminho 1204"
            className=""
            d="M22.28719,1.44211l4.209,8.907a2.549,2.549,0,0,0,1.915,1.45l9.412,1.428a2.692,2.692,0,0,1,1.411,4.535l-6.811,6.937a2.734,2.734,0,0,0-.733,2.352l1.608,9.792a2.569,2.569,0,0,1-3.691,2.8l-8.418-4.619a2.451,2.451,0,0,0-2.368,0l-8.418,4.623a2.571,2.571,0,0,1-3.693-2.8l1.608-9.792a2.733,2.733,0,0,0-.731-2.352l-6.81-6.934a2.691,2.691,0,0,1,1.409-4.535l9.411-1.435a2.555,2.555,0,0,0,1.917-1.455l4.209-8.907a2.5,2.5,0,0,1,4.564.005Z"
          />
        </g>
      </svg>
    );
  }
}

export default JubicalouroStar;
