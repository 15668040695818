import React, { PureComponent } from "react";
import classNames from "classnames";

class FacebookBackground extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>facebook</title>
        <desc>ícone da rede social facebook</desc>
        <g id="icon-facebook">
          <path
            class="cls-1"
            d="M22.22812,20.625,22.9375,16H18.5V12.99866A2.31246,2.31246,0,0,1,21.10744,10.5H23.125V6.5625A24.60468,24.60468,0,0,0,19.54344,6.25C15.88869,6.25,13.5,8.465,13.5,12.475V16H9.4375v4.625H13.5V31.80563a16.17477,16.17477,0,0,0,5,0V20.625Z"
          />
        </g>
      </svg>
    );
  }
}

export default FacebookBackground;
