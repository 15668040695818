import React, { Component } from "react";
import { Button, Toast } from "ratel-ui";
import MediaQuery from "react-responsive";
import moment from "moment";

import { sendEventGA } from "../../core/eventsGA";
import Logo from "../../images/logo-text-blue.svg";

import Icon from "../../components/icon";
import FullPort from "../components/fullPort";
import RenderModal from "../../components/renderModal";
import { SimuladaoContext } from "../../core/context";
import * as Latex from "../../components/latex";
import SimulateQuestion from "../components/simulateQuestion";
import SimulateRunningTimer from "../components/simulateRunningTimer";
import ModalFinishSimulation from "../components/ModalFinishSimulation";
import * as S from "./styles";

class DoSimulated extends Component {
  static contextType = SimuladaoContext;

  state = {
    modal: false,
    maxmized: true,
    activeArea: {},
    areasButtons: [],
    alert1min: false,
    alert30min: false,
    alert15min: false,
    isFinishing: false,
    answerError: false,
    areaTooltip: false,
    startDate: new Date(this.props.simulated.currentDate),
  };

  componentDidMount() {
    Latex.compile();
  }

  componentDidUpdate(prevProps, prevState) {
    const { maxmized } = this.state;
    if (maxmized !== prevState.maxmized) {
      Latex.compile();
    }
  }

  finish = () => {
    this.setState({ modal: true });
  };

  forceFinish = async () => {
    const { doFinishSimulatedRequest, simulated, getRanking, history } =
      this.props;
    this.setState({ isFinishing: true });
    await doFinishSimulatedRequest({ simulated: simulated.id });
    await getRanking();
  };

  onChangeAnswer = (question, alternative) => {
    const { doInsertAnswerRequest, simulated } = this.props;
    sendEventGA("Simuladão", "Clique", "Alternativa");
    const body = {
      simulated: simulated.id,
      question: question,
      answer: alternative,
    };

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      doInsertAnswerRequest(body).then((response) => {
        if (!response.simulated) {
          Toast.warning(
            <>
              <div>Sua resposta não foi marcada!</div>
              <div>
                Atualize a página e tente novamente! Não se preocupe, suas
                respostas não serão perdidas.
              </div>
            </>
          );
          this.setState({
            answerError: true,
          });
        }
        this.setState({
          answerError: false,
        });

        return response;
      });
    }, 200);
  };

  verifyIsAnsweredQuestion = (question) => {
    const { simulatedHistory } = this.props;
    if (simulatedHistory.answers) {
      const userAnswer = simulatedHistory.answers.filter(
        (it) => it.question === question.questionId && it.answer
      );
      return userAnswer.length > 0 ? "yes" : "";
    }
  };

  scrollToQuestion(questionNumber) {
    if (document.getElementById(questionNumber)) {
      const offset = document.getElementById(questionNumber).offsetTop;
      const containerSimulate = document.getElementById("container-simulate");

      containerSimulate.scroll({
        top: offset,
        left: 0,
      });
    }
  }

  toggleMaxMin = () => {
    const { maxmized } = this.state;
    this.setState({ maxmized: !maxmized });
  };

  handleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  AlertUser = (event) => {
    if (event === "alert30min") {
      this.setState(
        {
          alert30min: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alert30min: false,
            });
          }, 1000);
        }
      );
    }

    if (event === "alert15min") {
      this.setState(
        {
          alert15min: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alert15min: false,
            });
          }, 60000);
        }
      );
    }

    if (event === "alert1min") {
      this.setState(
        {
          alert1min: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              alert1min: false,
            });
          }, 60000);
        }
      );
    }
  };

  handleAreaChange = (area) => {
    this.setState({
      activeArea: {
        id: area.id,
        label: area.label,
        firstQuestion: area.firstQuestion,
      },
    });
    DoSimulated.scrollToQuestion(area.firstQuestion);
  };

  handleScroll = () => {
    this.state.areasButtons.map((area) => {
      let firstElement = document.getElementById(area.firstQuestion);
      let lastElement = document.getElementById(area.lastQuestion);
      let firstActiveInViewport = this.isInViewport(firstElement);
      let lastActiveInViewport = this.isInViewport(lastElement);

      if (firstActiveInViewport || lastActiveInViewport) {
        this.setState({
          activeArea: {
            id: area.id,
            label: area.label,
          },
        });
      }
    });
  };

  isInViewport = (element) => {
    if (element) {
      const rect = element.getBoundingClientRect();
      const simulateWrapper = document.getElementById("simulate-wrapper");

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.top <= simulateWrapper.offsetHeight &&
        rect.right <= simulateWrapper.offsetWidth
      );
    }
  };

  messageAlert15min = () => {
    return (
      <S.AlertTimer>
        <span>
          <MediaQuery query="(min-width: 767px)"> Atenção! </MediaQuery>
          Faltam <strong>poucos minutos</strong> para encerrar o Simulado
        </span>
        <S.CloseAlertTimer
          onClick={() => {
            this.setState({
              alert15min: false,
            });
          }}
        >
          <Icon icon="close" height={16} width={16} />
        </S.CloseAlertTimer>
      </S.AlertTimer>
    );
  };

  mountFooter = () => {
    const {
      simulated,
      simulatedHistory = [],
      beforeTimeSimulation,
    } = this.props;
    const {
      maxmized,
      alert1min,
      alert15min,
      alert30min,
      areaTooltip,
      activeArea,
      areasButtons,
    } = this.state;

    const start = moment(simulated.startDate).valueOf();
    const end = moment(simulated.endDate).valueOf();
    // const totalTime = new Date(new Date().valueOf() + (end - start));

    let warning = alert30min || alert1min;

    return (
      <S.Footer>
        {alert15min && this.messageAlert15min()}
        <S.FooterTop warning={warning}>
          <S.FooterTopWrapper>
            <MediaQuery query="(min-width: 768px)">
              <SimulateRunningTimer
                warning={warning}
                AlertUser={this.AlertUser}
                forceFinish={this.forceFinish}
                currentDate={simulated.currentDate}
                date={
                  beforeTimeSimulation
                    ? simulated.endDate
                    : moment(simulatedHistory.startDate).add(
                        end - start,
                        "milliseconds"
                      )
                }
              />
            </MediaQuery>
          </S.FooterTopWrapper>
          <S.FooterTopWrapper>
            <S.FooterQuestions className="footer-questions">
              {simulated.questions.map((question, index) => {
                return (
                  <S.FooterQuestion
                    key={index}
                    className={`footer-question ${this.verifyIsAnsweredQuestion(
                      question
                    )}`}
                    onClick={() => this.scrollToQuestion(index + 1)}
                  >
                    {index + 1}
                  </S.FooterQuestion>
                );
              })}
            </S.FooterQuestions>
          </S.FooterTopWrapper>
        </S.FooterTop>
        <S.Bottom warning={warning} maxmized={maxmized}>
          <div style={{ display: "flex" }}>
            {/* <S.IconMaxMin onClick={this.toggleMaxMin}>
              <Icon icon={maxmized ? "min" : "max"} height={16} widht={16} />
            </S.IconMaxMin> */}
            {simulated && simulated.pdfNotFeedbackUrl && (
              <MediaQuery query="(min-width: 768px)">
                {localStorage.getItem("isDarkMode") === "true" ? (
                  <a
                    href={simulated.pdfNotFeedbackUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button link outline>
                      IMPRIMIR
                    </Button>
                  </a>
                ) : (
                  <a
                    href={simulated.pdfNotFeedbackUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button link>IMPRIMIR</Button>
                  </a>
                )}
              </MediaQuery>
            )}
          </div>
          <MediaQuery query="(max-width: 767px)">
            <S.SimulateRunningTimerMobile
              warning={warning}
              AlertUser={this.AlertUser}
              forceFinish={this.forceFinish}
              currentDate={simulated.currentDate}
              date={
                beforeTimeSimulation
                  ? simulated.endDate
                  : moment(simulatedHistory.startDate).add(
                      end - start,
                      "milliseconds"
                    )
              }
            />
          </MediaQuery>
          <S.RightBox>
            <MediaQuery query="(min-width: 768px)">
              <S.ReloadWarning>
                {/* <Icon icon={"reportFill"} height={16} width={16} /> */}
                {/* Encontrou algum erro? Reporte a questão e atualize a página,
                suas respostas serão mantidas. */}
              </S.ReloadWarning>
            </MediaQuery>
            <Button link outline loading={this.state.isFinishing} onClick={this.finish}>
              FINALIZAR
            </Button>
          </S.RightBox>
        </S.Bottom>
      </S.Footer>
    );
  };

  renderDoSimulate = () => {
    const {
      simulated,
      simulatedHistory = [],
      isFetchingSimulationSetAnswer,
      doReportRequest,
      beforeTimeSimulation,
    } = this.props;
    const { maxmized, modal, answerError } = this.state;

    const questions = simulated.questions.sort((a, b) => a.order - b.order);
    const simulatedName = simulated.name.split(" ");
    return (
      <S.Wrapper
        maxmized={maxmized}
        id="simulate-wrapper"
        onScroll={() => this.handleScroll()}
      >
        <S.QuestionsContainer maxmized={maxmized} id="container-simulate">
          <S.SimulatedHeader>
            <S.Logo src={Logo} alt="Logotipo do Aprova Total" />
            <S.HeaderTitle>
              <strong>SIMULADO</strong> SUPER MED
            </S.HeaderTitle>
          </S.SimulatedHeader>

          {questions.map((question, index) => {
            return (
              <SimulateQuestion
                beforeTimeSimulation={beforeTimeSimulation}
                number={index + 1}
                question={question}
                simulateId={simulated.id}
                answerError={answerError}
                key={question.questionId}
                name={index + 1}
                endDate={simulated.endDate}
                doReportRequest={doReportRequest}
                simulatedHistory={simulatedHistory.answers || []}
                isFetchingSimulationSetAnswer={isFetchingSimulationSetAnswer}
                onChangeAnswer={(questionId, alternative) =>
                  this.onChangeAnswer(questionId, alternative)
                }
              />
            );
          })}
        </S.QuestionsContainer>

        {this.mountFooter()}

        {modal && (
          <RenderModal>
            <ModalFinishSimulation
              finish={this.forceFinish}
              toggleModal={this.handleModal}
              qtde={
                simulatedHistory &&
                simulatedHistory.answers &&
                simulatedHistory.answers.filter((it) => !it.answer).length
              }
              loading={this.state.isFinishing}
            />
          </RenderModal>
        )}
      </S.Wrapper>
    );
  };

  render() {
    const { maxmized } = this.state;
    return maxmized ? (
      <FullPort> {this.renderDoSimulate()} </FullPort>
    ) : (
      this.renderDoSimulate()
    );
  }
}

export default DoSimulated;
