// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Mic extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} viewBox="0 0 16 16">
        <title>Podcasts</title>
        <desc>Representação de um microfone</desc>
        <g id="icon-mic">
          <path d="M13.25,7A1,1,0,0,0,12,7.75,4.3,4.3,0,0,1,8,11C4.85,11,4,7.89,4,7.75A1,1,0,0,0,2,8.24a6.36,6.36,0,0,0,5,4.68A.24.24,0,0,0,7,13v1H6a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2H9V13a.24.24,0,0,0,0-.08,6.36,6.36,0,0,0,5-4.68A1,1,0,0,0,13.25,7Z" />
          <path d="M8,10a3,3,0,0,0,3-3V3A3,3,0,0,0,5,3V7A3,3,0,0,0,8,10ZM7,3.22a1,1,0,0,1,2,0V6.78a1,1,0,1,1-2,0Z" />
        </g>
      </svg>
    );
  }
}

export default Mic;
