import React from "react";

import * as S from "./styles";

export const StepTwo = () => {
  return (
    <S.Container>
      <S.Title>Leia atentamente o <strong>regulamento</strong></S.Title>

      <S.Text>
        Todas as informações sobre o simulado, as bolsas e orientações de participação estão descritas no regulamento.
        <br/>
        <br/>
        Para acessá-lo, <a href="https://content.app-us1.com/ldV4B/2023/12/04/77daa1ee-7196-4135-b965-a1a119fe0829.pdf" target="_blank" rel="noopener noreferrer">clique aqui</a>.
      </S.Text>
    </S.Container>
  );
};
