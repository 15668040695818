// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class PlayHome extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>Play</title>
        <desc>Símbolo de play para assistir o vídeo</desc>
        <path d="M32,16A16,16,0,1,1,16,0,16,16,0,0,1,32,16ZM16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm-3,8V22l8-6Z" />
      </svg>
    );
  }
}

export default PlayHome;
