import React, { Component } from "react";
import styled from "styled-components";
import { Button } from "ratel-ui";

import * as S from "./styles";

class ModalFinishSimulation extends Component {
  render() {
    const { toggleModal, qtde = 0, finish, loading } = this.props;

    return (
      <S.Container className="modal is-active">
        <S.Background
          className="modal-background"
          onClick={() => toggleModal(false)}
        />
        <S.Card className="modal-card">
          <S.Header className="modal-card-head">
            <S.Title className="modal-card-title">Questões</S.Title>
            <S.Close aria-label="close" onClick={() => toggleModal(false)}>
              <S.CloseIcon icon="close" width={16} height={16} />
            </S.Close>
          </S.Header>
          <S.Body>
            <S.Text>
              {qtde > 0 && (
                <>
                  Há{" "}
                  <strong>
                    {qtde} {qtde <= 1 ? `pergunta` : `perguntas`}
                  </strong>{" "}
                  sem resposta. Se você finalizar o simulado, essas respostas
                  serão consideradas erradas.
                </>
              )}
              <p>Tem certeza de que deseja finalizar o simulado?</p>
            </S.Text>
          </S.Body>
          <S.Footer>
            <LeftButton link outline loading={loading} onClick={() => finish()}>
              FINALIZAR
            </LeftButton>
            <RightButton link onClick={() => toggleModal(false)}>
              CONTINUAR NO SIMULADO
            </RightButton>
          </S.Footer>
        </S.Card>
      </S.Container>
    );
  }
}

const LeftButton = styled(Button)`
  margin-right: 8px;

  @media (max-width: 434px) {
    margin-bottom: 8px;
    width: 100%;
  }
`;

const RightButton = styled(Button)`
  @media (max-width: 434px) {
    margin-bottom: 8px;
    width: 100%;
  }
`;

export default ModalFinishSimulation;
