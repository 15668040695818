// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Some extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
        <title>Adicionar</title>
        <desc>Ícone de um +</desc>
        <g id="icon-_-small" data-name="icon-+-small">
          <path d="M4,0H4A1,1,0,0,0,3,1V3H1A1,1,0,0,0,0,4H0A1,1,0,0,0,1,5H3V7A1,1,0,0,0,4,8H4A1,1,0,0,0,5,7V5H7A1,1,0,0,0,8,4H8A1,1,0,0,0,7,3H5V1A1,1,0,0,0,4,0Z" />
        </g>
      </svg>
    );
  }
}

export default Some;
