import React from "react";

import * as S from "./styles";

export const StepThree = () => {
  return (
    <S.Container>
      <S.Title>Durante o <strong>simulado</strong></S.Title>

      <S.Text>
        <S.List>
          <S.Item>
            <strong>ATENÇÃO:</strong> Só é valida uma única tentativa por CPF!
          </S.Item>
          <S.Item>
            O simulado tem um total de <strong>30 questões</strong>, sobre <strong>todas as disciplinas;</strong>
          </S.Item>
          <S.Item>
            As respostas são <strong>objetivas</strong> e existe apenas <strong>uma alternativa certa por questão;</strong>
          </S.Item>
        </S.List>
      </S.Text>
    </S.Container>
  );
};
