// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  className: any
};

class Wave extends PureComponent<Props> {
  render() {
    const { className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 112">
        <title>img-wave</title>
        <g id="img-wave">
          <path className="cls-1" d="M0,37s270.35-83.3,569.17,0S1053,137,1366,3.7V112H0Z" />
        </g>
      </svg>
    );
  }
}

export default Wave;
