import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Inter extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Interdisciplinar</title>
        <desc>Representação de duas setas se cruzando</desc>
        <g id="icon-inter">
          <path d="M16,13.12a1.34,1.34,0,0,0,0-.2.21.21,0,0,1,0-.08,1,1,0,0,0,0-.1,1.07,1.07,0,0,0-.07-.19,1,1,0,0,0-.11-.17.47.47,0,0,1-.06-.09l-2-2a1,1,0,1,0-1.41,1.42l.18.18A4.65,4.65,0,0,1,9,8a4.54,4.54,0,0,1,3.45-3.87l-.16.16a1,1,0,0,0,1.41,1.42l2-2a.56.56,0,0,1,0-.08.94.94,0,0,0,.12-.18A1.07,1.07,0,0,0,16,3.26a1,1,0,0,0,0-.1.21.21,0,0,1,0-.08,1.34,1.34,0,0,0,0-.2,1.16,1.16,0,0,0,0-.18,1.22,1.22,0,0,0-.07-.17,1,1,0,0,0-.12-.17.25.25,0,0,0,0-.07l-2-2a1,1,0,0,0-1.41,1.42l.34.34A6.57,6.57,0,0,0,8,5.15,6.48,6.48,0,0,0,3.36,2l.33-.33A1,1,0,0,0,2.28.29l-2,2a.25.25,0,0,0,0,.07.76.76,0,0,0-.11.16.71.71,0,0,0-.08.18,1,1,0,0,0,0,.17.81.81,0,0,0,0,.22s0,.05,0,.07l0,.09a.86.86,0,0,0,.07.21l.11.15a.53.53,0,0,0,.06.1l2,2A1,1,0,0,0,3.69,4.29l-.18-.18A4.44,4.44,0,0,1,7,8a4.58,4.58,0,0,1-3.5,3.93l.22-.22a1,1,0,0,0-1.41-1.42l-2,2a.47.47,0,0,0-.06.09,1,1,0,0,0-.11.17,1.07,1.07,0,0,0-.07.19,1,1,0,0,0,0,.1.21.21,0,0,1,0,.08,1.34,1.34,0,0,0,0,.2,1.16,1.16,0,0,0,0,.18,1.22,1.22,0,0,0,.07.17,1,1,0,0,0,.12.17.25.25,0,0,0,0,.07l2,2a1,1,0,0,0,1.41-1.42L3.38,14A6.42,6.42,0,0,0,8,11a6.49,6.49,0,0,0,4.63,3l-.32.32a1,1,0,0,0,1.41,1.42l2-2a.25.25,0,0,0,0-.07,1,1,0,0,0,.12-.17A1.22,1.22,0,0,0,16,13.3,1.16,1.16,0,0,0,16,13.12Z" />
        </g>
      </svg>
    );
  }
}

export default Inter;
