import styled from "styled-components";
import Breadcrumbs from "../../../components/breadcrumbs";

export const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;

  ${({ isLast }) => isLast && `padding: 0 16px;`}
`;

export const Bread = styled(Breadcrumbs)`
  padding: 16px 0;
`;
