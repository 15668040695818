import config from "./config";

//localStorage.setItem("inscrito-simulado-super-med-i-2024", JSON.stringify({ name: "Mateus Diaz", email: "mateus@aprovatotal.com.br", cpf: "02703993056" }))

function http(url, method, data = {}, stringify = true) {
  let headers = { Accept: "application/json" };
  const userData = JSON.parse(localStorage.getItem("inscrito-simulado-super-med-i-2024"));

  if (userData) {
    data = {
      ...data,
      user: userData,
    };
  }

  if (method === "get" || method === "GET") {
    data = undefined;
  }

  if (stringify) {
    headers = { ...headers, "Content-Type": "application/json" };
  }
  return fetch(`${config.apiUrl}/${url}`, {
    method: method,
    headers,
    body: stringify ? JSON.stringify(data) : data,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return response;
    })
    .catch((err) => {
      return { error: err };
    });
}

export { http };
