// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Test extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Simulados</title>
        <desc>Representação de caixas de seleção de múltipla escolha</desc>
        <g id="icon-test">
          <path d="M6,0H1A1,1,0,0,0,0,1V6A1,1,0,0,0,1,7H6A1,1,0,0,0,7,6V1A1,1,0,0,0,6,0ZM5,5H2V2H5Z" />
          <path d="M6,9H1a1,1,0,0,0-1,1v5a1,1,0,0,0,1,1H6a1,1,0,0,0,1-1V10A1,1,0,0,0,6,9ZM5,14H2V11H5Z" />
          <path d="M9,3h6a1,1,0,0,0,0-2H9A1,1,0,0,0,9,3Z" />
          <path d="M13.25,4H8.75C8.34,4,8,4.45,8,5s.34,1,.75,1h4.5c.41,0,.75-.45.75-1S13.66,4,13.25,4Z" />
          <path d="M15,10H9a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" />
          <path d="M13.25,13H8.75c-.41,0-.75.45-.75,1s.34,1,.75,1h4.5c.41,0,.75-.45.75-1S13.66,13,13.25,13Z" />
        </g>
      </svg>
    );
  }
}

export default Test;
