import React, { Component } from "react";
import { Button } from "ratel-ui";
import ReactToPrint from "react-to-print";

import Icon from "../../../components/icon";
import PrintFeedback from "../printFeedback";
import Tabbable from "../../../components/tabbable";
import * as S from "./styles";

export class ModalPrintFeedback extends Component {
  render() {
    const { toggleModalAnswer, feedback = [] } = this.props;

    return (
      <S.Container>
        <div className="modal modal-print-feedback is-active">
          <div
            className="modal-background"
            onClick={() => toggleModalAnswer(false)}
          />
          <div className="modal-card modal-print-feedback-card">
            <header className="modal-card-head modal-print-feedback-header">
              <div>
                <span className="modal-card-title modal-print-feedback-title">
                  Imprimir gabarito
                </span>
                {/* <span className="modal-card-title modal-print-feedback-subtitle">Gabarito</span> */}
              </div>
              <Tabbable
                aria-label="close"
                className="modal-print-feedback-close"
                onClick={() => toggleModalAnswer(false)}
              >
                <Icon
                  icon="close"
                  width={16}
                  height={16}
                  className="modal-print-feedback-icon"
                />
              </Tabbable>
            </header>
            <section className="modal-print-feedback-body">
              <PrintFeedback
                feedback={feedback}
                ref={(el) => (this.componentRef = el)}
              />
            </section>
            <div className="modal-print-feedback-footer">
              <ReactToPrint
                trigger={() => (
                  <div>
                    <Button link>IMPRIMIR</Button>
                  </div>
                )}
                content={() => this.componentRef}
              />
            </div>
          </div>
        </div>
      </S.Container>
    );
  }
}

export default ModalPrintFeedback;
