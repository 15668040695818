// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class LockerLarge extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>Ícone cadeado</title>
        <desc>Ícone de um cadeado</desc>
        <g id="icon-locker-large">
          <path d="M24,8s0-8-8-8S8,8,8,8,2,8,2,14V26s0,6,6,6H24s6,0,6-6V14S30,8,24,8ZM16,2c6,0,6,6,6,6H10S10,2,16,2ZM28,26c0,4-4,4-4,4H8c-4,0-4-4-4-4V14c0-4,4-4,4-4H24c4,0,4,4,4,4Zm-9-9a3,3,0,0,1-2,2.82V23a1,1,0,0,1-2,0V19.82A3,3,0,1,1,19,17Zm-3-1a1,1,0,1,0,1,1A1,1,0,0,0,16,16Z" />
        </g>
      </svg>
    );
  }
}

export default LockerLarge;
