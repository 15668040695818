// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class LampSmall extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Lâmpada</title>
        <desc>Representação de uma lâmpada</desc>
        <g id="icon-lamp">
          <path d="M14,6a6,6,0,1,0-9,5.18V12a1,1,0,0,0,0,2,1,1,0,0,0,1,1,1,1,0,0,0,1,1H9a1,1,0,0,0,1-1,1,1,0,0,0,1-1,1,1,0,0,0,0-2v-.82A6,6,0,0,0,14,6ZM7,12V10.6H7v0a.53.53,0,0,0,0-.12.7.7,0,0,0,0-.25A.55.55,0,0,0,6.8,10l-.08-.13a1,1,0,0,0-.29-.2l0,0h0a4,4,0,1,1,3.22,0h0l0,0a1.1,1.1,0,0,0-.28.2L9.2,10a.55.55,0,0,0-.12.19.7.7,0,0,0-.05.25.53.53,0,0,0,0,.12v0H9V12Z" />
        </g>
      </svg>
    );
  }
}

export default LampSmall;
