import styled from "styled-components";
import { MediaQueries } from "ratel-ui";

export const Box = styled.div`
  display: flex;
  justify-content: center;
`;

export const Item = styled.div`
  position: relative;
  padding: 8px;
  width: 82px;

  @media (max-width: 374px) {
    padding: 6px;
  }
  @media (max-width: 414px) {
    width: 48px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 59px;
  }
/* 
  &:not(:last-child):after {
    content: "";
    position: absolute;
    top: -3px;
    left: 52px;
    right: 0;
    width: 1px;
    height: 40px;
    background: ${props => props.theme.colors.clear};

    ${MediaQueries.mobile`
      top: 30px;
    `}

    @media (max-width: 374px) {
      top: 18px;
      width: 2px;
      height: 65px;
    }
  } */
`;

export const Text = styled.div`
  position: absolute;
  bottom: 86px;
  line-height: 0.875rem;
  font-size: 0.875rem;
  color: ${props => props.theme.colors.clear};
  @media (min-width: 320px) and (max-width: 1023px) {
    bottom: 58px;
  }
 
`;

export const Number = styled.span`
  line-height:1.125rem;
  font-size: 3.5rem;
  font-weight: 700;
  color: ${props => props.theme.colors.clear};
  @media (max-width: 414px) {
    font-size: 2rem;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.5rem;
  }
`;

export const Label = styled.span`
  display: block;
  font-size: 1rem;
  color: ${props => props.theme.colors.clear};
  text-align: center;
`;
