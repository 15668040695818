import styled, { css, keyframes } from "styled-components";
import { MediaQueries } from "ratel-ui";
import Tooltip from "../../components/tooltip";
import SimulateRunningTimer from "../components/simulateRunningTimer";
import { hexToRgb } from "../../components/utils/hexToRgb";

//
// ANIMATIONS
//
function warningDesktop(props) {
  return keyframes`
    0% {
      color: ${props.theme.colors.transparent};
      background-color: ${props.theme.colors.font6};
    }
    50% {
      color: ${props.theme.colors.clear};
      background-color: ${props.theme.colors.error};
    }
    100% {
      color: ${props.theme.colors.transparent};
      background-color: ${props.theme.colors.font6};
    }
  `;
}
function warningMobile(props) {
  return keyframes`
  0% {
      background: ${props.theme.colors.transparent};
    }
    50% {
      color:${props.theme.colors.clear};
      fill: ${props.theme.colors.clear};
      background: ${props.theme.colors.error};
      border-color: ${props.theme.colors.clear};
    }
    100% {
      background:${props.theme.colors.transparent};
    }
  `;
}
function borderDesktop(props) {
  return keyframes`
    50% {
      border-left: 2px solid ${props.theme.colors.clear};
    }
  `;
}
function ballsDesktop(props) {
  return keyframes`
    50% {
      border: 1px solid ${props.theme.colors.clear};
      background: ${
        props.answered === "yes" ? props.theme.colors.clear : "transparent"
      };
    }
  `;
}

//
//   DETAILS.js
//
export const Container = styled.div`
  position: relative;
  min-height: calc(100vh - 80px);
  margin-bottom: 16px;
`;

//
//    DOSIMULATED.js
//
export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  border-radius: 16px;

  ${MediaQueries.mobile`
    position: fixed;
    left: 0;
  `};
`;

export const FooterTop = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: #eeeeee;

  ${(props) =>
    props.warning &&
    css`
      animation: ${warningDesktop} 1s infinite;
    `};

  @media (max-width: 1199px) {
    overflow-x: auto;
  }
`;

export const FooterTopWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const QuestionsWrapper = styled.div`
  display: flex;
  align-items: center;

  ${MediaQueries.tabletDesktop`
    margin-left: 16px;
    border-left: 2px solid ${(props) => props.theme.colors.transparent};
  `}
`;

export const ToggleArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.clear};
  cursor: pointer;

  > svg {
    fill: ${(props) => props.theme.colors.bg2};
  }

  ${MediaQueries.tabletDesktop`
    margin-left: 32px;
  `}
`;

export const AreasTooltip = styled(Tooltip)`
  &&& {
    position: absolute;
    bottom: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 185px;
    padding: 4px 8px;
    text-align: right;
    z-index: 15;

    &:after {
      content: " ";
      position: absolute;
      top: 100%;
      left: 96px;
      width: 0;
      height: 0;
      transform: rotate(180deg);
    }
  }

  ${MediaQueries.mobile`
    &&& {
      left: -10px;
  
      &:after {
        left: 29px;
      }
    }
  `}
`;

export const AreaButton = styled.button`
  margin: 4px 0;
  padding: 6px 16px;
  color: ${(props) => props.theme.colors.clear};
  border: 1px solid ${(props) => props.theme.colors.bg2};
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.bg2};
  cursor: pointer;

  &:hover {
    background-color: rgba(
      ${(props) => hexToRgb(props.theme.colors.vanta)},
      0.3
    );
  }
`;

export const ButtonDivider = styled.div`
  width: 32px;
  height: 1px;
  background-color: ${(props) => props.theme.colors.clear};
  opacity: 0.3;

  ${(props) =>
    props.isLast &&
    `
    display: none;
  `};
`;

export const BallsLabels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 8px;
  padding-left: 16px;
  font-size: 0.65rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.transparent};
  white-space: pre;
  text-transform: uppercase;

  ${(props) =>
    props.warning &&
    css`
      animation: ${warningDesktop} 1s infinite, ${borderDesktop} 1s infinite;
    `};
`;

export const ContainerBalls = styled.div`
  display: flex;
  justify-content: right;
  align-items: flex-start;
  flex-direction: column;
  font-size: 0.75rem;
`;

export const Balls = styled.div`
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-right: 4px;
  border: 1px solid #555;
  background: ${(props) =>
    props.answered === "yes" ? "#555" : "transparent"};
  border-radius: 50%;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  ${(props) =>
    props.warning &&
    css`
      animation: ${ballsDesktop} 1s infinite;
    `};

  ${(props) =>
    props.isCanceled &&
    css`
      border: 1px solid
        rgba(${(props) => hexToRgb(props.theme.colors.transparent)}, 0.3);
      background: rgba(
        ${(props) =>
          props.answered === "yes"
            ? hexToRgb(props.theme.colors.transparent)
            : "transparent"},
        0.3
      );
    `};
`;

export const AnswerCount = styled.div`
  .footer-questions {
    .footer-question {
      border: 2px solid ${(props) => props.theme.colors.transparent};
      color: ${(props) => props.theme.colors.transparent};

      &.answered {
        background-color: ${(props) => props.theme.colors.transparent};
        color: ${(props) => props.theme.colors.font6};
      }

      &.done {
        color: ${(props) => props.theme.colors.font6};
        background-color: ${(props) => props.theme.colors.transparent};
      }
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 64px;
  padding: 16px;
  border-radius: 0 0 16px 16px;
  background: ${(props) => props.theme.colors.transparent};

  ${(props) =>
    props.maxmized &&
    `
    border-radius: 0;
  `};

  ${MediaQueries.mobile`
    padding-top: 8px;
    
    ${(props) =>
      props.warning &&
      css`
        animation: ${warningMobile} 1s infinite;
        border-radius: 0;

        button {
          animation: ${warningMobile} 1s infinite;
        }

        div {
          animation: ${warningMobile} 1s infinite;

          span {
            animation: ${warningMobile} 1s infinite;
          }
        }
      `}
  `}
`;

export const Top = styled.div`
  padding: 8px 8px 0 8px;
  background: ${(props) => props.theme.colors.transparent};
`;

export const RightBox = styled.div`
  display: flex;
  align-items: center;
`;

export const ReloadWarning = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-size: 0.875rem;
  text-align: left;
  color: ${(props) => props.theme.colors.live};

  > svg {
    flex-shrink: 0;
    margin-right: 8px;
    fill: ${(props) => props.theme.colors.live};
  }

  ${MediaQueries.mobile`
    margin-right: 0;
    font-size: 0.675rem;

    > svg {
      width: 12px;
    }
  `};

  ${MediaQueries.tabletDesktop`
    max-width: 340px;
  `}
`;

export const IconMaxMin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  fill: ${(props) => props.theme.colors.ebony};
  border: 1px solid ${(props) => props.theme.colors.font5};
  transition: 0.2s all;
  cursor: pointer;
  margin-right: 16px;

  ${MediaQueries.mobile`
    ${({ warning }) =>
      warning &&
      css`
        animation: ${warningMobile} 1s infinite;

        svg {
          animation: ${warningMobile} 1s infinite;
        }
      `}
  `}
`;

export const SimulateRunningTimerMobile = styled(SimulateRunningTimer)`
  color: ${(props) => props.theme.colors.font6};
  text-align: center;
`;

export const Wrapper = styled.div`
  position: relative;
  height: ${(props) => (props.maxmized ? "100vh" : "calc(100vh - 168px)")};
  width: ${(props) => (props.maxmized ? "calc(100vw - 16px)" : "")};
  box-sizing: border-box;

  ${MediaQueries.mobileTablet`
    width: ${(props) => (props.maxmized ? "100vw" : "")};
  `}
`;

export const QuestionsContainer = styled.div`
  height: ${(props) =>
    props.maxmized ? "calc(100vh - 110px)" : "calc(100vh - 278px)"};
  min-height: ${(props) =>
    props.maxmized ? "calc(100vh - 110px)" : "calc(100vh - 278px)"};
  border: 1px solid ${(props) => props.theme.colors.bg4};
  border-radius: 16px 16px 0 0;
  overflow: auto;
  box-sizing: border-box;

  ${(props) =>
    props.maxmized &&
    `
    border-radius: 0;
  `};

  ${MediaQueries.mobile`
    height: ${(props) =>
      props.maxmized ? "calc(100vh - 64px)" : "calc(100vh - 160px)"};
    min-height: ${(props) =>
      props.maxmized ? "calc(100vh - 64px)" : "calc(100vh - 160px)"};
  `}
`;

export const SimulatedHeader = styled.div`
  padding: 32px;
  background-color: #ffffff;
  position: relative;
`;

export const HeaderTitle = styled.h1`
  color: #1B3FB5;
  font-weight: 400;
  font-size: 1.75rem;
  font-family: Roboto;
  letter-spacing: 0;

  > strong {
    font-weight: 900;
    color: #1B3FB5;
  }
`;

export const HeaderSubTitle = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  color: #00c5e6;

  > strong {
    padding-left: 8px;
    font-size: 0.75rem;
    color: #003558;
  }
`;

export const IconPosition = styled.div`
  position: absolute;
  top: 15px;
  right: 48px;
  width: 34px;

  @media (max-width: 767px) {
    top: 16px;
    right: 16px;
    width: 32px;
  }
`;

export const Logo = styled.img`
  width: 140px;
  height: 32px;
  margin-bottom: 16px;
`;

export const FooterQuestions = styled.div`
  display: flex;
`;

export const FooterQuestion = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999FA3;
  color: #555;
  cursor: pointer;
  margin: 0px 4px;
  height: 24px;
  text-align: center;
  width: 24px;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 50%;
  transition: 0.2s all ease-in;

  &.yes {
    background-color: #999FA3;
    color: ${(props) => props.theme.colors.clear};
  }

  &.done {
    color: ${(props) => props.theme.colors.font6};
    background-color: #999FA3;
  }
`;

export const AlertTimer = styled.div`
  position: absolute;
  top: -46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px 8px 8px;
  color: ${(props) => props.theme.colors.clear};
  background: #ffb908;

  strong {
    color: ${(props) => props.theme.colors.clear};
  }

  &:before {
    content: " ";
    position: absolute;
    top: 45px;
    left: 64px;
    width: 0;
    height: 0;
    margin-left: -13px;
    border-left: solid transparent 7px;
    border-right: solid transparent 7px;
    border-top: solid #ffb908 7px;
    z-index: 2;

    ${MediaQueries.mobile`
      left: 47%;
    `}
  }

  ${MediaQueries.mobile`
    font-size: 0.875rem;
    padding: 8px 4px;
  `}
`;

export const CloseAlertTimer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.15);
  cursor: pointer;

  svg {
    fill: ${(props) => props.theme.colors.clear};
  }
`;

//
//    LIST.js
//
export const ListContainer = styled.div`
  /* min-height: calc(100vh - 74px);
  padding-top: 16px; */
  overflow: hidden;
`;

//
//    LOADING.js
//
export const Loading = styled.div`
  padding: 10px 0;
`;

//
//   RANKING.js
//
export const RankingContainer = styled.div`
  margin-top: 16px;
  min-height: calc(100vh - 64px);
`;
