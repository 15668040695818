// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Max extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Maximizar tela</title>
        <desc>Duas setas em diagonal apontando para fora</desc>
        <g id="icon-max">
          <path d="M5.29,9.29,2,12.57,2,11a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H5a1,1,0,0,0,1-1H6a1,1,0,0,0-1-1L3.43,14l3.28-3.27A1,1,0,0,0,5.29,9.29Z" />
          <path d="M16,1a1,1,0,0,0-1-1H11a1,1,0,0,0-1,1h0a1,1,0,0,0,1,1l1.57,0L9.29,5.29a1,1,0,0,0,1.42,1.42L14,3.43,14,5a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1Z" />
        </g>
      </svg>
    );
  }
}

export default Max;
