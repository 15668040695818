// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class CircleCheck extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone concluído</title>
        <desc>Ícone com símbolo de check</desc>
        <g id="icon-check">
          <path d="M16,8A8,8,0,1,1,8,0,8,8,0,0,1,16,8ZM3.4,8.35l2.83,2.83a1,1,0,0,0,1.42,0L12.6,6.23a1,1,0,0,0,0-1.41h0a1,1,0,0,0-1.42,0L6.94,9.06,4.82,6.94a1,1,0,0,0-1.42,0h0A1,1,0,0,0,3.4,8.35Z" />
        </g>
      </svg>
    );
  }
}

export default CircleCheck;
