import * as R from 'ramda'

const emptyUser = {
  name: 'Jubialuno (a)',
  email: ''
}

const initialState = {
  buildNumber: 1,
  ui: {
    isRequestingMe: true
  },
  data: {
    user: {...emptyUser}
  }
}

export function appReducer(state = initialState, action) {
  switch (action.type) {
    case 'APP/REQUEST_ME': {
      const lens = R.lensPath(['ui', 'isRequestingMe'])
      return R.set(lens, true, state)
    }
    case 'APP/REQUEST_ME_SUCCESS': {
      const lens = R.lensPath(['ui', 'isRequestingMe'])
      const lensData = R.lensPath(['data', 'user'])
      return R.compose(
        R.set(lens, false),
        R.set(lensData, {
          ...action.data
        })
      )(state)
    }
    case 'APP/REQUEST_ME_FAILURE':
    case 'APP/REQUEST_ME_ERROR': {
      const lens = R.lensPath(['ui', 'isRequestingMe'])
      return R.set(lens, false, state)
    }
    default:
      return state
  }
}
