// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Flag extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Bandeira</title>
        <desc>Representação de uma bandeira</desc>
        <g id="icon-flag">
          <path d="M46.2,20,53.83,8.55a1,1,0,0,0,.05-1A1,1,0,0,0,53,7H15V2h2a1,1,0,0,0,0-2H11a1,1,0,0,0,0,2h2V63a1,1,0,0,0,2,0V33H53a1,1,0,0,0,.88-.53,1,1,0,0,0-.05-1ZM15,31V9H51.13l-7,10.45a1,1,0,0,0,0,1.1l7,10.45Z" />
        </g>
      </svg>
    );
  }
}

export default Flag;
