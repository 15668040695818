import styled from "styled-components";
import { MediaQueries } from "ratel-ui";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
`;

export const Brain = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 32px;

  ${MediaQueries.mobile`
    display: none;
  `}
  
`;
