import styled from "styled-components";

export const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  cursor: pointer;
  text-align: left;

  &:focus {
    outline: -webkit-focus-ring-color auto 5px;
  }
`;
