import React, { PureComponent } from "react";
import classNames from "classnames";

class TwitterBackground extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>twitter</title>
        <desc>ícone da rede social twitter</desc>
        <g id="icon-twitter">
          <path
            className="cls-1"
            d="M15.39506,31.97925c-.066-.12762-.18635-.07515-.28352-.07758A14.67525,14.67525,0,0,1,9.928,30.76739a15.92516,15.92516,0,0,1-4.22415-2.53606A15.55817,15.55817,0,0,1,3.00533,25.3118a15.80185,15.80185,0,0,1-2.2852-4.57238,17.33454,17.33454,0,0,1-.67959-3.72921,15.11,15.11,0,0,1,.048-2.71863,14.9495,14.9495,0,0,1,.97-4.0544A15.87852,15.87852,0,0,1,3.69764,5.76687,16.451,16.451,0,0,1,7.11512,2.67792,16.06032,16.06032,0,0,1,17.32761.05146a15.56414,15.56414,0,0,1,7.0874,2.3158,15.65846,15.65846,0,0,1,3.43566,2.81665,15.83268,15.83268,0,0,1,3.7462,7.09727,9.54056,9.54056,0,0,1,.326,2.12666A.11883.11883,0,0,0,32,14.53129v2.86815c-.08948.59924-.069,1.20769-.19464,1.80632A16.10838,16.10838,0,0,1,18.4897,31.8681c-.60212.09533-1.21457.025-1.81734.11115Z"
          />
          <path
            className="cls-2"
            d="M7.93384,14.453a4.21179,4.21179,0,0,0,1.54273.49664A4.43443,4.43443,0,0,1,8.25823,13.35a4.02488,4.02488,0,0,1,.09126-3.31157c.10493-.2274.15305-.23373.32908-.03323a11.0081,11.0081,0,0,0,5.69826,3.53379c.54322.13569,1.108.18545,1.66271.27541.27937.0453.35734-.08942.30389-.33882a3.86139,3.86139,0,0,1,1.70834-3.737,4.02106,4.02106,0,0,1,3.759-.28306,3.31429,3.31429,0,0,1,1.04129.69291.44484.44484,0,0,0,.4907.10842,10.6102,10.6102,0,0,0,2.145-.87512,3.88773,3.88773,0,0,1-1.48169,2.10454,9.34687,9.34687,0,0,0,1.95484-.52838,4.89836,4.89836,0,0,1-1.17928,1.46545,2.86,2.86,0,0,1-.58173.46723c-.16229.07123-.10277.25747-.10144.39708a11.13223,11.13223,0,0,1-1.28848,5.32162,10.51952,10.51952,0,0,1-5.03392,4.84411,10.74749,10.74749,0,0,1-3.31329.888,11.1948,11.1948,0,0,1-6.557-1.20378,4.95934,4.95934,0,0,1-.73417-.468,8.20074,8.20074,0,0,0,5.64457-1.603,3.19307,3.19307,0,0,1-1.84474-.49557,3.67719,3.67719,0,0,1-1.6551-1.9783c-.08172-.21739-.02232-.28583.16927-.22491a2.14741,2.14741,0,0,0,1.232-.0388A3.83847,3.83847,0,0,1,7.93384,14.453Z"
          />
        </g>
      </svg>
    );
  }
}

export default TwitterBackground;
