import React, { PureComponent } from "react";
import classNames from "classnames";

class ClapBlue extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88">
        <title>Parabéns</title>
        <desc>Representação de duas mãos batendo palmas</desc>
        <g id="icon-clap-blue">
          <path
            className="cls-1"
            d="M74.6,52.15a4.69,4.69,0,0,0-6.63,0L74.09,46a4.69,4.69,0,1,0-6.63-6.63l5.61-5.61a4.69,4.69,0,0,0-6.63-6.63l-5.37,5.38a4.69,4.69,0,0,0-6.63-6.63l-24.2,24.2,0,.06L28.7,51.66V39.18a5.1,5.1,0,0,0-5.09-5.09h0a5.1,5.1,0,0,0-5.09,5.09V67.52a5,5,0,0,0,.35,1.82,16.47,16.47,0,0,0,4.81,10.85l3,3a16.65,16.65,0,0,0,23.14.33,4.27,4.27,0,0,0,.63-.51L74.6,58.78A4.69,4.69,0,0,0,74.6,52.15Z"
          />
          <path
            className="cls-2"
            d="M68.39,32.88H60.78a4.69,4.69,0,0,0,0-9.38H26.56a4.5,4.5,0,0,0-.63.07H24.32l8.88-8.89A5.09,5.09,0,0,0,26,7.48L6.24,27.24A16.62,16.62,0,0,0,0,40.19v4.19A16.67,16.67,0,0,0,16.62,61H51.8a4.69,4.69,0,1,0,0-9.37h8.66a4.69,4.69,0,0,0,0-9.38h7.93a4.69,4.69,0,1,0,0-9.37Z"
          />
          <path
            className="cls-2"
            d="M58,13.15a1.74,1.74,0,0,1-1-2.22L60.5,1.14a1.74,1.74,0,0,1,3.26,1.19L60.2,12.12A1.74,1.74,0,0,1,58,13.15Z"
          />
          <path
            className="cls-2"
            d="M67.57,18.23a1.73,1.73,0,0,1,.64-2.37l9-5.21a1.73,1.73,0,1,1,1.73,3l-9,5.21A1.73,1.73,0,0,1,67.57,18.23Z"
          />
          <path
            className="cls-2"
            d="M63.09,14.52a1.74,1.74,0,0,1-.21-2.45l6.69-8a1.74,1.74,0,0,1,2.66,2.23l-6.69,8A1.74,1.74,0,0,1,63.09,14.52Z"
          />
          <path
            className="cls-1"
            d="M80.63,68.16a1.74,1.74,0,0,1,2.31.84l4.4,9.44a1.74,1.74,0,0,1-3.15,1.47l-4.4-9.44A1.74,1.74,0,0,1,80.63,68.16Z"
          />
          <path
            className="cls-1"
            d="M70.25,71.36a1.74,1.74,0,0,1,1.23,2.13L68.79,83.55a1.74,1.74,0,1,1-3.36-.9l2.7-10.06A1.73,1.73,0,0,1,70.25,71.36Z"
          />
          <path
            className="cls-1"
            d="M76.05,70.82a1.74,1.74,0,0,1,1.88,1.58l.9,10.37a1.73,1.73,0,1,1-3.45.31L74.47,72.7A1.73,1.73,0,0,1,76.05,70.82Z"
          />
        </g>
      </svg>
    );
  }
}

export default ClapBlue;
