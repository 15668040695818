// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Paper extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} viewBox="0 0 16 16">
        <title>Materiais</title>
        <desc>Representação de uma folha de papel</desc>
        <g id="icon-paper">
          <path d="M10,16H2a1,1,0,0,1-1-1V1A1,1,0,0,1,2,0H14a1,1,0,0,1,1,1V11Zm3-7V2H3V14H8V10A1,1,0,0,1,9,9Zm-3,4,2-2H10ZM11,3H5A1,1,0,0,0,4,4H4A1,1,0,0,0,5,5h6a1,1,0,0,0,1-1h0A1,1,0,0,0,11,3Zm0,3H5A1,1,0,0,0,4,7H4A1,1,0,0,0,5,8h6a1,1,0,0,0,1-1h0A1,1,0,0,0,11,6Z" />
        </g>
      </svg>
    );
  }
}

export default Paper;
