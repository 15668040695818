// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Clock extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone relógio</title>
        <desc>Ícone de um relógio</desc>
        <g id="icon-clock">
          <path d="M16,8A8,8,0,1,1,8,0,8,8,0,0,1,16,8ZM8,2a6,6,0,1,0,6,6A6,6,0,0,0,8,2ZM8,4H8A1,1,0,0,0,7,5V8A1,1,0,0,0,8,9h2a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H9V5A1,1,0,0,0,8,4Z" />
        </g>
      </svg>
    );
  }
}

export default Clock;
