import logotipoOpen from "../images/light/logotipo-full.png";
import logotipoClosed from "../images/light/logotipo-mini.png";

export default {
  name: "light",

  colors: {
    bgBtnDisabled: "#dddddd",
    colorBtnDisabled: "#FFFFFF",
    background: "#c41c6d",
    buttonColor: "#2E3440",
    physics: "#00b7ac",
    chemistry: "#c41c6d",
    biology: "#193ba5",
    mathematics: "#6b59a6",
    interdisciplinary: "#00c5e5",
    live: "#ff8e06",
    community: "#582a5a",
    superior: "#3171f1",
    subarea01: "#ff6a40",
    subarea02: "#ff184f",
    subarea03: "#ffbc00",
    subarea04: "#af7dff",
    subarea05: "#13b3ce",
    subarea06: "#ff92aa",
    subarea07: "#592b5b",
    subarea08: "#00c5e5",
    subarea09: "#00b7ac",
    subarea10: "#c41c6d",
    subarea11: "#193ba5",
    subarea12: "#a3c136",
    subarea13: "#0f6c7c",
    subarea14: "#ff8e06",
    loadingBook: "#00c5e5",
    loadingPlay: "#ffd700",
    error: "#db001b",
    alert: "#db001b",
    success: "#44ba3a",
    warning: "#fdd703",
    font1: "#555555",
    font2: "#3171f1",
    font3: "#003558",
    font4: "#999999",
    font5: "#bbbbbb",
    font6: "#999fa3",
    font7: "#e3e3e3",
    bg1: "#3171f1",
    bg2: "#003558",
    bg3: "#eeeeee",
    bg4: "#e3e3e3",
    bg5: "#eeeeee",
    bg6: "#3171f1",
    transparent: "#ffffff",
    clear: "#ffffff",
    ebony: "#000000",
    vanta: "#000000",
    dimmer: "#cccccc",
    promotion: "#FFBC00",
    titleRules: "#003558"
  },

  config: {
    // Menu Lateral
    drawerOpenedWidth: "192px",
    drawerClosedWidth: "48px",
    // Menu Mobile
    drawerMenuMobile: "256px",
    // Header
    headerHeightDesktop: "64px",
    headerHeightMobile: "48px",
    // Submenu
    submenuWidth: "144px",
    submenuHeightMobile: "64px"
  },

  gradients: {
    sexyBlue: "linear-gradient(90deg, #3171f1, #0e98f3)"
  },

  images: {
    logotipoOpen: logotipoOpen,
    logotipoClosed: logotipoClosed,
  }
};
