import React, { useState, useEffect } from "react";
import MediaQuery from "react-responsive";

import { StepOne } from "./components/stepOne";
import { StepTwo } from "./components/stepTwo";
import { StepThree } from "./components/stepThree";
import { StepFour } from "./components/stepFour";

// import Icon from "../../../components/icon";
import Timeline from "./components/timeline";
import AssetLogoDesktop from "../../../images/logo-text.svg";
import * as S from "./styles";

export default function Instructions({
  loading,
  history,
  startDate,
  simulatedName,
  onSimulatedStart,
  currentSimulated,
  doStartSimulation,
  isGatesOpen = false,
  isInstructionStarted,
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [finishedInstructions, setFinishedInstructions] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const steps = [
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
  ];

  const howManySteps = steps.length - 1;


  const startSimulationIfGatesOpen =
  isGatesOpen && currentStep === howManySteps;

  const nextStep = () => {
    if (currentStep < howManySteps) {
      setCurrentStep((prevState) => prevState + 1);
    }

    if (currentStep === howManySteps) {
      setFinishedInstructions(true);
    }
  };

  const prevStep = () => {
    if (currentStep === 0) return;
    setCurrentStep((prevState) => prevState - 1);
  };

  const transparentButton = currentStep !== 0 && currentStep !== 3;

  return (
    <>
      <S.Container>
        <S.Scrollable>
          <S.Content>
            <S.LogoText src={AssetLogoDesktop} alt="Logotipo do Aprova Total" />
            <S.Title><strong>SIMULADO</strong> SUPER MED</S.Title>

            {!isGatesOpen ? <S.Box style={{fontSize: 18}}>

              <div
                style={{fontSize: 22, fontWeight: 600, paddingBottom: 24}}
              >O Simulado Super Med já acabou!</div>
              <div>Fique ligado nos próximos eventos do Aprova Total</div>

            </S.Box> :
            <S.Box>
              {/* {currentStep > 0 && (
                <S.IconCircle onClick={prevStep}>
                  <Icon icon="back" width={16} height={16} />
                </S.IconCircle>
              )} */}
              {steps.map((Instruction, index) => {
                return (
                  <S.Instruction
                    key={index}
                    className={currentStep === index ? "visible" : ""}
                  >
                    {<Instruction />}
                  </S.Instruction>
                );
              })}
              {isInstructionStarted && (
                <>
                  <MediaQuery query="(max-width: 767px)">
                    <S.NextButton
                      type="button"
                      loading={loading}
                      transparentButton={transparentButton}
                      onClick={startSimulationIfGatesOpen ? doStartSimulation : nextStep}
                    >
                      {currentStep === 0 ? "FAZER O SIMULADO AGORA" : currentStep === 3 ? "COMEÇAR O SIMULADO AGORA" : "PRÓXIMO"}
                    </S.NextButton>
                  </MediaQuery>
                  <MediaQuery query="(min-width: 768px)">
                    <S.NextButton
                      type="button"
                      loading={loading}
                      transparentButton={transparentButton}
                      onClick={startSimulationIfGatesOpen ? doStartSimulation : nextStep}
                    >
                      {currentStep === 0 ? "QUERO FAZER O SIMULADO AGORA" : currentStep === 3 ? "COMEÇAR O SIMULADO AGORA" : "PRÓXIMO"}
                    </S.NextButton>
                  </MediaQuery>
                </>
              )}
            </S.Box> }
            {currentStep !== 0 && isGatesOpen ?
              <Timeline currentStep={currentStep} totalSteps={steps.length} /> : null
            }
          </S.Content>
        </S.Scrollable>
      </S.Container>
      {/* )} */}
    </>
  );
}
