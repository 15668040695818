// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class ContentHome extends PureComponent<Props> {
  render() {
    const { className, height, width } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 136">
        <title>Materiais</title>
        <desc>Imagem de um livro simbolizando os materiais da plataforma</desc>
        <circle className="cls-1" cx="73.5" cy="67.5" r="50.5" />
        <path d="M103,94.2a1.42,1.42,0,0,1-1.42-1.42c0-5.72-1.84-7.56-7.57-7.56a1.43,1.43,0,0,1,0-2.85c5.73,0,7.57-1.84,7.57-7.56a1.42,1.42,0,0,1,2.84,0c0,5.72,1.84,7.56,7.57,7.56a1.43,1.43,0,0,1,0,2.85c-5.73,0-7.57,1.84-7.57,7.56A1.41,1.41,0,0,1,103,94.2Zm-2.4-10.41a6.81,6.81,0,0,1,2.4,2.4,6.81,6.81,0,0,1,2.4-2.4A6.7,6.7,0,0,1,103,81.4,6.7,6.7,0,0,1,100.63,83.79Z" />
        <path d="M33.84,19a1.42,1.42,0,0,1-1.42-1.42c0-1.86-.35-2.21-2.21-2.21a1.43,1.43,0,0,1,0-2.85c1.86,0,2.21-.35,2.21-2.21a1.43,1.43,0,0,1,2.85,0c0,1.86.35,2.21,2.21,2.21a1.43,1.43,0,0,1,0,2.85c-1.86,0-2.21.35-2.21,2.21A1.42,1.42,0,0,1,33.84,19Z" />
        <path d="M43.48,136a5.21,5.21,0,1,1,5.21-5.21A5.22,5.22,0,0,1,43.48,136Zm0-7.57a2.37,2.37,0,1,0,2.36,2.36A2.36,2.36,0,0,0,43.48,128.43Z" />
        <path d="M138.79,28.38a5.21,5.21,0,1,1,5.21-5.2A5.21,5.21,0,0,1,138.79,28.38Zm0-7.57a2.37,2.37,0,1,0,2.37,2.37A2.37,2.37,0,0,0,138.79,20.81Z" />
        <path
          className="cls-2"
          d="M95.11,8.2a4.1,4.1,0,1,1,4.1-4.1A4.11,4.11,0,0,1,95.11,8.2Zm0-5.36a1.26,1.26,0,1,0,0,2.51,1.26,1.26,0,0,0,0-2.51Z"
        />
        <path
          className="cls-2"
          d="M7.61,71.43a1.43,1.43,0,0,1-1-.42L.42,64.82a1.41,1.41,0,0,1,0-2,1.43,1.43,0,0,1,2,0L8.61,69a1.41,1.41,0,0,1,0,2A1.39,1.39,0,0,1,7.61,71.43Z"
        />
        <path
          className="cls-2"
          d="M1.42,71.43a1.39,1.39,0,0,1-1-.42,1.41,1.41,0,0,1,0-2L6.6,62.81a1.43,1.43,0,0,1,2,0,1.41,1.41,0,0,1,0,2L2.43,71A1.42,1.42,0,0,1,1.42,71.43Z"
        />
        <path
          className="cls-2"
          d="M133.66,117a1.42,1.42,0,0,1-1.42-1.43v-7.83a1.42,1.42,0,0,1,2.84,0v7.83A1.43,1.43,0,0,1,133.66,117Z"
        />
        <path className="cls-2" d="M137.58,113.09h-7.84a1.43,1.43,0,0,1,0-2.85h7.84a1.43,1.43,0,0,1,0,2.85Z" />
        <path d="M74.27,98.88a1.52,1.52,0,0,1-.51-.09,1.42,1.42,0,0,1-.91-1.33V45.36a1.4,1.4,0,0,1,.5-1.08c7.44-6.37,16.24-4.42,24-2.71,6,1.33,11.63,2.58,15.58-.26a1.42,1.42,0,0,1,2.25,1.15V71.35a1.42,1.42,0,1,1-2.84,0V44.88c-4.56,1.91-9.95.72-15.61-.53C89.47,42.74,82,41.08,75.69,46V94.3a16.07,16.07,0,0,1,10.13-2.63,1.42,1.42,0,0,1,1.37,1.47,1.38,1.38,0,0,1-1.47,1.37c-4.54-.16-7.86,1.08-10.39,3.9A1.41,1.41,0,0,1,74.27,98.88Z" />
        <path d="M74.27,98.88a1.43,1.43,0,0,1-.82-.26c-9-6.45-16.84-4.29-23.7-2.38-5.64,1.57-11,3.05-15.82-.53a1.4,1.4,0,0,1-.58-1.14V42.46a1.42,1.42,0,0,1,.77-1.26,1.39,1.39,0,0,1,1.48.11c3.95,2.84,9.6,1.59,15.59.26,7.76-1.71,16.56-3.66,24,2.71a1.43,1.43,0,0,1,.49,1.08v52.1a1.43,1.43,0,0,1-.77,1.27A1.47,1.47,0,0,1,74.27,98.88ZM36.19,93.81c3.6,2.24,7.69,1.11,12.79-.31,6.59-1.83,14.65-4.07,23.87,1.34V46c-6.27-5-13.78-3.29-21-1.68-5.66,1.25-11,2.45-15.61.53Z" />
        <path d="M69,84.22a1.4,1.4,0,0,1-.82-.26A17.59,17.59,0,0,0,65,82.15a1.42,1.42,0,0,1,1.13-2.61,20,20,0,0,1,3.69,2.1,1.43,1.43,0,0,1,.33,2A1.4,1.4,0,0,1,69,84.22Z" />
        <path d="M45.73,83.56a8.15,8.15,0,0,1-5-1.63,1.42,1.42,0,0,1,1.69-2.29c2.43,1.79,5.29,1,8.91,0,3.3-.91,7-1.95,11.26-1.18a1.42,1.42,0,1,1-.52,2.8c-3.57-.65-6.83.25-10,1.12A24.12,24.12,0,0,1,45.73,83.56Z" />
        <path d="M69,76.05a1.4,1.4,0,0,1-.82-.26c-5.63-4-10.27-3.19-15.33-1.8a1.42,1.42,0,0,1-.75-2.74c5.34-1.47,11.1-2.5,17.73,2.22a1.43,1.43,0,0,1,.33,2A1.4,1.4,0,0,1,69,76.05Z" />
        <path d="M45.76,75.38a8.18,8.18,0,0,1-5.08-1.62,1.42,1.42,0,0,1,1.69-2.29,5.94,5.94,0,0,0,4.51,1,1.42,1.42,0,1,1,.33,2.82A12.26,12.26,0,0,1,45.76,75.38Z" />
        <path d="M69,67.88a1.4,1.4,0,0,1-.82-.26C62,63.26,57,64.67,52.05,66a22.8,22.8,0,0,1-6.48,1.18,1.42,1.42,0,0,1-1.4-1.45,1.39,1.39,0,0,1,1.45-1.39,21.48,21.48,0,0,0,5.66-1.08c5-1.4,11.29-3.13,18.51,2A1.42,1.42,0,0,1,69,67.88Z" />
        <path d="M69,59.71a1.4,1.4,0,0,1-.82-.26,16.41,16.41,0,0,0-4-2.14,1.42,1.42,0,1,1,1-2.67,19,19,0,0,1,4.69,2.49A1.42,1.42,0,0,1,69,59.71Z" />
        <path d="M45.73,59.05a8.21,8.21,0,0,1-5-1.63,1.42,1.42,0,0,1,1.69-2.29c2.43,1.79,5.29,1,8.91,0a33.27,33.27,0,0,1,7.4-1.43,1.42,1.42,0,1,1,.13,2.84,30.56,30.56,0,0,0-6.76,1.33A24.46,24.46,0,0,1,45.73,59.05Z" />
        <path d="M40.89,32a1.42,1.42,0,0,1-1-2.44,48.37,48.37,0,0,1,66.19-1.48,1.42,1.42,0,1,1-1.9,2.11,45.52,45.52,0,0,0-62.29,1.4A1.44,1.44,0,0,1,40.89,32Z" />
        <path d="M73.78,120A48.43,48.43,0,0,1,43,109a1.42,1.42,0,0,1,1.81-2.2,45.49,45.49,0,0,0,58.69-.67,1.42,1.42,0,1,1,1.86,2.15A48.33,48.33,0,0,1,73.78,120Z" />
      </svg>
    );
  }
}

export default ContentHome;
