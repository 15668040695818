// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class CrownSmall extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Coroa</title>
        <desc>Representação de uma coroa</desc>
        <g id="icon-crown">
          <path d="M15,14V8s0-.06,0-.09A1.5,1.5,0,1,0,13,6.5a1.48,1.48,0,0,0,.25.83L11.39,9.19,8.94,2.66a1.5,1.5,0,1,0-1.88,0L4.61,9.19,2.75,7.33A1.48,1.48,0,0,0,3,6.5,1.5,1.5,0,1,0,1,7.91S1,8,1,8v6a1,1,0,0,0,0,2H15a1,1,0,0,0,0-2ZM3,10.41l1.29,1.3a1,1,0,0,0,1.65-.36L8,5.85l2.06,5.5a1,1,0,0,0,1.65.36L13,10.41V14H3Z" />
        </g>
      </svg>
    );
  }
}

export default CrownSmall;
