// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Youtube extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24">
        <title>Youtube</title>
        <path d="M34.35,3.75a4.25,4.25,0,0,0-3-3C28.68,0,18,0,18,0S7.38,0,4.72.72a4.27,4.27,0,0,0-3,3A45,45,0,0,0,1,12a45,45,0,0,0,.71,8.25,4.27,4.27,0,0,0,3,3C7.38,24,18,24,18,24s10.65,0,13.31-.72a4.25,4.25,0,0,0,3-3A45,45,0,0,0,35.06,12,45,45,0,0,0,34.35,3.75ZM14.55,17.06V6.94L23.45,12Z" />
      </svg>
    );
  }
}

export default Youtube;
