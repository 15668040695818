// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Biology extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Biologia</title>
        <desc>Ícone de um DNA</desc>
        <g id="icon-bio">
          <path d="M10.59,8C13.22,6.24,14,2.88,14,1a1,1,0,0,0-2,0H4A1,1,0,0,0,2,1c0,1.88.78,5.24,3.41,7C2.78,9.76,2,13.12,2,15a1,1,0,0,0,2,0h8a1,1,0,0,0,2,0C14,13.12,13.22,9.76,10.59,8Zm-.67,2H6.09A4.42,4.42,0,0,1,8,9,4.4,4.4,0,0,1,9.92,10ZM8,7A4.4,4.4,0,0,1,6.08,6H9.91A4.42,4.42,0,0,1,8,7Zm3.69-4a9.22,9.22,0,0,1-.35,1H4.65A7.48,7.48,0,0,1,4.3,3ZM4.31,13a9.22,9.22,0,0,1,.35-1h6.69a7.48,7.48,0,0,1,.35,1Z" />
        </g>
      </svg>
    );
  }
}

export default Biology;
