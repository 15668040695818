// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Hide extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone ocultar</title>
        <desc>Ícone de um olho com um traço para ocultar conteúdo</desc>
        <g id="icon-hide">
          <path d="M12.79,4.63l1.92-1.92a1,1,0,1,0-1.42-1.42L11,3.62A7.68,7.68,0,0,0,8,3C3,3,0,8,0,8a12.65,12.65,0,0,0,3.21,3.37L1.29,13.29a1,1,0,0,0,1.42,1.42L5,12.38A7.68,7.68,0,0,0,8,13c5,0,8-5,8-5A12.65,12.65,0,0,0,12.79,4.63ZM3,8S5,5,8,5a4.37,4.37,0,0,1,1.37.22l-.86.85A2.09,2.09,0,0,0,8,6,2,2,0,0,0,6,8a2.09,2.09,0,0,0,.07.51L4.77,9.82A8.29,8.29,0,0,1,3,8Zm5,3a4.37,4.37,0,0,1-1.37-.22l.86-.85A2.09,2.09,0,0,0,8,10a2,2,0,0,0,2-2,2.09,2.09,0,0,0-.07-.51l1.3-1.31A8.29,8.29,0,0,1,13,8S11,11,8,11Z" />
        </g>
      </svg>
    );
  }
}

export default Hide;
