import * as R from "ramda";


const initialState = {
  ui: {
    isFetchingSimulation: false,
    isFetchingSimulationLastFeedback: false,
    isFetchingLastSimulation: false,
    isFetchingLastAllSimulation: false,
    isFetchingSimulationAverage: false,
    isFetchingSimulationStart: false,
    isFetchingSimulationOpen: false,
    isFetchingSimulationFinish: false,
    isFetchingSimulationSetAnswer: false,
    isFetchingSimulationRanking: false,
    isFetchingSimulationCompleteRanking: false,
    isFetchingSimulationFeedback: false,
    isFetchingLastOneSimulation: false
  },
  data: {
    simulation: {},
    history: {},
    ranking: {},
    completeRanking: [],
    feedback: {},
    lastFeedback: {},
    lastSimulations: [],
    lastAllSimulations: {},
    lastSimulation: {},
    cachedLastSimulations: null,
    cachedLastAllSimulations: null
  }
};

export function simulationReducer(state = initialState, action) {
  switch (action.type) {
    case "SIMULATION/SIMULATION_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulation"]);

      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulation"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulation"]);
      const lens = R.lensPath(["data", "simulation"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "SIMULATION/SIMULATION_AVERAGE_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationAverage"]);

      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_AVERAGE_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationAverage"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_AVERAGE_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationAverage"]);
      const lens = R.lensPath(["data", "simulation", "average"]);

      return R.compose(R.set(fetchLens, false), R.set(lens, action.data))(state);
    }

    case "SIMULATION/SIMULATION_START_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationStart"]);

      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_START_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationStart"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_START_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationStart"]);
      const simulation = R.lensPath(["data", "simulation"]);
      const history = R.lensPath(["data", "history"]);

      let newHistory = action.data.history || {};
      let newSimulation = action.data.simulated || {};

      return R.compose(R.set(fetchLens, false), R.set(history, newHistory), R.set(simulation, newSimulation))(state);
    }

    case "SIMULATION/SIMULATION_SET_ANSWER_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationSetAnswer"]);

      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_SET_ANSWER_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationSetAnswer"]);

      return R.compose(R.set(fetchLens, false))(state);
    }
    case "SIMULATION/SIMULATION_SET_ANSWER_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationSetAnswer"]);
      const history = R.lensPath(["data", "history"]);

      let newHistory = action.data;
      if (!newHistory.user) {
        newHistory = state.data.history;
      }

      return R.compose(R.set(fetchLens, false), R.set(history, newHistory))(state);
    }

    case "SIMULATION/SIMULATION_OPEN_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationOpen"]);

      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_OPEN_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationOpen"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_OPEN_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationOpen"]);
      const simulation = R.lensPath(["data", "simulation"]);
      const history = R.lensPath(["data", "history"]);

      let newHistory = action.data.history || {};
      let newSimulation = action.data.simulated || {};

      return R.compose(R.set(fetchLens, false), R.set(history, newHistory), R.set(simulation, newSimulation))(state);
    }

    case "SIMULATION/SIMULATION_FINISH_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationFinish"]);

      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_FINISH_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationFinish"]);

      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_FINISH_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationFinish"]);
      const simulation = R.lensPath(["data", "simulation"]);
      const history = R.lensPath(["data", "history"]);

      let newHistory = action.data || {};
      let newSimulation = state.data.simulation;
      newSimulation.isFinished = true;
      newSimulation.questions = [];

      return R.compose(R.set(fetchLens, false), R.set(history, newHistory), R.set(simulation, newSimulation))(state);
    }

    case "SIMULATION/SIMULATION_RANKING_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationRanking"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_RANKING_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationRanking"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_RANKING_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationRanking"]);
      const simulation = R.lensPath(["data", "ranking"]);

      return R.compose(R.set(fetchLens, false), R.set(simulation, action.data))(state);
    }

    case "SIMULATION/SIMULATION_FEEDBACK_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationFeedback"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_FEEDBACK_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationFeedback"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_FEEDBACK_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationFeedback"]);
      const feedback = R.lensPath(["data", "feedback"]);
      const simulation = R.lensPath(["data", "simulation"]);

      let simulationData = state.data.simulation;
      simulationData.isFinished = true;
      simulationData.isStarted = true;

      return R.compose(R.set(fetchLens, false), R.set(feedback, action.data), R.set(simulation, simulationData))(state);
    }
    case "SIMULATION/SIMULATION_LAST_FEEDBACK_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationLastFeedback"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_LAST_FEEDBACK_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationLastFeedback"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_LAST_FEEDBACK_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationLastFeedback"]);
      const feedback = R.lensPath(["data", "lastFeedback"]);

      return R.compose(R.set(fetchLens, false), R.set(feedback, action.data))(state);
    }

    case "SIMULATION/SIMULATION_COMPLETE_RANKING_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationCompleteRanking"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_COMPLETE_RANKING_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationCompleteRanking"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_COMPLETE_RANKING_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingSimulationCompleteRanking"]);
      const simulation = R.lensPath(["data", "completeRanking"]);

      return R.compose(R.set(fetchLens, false), R.set(simulation, action.data))(state);
    }

    case "SIMULATION/SIMULATION_LAST_ALL_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastAllSimulation"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_LAST_ALLFAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastAllSimulation"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_LAST_ALL_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastAllSimulation"]);
      const simulation = R.lensPath(["data", "lastAllSimulations"]);
      const cachedLastAllSimulations = R.lensPath(["data", "cachedLastAllSimulations"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(simulation, action.data),
        R.set(cachedLastAllSimulations, action.data)
      )(state);
    }

    case "SIMULATION/SIMULATION_LAST_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastSimulation"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/SIMULATION_LAST_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastSimulation"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/SIMULATION_LAST_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastSimulation"]);
      const simulation = R.lensPath(["data", "lastSimulations"]);
      const cachedLastSimulations = R.lensPath(["data", "cachedLastSimulations"]);

      return R.compose(
        R.set(fetchLens, false),
        R.set(simulation, action.data),
        R.set(cachedLastSimulations, action.data)
      )(state);
    }

    case "SIMULATION/ONE_SIMULATION_LAST_REQUEST": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastOneSimulation"]);
      return R.set(fetchLens, true, state);
    }
    case "SIMULATION/ONE_SIMULATION_LAST_FAILURE": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastOneSimulation"]);
      return R.compose(R.set(fetchLens, true))(state);
    }
    case "SIMULATION/ONE_SIMULATION_LAST_SUCCESS": {
      const fetchLens = R.lensPath(["ui", "isFetchingLastOneSimulation"]);
      const simulation = R.lensPath(["data", "lastSimulation"]);

      return R.compose(R.set(fetchLens, false), R.set(simulation, action.data))(state);
    }

    case "APP/CLEAN_STATE": {
      return initialState;
    }
    default:
      return state;
  }
}
