// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class UserXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>Cadastro</title>
        <desc>Representação de uma pessoa</desc>
        <g id="icon-user-xl">
          <path d="M20.22,13.71a7.5,7.5,0,1,0-8.4,0A13,13,0,0,0,3,26c0,1.39.77,3.33,4.41,4.67A25.69,25.69,0,0,0,16,32c6.48,0,13-1.85,13-6A13,13,0,0,0,20.22,13.71ZM10.52,7.5A5.5,5.5,0,1,1,16,13,5.51,5.51,0,0,1,10.52,7.5ZM16,30C9.84,30,5,28.25,5,26a11,11,0,0,1,22,0C27,28.25,22.2,30,16,30Z" />
        </g>
      </svg>
    );
  }
}

export default UserXl;
