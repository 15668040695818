// @flow
import React, { Component } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Bug extends Component<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Reportar problemas</title>
        <desc>Ícone de um inseto</desc>
        <g id="icon-bug">
          <path d="M15,10a1,1,0,0,0,0-2H12.93V8A3.49,3.49,0,1,0,9.26,3.2,4.15,4.15,0,0,0,8,3,4.67,4.67,0,0,0,4.25,5.05,1,1,0,0,0,4,5H2.62L1.89,3.55a1,1,0,0,0-1.78.9l1,2a.81.81,0,0,0,.17.24l.05,0a.78.78,0,0,0,.24.16l.09,0A1,1,0,0,0,2,7H3.29a6.57,6.57,0,0,0-.21,1H1a1,1,0,0,0,0,2H3.08a6.57,6.57,0,0,0,.21,1H2l-.15,0-.2,0a.75.75,0,0,0-.17.1.78.78,0,0,0-.15.1,1.15,1.15,0,0,0-.14.17s-.06.07-.08.11l-1,2a1,1,0,0,0,.44,1.34A.93.93,0,0,0,1,15a1,1,0,0,0,.89-.55L2.62,13H4A1,1,0,0,0,4.25,13,4.67,4.67,0,0,0,8,15,4.67,4.67,0,0,0,11.75,13,1,1,0,0,0,12,13h1.38l.73,1.45A1,1,0,0,0,15,15a.93.93,0,0,0,.45-.11,1,1,0,0,0,.44-1.34l-1-2a.76.76,0,0,0-.08-.11.75.75,0,0,0-.14-.17.56.56,0,0,0-.15-.1.59.59,0,0,0-.17-.1l-.2,0L14,11H12.71a8.07,8.07,0,0,0,.22-1ZM12.85,6.85a.5.5,0,1,1-.7-.7.5.5,0,0,1,.7,0,.48.48,0,0,1,0,.7ZM12,2.5a.5.5,0,0,1,1,0v2a.5.5,0,0,1-1,0ZM8,13c-1.65,0-3-1.79-3-4S6.35,5,8,5a2.34,2.34,0,0,1,1.1.29,3.44,3.44,0,0,0,1.69,2.24A5.38,5.38,0,0,1,11,9C11,11.21,9.65,13,8,13Z" />
        </g>
      </svg>
    );
  }
}

export default Bug;
