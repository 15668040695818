import styled, { keyframes } from "styled-components";
import Clipboard from "react-clipboard.js";
import AssetCoupon from "../../../images/asset-coupon.svg";

const rotate = keyframes`
  from {
    transform: rotate(0deg);

  }
  to {
    transform: rotate(360deg);

  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 652px;
  height: 100%;
  margin: 0 auto;
  color: #fff;
  font-family: "Nunito", sans-serif;

  @media (max-width: 767px) {
    max-width: 375rem;
    overflow: hidden;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: ${props => props.isWinner ? "40px" : "130px"};

  @media (max-width: 767px) {
    padding-top: ${props => props.isWinner ? "100px" : "100px"};
  }
`;

export const Confetti = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

export const JubiGif = styled.img`
  position: relative;
  z-index: 3;
  margin-bottom: 16px;
`;

export const TextBox = styled.div`
  
`;

export const Title = styled.h2`
  margin-bottom: 16px;
  color: ${props => props.isWinner ? "#FD742F" : "#3ed0f3"};
  font-size: 1.75rem;
  font-weight: 700;
  font-family: "Rubik", sans-serif;
  text-align: center;
  position: relative;
  z-index: 3;

  @media (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 28px;
  }
`;

export const WinnerText = styled.p`
  max-width: 284px;
  margin: 0 auto 16px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;
  font-size: 1.125rem;
  position: relative;
  z-index: 3;

  > strong {
    display: block;
    color: #3ED0F3;
    font-weight: 900;
  }

  @media (max-width: 767px) {
    line-height: 24px;
  }
`;

export const Text = styled.div`
  line-height: 27px;
  font-size: 1.125rem;
  text-align: center;
  position: relative;
  z-index: 3;

  > strong {
    font-weight: 700;
    color: #ffffff;
  }

  a {
    color: #ffffff; 
    text-decoration: underline;

    &:hover,
    &:visited {
      color: #fff;
    }
  }

  ${props => props.isWinner && `
    max-width: 600px;
  `};

  @media (max-width: 767px) {
    font-size: 1rem;
    line-height: 24px;
  }
`;

export const LinkClipboard = styled(Clipboard)`
  position: relative;
  color: #3ED0F3;
  font-size: 1.125rem;
  font-family: Nunito;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
`;

export const Rotator = styled.img`
  position: absolute;
  top: -8px;
  z-index: 1;
  height: 416px;
  width: 416px;
  min-height: 416px;
  min-width: 416px;
  animation: ${rotate} 40s linear infinite;

  @media (max-width: 767px) {
    top: -80px;
  }
`;

export const CouponClipboard = styled(Clipboard)`
  position: relative;
  width: 246px;
  height: 106px;
  margin-top: 16px;
  margin-bottom: 16px;
  background: url(${AssetCoupon}) center/cover no-repeat;
  border: none;
  cursor: pointer;
  z-index: 3;
`;

export const CouponText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: 1.125rem;
  font-family: Roboto;
  text-align: center;
  transform: translate(-50%, -50%);

  > strong {
    display: block;
    margin-top: 4px;
    color: #fff;
    font-weight: 700;
    font-size: 1.625rem;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  display: none;
  padding: 8px;
  font-size: 0.75rem;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  border: 8px;
  transform: translate(-50%, 0);
  transition: all 1s ease;

  ${props =>
    props.showTooltip &&
    `
    display: block;
    transform: translate(-50%, 50%);
    transition: all 1s ease;
  `};
`;
