// @flow
import React, { Component } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Bill extends Component<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Boleto</title>
        <desc>Representação do pagamento com boleto</desc>
        <g id="icon-bill">
          <path d="M56,8H8a8,8,0,0,0-8,8V48a8,8,0,0,0,8,8H56a8,8,0,0,0,8-8V16A8,8,0,0,0,56,8Zm6,40a6,6,0,0,1-6,6H8a6,6,0,0,1-6-6V16a6,6,0,0,1,6-6H56a6,6,0,0,1,6,6Z" />
          <rect x="6" y="14" width="1" height="36" />
          <rect x="9" y="14" width="1" height="36" />
          <rect x="12" y="14" width="3" height="36" rx="1" ry="1" />
          <rect x="17" y="14" width="1" height="36" />
          <rect x="20" y="14" width="3" height="36" rx="1" ry="1" />
          <rect x="25" y="14" width="3" height="36" rx="1" ry="1" />
          <rect x="30" y="14" width="1" height="36" />
          <rect x="33" y="14" width="1" height="36" />
          <rect x="43" y="14" width="1" height="36" />
          <rect x="53" y="14" width="1" height="36" />
          <path d="M57,14a1,1,0,0,0-1,1V49a1,1,0,0,0,2,0V15A1,1,0,0,0,57,14Z" />
          <rect x="36" y="14" width="5" height="36" rx="1" ry="1" />
          <rect x="46" y="14" width="5" height="36" rx="1" ry="1" />
        </g>
      </svg>
    );
  }
}

export default Bill;
