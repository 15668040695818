import styled from "styled-components";

export const Container = styled.div`
  .breadcrumbs-title {
    color: ${props => props.theme.colors.font3};
  }

  .breadcrumbs-title-nav {
    .is-active {
      a {
        color: ${props => props.theme.colors.font5};
      }
    }
    a {
      color: ${props => props.theme.colors.font3};
    }
    &-arrow {
      fill: ${props => props.theme.colors.font3};
    }
  }
`;
