// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Speaker extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Novidades</title>
        <desc>Representação de um alto-falante</desc>
        <g id="icon-speaker">
          <path d="M15,0a1,1,0,0,0-1,1v.18L4.9,3H3A3,3,0,0,0,0,6V8a3,3,0,0,0,3,3H4v4a1,1,0,0,0,2,0V11.22l8,1.6V13a1,1,0,0,0,2,0V1A1,1,0,0,0,15,0ZM4,9H3A1,1,0,0,1,2,8V6A1,1,0,0,1,3,5H4Zm2,.18V4.82l8-1.6v7.56Z" />
        </g>
      </svg>
    );
  }
}

export default Speaker;
