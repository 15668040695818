// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Chart extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Gráfico</title>
        <desc>Representação de uma linha de gráfico</desc>
        <g id="icon-chart">
          <path d="M61,10a3,3,0,0,0-2.34,4.86l-12,19.21A3.5,3.5,0,0,0,46,34a3,3,0,0,0-2.18,1L33,30.21c0-.07,0-.14,0-.21a3,3,0,0,0-6,0A3,3,0,0,0,27.74,32l-9.21,16.1A3.07,3.07,0,0,0,18,48a2.94,2.94,0,0,0-2,.79L6,43.44A3.13,3.13,0,0,0,6,43a3,3,0,1,0-3,3,2.94,2.94,0,0,0,2-.79l10,5.35A3.13,3.13,0,0,0,15,51a3,3,0,0,0,6,0,3,3,0,0,0-.74-2L29.47,33A3.07,3.07,0,0,0,30,33a3,3,0,0,0,2.18-1L43,36.79c0,.07,0,.14,0,.21a3,3,0,1,0,5.34-1.86l12-19.22A2.82,2.82,0,0,0,61,16a3,3,0,0,0,0-6ZM30,31a1,1,0,1,1,1-1A1,1,0,0,1,30,31ZM17,51a1,1,0,1,1,1,1A1,1,0,0,1,17,51ZM3,44a1,1,0,1,1,1-1A1,1,0,0,1,3,44ZM61,14a1,1,0,1,1,1-1A1,1,0,0,1,61,14ZM45,37a1,1,0,1,1,1,1A1,1,0,0,1,45,37Z" />
        </g>
      </svg>
    );
  }
}

export default Chart;
