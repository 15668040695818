import { Component } from "react";
import ReactDOM from "react-dom";
import "./fullPort.scss";

class FullPort extends Component {
  el;
  root;

  constructor(props) {
    super(props);

    this.el = document.createElement("div");
    this.root = document.getElementById("full-port");
  }

  componentDidMount() {
    this.root.appendChild(this.el);
  }

  componentWillUnmount() {
    this.root.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default FullPort;
