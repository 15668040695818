import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import classNames from "classnames";
import { Footer } from "../../../components/footer";
import Logo from "../../../images/logoAprovaBranco.svg";
import Instructions from "../instructions";

import CounterStart from "./counter";
import Icon from "../../../components/icon";
import { SimuladaoContext } from "../../../core/context";
import image from "../../../images/cerebro-barrado-na-entrada.gif";
import * as S from "./styles.js";

const SimulateStart = ({
  loading,
  startDate,
  beforeTimeSimulation,
  currentDate,
  history,
  doStartSimulation,
  rankingStartDate,
  setShowInstructions,
}) => {
  useEffect(() => {
    if (!startDate) return;
    if (currentDate === -999) return;

    const now = new Date(currentDate);
    const closedGateStart = new Date(moment(startDate).add("31", "minutes"));
    const rankingStart = new Date(moment(rankingStartDate));
  }, [startDate, currentDate]);

  const handleOpenInstructions = () => {
    setShowInstructions(true);
  };
  return (
    <S.Container>
      <Instructions
        loading={loading}
        history={history}
        isGatesOpen={false}
        startDate={startDate}
        doStartSimulation={doStartSimulation}
        isInstructionStarted={handleOpenInstructions}
      />
    </S.Container>
  );
};

export default SimulateStart;
