// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class CircleCheckTraceXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Concluído</title>
        <desc>Símbolo de concluído no centro de um círculo</desc>
        <g id="icon-check-trace">
          <path d="M32,0A32,32,0,1,0,64,32,32,32,0,0,0,32,0Zm0,62A30,30,0,1,1,62,32,30,30,0,0,1,32,62Z" />
          <path d="M48.29,20.29,26.94,41.59,15.71,30.29a1,1,0,0,0-1.42,1.42l11.94,12a1,1,0,0,0,1.42,0l22.06-22a1,1,0,0,0-1.42-1.42Z" />
        </g>
      </svg>
    );
  }
}

export default CircleCheckTraceXl;
