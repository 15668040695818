import React, { PureComponent } from "react";
import { colorsConfig } from "../../../core/avatarColors";

import NewUserAvatar from "../../../components/newUserAvatar";
import * as S from "./styles";

export class Ranking extends PureComponent {
  formatScore = (score = 0) => {
    return Number(score)
      .toFixed(1)
      .replace(".", ",");
  };

  render() {
    const { ranking = [], userLevel } = this.props;

    return (
      <S.Container>
        <S.BodyRanking>
          {ranking.map((it, index) => {
            const score = this.formatScore(it.finalScore);
            return (
              <S.Item key={`ranking-${index}`}>
                <NewUserAvatar
                  userLevel={userLevel}
                  size={64}
                  userId={it.user.id}
                  name={it.user.name}
                  colorsConfig={colorsConfig(it.user.colorAvatar)}
                  src={it.user.picture}
                />
                <S.Name>{it.user.name}</S.Name>
                <S.Points>{score === 1 ? `${score} ponto` : `${score} pontos`}</S.Points>
              </S.Item>
            );
          })}
        </S.BodyRanking>
      </S.Container>
    );
  }
}
