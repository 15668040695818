// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Rotate extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Girar</title>
        <desc>Uma seta indicando o movimento de rotacionar</desc>
        <g id="icon-rotate">
          <path d="M8,0A8,8,0,0,0,2,2.75V1A1,1,0,0,0,0,1V6A1,1,0,0,0,1,7H6A1,1,0,0,0,6,5H2.83a6,6,0,1,1,1,7.29,1,1,0,1,0-1.4,1.42A8,8,0,1,0,8,0Z" />
        </g>
      </svg>
    );
  }
}

export default Rotate;
