// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class ChatXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Chat</title>
        <desc>Representação de dois balões de fala</desc>
        <g id="icon-chat-xl">
          <path d="M58.18,24H44V7.53C44,3.38,41.39,0,38.18,0H5.82C2.61,0,0,3.38,0,7.53V25.47C0,29.62,2.61,33,5.82,33H7v6a1,1,0,0,0,.59.91,1,1,0,0,0,1.07-.16L16.38,33H20V49.47C20,53.62,22.61,57,25.82,57h21.8l7.72,6.75a1,1,0,0,0,1.07.16A1,1,0,0,0,57,63V57h1.18C61.39,57,64,53.62,64,49.47V31.53C64,27.38,61.39,24,58.18,24ZM9,32a1,1,0,0,0-1-1H5.82C3.75,31,2,28.47,2,25.47V7.53C2,4.53,3.75,2,5.82,2H38.18C40.25,2,42,4.53,42,7.53V24H25.82c-3.07,0-5.59,3.1-5.8,7H16a.5.5,0,0,0-.13,0,.78.78,0,0,0-.22,0,1.08,1.08,0,0,0-.18.11.42.42,0,0,0-.13.07L9,36.8ZM62,49.47c0,3-1.75,5.53-3.82,5.53H56a1,1,0,0,0-1,1v4.8l-6.34-5.55a.42.42,0,0,0-.13-.07,1.08,1.08,0,0,0-.18-.11.78.78,0,0,0-.22,0A.5.5,0,0,0,48,55H25.82C23.75,55,22,52.47,22,49.47V31.53c0-3,1.75-5.53,3.82-5.53H58.18C60.25,26,62,28.53,62,31.53Z" />
        </g>
      </svg>
    );
  }
}

export default ChatXl;
