
import React, { PureComponent } from "react";
import classNames from "classnames";

class Mathematics extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        {/* deve ficar comentado até a área de Matemática entrar de fato
        <title>Ícone Matemática</title> */}
        <desc>Ícone de uma calculadora</desc>
        <g id="icon-math">
          <path d="M12,16H4a2,2,0,0,1-2-2V2A2,2,0,0,1,4,0h8a2,2,0,0,1,2,2V14A2,2,0,0,1,12,16ZM9,13a1,1,0,1,0-1,1A1,1,0,0,0,9,13ZM6,13a1,1,0,1,0-1,1A1,1,0,0,0,6,13Zm6,0a1,1,0,1,0-1,1A1,1,0,0,0,12,13ZM9,10a1,1,0,1,0-1,1A1,1,0,0,0,9,10ZM6,10a1,1,0,1,0-1,1A1,1,0,0,0,6,10Zm6,0a1,1,0,1,0-1,1A1,1,0,0,0,12,10ZM9,7A1,1,0,1,0,8,8,1,1,0,0,0,9,7ZM6,7A1,1,0,1,0,5,8,1,1,0,0,0,6,7Zm6,0a1,1,0,1,0-1,1A1,1,0,0,0,12,7ZM11,2H5A1,1,0,0,0,4,3H4A1,1,0,0,0,5,4h6a1,1,0,0,0,1-1h0A1,1,0,0,0,11,2Z" />
        </g>
      </svg>
    );
  }
}

export default Mathematics;
