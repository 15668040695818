// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class ChemXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Química</title>
        <desc>Ícone de um tubo de ensaio</desc>
        <g id="icon-chem-xl">
          <path d="M63,50.77,44,20V8h4a4,4,0,0,0,0-8H16a4,4,0,0,0,0,8h4V20L1,50.77A9.14,9.14,0,0,0,5.05,63a9,9,0,0,0,4.08,1H54.87A9.15,9.15,0,0,0,64,54.84,9,9,0,0,0,63,50.77ZM21.7,21.08A2,2,0,0,0,22,20V8a2,2,0,0,0-2-2H16a2,2,0,0,1,0-4H48a2,2,0,0,1,0,4H44a2,2,0,0,0-2,2V20a2,2,0,0,0,.3,1.06L54,40H10ZM59.92,59.9A7.1,7.1,0,0,1,54.87,62H9.13A7,7,0,0,1,6,61.24a7.14,7.14,0,0,1-3.23-9.5l6-9.74h46.5l6,9.74a7.14,7.14,0,0,1-1.36,8.16Z" />
        </g>
      </svg>
    );
  }
}

export default ChemXl;
