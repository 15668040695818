// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class FlowerTwo extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 80">
        <title>animacao</title>
        <g id="flowerTwo">
          <path d="M37.28,59.09c-3.8,1.61-6.63,5.24-8.34,8-.15-5.12-.54-9.23-.75-11.1A15,15,0,0,0,42.72,41a14.81,14.81,0,0,0-1.33-6.16h0l-.07-.16,0-.1h0L41,34l-.59,0-.7,0a14.93,14.93,0,0,0-4.36.65,35.43,35.43,0,0,0-6.93-10.36l-.71-.7-.7.7a35.43,35.43,0,0,0-6.93,10.36A15.1,15.1,0,0,0,15.72,34H14.44l-.27.57A14.86,14.86,0,0,0,12.72,41,15,15,0,0,0,26.17,55.92c.19,1.65.61,5.75.76,11-1.72-2.72-4.5-6.22-8.21-7.79-6.35-2.69-14.95.51-15.31.65l-1,.37.41,1C3,61.43,6.68,69.83,13,72.52A15.25,15.25,0,0,0,19,73.59a29.09,29.09,0,0,0,8-1.27c0,1.82-.15,4.71-.27,6.62A1,1,0,0,0,27.65,80h0a1,1,0,0,0,1.07-.94c.12-2,.25-4.88.27-6.74A29.12,29.12,0,0,0,37,73.59,15.25,15.25,0,0,0,43,72.52c6.36-2.69,10-11.09,10.2-11.45l.41-1-1-.37C52.23,59.6,43.63,56.4,37.28,59.09ZM27.74,54a13,13,0,0,1,12-18,13,13,0,0,1-12,18Zm0-27.53a32.71,32.71,0,0,1,5.76,8.91A15.13,15.13,0,0,0,27.73,40,14.91,14.91,0,0,0,22,35.36,33.15,33.15,0,0,1,27.72,26.47ZM14.72,41a12.9,12.9,0,0,1,1-5h0a12.89,12.89,0,0,1,10.83,5.84,14.94,14.94,0,0,0-1,12A13,13,0,0,1,14.72,41Zm-.9,29.68c-4.42-1.87-7.53-7.2-8.66-9.41,2.38-.73,8.36-2.21,12.78-.34s7.53,7.2,8.66,9.42C24.22,71.07,18.25,72.55,13.82,70.68Zm28.36,0c-4.42,1.87-10.4.39-12.79-.34,1.14-2.21,4.24-7.53,8.67-9.41s10.4-.39,12.78.34C49.71,63.48,46.6,68.81,42.18,70.68Z" />
        </g>
      </svg>
    );
  }
}

export default FlowerTwo;
