// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Show extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone exibir</title>
        <desc>Ícone de um olho para exibir conteúdo</desc>
        <g id="icon-show">
          <path d="M0,8S3,3,8,3s8,5,8,5-3,5-8,5S0,8,0,8Zm8,3c3,0,5-3,5-3S11,5,8,5,3,8,3,8,5,11,8,11ZM8,6a2,2,0,1,0,2,2A2,2,0,0,0,8,6Z" />
        </g>
      </svg>
    );
  }
}

export default Show;
