// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class CommunityXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} viewBox="0 0 128 128">
        <title>Comunidade</title>
        <desc>Representação de um balão de fala</desc>
        <g id="icon-community-xl">
          <path d="M60,100H12A12,12,0,0,1,0,88V12A12,12,0,0,1,12,0H116a12,12,0,0,1,12,12V88a12,12,0,0,1-12,12H88v24a4,4,0,0,1-6.4,3.2Zm22.72,25.53A2,2,0,0,0,86,124V98h30a10,10,0,0,0,10-10V12A10,10,0,0,0,116,2H12A10,10,0,0,0,2,12V88A10,10,0,0,0,12,98H61Z" />
        </g>
      </svg>
    );
  }
}

export default CommunityXl;
