import React, { PureComponent } from "react";
import classNames from "classnames";

class ClapGray extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88">
        <title>Parabéns</title>
        <desc>Representação de duas mãos batendo palmas</desc>
        <g id="icon-clap-gray">
          <path
            className="cls-1"
            d="M77.15,51.22H69.54a4.69,4.69,0,0,0,0-9.38H35.32a4.5,4.5,0,0,0-.63.07H33.08L42,33a5.09,5.09,0,0,0-7.2-7.2L15,45.58a16.64,16.64,0,0,0-6.24,13v4.19A16.67,16.67,0,0,0,25.38,79.34H60.56a4.69,4.69,0,1,0,0-9.37h8.66a4.69,4.69,0,0,0,0-9.38h7.93a4.69,4.69,0,0,0,4.68-4.68h0A4.69,4.69,0,0,0,77.15,51.22Z"
          />
          <path
            className="cls-2"
            d="M62.36,36.26a4.71,4.71,0,0,0-6.63,0l6.12-6.12a4.69,4.69,0,0,0-6.63-6.63l5.61-5.61a4.71,4.71,0,0,0,0-6.63h0a4.71,4.71,0,0,0-6.63,0l-18,18h0L48.82,16.65A4.69,4.69,0,0,0,42.19,10L18,34.22a3.58,3.58,0,0,0-.4.49l-1.15,1.14V23.29a5.09,5.09,0,0,0-10.18,0V51.23A16.62,16.62,0,0,0,11,64.8l3,3a16.67,16.67,0,0,0,23.5,0l.68-.69,7.43-7.42L62.36,42.89A4.71,4.71,0,0,0,62.36,36.26Z"
          />
        </g>
      </svg>
    );
  }
}

export default ClapGray;
