// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Buy extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>Compra</title>
        <desc>Representação de um código de barras e um cartão de crédito</desc>
        <g id="icon-buy">
          <path d="M28,13V4a4,4,0,0,0-4-4H4A4,4,0,0,0,0,4V16a4,4,0,0,0,4,4v8a4,4,0,0,0,4,4H28a4,4,0,0,0,4-4V17A4,4,0,0,0,28,13ZM2,4A2,2,0,0,1,4,2H24a2,2,0,0,1,2,2V6H2ZM4,17v1a2,2,0,0,1-2-2V11H26v2H8A4,4,0,0,0,4,17ZM30,28a2,2,0,0,1-2,2H8a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2H28a2,2,0,0,1,2,2Z" />
          <path d="M14.33,17h-.66c-.37,0-.67.58-.67,1.29v8.42c0,.71.3,1.29.67,1.29h.66c.37,0,.67-.58.67-1.29V18.29C15,17.58,14.7,17,14.33,17Z" />
          <path d="M10,17H9a1.18,1.18,0,0,0-1,1.29v8.42A1.18,1.18,0,0,0,9,28h1a1.18,1.18,0,0,0,1-1.29V18.29A1.18,1.18,0,0,0,10,17Z" />
          <rect x="17" y="17" width="1" height="11" />
          <rect x="20" y="17" width="1" height="11" />
          <rect x="27" y="17" width="1" height="11" />
          <path d="M24.33,17h-.66c-.37,0-.67.58-.67,1.29v8.42c0,.71.3,1.29.67,1.29h.66c.37,0,.67-.58.67-1.29V18.29C25,17.58,24.7,17,24.33,17Z" />
        </g>
      </svg>
    );
  }
}

export default Buy;
