// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class SealXl extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg
        className={classes}
        height={height}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 36 36">
        <title>Resposta correta</title>
        <desc>Jubiluzinho representando o selo de resposta correta</desc>
        <g id="img-seal-alert" transform="translate(-17 -18.5)">
          <image
            id="Retângulo_358"
            data-name="Retângulo 358"
            width="36"
            height="36"
            transform="matrix(0.996, 0.091, -0.091, 0.996, 18.549, 16.725)"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABHNCSVQICAgIfAhkiAAACFRJREFUWIW1mGtsXFcRx39zzt27D+967fUjtuMmoU3zaEhL46IGilQ+0EBb
mqQtiapWQvARiVKkIigV4ksBAaoQRa0C3yokRElok6ZCoMAHKhCCNqlo+gxNhROldmzH9u569+7ufZzhg/Nw7LUjUhjpaq/OvP5n5szMuSt8SNp9YGQHjh+DHJNU+O2D9x2f/DD25GoV733ulo+q4W
ng9kurWkP4Ubk2/eSfvzza/L8Auve5bZvVmH0L11TxEP2kIG31VXVMRN5b4sy5rxx84LV3VvLnXQmQGnmKy6LAeRjL70VEhoChZWztWMmfWYm56ze33AVyx0oy/x3JHbsP3HLnVQHasx+L6FP/OzDz
pKo/27Mfuxy/LWPnr28ecmb1s4LctpLx+nRCfVppVi89LlH87PKBF6SUMNg4cWD8r+35C4G8uLEgrcK3UB4VIdNOIW4q06MJwawigIfBV8E4SFAiUWKjZLuFnnUWL7PsWXvBWP3qC/cfG28LaNf+kQ
cFfgrSt5yF6dGY6rgiCB1YeloWrxwhrQQNE1LWYDIeYWeK8WxM3SR0Dgo969rXjsKcwOOH9hx9BkFhQco2fWH1PhHZ1E7RxcrZdxKCaSWnlsFmioGzjto75yifLhOXmzTLAZkItpRKbF+1mi6bYcI1
qNQSmlWloySIuTxaAmngrk1vDWXf/e3Yn2Bh2Yu65Up54kRCq6p0xh59s6BjVaZn66wpFdl8w/XM1BqU6wGDpQK3bb2ObTdtYCpoUT3+Cn+pfkCzqkycSBjcskyXEbouvJpLa5K0k50ejWlVlXxs6Z
t0RP8uE8zUuW3DWr6++3Z2bt9Cf3cOsSCeYabZIkTpLeS4OdXFGtOBAM2qMj0at8WjqhdxLIC8NEJxU6mOK74TSucc4Zk5atWArcMD7Ny+lYHuAof/8RZiDd986HP0DvQQImR8DxLHpzdcS3osy75T
r1MmpDquFAe0zUGXi0fnIjJVcUuicyrBKORrYMYDGrUmonDjukGGejqZrNSJVbn/9hGGhvrwC3k6clmsgk1Zhrs72T48xI1d/Zjzm509szQRwqUIGYC7f7W1G+HWxYLBjOI5ITuXEAYhceKI4viima
6OLHd+/Ab6uwskcUIUNEjqdZKpGeLZGnErpJhKsbHUS8bOJ6M2pUtzJuy456WR3EVAnpd+QqCwUKY+Pb8TXw3pSEDBKYSx4+U3TjJZnqOQTdNfzBO2QoK5Oo25gEbQohXFVMcniWoBNk5Yk8tT9NMX
bTcqi5Mhg6YhjwPYnfu3rTciz7JojFzowHln6axCUgtphTFxHFNrtujqyHDD8ACeEdQpNuWRLeZJ59J4KYsmjqDRws9lGegqcrJWYbRWRgEvLWSLi7u53nr9nqFfGoM8wzIjZB684BmDtQYRQRGstS
QKTh2qimctvrVEtYCg1qQRCw3SzIaGci0ilxh29a/DNyu4QdJW5WlPIVrpUhQZxUtZPM/OAzPCR/pL3LRuCEUI4wQfUCNMVUN0zcfI964itcZSSpQwcYxOTUJ5jD4/y+nG3PLOhJQnjkcQ3YFIqp1M
QxI055NKeXiexfc8runtolTIEScOYb5ZpHNZBq4p0eztw61aj/Wz5Pw0IkJ6YIba2bcZqHSsBChR3MPm0APH3geeXMz1c/O/McpsTslmfFIpi7FClDhaUYxTxTEfhbDRRFyL1NhruON/QM68jkz+Cz
dxguSDNzEuYrijgACZzrY52Xd472snPYAkx/dsQ78EMniB29FjgflqmLYhPUWfzJxPPWjxwUyFcr1Jf1eeC002bEXYWoBfLJDSMu7MKySx0oocaaesLXj01TtIW49s8XJAqjobx+F34XxlvXTPsUCV
Py6GnCvNK7bEEXQZNq5ZxbZrV+NZS6MVkThFdf5JEkez3iAOGkjax+sp4nem6chBPgtdHWnyqRSl/qXzTIRXf/fQG7OwYHSIsKRT96y1BDMxiSiu4HHz8DX0RhaMUMzniOIEawzWzEdJw5hmpUZGwR
ZyGOewdj7SUeSIopjOQUOLy7u1qrSbZSzp6V5G6BwUquPKlGtyOq7jhxmGuosYERrhfNdOeRYjgoiQNEOSpIJfC7CexSCoKtUwZkKqJOk2o0PaDFdVXLuPmp51HmE9plKNODI7xqmzHp9Zfx2rijli
ERKnpLyElLWY8/edoBWhtBARMIZIhVNxgzPdZeKliWDhcF1wH5JyG0kAVm20TJxIGK80OVeuUfl7g09tvpahUieZlMGzFs8Ixgixg2YCTQf10DHTctSyjom1U5w17UteaROhF98++tiuzSNngB+ISM
dCBeMJg1s8pkdjKo0M/3y/zNS777FlYJDhQp6OjI8DKkGTc5U6E+U65yLLnFcgKlg6twWkM2WgzWBFp0CfuRiXxez7nr9xOElSPxfk7na7iZvKuZMxzdOG3EyO1YnP6kIaK4bT01Xem6gw2XC4/nX0
bxV6N1bI9UR46csvZ4oGovwkaEU/PPLF4/VlAV2g3QdGvoPKE22ZCq2qz+QbBZpjq8jZTowx1F2E5ufI9Zcprp4j19PAmHY3Yz3g4tbDhx98c2IxZ1lAI78YSQ1387Yg65eTURVq5/qZndxOwhD54r
t0ll7Hz8wipl16QNGTPsc2Hdi7tKpXBARw74Ftn1c1L60kAxDHeaKwj5Q/jefN0f6sXPConz2059iRZdlXcrZ7/8iRK3/fC6oGEbcyGPj9ob1H71pJ4Ir/fojTR9TIvqUc3XBp9imy4KNFUUXlbyJc
dpLFuUev6O9KAsvRnv2fyEYafkOFxwTJLQB6RJw8evCBo29ejd2rBnSB7nt+ZDCJ5fuIjhjM1w7uffXlD2PvP0fSp1BpHBe1AAAAAElFTkSuQmCC"
          />
        </g>
      </svg>
    );
  }
}

export default SealXl;
