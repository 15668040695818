import * as S from "../simulateQuestion/styles";
import React, { useState } from "react";

export function Alternative({ alternative, changeSelectedAnswer, isSelected, selectedAnswer, selected }) {
  const [eliminate, setEliminate] = useState(false);

  const changeEliminate = event => {
    event.stopPropagation();
    setEliminate(!eliminate);
  };
  return (
    <S.Alternative
      isSelected={isSelected}
      key={alternative.alternativeId}
      onClick={() =>
        changeSelectedAnswer(selectedAnswer !== alternative.alternativeId ? alternative.alternativeId : null)
      }>
      <S.AlternativeInput isSelected={isSelected} />
      <S.Tabbables lineThrough={eliminate}>
        <S.AlternativeText dangerouslySetInnerHTML={{ __html: alternative.text.replaceAll("{STORAGE_BASE_URL}", "https://storage.aprovatotal.com.br") }} />
      </S.Tabbables>
      <S.IconContainer onClick={changeEliminate}>
        <S.Icons selected={selected} icon={eliminate ? "circleCheck" : "wrong"} />
      </S.IconContainer>
    </S.Alternative>
  );
}
