import React, { PureComponent } from "react";
import classNames from "classnames";

class Interdisciplinary extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Interdisciplinar</title>
        <desc>Representação de dois quadrados interseccionando</desc>
        <g id="icon-inter">
          <path d="M14,0H6A2,2,0,0,0,4,2V4H2A2,2,0,0,0,0,6v8a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V12h2a2,2,0,0,0,2-2V2A2,2,0,0,0,14,0ZM10,14H2V6H5V9.25A1.75,1.75,0,0,0,6.75,11H10Zm4-4H11V6.75A1.75,1.75,0,0,0,9.25,5H6V2h8Z" />
        </g>
      </svg>
    );
  }
}

export default Interdisciplinary;
