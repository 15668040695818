// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class ReportFill extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Reportar problemas</title>
        <desc>Representação de um balão de fala com um ponto de exclamação</desc>
        <g id="icon-report-fill">
          <path d="M8,0C3.59,0,0,2.92,0,6.5S3.59,13,8,13l.57,0,2.72,2.73A1,1,0,0,0,12,16a.84.84,0,0,0,.38-.08A1,1,0,0,0,13,15V11.58A6.13,6.13,0,0,0,16,6.5C16,2.92,12.41,0,8,0ZM9,4V6A1,1,0,0,1,7,6V4A1,1,0,0,1,9,4ZM9,9A1,1,0,1,1,8,8,1,1,0,0,1,9,9Z" />
        </g>
      </svg>
    );
  }
}

export default ReportFill;
