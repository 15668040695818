import styled from "styled-components";

export const Footer = styled.footer`
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  background: #3171F1;
  box-sizing: border-box;
  padding: 8px;
  
  @media(max-width: 1023px){
    flex-direction: column;
  }

  @media (min-width: 1024px) and (max-width: 1279px) {
    flex-direction: column;
  }
`

export const Social = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  
  @media(max-width: 1023px){
    flex-direction: column;
  }
`

export const LinksLeft = styled.div`
  @media(max-width: 1023px){
    text-align: center;
  }
`

export const Link = styled.a`
  color: #FFFFFF;
  text-decoration: none;

  &:focus,
  &:hover {
    color: #FFF;
  }

  &:not(:first-child) {
    margin-left: 8px;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
  
  svg {
    fill: #FFF;
    vertical-align: middle;
  }
  
  ${({ menu }) => menu && `
    @media(max-width: 767px){
      display: block;
    }
  `}

  ${props => props.isWpp && `
    > svg {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  `};

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 0.875rem;
  }  
`

export const StoreButtons = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

export const SocialButtons = styled.div``;

export const Image = styled.img``;