import styled, { css, keyframes } from "styled-components";
import { MediaQueries } from "ratel-ui";

function warningDesktop(props) {
  return keyframes`
    0% {
      color: ${props.theme.colors.transparent};
    }
    50% {
      color: ${props.theme.colors.clear};
    }
    100% {
      color: ${props.theme.colors.transparent};
    }
  `;
}

export const Box = styled.div`
  display: flex;
  justify-content: center;
  z-index: 9;
`;

export const Item = styled.div``;

export const Text = styled.div`
  padding: 0 8px 0 8px;
  line-height: 0.875rem;
  font-size: 0.75rem;
  color: #555;

  ${props =>
    props.warning &&
    css`
      animation: ${warningDesktop} 1s infinite;
    `};

  ${MediaQueries.mobile`
    color: ${props => props.theme.colors.ebony};
  `}
`;

export const Number = styled.span`
  line-height: 1.125rem;
  font-size: 1.125rem;
  font-weight: 700;
  color:#ED2929;

  ${props =>
    props.warning &&
    css`
      animation: ${warningDesktop} 1s infinite;
    `};
`;

export const Label = styled.span`
  font-size: 1rem;
  text-align: center;
`;
