import React, { PureComponent } from "react";
import classNames from "classnames";

class InfoStroke extends PureComponent {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>?</title>
        <desc>Símbolo de interrogação</desc>
        <g id="icon-info-stroke">
          <path d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,14a6,6,0,1,1,6-6A6,6,0,0,1,8,14Z" />
          <path d="M8,3A2.94,2.94,0,0,0,5,6H7A1,1,0,1,1,8,7C7,7,6,7,6,9v1H8V9a2.94,2.94,0,0,0,3-3A2.94,2.94,0,0,0,8,3Z" />
          <circle cx="7" cy="12" r="1" />
        </g>
      </svg>
    );
  }
}

export default InfoStroke;
