// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Attach extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Anexo</title>
        <desc>Representação de uma clipe</desc>
        <g id="icon-attach">
          <path d="M8.5,16C4.15,16,3,12.08,3,10V4A3.89,3.89,0,0,1,7,0a3.89,3.89,0,0,1,4,4v6.5A2.44,2.44,0,0,1,8.5,13,2.44,2.44,0,0,1,6,10.5V4A1,1,0,0,1,8,4v6.5c0,.5.33.5.5.5a.44.44,0,0,0,.5-.5V4A1.88,1.88,0,0,0,7,2,1.89,1.89,0,0,0,5,4v6c0,.41.13,4,3.5,4S12,10.16,12,10V4a1,1,0,0,1,2,0v6C14,12.08,12.85,16,8.5,16Z" />
        </g>
      </svg>
    );
  }
}

export default Attach;
