// @flow
import React, { Component } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Card extends Component<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>Cartão de crédito</title>
        <desc>Representação do pagamento com cartão de crédito</desc>
        <g id="icon-card">
          <path d="M56,8H8a8,8,0,0,0-8,8V48a8,8,0,0,0,8,8H56a8,8,0,0,0,8-8V16A8,8,0,0,0,56,8Zm6,40a6,6,0,0,1-6,6H8a6,6,0,0,1-6-6V28H62Zm0-28H2V16a6,6,0,0,1,6-6H56a6,6,0,0,1,6,6Z" />
        </g>
      </svg>
    );
  }
}

export default Card;
