// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Unsubscriber extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 136">
        <title>Quem ainda não é jubialuno</title>
        <desc>Ícone de um estudante</desc>
        <path d="M132.85,22.4a1.53,1.53,0,0,1-1.54-1.53c0-6.16-2-8.13-8.17-8.11a1.53,1.53,0,1,1,0-3.06c6.16,0,8.13-2,8.11-8.16a1.53,1.53,0,1,1,3,0c0,6.16,2,8.13,8.17,8.11a1.53,1.53,0,0,1,0,3.06c-6.16,0-8.13,2-8.11,8.17A1.54,1.54,0,0,1,132.85,22.4Zm-2.63-11.19a7.25,7.25,0,0,1,2.59,2.57,7.25,7.25,0,0,1,2.57-2.59,7.33,7.33,0,0,1-2.59-2.57A7.33,7.33,0,0,1,130.22,11.21Z" />
        <circle className="cls-1" cx="73.03" cy="69.51" r="48" />
        <path
          className="cls-4"
          d="M94.83,31a4.41,4.41,0,1,1,4.41-4.41A4.42,4.42,0,0,1,94.83,31Zm0-5.76a1.35,1.35,0,1,0,1.35,1.35A1.35,1.35,0,0,0,94.83,25.21Z"
        />
        <path
          className="cls-5"
          d="M8.19,70a1.53,1.53,0,0,1-1.09-.45L.45,62.9a1.53,1.53,0,0,1,2.16-2.17l6.66,6.66A1.53,1.53,0,0,1,8.19,70Z"
        />
        <path
          className="cls-5"
          d="M1.53,70A1.53,1.53,0,0,1,.45,67.39L7.1,60.73A1.53,1.53,0,0,1,9.27,62.9L2.61,69.55A1.52,1.52,0,0,1,1.53,70Z"
        />
        <path d="M115.57,93.44a1.5,1.5,0,0,1-1.37,0,1.53,1.53,0,0,1-.66-2.07,49.16,49.16,0,0,0,.54-44.06A1.53,1.53,0,0,1,116.83,46a52.26,52.26,0,0,1-.57,46.81A1.49,1.49,0,0,1,115.57,93.44Z" />
        <path d="M77.05,44A14.78,14.78,0,1,1,62.28,58.78,14.8,14.8,0,0,1,77.05,44m0-3A17.78,17.78,0,1,0,94.83,58.78,17.78,17.78,0,0,0,77.05,41Z" />
        <path d="M109,106.5a1.51,1.51,0,0,1-1.5-1.42A30.55,30.55,0,0,0,87.22,78a1.5,1.5,0,0,1,1-2.83,33.58,33.58,0,0,1,22.28,29.74,1.51,1.51,0,0,1-1.42,1.58Z" />
        <path d="M45.11,106.5H45a1.51,1.51,0,0,1-1.42-1.58A33.58,33.58,0,0,1,65.89,75.18a1.5,1.5,0,0,1,1,2.83,30.51,30.51,0,0,0-20.28,27.07A1.51,1.51,0,0,1,45.11,106.5Z" />
        <path
          className="cls-5"
          d="M99.5,109.89a1.5,1.5,0,0,0-2.07-.46A47.5,47.5,0,1,1,63.14,22.69a1.47,1.47,0,0,0,1.2-1.39v0a1.45,1.45,0,0,0-1.71-1.48A50.47,50.47,0,1,0,99,112,1.49,1.49,0,0,0,99.5,109.89Z"
        />
        <path d="M122.08,136a1.53,1.53,0,0,1-1.53-1.53c0-2-.38-2.38-2.38-2.38a1.53,1.53,0,1,1,0-3.06c2,0,2.38-.38,2.38-2.38a1.53,1.53,0,0,1,3.06,0c0,2,.38,2.38,2.38,2.38a1.53,1.53,0,1,1,0,3.06c-2,0-2.38.38-2.38,2.38A1.52,1.52,0,0,1,122.08,136Z" />
      </svg>
    );
  }
}

export default Unsubscriber;
