// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Check extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
        <title>Ícone check</title>
        <desc>Ícone com o símbolo de check</desc>
        <g id="icon-check-small">
          <path d="M.29,4.71l2,2a1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,0-1.42h0a1,1,0,0,0-1.42,0L3,4.59,1.71,3.29a1,1,0,0,0-1.42,0h0A1,1,0,0,0,.29,4.71Z" />
        </g>
      </svg>
    );
  }
}

export default Check;
