// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Pencil extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} viewBox="0 0 16 16">
        <title>Exercícios</title>
        <desc>Representação de um lápis</desc>
        <g id="icon-pencil">
          <path d="M15.07,3l-2-2A3.12,3.12,0,0,0,10.82,0,3.16,3.16,0,0,0,8.58.93L.32,9.19A1.06,1.06,0,0,0,0,10v5A1.08,1.08,0,0,0,1.08,16H6a1.06,1.06,0,0,0,.77-.32l8.26-8.26A3.15,3.15,0,0,0,15.07,3ZM5.66,14H4L2,12V10.34l6-6L11.62,8Zm8-8L13,6.62,9.38,3,10,2.34a1.16,1.16,0,0,1,1.64,0l2,2A1.16,1.16,0,0,1,13.66,6Z" />
        </g>
      </svg>
    );
  }
}

export default Pencil;
