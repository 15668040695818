// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Contract extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone ocultar</title>
        <desc>Ícone de uma seta apontando para a esquerda para ocultar o menu</desc>
        <g id="icon-contract">
          <path d="M7,5H7A1,1,0,0,1,6,4V1A1,1,0,0,1,7,0h8a1,1,0,0,1,1,1V15a1,1,0,0,1-1,1H6.94a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H7a1,1,0,0,1,1,1v2h6V2H8V4A1,1,0,0,1,7,5Zm4,4a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H3.44L4.71,5.71a1,1,0,0,0,0-1.42h0a1,1,0,0,0-1.42,0l-3,3a1,1,0,0,0,0,1.42l3,3a1,1,0,0,0,1.42,0h0a1,1,0,0,0,0-1.42L3.44,9Z" />
        </g>
      </svg>
    );
  }
}

export default Contract;
