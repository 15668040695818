import styled, { css, keyframes } from "styled-components";
import InputMask from "react-input-mask";

import { Tilt } from "./index";
import BgMainMobile from "../../images/bg-main-mobile.webp";
import BgMainTablet from "../../images/bg-main-tablet.webp";
import BgMain1366 from "../../images/bg-main-1366.webp";
import BgMain1920 from "../../images/bg-main-1920.webp";

const SpinLoader = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const Section = styled.section`
  width: 100%;
  height: 604px;
  background: url(${BgMain1366}) center/cover no-repeat;

  @media (max-width: 767px) {
    height: auto;
    background: url(${BgMainMobile}) bottom/cover no-repeat, #193fb4;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    height: auto;
    background: url(${BgMainTablet}) bottom/cover no-repeat;
  }

  @media (min-width: 1920px) {
    height: 857px;
    background: url(${BgMain1920}) center/cover no-repeat;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding: 40px 8px 20px;

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    max-width: 666px;
    height: 850px;
  }

  @media (min-width: 1920px) {
    align-items: center;
    max-width: 1600px;
  }

  @media (max-width: 767px) {
    padding-bottom: 300px;
  }

  @media (max-width: 700px) {
    padding-bottom: 250px;
  }

  @media (max-width: 650px) {
    padding-bottom: 200px;
  }

  @media (max-width: 600px) {
    padding-bottom: 150px;
  }

  @media (max-width: 550px) {
    padding-bottom: 120px;
  }

  @media (max-width: 500px) {
    padding-bottom: 100px;
  }

  @media (max-width: 450px) {
    padding-bottom: 50px;
  }

  @media (max-width: 400px) {
    padding-bottom: 20px;
  }
`;

export const Logo = styled.img`
  position: absolute;
  top: 40px;
  left: 8px;
  width: 140px;

  @media (max-width: 1023px) {
    top: 16px;
    left: 16px;
    width: 16px;
  }
`;

export const Column = styled.div`
  color: #fff;
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "auto")};

  @media (max-width: 1199px) {
    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    max-width: 100%;

    ${(props) =>
      props.marginRight &&
      `
      margin-right: auto;
    `};
  }

  ${props => props.alignCenter && `
    align-self: center;
  `};
`;

export const AssetIcon = styled.img`
  margin-top: 116px;

  @media (max-width: 1199px) {
    margin-top: 24px;
  }

  @media (min-width: 1920px) {
    margin-top: 0;
  }
`;

export const Title = styled.h1`
  font-size: 3.125rem;
  font-family: Roboto;
  margin: 8px 0 24px;
  > strong {
    font-weight: 900;
    color: #fff;
  }

  @media (max-width: 767px) {
    font-size: 2rem;
    margin-bottom: 16px;
  }
`;

export const BlueText = styled.h3`
  margin-bottom: 24px;
  color: #3ed0f3;
  font-size: 1.75rem;
  line-height: 32px;
  font-family: Roboto;

  > strong {
    display: block;
    color: #3ed0f3;
    font-weight: 700;
  }

  @media (max-width: 767px) {
    font-size: 1.25rem;
    line-height: 23px;
  }
`;

export const WhiteText = styled.div`
  max-width: 480px;
  line-height: 33px;
  font-size: 1.5rem;
  font-family: "Nunito";

  > strong {
    color: #FFD600;
    font-weight: 700;
  }

  > span {
    display: block;
    margin-top: 16px;
    font-size: 0.75rem;

    > a {
      color: #fff;
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    max-width: 348px;
    margin: 0 auto;
    font-size: 1.25rem;
    line-height: 23px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    max-width: 624px;
    margin: 0 auto;

    > br {
      display: none;
    }
  }
`;

export const CardTilt = styled(Tilt)`
  width: 100%;
  max-width: 380px;
  padding: 24px;
  border-radius: 16px;
  z-index: 5;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);

  @media (max-width: 767px) {
    margin: 24px auto 321px;
    padding: 24px 8px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 24px 0 100px;
  }
`;

export const FormTitle = styled.div`
  margin-bottom: 8px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 27px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 1rem;
    line-height: 22px;
  }
`;

export const FormText = styled.div`
  font-size: 0.875rem;
  line-height: 19px;
  text-align: center;

  > strong {
    font-weight: 700;
    color: #ffffff;
  }

  > span {
    font-size: 0.75rem;
  }
`;

export const Form = styled.form`
  margin-top: ${(props) => (props.isSecondPhase ? "16px" : "24px")};
  padding: 0;
`;

export const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow: auto;
  padding: 0px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 46px;
  margin: 8px 0;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font-style: normal;
  font-family: "Nunito";
  color: #fff;
  border-radius: 30px;
  border: 1px solid #fff;
  background: transparent;

  &::placeholder {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 43px;
    font-size: 14px;
    line-height: 19px;

    &::placeholder {
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

export const CustomInput = styled(InputMask)`
  width: 100%;
  height: 46px;
  margin: 8px 0;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font-style: normal;
  font-family: "Nunito";
  color: #fff;
  border-radius: 30px;
  border: 1px solid #fff;
  background: transparent;

  &::placeholder {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 43px;
    font-size: 14px;
    line-height: 19px;

    &::placeholder {
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 46px;
  margin: 16px auto 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  font-style: normal;
  text-align: center;
  font-family: "Nunito";
  text-transform: uppercase;
  border: none;
  border-radius: 30px;
  background: #fd742f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  ${(props) =>
    props.loading &&
    css`
      span {
        display: none;
      }

      &:before {
        content: "";
        display: block;
        height: 20px;
        margin: 0 auto;
        width: 20px;
        animation: ${SpinLoader} 2s linear infinite;
        border: 3px solid #f3f3f3;
        border-top: 3px solid #ff8200;
        border-radius: 50%;
      }
    `}

  @media (max-width: 767px) {
    width: 100%;
    height: 43px;
    font-size: 14px;
    line-height: 19px;
  }
`;

export const Checkbox = styled.label`
  position: relative;
  display: block;
  height: 20px;
  margin: 16px 0 0 16px;
  padding: 0 0 0 26px;
  color: #fff;
  line-height: 20px;
  font-size: 0.75rem;
  cursor: pointer;

  > a {
    color: #fff;
    text-decoration: underline;

    &:hover,
    &:visited {
      color: #fff;
    }
  }

  span {
    color: #fff;
    text-decoration: underline;

    &:visited {
      color: #fff;
    }
  }
`;

export const FakeCheckbox = styled.span`
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 18px;

  &:after {
    border-bottom: 3px solid #fd742f;
    border-right: 3px solid #fd742f;
    content: "";
    display: none;
    height: 10px;
    left: 5px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg);
    width: 7px;
  }
`;

export const CheckboxInput = styled.input`
  position: absolute;
  left: 0;
  margin: 0;
  opacity: 0;

  &:checked {
    ~ ${FakeCheckbox} {
      &:after {
        display: inline-block;
      }
    }
  }
`;

export const CheckboxError = styled.div`
  padding: 8px 0 0 16px;
  color: #db001b;
  font-size: 0.75rem;
  @media (max-width: 1199px) {
    padding: 8px 0 0 0;
  }
`;
