// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class ClockMD extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>Desconto</title>
        <desc>Representação de um relógio</desc>
        <g id="ico-clock-md">
          <path d="M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm0,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30Z" />
          <path d="M19,15H16.57l-2.71-4.51a1,1,0,1,0-1.72,1l3,5A1,1,0,0,0,16,17h3a1,1,0,0,0,0-2Z" />
          <path d="M16,8a1,1,0,0,0,1-1V5a1,1,0,0,0-2,0V7A1,1,0,0,0,16,8Z" />
          <path d="M16,24a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V25A1,1,0,0,0,16,24Z" />
          <path d="M8,16a1,1,0,0,0-1-1H5a1,1,0,0,0,0,2H7A1,1,0,0,0,8,16Z" />
          <path d="M27,15H25a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" />
          <path d="M7.84,10.13,7,9.63a1,1,0,0,0-1,1.74l.87.5a1,1,0,1,0,1-1.74Z" />
          <path d="M26,20.63l-.87-.5a1,1,0,0,0-1,1.74l.87.5A1,1,0,0,0,26.39,22,1,1,0,0,0,26,20.63Z" />
          <path d="M11.5,23.79a1,1,0,0,0-1.37.37l-.5.87A1,1,0,0,0,10,26.39,1,1,0,0,0,11.37,26l.5-.87A1,1,0,0,0,11.5,23.79Z" />
          <path d="M22,5.61A1,1,0,0,0,20.63,6l-.5.87a1,1,0,0,0,1.74,1l.5-.87A1,1,0,0,0,22,5.61Z" />
          <path d="M6.84,20.13l-.87.5A1,1,0,0,0,5.61,22,1,1,0,0,0,7,22.37l.87-.5a1,1,0,1,0-1-1.74Z" />
          <path d="M24.66,12a1,1,0,0,0,.5-.13l.87-.5a1,1,0,0,0-1-1.74l-.87.5a1,1,0,0,0,.5,1.87Z" />
          <path d="M21.87,24.16a1,1,0,1,0-1.74,1l.5.87a1,1,0,0,0,1.37.36A1,1,0,0,0,22.37,25Z" />
          <path d="M11.37,6A1,1,0,0,0,9.63,7l.5.87a1,1,0,1,0,1.74-1Z" />
        </g>
      </svg>
    );
  }
}

export default ClockMD;
