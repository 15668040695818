import styled from "styled-components";
import { MediaQueries } from "ratel-ui";

export const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 16px;
`;

export const BodyRanking = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding-bottom: 16px;
`;

export const Item = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  max-width: calc(100% / 9);
  width: calc(100% / 9);
  padding-top: 16px;

  ${MediaQueries.mobile`
    width: calc(100% / 2);
    max-width: calc(100% / 2);
  `}
  ${MediaQueries.tablet`
    width: calc(100% / 5);
    max-width: calc(100% / 5);
  `}
  ${MediaQueries.desktop`
    width: calc(100% / 10);
    max-width: calc(100% / 10);
  `}
`;

export const Name = styled.span`
  display: block;
  margin-top: 8px;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 16px;
  color: ${props => props.theme.colors.font1};
`;

export const Points = styled.span`
  font-size: 0.75rem;
  color: ${props => props.theme.colors.font5};
`;
