import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useSimuladao } from "../utils";
import LoadingSimulated from "./Loading";
import Clock from "../components/Clock";
import * as S from "./styles";

const Redirector = (props) => {
  const { history, user } = props;
  //não excluir essa linha abaixo, nem apagar a variavel nao utilizada
  // eslint-disable-next-line no-unused-vars
  const [simuladao, setSimuladao, isFetchingSimuladao] = useSimuladao();

  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem("inscrito-simulado-super-med-i-2024"));

    if(!_user) {
      localStorage.removeItem("inscrito-simulado-super-med-i-2024")
      return history.push("/")
    }
  }, []);

  const redirectToSimuladao = () => {
    const path = "/simulado";
    history.push(path);
  };

  if (isFetchingSimuladao) {
    return (
      <S.ListContainer>
        <LoadingSimulated />
      </S.ListContainer>
    );
  }

  // SE DEIXAR ISSO PODE ACONTECER UM LOOP INFINITO QUANDO DER ERRO

  // Ja fez o fetch mas não encontrou o simuladao, retorna pra minha área
  // if (!simuladao || !simuladao.id) {
  //   history.push("/");
  //   return null;
  // }

  // Se ja começou, manda pro simuladao
  // const now = new Date(simuladao.currentDate);
  // if (new Date(simuladao.startDate) < now) {
  //   redirectToSimuladao()
  //   return null
  // }

  // Se ainda não começou, manda pro relógio
  return (
    <S.ListContainer>
      <Clock
        history={history}
        name={simuladao.name}
        slug={simuladao.slug}
        time={simuladao.startDate}
        currentDate={simuladao.currentDate}
        verifyStartedSimulation={redirectToSimuladao}
      />
    </S.ListContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.app.data.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Redirector);
