// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Close extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone fechar</title>
        <desc>Ícone de um x</desc>
        <g id="icon-x">
          <path d="M.29.29h0a1,1,0,0,0,0,1.42L6.59,8,.29,14.29a1,1,0,0,0,0,1.42h0a1,1,0,0,0,1.42,0L8,9.41l6.29,6.3a1,1,0,0,0,1.42,0h0a1,1,0,0,0,0-1.42L9.41,8l6.3-6.29a1,1,0,0,0,0-1.42h0a1,1,0,0,0-1.42,0L8,6.59,1.71.29A1,1,0,0,0,.29.29Z" />
        </g>
      </svg>
    );
  }
}

export default Close;
