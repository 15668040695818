// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class ArrowLeft extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
        <title>Ícone seta esquerda</title>
        <desc>Ícone de uma seta apontando para a esquerda</desc>
        <g id="icon-left">
          <path d="M5.29,7.71l-3-3a1,1,0,0,1,0-1.42l3-3a1,1,0,0,1,1.42,0h0a1,1,0,0,1,0,1.42L4.41,4l2.3,2.29a1,1,0,0,1,0,1.42h0A1,1,0,0,1,5.29,7.71Z" />
        </g>
      </svg>
    );
  }
}

export default ArrowLeft;
