import styled, { keyframes } from "styled-components";
const FadeIn = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

export const Timeline = styled.ul`
  align-items: center;
  background-color: #fff;
  display: flex;
  list-style: none;
  margin: 51px auto;
  position: relative;
  height: 0;
  width: fit-content;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const Item = styled.li`
  display: inline-block;
  background-color: #fff;
  border-radius: 50%;
  height: 20px;
  margin-left: 114px;
  position: relative;
  opacity: 1;
  width: 20px;
  opacity: 0.3;

  &::before {
    background-color: #fff;
    display: inline-block;
    content: "";
    left: -114px;
    height: 5px;
    opacity: 0.3;
    position: absolute;
    top: 7px;
    width: 114px;
  }

  &.active {
    opacity: 1;
    animation: ${FadeIn} 0.8s;

    &::before {
      background-color: #fff;
      content: "";
      display: inline-block;
      height: 5px;
      left: -114px;
      opacity: 1;
      position: absolute;
      top: 7px;
      width: 114px;
    }
  }
  &.firstChild {
    margin-left: 56px;
    opacity: 1;
    &::before {
      background-color: #fff;
      content: "";
      display: inline-block;
      height: 5px;
      left: -56px;
      opacity: 1;
      position: absolute;
      top: 7px;
      width: 56px;
    }
  }
  /* &.active { */
  &.lastChild {
    margin-left: 114px;
    opacity: 1;
    &::after {
      background-color: #fff;
      content: "";
      display: inline-block;
      height: 5px;
      left: -114px;
      opacity: 1;
      position: absolute;
      top: 7px;
      width: 114px;
    }
  }
`;

export const StepNumber = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  color: #555555;
  display: flex;
  height: 39px;
  justify-content: center;
  margin: 16px auto 24px;
  position: relative;
  width: 39px;
`;
