// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class TextMd extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <title>Texto</title>
        <desc>Representação de linhas de texto</desc>
        <g id="icon-text-md">
          <path d="M1,3H31a1,1,0,0,0,0-2H1A1,1,0,0,0,1,3Z" />
          <path d="M31,5H1A1,1,0,0,0,1,7H31a1,1,0,0,0,0-2Z" />
          <path d="M1,11H21a1,1,0,0,0,0-2H1a1,1,0,0,0,0,2Z" />
          <path d="M31,13H1a1,1,0,0,0,0,2H31a1,1,0,0,0,0-2Z" />
          <path d="M31,17H1a1,1,0,0,0,0,2H31a1,1,0,0,0,0-2Z" />
          <path d="M1,23H21a1,1,0,0,0,0-2H1a1,1,0,0,0,0,2Z" />
          <path d="M31,25H1a1,1,0,0,0,0,2H31a1,1,0,0,0,0-2Z" />
          <path d="M21,29H1a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z" />
        </g>
      </svg>
    );
  }
}

export default TextMd;
