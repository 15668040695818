// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
    height: number,
    width: number,
    className: any
};

class ToggleArea extends PureComponent<Props> {
    render() {
        const { height, width, className } = this.props;
        const classes = classNames("icone", className);

        return (
            <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <title>Trocar Area Simuladão</title>
                <desc>Três linhas grossas</desc>
                <g id="Grupo_392" transform="translate(-173.5 -668.5)">
                    <g id="Linha_166">
                        <path
                            d="M187.8,678.6C187.8,678.6,187.8,678.6,187.8,678.6l-10.4-0.1c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c0,0,0,0,0,0l10.3,0.1
              c1.1,0,2,0.9,2,2C189.8,677.7,188.9,678.6,187.8,678.6z"
                        />
                    </g>
                    <g id="Linha_168">
                        <path d="M190.5,672.5h-15c-1.1,0-2-0.9-2-2s0.9-2,2-2h15c1.1,0,2,0.9,2,2S191.6,672.5,190.5,672.5z" />
                    </g>
                    <g id="Linha_167">
                        <path d="M185.8,684.6h-6.3c-1.1,0-2-0.9-2-2s0.9-2,2-2h6.3c1.1,0,2,0.9,2,2S186.9,684.6,185.8,684.6z" />
                    </g>
                </g>
            </svg>
        );
    }
}

export default ToggleArea;
