import { useEffect, useState } from "react";
import { useServerTime, getServerTime } from "../../utils";

const intervalTime = 1000;

function useCountdown(endDate) {
  const [serverTime, setServerTime] = useServerTime();

  // const [timeLeft, setTimeLeft] = useState(new Date(endDate) - new Date(getCurrentDate(previousDate, startDate)));
  const [timeLeft, setTimeLeft] = useState(-999);

  const resetServerTime = async (force, retryNumber = 0) => {
    if (force || document.visibilityState === "visible") {
      const stime = await getServerTime();
      if (typeof stime === "number") {
        setServerTime(stime);
      } else if (retryNumber < 10) {
        resetServerTime(true, ++retryNumber);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", resetServerTime, false);

    return () => {
      document.removeEventListener("visibilitychange", resetServerTime);
    };
  }, []);

  useEffect(() => {
    let interval;

    if (serverTime) {
      setTimeLeft(new Date(endDate) - new Date(serverTime));

      interval = setInterval(() => {
        setTimeLeft(current => {
          if (current <= 0) {
            clearInterval(interval);
            return 0;
          }
          return current - intervalTime;
        });
      }, intervalTime);
    }
    return () => {
      clearInterval(interval);
    };
  }, [serverTime]);

  // useEffect(() => {
  //   const newTimeLeft = new Date(endDate) - new Date(getCurrentDate(previousDate, startDate));

  //   if (newTimeLeft - timeLeft > 1000) {
  //     setTimeLeft(newTimeLeft);
  //   }
  // }, [endDate, previousDate, startDate]);

  return convertMS(timeLeft);
}

// function getCurrentDate(previous, startDate) {
//   const now = new Date();
//   let diff = now.getTime() - previous.getTime();
//   const timerDiff = diff < 1000 ? 1050 : diff;
//   startDate = new Date(startDate);
//   return new Date(startDate.valueOf() + timerDiff);
// }

function convertMS(milliseconds) {
  if (milliseconds === -999) {
    return {
      loading: true,
      isFinished: false
    };
  }

  let day, hour, minute, seconds;
  seconds = Math.floor(milliseconds / 1000);
  minute = Math.floor(seconds / 60);
  seconds = Math.max(0, seconds % 60);
  hour = Math.floor(minute / 60);
  minute = Math.max(0, minute % 60);
  day = Math.max(0, Math.floor(hour / 24));
  hour = Math.max(0, hour % 24);
  return {
    loading: false,
    days: day.toString().padStart(2, "0"),
    hours: hour.toString().padStart(2, "0"),
    minutes: minute.toString().padStart(2, "0"),
    seconds: seconds.toString().padStart(2, "0"),
    milliseconds: milliseconds,
    isFinished: Number(day) === 0 && Number(hour) === 0 && Number(minute) === 0 && Number(seconds) === 0,
    alert30min: (Number(minute) === 30 && Number(seconds) === 0) || (Number(minute) === 0 && Number(seconds) === 0),
    alert15min: Number(hour) === 0 && Number(minute) === 16 && Number(seconds) === 0,
    alert1min: Number(hour) === 0 && Number(minute) === 0 && seconds === 59
  };
}

export default useCountdown;
