// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Expand extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Ícone expandir</title>
        <desc>Ícone de uma seta apontando para a direita para expandir o menu</desc>
        <g id="icon-expand">
          <path d="M9,11H9a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H9.06a1,1,0,0,1,1,1V4A1,1,0,0,1,9,5H9A1,1,0,0,1,8,4V2H2V14H8V12A1,1,0,0,1,9,11ZM5,7A1,1,0,0,0,4,8H4A1,1,0,0,0,5,9h7.56l-1.27,1.29a1,1,0,0,0,0,1.42h0a1,1,0,0,0,1.42,0l3-3a1,1,0,0,0,0-1.42l-3-3a1,1,0,0,0-1.42,0h0a1,1,0,0,0,0,1.42L12.56,7Z" />
        </g>
      </svg>
    );
  }
}

export default Expand;
