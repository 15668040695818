// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Text extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Texto</title>
        <desc>Representação de um parágrafo de texto</desc>
        <g id="icon-text">
          <path d="M1,2H15a1,1,0,0,0,0-2H1A1,1,0,0,0,1,2Z" />
          <path d="M15,4H1A1,1,0,0,0,1,6H15a1,1,0,0,0,0-2Z" />
          <path d="M15,8H1a1,1,0,0,0,0,2H15a1,1,0,0,0,0-2Z" />
          <path d="M10,12H1a1,1,0,0,0,0,2h9a1,1,0,0,0,0-2Z" />
        </g>
      </svg>
    );
  }
}

export default Text;
