// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Video extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Resolução em vídeo</title>
        <desc>Representação de uma câmera de vídeo</desc>
        <g id="icon-video">
          <path d="M14.75,4a1.24,1.24,0,0,0-.56.13L13,4.7A2,2,0,0,0,11,3H2A2,2,0,0,0,0,5v6a2,2,0,0,0,2,2h9a2,2,0,0,0,2-1.7l1.22.57a1.24,1.24,0,0,0,.56.13A1.22,1.22,0,0,0,16,10.82V5.18A1.22,1.22,0,0,0,14.75,4ZM11,11H2V5h9v6Zm3-1.43L13,9.1V6.9l1-.47Z" />
        </g>
      </svg>
    );
  }
}

export default Video;
