// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class FlowerOne extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 80">
        <title>animacao</title>
        <g id="flowerOne">
          <path d="M36.07,49.08a57.31,57.31,0,0,1-8.19,2.77,16.94,16.94,0,0,0,5.48-16.37,1,1,0,0,0-1.77-.43,16.74,16.74,0,0,1-6,4.56,14.46,14.46,0,0,0,3.91-7.42c.63-3.73-.58-7.53-3.59-11.29a1,1,0,0,0-1-.35,1,1,0,0,0-.74.77,15.06,15.06,0,0,1-2.67,5.81C22.67,22.65,23.05,14,12.6,6.68l0,0-.14-.07-.14,0,0,0H12l-.14,0-.14,0a.61.61,0,0,0-.14.07l-.08,0,0,0a.52.52,0,0,0-.11.1.57.57,0,0,0-.11.11l0,0c-7,10.7-3.45,18.56-.73,22.29a15.12,15.12,0,0,1-4.62-4.4,1,1,0,0,0-1-.43,1,1,0,0,0-.81.69c-1.4,4.6-1.12,8.58.84,11.82a14.47,14.47,0,0,0,6.37,5.44,16.71,16.71,0,0,1-7.22-2,1,1,0,0,0-1.49,1.06A16.93,16.93,0,0,0,13.7,54.57,57.39,57.39,0,0,1,5.06,55a1,1,0,0,0-.92.55,1,1,0,0,0,.11,1.07c4.14,5.25,8.2,7.92,12.07,7.92h.23A8.19,8.19,0,0,0,21.2,63a100.35,100.35,0,0,1,.42,16A1,1,0,0,0,22.54,80h.08a1,1,0,0,0,1-.92,101.73,101.73,0,0,0-.49-16.94,9,9,0,0,0,3,.54,8,8,0,0,0,2.78-.49c3.7-1.36,6.55-5.36,8.49-11.9a1,1,0,0,0-.29-1A1,1,0,0,0,36.07,49.08ZM16.47,62.56c-2.85.08-6-1.83-9.29-5.52,3.06,0,8.4-.31,10.84-1.63a1,1,0,0,0,.5-1.08,1,1,0,0,0-.88-.79C17.22,53.5,8,52.46,5,43c2.41,1,6.64,2.16,10.61.59a1,1,0,0,0,.63-1,1,1,0,0,0-.81-.89c-.06,0-6.08-1.21-8.86-5.82a11.33,11.33,0,0,1-1.12-8.25c1.64,1.87,4.62,4.61,7.9,4.26a1,1,0,0,0,.53-1.76c-.39-.32-8.89-7.57-2.36-19.84l5.77,30.09,3.83,20A6.54,6.54,0,0,1,16.47,62.56Zm11.8-2.26A6.54,6.54,0,0,1,23.1,60l-1-5.22L13.5,9.9c10.61,9,5.39,18.87,5.15,19.3a1,1,0,0,0,.09,1.11,1,1,0,0,0,1.06.34c3.17-.89,4.93-4.54,5.75-6.88a11.27,11.27,0,0,1,2,8.08c-.88,5.31-6,8.65-6.07,8.68A1,1,0,0,0,22,42.38h0c4.27,0,7.76-2.68,9.64-4.48.77,9.87-7.46,14.25-7.83,14.45a1,1,0,0,0-.52,1.06,1,1,0,0,0,.86.82c2.75.33,7.82-1.4,10.67-2.5C33.17,56.37,30.92,59.31,28.27,60.3Z" />
        </g>
      </svg>
    );
  }
}

export default FlowerOne;
