// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Min extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Minimizar tela</title>
        <desc>Duas setas em diagonal apontando para o centro</desc>
        <g id="icon-min">
          <path d="M6,9H2a1,1,0,0,0-1,1H1a1,1,0,0,0,1,1l1.57,0L.29,14.29a1,1,0,0,0,1.42,1.42L5,12.43,5,14a1,1,0,0,0,1,1H6a1,1,0,0,0,1-1V10A1,1,0,0,0,6,9Z" />
          <path d="M15.71.29a1,1,0,0,0-1.42,0L11,3.57,11,2a1,1,0,0,0-1-1h0A1,1,0,0,0,9,2V6a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1L12.43,5l3.28-3.27A1,1,0,0,0,15.71.29Z" />
        </g>
      </svg>
    );
  }
}

export default Min;
