import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.h2`
  color: #FD742F;
  font-family: "Roboto", sans-serif;
  font-size: 1.75rem;
  font-weight: 900;
  margin: 0 0 16px;

  @media (max-width: 767px) {
    max-width: 250px;
    font-size: 1.5rem;
    line-height: 28px;
  }
`;

export const Text = styled.p`
  margin-bottom: 16px;
  color: #001036;
  font-size: 1.25rem;
  line-height: 27px;
  font-family: "Nunito", sans-serif;

  > strong {
    color: #001036;
    font-weight: 700;
  }

  > span {
    display: block;
    margin-top: 32px;
  }

  @media (max-width: 767px) {
    font-size: 1rem;
    line-height: 22px;
    text-align: center;

    > br {
      display: none;
    }
  }
`;