// @flow
import React, { PureComponent } from "react";
import classNames from "classnames";

type Props = {
  height: number,
  width: number,
  className: any
};

class Go extends PureComponent<Props> {
  render() {
    const { height, width, className } = this.props;
    const classes = classNames("icone", className);

    return (
      <svg className={classes} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <title>Avançar</title>
        <desc>Representação de uma seta apontando para a direita</desc>
        <g id="icon-go">
          <path d="M12.57,7,7.29,1.71A1,1,0,0,1,7.29.3h0A1,1,0,0,1,8.7.3l7,7a1,1,0,0,1,0,1.41l-7,7a1,1,0,0,1-1.41,0h0a1,1,0,0,1,0-1.41L12.57,9H1A1,1,0,0,1,0,8H0A1,1,0,0,1,1,7Z" />
        </g>
      </svg>
    );
  }
}

export default Go;
